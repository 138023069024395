const Menu = () => {
  return (
    <div className="w-6 h-6">
      <svg
        className="w-full h-full"
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M16 18V20H5V18H16ZM21 11V13H3V11H21ZM19 4V6H8V4H19Z" fill="black" />
      </svg>
    </div>
  );
};
export default Menu;
