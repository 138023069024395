import { DEPOSIT_PAYMENT_METHOD_KEY, DEPOSIT_REDIRECT_URL } from 'lib/constants/accounts/deposit';
import {
  SEND_FROM_AMOUNT_KEY,
  SEND_FROM_CURRENCY_KEY,
  SEND_PAYMENT_METHOD_KEY,
  SEND_TO_AMOUNT_KEY,
  SEND_TO_CURRENCY_KEY,
} from 'lib/constants/accounts/send';

export const ACCOUNTS_SLICE_REDUCER_PATH = 'account' as const;
export const PAYMENT_METHOD_DURATION_KEY = 'duration' as const;
export const SEND_PAYMENT_STEP_KEY = 'send_payment_step' as const;
export const SEND_PAYMENT_INPUT_VALUES = 'send_payment_input_values' as const;
export const VERIFIED_BANK_ACCOUNT = 'verified_bank' as const;
export const SEND_AMOUNT_VALUES = 'send_amount_values' as const;
export const SELECTED_BANK = 'selectedBank' as const;
export const SECOND_SELECT_DATA = 'secondSelectData' as const;
export const IS_MUTATION_PENDING = 'isMutationPending' as const;
export const IS_TAGS_PENDING = 'isTagsPending' as const;
export const NOVATAG_USERS = 'novatag_users' as const;
export const FINALIZE_SEND_PAGE = 'finalizeSendPage' as const;
export const SAVE_TO_BENEFICIARY = 'saveToBeneficiary' as const;
export const IS_BENEFICIARY = 'isBeneficiary' as const;
export const IS_ACTIVE = 'isActive' as const;
export const INACTIVE_MODAL_IS_OPEN = 'inactiveModalIsOpen' as const;

export type SendPaymentInputValues = {
  [key: string]: string; // or a more specific type for the values
};

export type SendAmountValue = {
  [SEND_FROM_AMOUNT_KEY]: number;
  [SEND_FROM_CURRENCY_KEY]: string;
  [SEND_TO_AMOUNT_KEY]: number;
  [SEND_TO_CURRENCY_KEY]: string;
  [SEND_PAYMENT_METHOD_KEY]: string;
};

export const accountsSliceReducerInitialState = {
  [IS_ACTIVE]: false,
  [DEPOSIT_PAYMENT_METHOD_KEY]: '',
  [DEPOSIT_REDIRECT_URL]: '',
  [PAYMENT_METHOD_DURATION_KEY]: '',
  [SEND_PAYMENT_STEP_KEY]: 0,
  [SEND_PAYMENT_METHOD_KEY]: '',
  [SEND_PAYMENT_INPUT_VALUES]: {},
  [VERIFIED_BANK_ACCOUNT]: '',
  [SELECTED_BANK]: '',
  [IS_MUTATION_PENDING]: false,
  [IS_TAGS_PENDING]: false,
  [SECOND_SELECT_DATA]: [{ value: '', label: '', code: '' }],
  [NOVATAG_USERS]: [{ avatar: '', first_name: '', last_name: '', username: '' }],
  [FINALIZE_SEND_PAGE]: 0,
  [SAVE_TO_BENEFICIARY]: true,
  [IS_BENEFICIARY]: false,
  [INACTIVE_MODAL_IS_OPEN]: false,
  [SEND_AMOUNT_VALUES]: {
    [SEND_FROM_AMOUNT_KEY]: 0,
    [SEND_FROM_CURRENCY_KEY]: '',
    [SEND_TO_AMOUNT_KEY]: 0,
    [SEND_TO_CURRENCY_KEY]: '',
    [SEND_PAYMENT_METHOD_KEY]: '',
  },
};
