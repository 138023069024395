import { useFormik } from 'formik';
import {
  LOGIN_EMAIL_KEY,
  VERIFY_LOGIN_OTP_KEY,
  VERIFY_LOGIN_TYPE_KEY,
} from 'lib/constants/auth/login';
import { useNavigate } from 'react-router-dom';
import { ACCOUNTS_ROUTE } from 'routes/path';
import { useVerifyLogin } from 'services/auth/login';
import { useAppSelector } from 'store';
import { LOGIN_SLICE_REDUCER_PATH } from 'store/slices/login/constants';
import { object, string } from 'yup';

export default function useVerifyLoginForm() {
  const { mutate, error, isError, isPending } = useVerifyLogin();
  const navigate = useNavigate();
  const { [LOGIN_EMAIL_KEY]: email } = useAppSelector((state) => state[LOGIN_SLICE_REDUCER_PATH]);

  const formik = useFormik({
    initialValues: {
      [LOGIN_EMAIL_KEY]: email,
      [VERIFY_LOGIN_OTP_KEY]: '',
      [VERIFY_LOGIN_TYPE_KEY]: 'account_authorize',
    },
    onSubmit: (values) => {
      mutate(values, {
        onSuccess: () => navigate(ACCOUNTS_ROUTE),
      });
    },
    validationSchema: object().shape({
      [VERIFY_LOGIN_OTP_KEY]: string()
        .min(4, 'Invalid Token')
        .max(4, 'Invalid Token')
        .required('Token cannot be empty'),
    }),
  });

  function getInputProps(id: keyof typeof formik.values) {
    return {
      ...formik.getFieldHelpers(id),
      ...formik.getFieldMeta(id),
      ...formik.errors,
    };
  }

  return Object.freeze({
    onSubmit: formik.handleSubmit,
    getInputProps,
    setValue: formik.setFieldValue,
    isValid: formik.isValid,
    errors: formik.errors,
    verifyError: error,
    isVerifyError: isError,
    isVerifyPending: isPending,
    values: formik.values,
  });
}
