import Chip from 'assets/icons/chip';
import { useAppSelector } from 'store';
import { ACTIVE_CARD_BRAND, CARD_SLICE_REDUCER_PATH } from 'store/slices/cards/constants';

const EmptyCard = ({ background, type }: { background: string; type: 'physical' | 'virtual' }) => {
  const { [ACTIVE_CARD_BRAND]: selectedCard } = useAppSelector(
    (state) => state[CARD_SLICE_REDUCER_PATH]
  );

  return (
    <div style={{ background: background }} className="p-5 rounded-2xl text-white w-80 md:w-96">
      <div className="justify-between items-center flex w-full mb-6">
        <p className="text-xs md:text-base">Card Label</p>
        <p className="text-xs md:text-base italic">
          {type === 'physical' && 'Physical'} {type === 'virtual' && 'Virtual'} card
        </p>
      </div>
      <Chip />
      <div className="flex justify-between items-center w-full mt-10 md:mt-16">
        <p className="text-sm md:text-lg">Cardholder name</p>
        {selectedCard && (
          <img
            src={`https://res.cloudinary.com/platnova/assets/payment_methods/${selectedCard.id}.svg`}
            alt="gg"
            className="w-10 h-max"
          />
        )}
      </div>
    </div>
  );
};

export default EmptyCard;
