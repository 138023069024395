export const DEPOSIT_PAYMENT_METHOD_KEY = 'payment_option' as const;
export const DEPOSIT_REDIRECT_URL = 'url' as const;
export const DEPOSIT_BANK_TRANSFER_KEY = 'bank_transfer' as const;
export const DEPOSIT_VIRTUAL_BANK_ACCOUNT_KEY = 'v-bank' as const;
export const DEPOSIT_USSD_KEY = 'ussd' as const;
export const DEPOSIT_OTC_KEY = 'otc_deposit' as const;
export const DEPOSIT_PAYPAL_KEY = 'paypal' as const;
export const DEPOSIT_ZELLE_KEY = 'zelle' as const;
export const DEPOSIT_REVOLUT_KEY = 'revolut' as const;
export const DEPOSIT_CASHAPP_KEY = 'cashapp' as const;
export const DEPOSIT_OTC_PAYOUT_KEY = 'otc_payout' as const;
export const DEPOSIT_MOBILE_MONEY_KEY = 'mobile_money' as const;
export const DEPOSIT_MOBILE_MONEY_GHANA_KEY = 'mobilemoneyghana' as const;
export const DEPOSIT_CARD_KEY = 'card' as const;
export const DEPOSIT_CRYPTO_KEY = 'crypto' as const;
export const DEPOSIT_AMOUNT_KEY = 'amount' as const;
export const DEPOSIT_CURRENCY_KEY = 'currency' as const;
export const DEPOSIT_PAYMENT_OPTION_KEY = 'payment_option' as const;
export const DEPOSIT_PLATFORM_KEY = 'platform' as const;
export const CREATE_BANK_ACCOUNT_ID_NUMBER_KEY = 'id_number' as const;
export const CREATE_BANK_ACCOUNT_CURRENCY_KEY = 'currency' as const;
export const DEPOSIT_SUCCESS_URL_KEY = 'success_url' as const;
export const DEPOSIT_CANCEL_URL_KEY = 'cancel_url' as const;
