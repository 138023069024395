import {
  CHANGE_PASSWORD_NEW_PASSWORD_ID,
  CHANGE_PASSWORD_OLD_PASSWORD_ID,
} from 'lib/constants/changePassword';
import http from 'services/http';
import { GeneralApiResponse } from 'types';

export const postCreatePin = async (data: { pin: string }): Promise<GeneralApiResponse<object>> => {
  const res = await http.post('/auth/authorize/pin/create', data);
  return res.data;
};

export const postAuthorizePin = async (data: {
  pin: string;
}): Promise<GeneralApiResponse<object>> => {
  const res = await http.post('/auth/authorize/pin', data);
  return res.data;
};

export const patchChangePassword = async (data: {
  [CHANGE_PASSWORD_OLD_PASSWORD_ID]: string;
  [CHANGE_PASSWORD_NEW_PASSWORD_ID]: string;
}): Promise<GeneralApiResponse<object>> => {
  const res = await http.patch('/accounts/password', data);
  return res.data;
};
