import Accounts from 'assets/icons/accounts';
import Cards from 'assets/icons/cards';
import Lifestyle from 'assets/icons/lifestyle';
import Refill from 'assets/icons/refill';
import Settings from 'assets/icons/settings';
import Transactions from 'assets/icons/transactions';
import Vault from 'assets/icons/vault';
import FullLogo from 'assets/logo/platnova-logo.png';
import Avatar from 'components/avatar';
import { cn } from 'lib/utils';
import { ChevronRight } from 'lucide-react';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import {
  ACCOUNT_DETAILS_ROUTE,
  ACCOUNTS_ROUTE,
  CARDS_ROUTE,
  SETTINGS_ROUTE,
  TRANSACTIONS_ROUTE,
} from 'routes/path';
import { useGetAccount } from 'services/account';
import { useAppSelector } from 'store';
import { truncateText } from 'utils/numberFormat';

import 'react-loading-skeleton/dist/skeleton.css';

import SidebarLink from './sidebarLink';
import Logo from '../../../../public/icons/favicon.svg';

const SideBar = () => {
  const { sideBarIsCollapsed } = useAppSelector((state) => state.layout);

  const { data, isPending } = useGetAccount();

  const firstName = data?.data.first_name;
  const lastName = data?.data.last_name;
  const fullName = `${firstName} ${lastName}`;

  return (
    <div
      className={cn(
        'w-full scroll-component relative h-screen overflow-y-scroll overflow-x-hidden flex flex-col space-y-14 bg-[#FAFAFA4D] backdrop-blur-[150px]',
        !sideBarIsCollapsed && 'p-9 items-start',
        sideBarIsCollapsed && 'py-9 px-3 items-center'
      )}
    >
      {!sideBarIsCollapsed ? (
        <img src={FullLogo} alt="logo" className="h-[1.625rem] w-max" />
      ) : (
        <img src={Logo} alt="logo" className="h-[1.625rem] w-max mx-auto" />
      )}

      <div className="h-full flex flex-col justify-between w-full">
        <div className="space-y-2">
          <SidebarLink icon={<Accounts />} route={ACCOUNTS_ROUTE} text="Accounts" />
          <SidebarLink icon={<Cards />} route={CARDS_ROUTE} text="Cards" />
          <SidebarLink comingSoon icon={<Refill />} route="#" text="Refill" />
          <SidebarLink comingSoon icon={<Vault />} route="#" text="Vaults" />
        </div>

        <div className="space-y-2">
          <SidebarLink icon={<Transactions />} route={TRANSACTIONS_ROUTE} text="Transactions" />
          <SidebarLink comingSoon icon={<Lifestyle />} route="#" text="Lifestyle" />
        </div>

        <div className="space-y-2">
          <SidebarLink icon={<Settings />} route={SETTINGS_ROUTE} text="Settings" />
          {/* <SidebarLink comingSoon icon={<Support />} route="#" text="Support" /> */}
          {!isPending && data ? (
            <Link
              to={ACCOUNT_DETAILS_ROUTE}
              className={`border rounded-lg py-3 flex ${
                !sideBarIsCollapsed
                  ? 'px-3 space-x-2 -ml-2 justify-between w-[14rem]'
                  : 'justify-center'
              } bg-white items-center`}
            >
              <Avatar avatar={data.data.preference.avatar} className="w-7 h-7 my-auto" />
              {!sideBarIsCollapsed && (
                <div className="flex space-x-3">
                  <div className="text-start">
                    <p className="text-xs capitalize">{truncateText(fullName, 10)}</p>
                    <p className="text-[0.625rem] text-grey-text">
                      {truncateText(data.data.email, 20)}
                    </p>
                  </div>
                  <ChevronRight size={20} />
                </div>
              )}
            </Link>
          ) : (
            <Skeleton className="h-[3.538rem] min-w-[13.5rem]" containerClassName="w-full" />
          )}
        </div>
      </div>
    </div>
  );
};

export default SideBar;
