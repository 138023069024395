import {
  FORGOT_PASSWORD_EMAIL_KEY,
  FORGOT_PASSWORD_OTP_KEY,
} from 'lib/constants/auth/forgotPassword';

export const FORGOT_PASSWORD_SLICE_REDUCER_PATH = 'forgot-password' as const;

export const forgotPasswordSliceReducerInitialState = {
  [FORGOT_PASSWORD_EMAIL_KEY]: '',
  [FORGOT_PASSWORD_OTP_KEY]: '',
};
