/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import Flag from 'components/flag';
import InputLabel from 'components/Inputs/inputLabel';
import { AnimatePresence, motion } from 'framer-motion';
import useDisclosure from 'hooks/useDisclosure';
import { cn } from 'lib/utils';
import { ChevronDown } from 'lucide-react';
import { useEffect } from 'react';
import { NumericFormat } from 'react-number-format';
import { useGetConfigurations } from 'services/configurations';
import { useGetWallets } from 'services/wallet';
import { useAppDispatch, useAppSelector } from 'store';
import {
  RECIPIENT_COUNTRY_KEY,
  RECIPIENT_CURRENCY_KEY,
  WALLET_SLICE_REDUCER_PATH,
} from 'store/slices/wallet/constants';
import { setRecipientCountry, setRecipientCurrency } from 'store/slices/wallet/walletSlice';
import { Configuration, RecipientCurrenciesValueType } from 'types/configuration';

import RecipientInputModal from './recipientInputModal';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  variant?: 'primary' | 'convert';
  touched?: boolean;
  error?: string;
  labelClassName?: string;
  label?: string;
  id: string;
  type?: 'password' | 'text' | 'tel'; // Ensure only supported types are listed
  inputClassName?: string;
  containerClassName?: string;
  initialValue?: string;
  initialTouched?: boolean;
  initialError?: string;
  eagerError?: boolean;
  helperText?: string;
  chosenWallet?: number;
  toAccount?: boolean;
  defaultValue?: string | number | undefined; // Ensure defaultValue type is compatible
}

const RecipientInput = ({
  id,
  className,
  labelClassName,
  label,
  required,
  touched,
  error,
  helperText,
  value,
  eagerError,
  ...rest
}: InputProps) => {
  delete rest.initialError;
  delete rest.initialTouched;
  delete rest.initialValue;

  const { data: wallets } = useGetWallets();
  const { [RECIPIENT_CURRENCY_KEY]: recipientCurrency, [RECIPIENT_COUNTRY_KEY]: recipientCountry } =
    useAppSelector((state) => state[WALLET_SLICE_REDUCER_PATH]);
  const dispatch = useAppDispatch();
  const { data: configurations } = useGetConfigurations();
  const recipientCurrencies = configurations?.data.find(
    (x): x is Configuration<RecipientCurrenciesValueType> => x.name === 'recipient_currencies'
  )?.value;

  const activeCountry = recipientCurrencies?.find((x) => x.country.code === recipientCountry);

  const {
    isOpen: isSelectDepositWalletModalOpen,
    open: openSelectDepositWalletModal,
    close: closeSelectDepositWalletModal,
  } = useDisclosure();

  useEffect(() => {
    recipientCurrencies &&
      (!recipientCurrency && dispatch(setRecipientCurrency(recipientCurrencies[0].type)),
      !recipientCountry && dispatch(setRecipientCountry(recipientCurrencies[0].country.code)));
  }, [dispatch, recipientCountry, recipientCurrencies, recipientCurrency]);

  return (
    <div className="w-full">
      <div className="flex flex-col gap-2 mb-1">
        {((label && !!label.length) || (helperText && !!helperText.length)) && (
          <div className="flex flex-col items-start justify-center gap-1">
            {label && !!label.length && (
              <InputLabel
                className={cn('text-[0.625rem] md:text-sm font-normal', labelClassName)}
                id={id}
                label={label}
              />
            )}
            {helperText && !!helperText.length && (
              <label htmlFor={id} className="text-red-500 text-xs 2xl:text-sm font-medium">
                {helperText}
              </label>
            )}
          </div>
        )}
        {wallets && wallets.data.length && (
          <div>
            <div className="bg-primary-grey flex rounded-[10px] border-2 border-transparent focus-within:border-platnova-purple">
              <button
                type="button"
                onClick={openSelectDepositWalletModal}
                className="flex items-center space-x-2 py-2 xl:py-5 border-r px-2 xl:px-5"
              >
                <Flag
                  code={activeCountry ? activeCountry.country.code : recipientCountry}
                  className="w-3 h-3 xl:w-6 xl:h-6"
                />
                <p className="text-xs md:text-base">
                  {activeCountry
                    ? activeCountry.type.toUpperCase()
                    : recipientCurrency.toUpperCase()}
                </p>
                <ChevronDown className="w-3 h-3 xl:w-6 xl:h-6" />
              </button>
              <NumericFormat
                className={cn(
                  'w-full bg-transparent pl-2 xl:pl-5 outline-none text-xs md:text-base',
                  [touched && error && 'border-error-primary'],
                  [className && className]
                )}
                placeholder="Enter amount"
                value={value as string | null}
                decimalScale={2}
                type="text"
                fixedDecimalScale
                allowLeadingZeros={false}
                thousandSeparator=","
                allowNegative={false}
                required={required}
                {...rest}
              />
            </div>
          </div>
        )}
      </div>
      <AnimatePresence>
        <div className="h-5 mt-2 w-full flex items-center justify-between">
          {(eagerError || touched) && error ? (
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 100 }}
              transition={{ ease: 'easeOut', duration: 0.5 }}
              className="pl-1 text-[0.625rem] md:text-xs font-semibold text-error-primary"
            >
              {error}
            </motion.div>
          ) : (
            <div></div>
          )}
        </div>
      </AnimatePresence>
      <RecipientInputModal
        close={closeSelectDepositWalletModal}
        isOpen={isSelectDepositWalletModalOpen}
      />
    </div>
  );
};

export default RecipientInput;
