const ConvertIcon = () => {
  return (
    <div className="w-4 h-4 xl:w-6 xl:h-6 mx-auto">
      <svg
        className="w-full h-full"
        width="1em"
        height="1em"
        viewBox="0 0 27 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.5 11.5C25.1332 8.86038 23.9086 6.41454 22.015 4.53929C20.1213 2.66405 17.6636 1.46343 15.0205 1.12238C12.3774 0.781328 9.69543 1.31877 7.3878 2.65192C5.08017 3.98506 3.27487 6.03995 2.25 8.50005M1.5 2.50005V8.50005H7.5M1.5 14.5C1.86684 17.1397 3.0914 19.5856 4.98504 21.4608C6.87869 23.3361 9.33637 24.5367 11.9795 24.8777C14.6226 25.2188 17.3046 24.6813 19.6122 23.3482C21.9198 22.015 23.7251 19.9601 24.75 17.5M25.5 23.5V17.5H19.5"
          stroke="#7049F7"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default ConvertIcon;
