import Button from 'components/Buttons/Button';
import useErrorNotification from 'components/error/useErrorNotification';
import Input from 'components/Inputs/input';
import { LOGIN_EMAIL_KEY, LOGIN_PASSWORD_KEY } from 'lib/constants/auth/login';
import { Link } from 'react-router-dom';
import { FORGOT_PASSWORD_ROUTE } from 'routes/path';

import useLoginForm from '../hooks/useLoginForm';

const LoginForm = () => {
  const { getInputProps, onSubmit, isValid, errors, isLoginError, isLoginPending, loginError } =
    useLoginForm();

  useErrorNotification(isLoginError, loginError);

  return (
    <form className="space-y-4 md:space-y-6 flex flex-col" onSubmit={onSubmit}>
      <Input
        id={LOGIN_EMAIL_KEY}
        label="Email address"
        inputMode="email"
        type="email"
        className="w-full"
        placeholder="Enter email address"
        error={errors[LOGIN_EMAIL_KEY]}
        {...getInputProps(LOGIN_EMAIL_KEY)}
      />
      <div>
        <Input
          id={LOGIN_PASSWORD_KEY}
          label="Password"
          inputMode="text"
          type="password"
          className="w-full"
          placeholder="Enter password"
          error={errors[LOGIN_PASSWORD_KEY]}
          {...getInputProps(LOGIN_PASSWORD_KEY)}
        />
        <div className="flex justify-end">
          <Link to={FORGOT_PASSWORD_ROUTE}>
            <span className="text-xs xl:text-sm text-platnova-purple">Forgot Password?</span>
          </Link>
        </div>
      </div>

      <div>
        <Button
          variant="purple"
          className="w-full"
          type="submit"
          isDisabled={!isValid || isLoginPending}
          isLoading={isLoginPending}
        >
          Login
        </Button>
      </div>
    </form>
  );
};

export default LoginForm;
