import { useMutation } from '@tanstack/react-query';

import { postAuthorizeRequest } from './function';

export const useAuthorizeRequest = () => {
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: postAuthorizeRequest,
  });

  return { mutate, isPending, isError, error };
};
