import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SupportedDocumentsIdTypes } from 'types/kyc';

import {
  SELFIE_SESSION_ID_KEY,
  SUMSUB_DOC,
  VERIFICATION_DOCUMENT,
  VERIFICATION_DOCUMENT_PROVIDER_KEY,
  VERIFICATION_SELECTED_DOC,
  VERIFICATION_SLICE_REDUCER_PATH,
  VERIFICATION_SLICE_STEP,
  VERIFICATION_VALUES,
  VERIFICATION_VARIANT_KEY,
  verificationSliceInitialState,
  VerificationValues,
} from './constants';

const verificationSlice = createSlice({
  name: VERIFICATION_SLICE_REDUCER_PATH,
  initialState: verificationSliceInitialState,
  reducers: {
    setVerificationStep: (state, { payload }: PayloadAction<number>) => {
      state[VERIFICATION_SLICE_STEP] = payload;
    },
    setVerificationProvider: (state, { payload }: PayloadAction<string>) => {
      state[VERIFICATION_DOCUMENT_PROVIDER_KEY] = payload;
    },
    setVerificationSelectedDoc: (state, { payload }: PayloadAction<SupportedDocumentsIdTypes>) => {
      state[VERIFICATION_SELECTED_DOC] = payload;
    },
    setVerificationValues: (state, { payload }: PayloadAction<VerificationValues>) => {
      state[VERIFICATION_VALUES] = payload;
    },
    setSumSubValues: (
      state,
      { payload }: PayloadAction<{ id: string; label: string; provider: string }>
    ) => {
      state[SUMSUB_DOC] = payload;
    },
    resetVerification: (state) => {
      state[VERIFICATION_VALUES] = {
        city: '',
        state: '',
        country: '',
        country_code: '',
        dial_code: '',
        phone: '',
        postal_code: '',
        street: '',
        ip_address: '',
        issuer_country: '',
        provider: '',
      };
      state[VERIFICATION_SLICE_STEP] = 0;
      state[VERIFICATION_DOCUMENT_PROVIDER_KEY] = '';
      state[VERIFICATION_SELECTED_DOC] = {
        fields: undefined,
        id: '',
        is_biometric_enabled: false,
        label: '',
        provider: '',
      };
    },
    setSelfieSessionId: (state, { payload }: PayloadAction<string>) => {
      state[SELFIE_SESSION_ID_KEY] = payload;
    },
    setVerificationDocument: (state, { payload }: PayloadAction<object>) => {
      state[VERIFICATION_DOCUMENT] = payload;
    },
    setVerificationVariant: (state, { payload }: PayloadAction<number>) => {
      state[VERIFICATION_VARIANT_KEY] = payload;
    },
  },
});

export const verificationReducer = verificationSlice.reducer;

export const {
  setVerificationStep,
  setVerificationProvider,
  setVerificationSelectedDoc,
  setVerificationValues,
  resetVerification,
  setSumSubValues,
  setSelfieSessionId,
  setVerificationDocument,
  setVerificationVariant,
} = verificationSlice.actions;
