import { useMutation, useQuery } from '@tanstack/react-query';
import { useAppDispatch, useAppSelector } from 'store';
import { setSendInputValues, setVerifiedBankAccount } from 'store/slices/account/accountsSlice';
import {
  ACCOUNTS_SLICE_REDUCER_PATH,
  SEND_PAYMENT_INPUT_VALUES,
  SendPaymentInputValues,
} from 'store/slices/account/constants';

import {
  GET_BANK_BRANCHES_QUERY_KEY,
  GET_BANKS_QUERY_KEY,
  GET_CRYPTO_NETWORKS_QUERY_KEY,
  GET_CRYPTOS_QUERY_KEY,
  VERIFY_BANK_ACCOUNT_MUTATION_KEY,
} from './constants';
import {
  getBankBranches,
  getBanks,
  getCryptoNetworks,
  getCryptos,
  postVerifyBankAccount,
} from './functions';

export const useGetBanks = (countryCode: string) => {
  const { data, isPending, isError, refetch } = useQuery({
    queryKey: [GET_BANKS_QUERY_KEY, countryCode],
    queryFn: () => getBanks(countryCode),
    retry: 1,
  });

  return {
    data,
    isPending,
    isError,
    refetch,
  };
};

export const useGetBankBranches = (countryCode: string, bankCode: string) => {
  const { data, isPending, isError, refetch } = useQuery({
    queryKey: [GET_BANK_BRANCHES_QUERY_KEY],
    queryFn: () => getBankBranches(countryCode, bankCode),
  });

  return {
    data,
    isPending,
    isError,
    refetch,
  };
};

export const useVerifyBankAccount = () => {
  const dispatch = useAppDispatch();
  const sendPaymentInputValues: SendPaymentInputValues = useAppSelector(
    (state) => state[ACCOUNTS_SLICE_REDUCER_PATH][SEND_PAYMENT_INPUT_VALUES]
  );

  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: postVerifyBankAccount,
    mutationKey: [VERIFY_BANK_ACCOUNT_MUTATION_KEY],
    onSuccess: (res) => {
      dispatch(setVerifiedBankAccount(res.data.account_name));
      const updatedValues = {
        ...sendPaymentInputValues,
        account_name: res.data.account_name,
      };
      dispatch(setSendInputValues(updatedValues));
    },
  });

  return { mutate, isPending, isError, error };
};

export const useGetCryptoNetworks = (currency: string) => {
  const { data, isPending, isError, refetch } = useQuery({
    queryKey: [GET_CRYPTO_NETWORKS_QUERY_KEY, currency],
    queryFn: () => getCryptoNetworks(currency),
    retry: 1,
  });

  return {
    data,
    isPending,
    isError,
    refetch,
  };
};

export const useGetCryptos = () => {
  const { data, isPending, isError, refetch } = useQuery({
    queryKey: [GET_CRYPTOS_QUERY_KEY],
    queryFn: () => getCryptos(),
  });

  return {
    data,
    isPending,
    isError,
    refetch,
  };
};
