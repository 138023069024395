import { useFormik } from 'formik';
import {
  CREATE_BANK_ACCOUNT_CURRENCY_KEY,
  CREATE_BANK_ACCOUNT_ID_NUMBER_KEY,
} from 'lib/constants/accounts/deposit';
import { useCreateBankAccount } from 'services/account';
import { object, string } from 'yup';

export default function useCreateBankAccountForm() {
  const { mutate: createBankAccount, error, isError, isPending } = useCreateBankAccount();

  const initialValues = {
    [CREATE_BANK_ACCOUNT_ID_NUMBER_KEY]: '',
    [CREATE_BANK_ACCOUNT_CURRENCY_KEY]: 'NGN',
  };

  const validationSchema = object().shape({
    [CREATE_BANK_ACCOUNT_CURRENCY_KEY]: string().required('Kindly input currency'),
    [CREATE_BANK_ACCOUNT_ID_NUMBER_KEY]: string()
      .required('Id number cannot be empty')
      .matches(/^\d{11}$/, 'ID number must be exactly 11 digits long'),
  });

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values) => {
      const id = values[CREATE_BANK_ACCOUNT_ID_NUMBER_KEY] as string;
      const newValues = { ...values, [CREATE_BANK_ACCOUNT_ID_NUMBER_KEY]: id };
      createBankAccount(newValues);
    },
    validationSchema: validationSchema,
  });

  function getInputProps(id: keyof typeof formik.values) {
    return {
      ...formik.getFieldProps(id),
      ...formik.getFieldMeta(id),
      ...formik.getFieldHelpers(id),
      ...formik.errors,
    };
  }

  return Object.freeze({
    onSubmit: formik.handleSubmit,
    setValue: formik.setFieldValue,
    isValid: formik.isValid,
    errors: formik.errors,
    createAccountError: error,
    isError: isError,
    isPending: isPending,
    values: formik.values,
    getInputProps,
    setError: formik.setErrors,
  });
}
