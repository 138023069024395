export const VERIFY_ACCOUNT_PHONE_KEY = 'phone' as const;
export const VERIFY_ACCOUNT_DIAL_CODE_KEY = 'dial_code' as const;
export const VERIFY_ACCOUNT_STREET_KEY = 'street' as const;
export const VERIFY_ACCOUNT_CITY_KEY = 'city' as const;
export const VERIFY_ACCOUNT_STATE_KEY = 'state' as const;
export const VERIFY_ACCOUNT_POSTAL_CODE_KEY = 'postal_code' as const;
export const VERIFY_ACCOUNT_COUNTRY_KEY = 'country' as const;
export const VERIFY_ACCOUNT_COUNTRY_CODE_KEY = 'country_code' as const;
export const VERIFY_ACCOUNT_IP_ADDRESS_KEY = 'ip_address' as const;
export const VERIFY_ACCOUNT_PROVIDER_KEY = 'provider' as const;
export const VERIFY_ACCOUNT_ISSUER_COUNTRY_KEY = 'issuer_country' as const;
export const SUMSUB_PROVIDER_KEY = 'sumsub' as const;

export const VERIFY_UPLOAD_ID_TYPE_KEY = 'id_type' as const;
export const VERIFY_UPLOAD_IS_BIOMETRIC_ENABLED_KEY = 'is_biometric_enabled' as const;
export const VERIFY_UPLOAD_FRONT_DOC_KEY = 'front_doc' as const;
export const VERIFY_UPLOAD_BACK_DOC_KEY = 'back_doc' as const;
export const VERIFY_UPLOAD_SELFIE_ID_KEY = 'selfie_id' as const;
export const VERIFY_UPLOAD_PROVIDER_KEY = 'provider' as const;
export const VERIFY_UPLOAD_COUNTRY_CODE_KEY = 'country_code' as const;
export const VERIFY_UPLOAD_ADDITIONAL_FIELDS_KEY = 'additional_fields' as const;
