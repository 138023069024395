import { ChevronRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useAppDispatch } from 'store';
import { setComingSoonModalOpen } from 'store/slices/layout/layoutSlice';

type SettingsButtonProps = {
  image: string;
  title: string;
  subtitle: string;
  link?: string;
  comingSoon?: boolean;
};
const SettingsButton = ({
  image,
  title,
  subtitle,
  link,
  comingSoon = false,
}: SettingsButtonProps) => {
  const dispatch = useAppDispatch();
  return !comingSoon ? (
    <Link to={link ? link : '#'} className="flex justify-between items-center py-6">
      <div className="flex items-center space-x-5">
        <img src={image} alt="" className="w-8 h-8" />
        <div>
          <p className="text-sm xl:text-xl font-medium">{title}</p>
          <p className="text-xs xl:text-base text-grey-text">{subtitle}</p>
        </div>
      </div>
      <ChevronRight />
    </Link>
  ) : (
    <button
      onClick={() => dispatch(setComingSoonModalOpen(true))}
      className="w-full flex justify-between items-center py-6"
    >
      <div className="flex items-center space-x-5">
        <img src={image} alt="" className="w-8 h-8" />
        <div>
          <p className="text-start text-sm xl:text-xl font-medium">{title}</p>
          <p className="text-start text-xs xl:text-base text-grey-text">{subtitle}</p>
        </div>
      </div>
      <ChevronRight />
    </button>
  );
};

export default SettingsButton;
