import { cva, VariantProps } from 'class-variance-authority';
import { cn } from 'lib/utils';
import { forwardRef, HTMLAttributes, useMemo } from 'react';

const progressRingVariants = cva(' relative', {
  variants: {
    variant: {
      default: '[&_#bg-ring]:stroke-[#021E65]/20 [&_#prog-ring]:stroke-[#021E65]',
    },
  },
});

export interface ProgressRingProps
  extends HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof progressRingVariants> {
  radius?: number;
  stroke?: number;
  progress?: number;
  backgroundColor?: string;
}

const ProgressRing = forwardRef<HTMLDivElement, ProgressRingProps>(
  ({ radius = 75, stroke = 16, progress = 50, variant = 'default' }) => {
    const normalizedRadius = useMemo(() => radius - stroke * 0.5, [radius, stroke]);

    const circumference = useMemo(() => normalizedRadius * 2 * Math.PI, [normalizedRadius]);

    const strokeDashoffset = useMemo(
      () => circumference - (progress / 100) * circumference,
      [circumference, progress]
    );

    return (
      <div className={cn(progressRingVariants({ variant }))}>
        <svg height={radius * 2} width={radius * 2} className="-rotate-90">
          <circle
            id="bg-ring"
            fill="transparent"
            strokeWidth={stroke}
            r={normalizedRadius}
            cx={radius}
            cy={radius}
          />
          <circle
            id="prog-ring"
            fill="transparent"
            strokeWidth={stroke}
            strokeDasharray={circumference + ' ' + circumference}
            style={{ strokeDashoffset }}
            strokeLinecap="round"
            r={normalizedRadius}
            cx={radius}
            cy={radius}
          />
        </svg>

        <div className="h-full w-full flex items-center justify-between text-background">
          <p className="text-center w-full text-[0.625rem] absolute top-[20%] xl:top-[35%] 2xl:top-[25%] bottom-0 inset-x-0">
            {progress}%<br /> Complete
          </p>
        </div>
      </div>
    );
  }
);

ProgressRing.displayName = 'ProgressRing';

export { ProgressRing };
