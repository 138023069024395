import http from 'services/http';
import { GeneralApiResponse } from 'types';
import { CreateBeneficiaryInput } from 'types/beneficiary';

import { Beneficiary } from './constants';

export const getBeneficiaries = async (): Promise<GeneralApiResponse<Beneficiary[]>> => {
  const res = await http.get('/beneficiaries');
  return res.data;
};

export const postCreateBeneficiary = async (
  data: CreateBeneficiaryInput
): Promise<GeneralApiResponse<object>> => {
  const res = await http.post('/beneficiaries', data);
  return res.data;
};

export const deleteBeneficiary = async (id: string): Promise<GeneralApiResponse<object>> => {
  const res = await http.delete(`/beneficiaries/${id}`);
  return res.data;
};
