import { createElement } from 'react';
import { TRANSACTION_DETAILS_ROUTE, TRANSACTIONS_ROUTE } from 'routes/path';
import { AppRouteConfig } from 'types/navigation';
import TransactionDetails from 'views/dashboard/transactions/transactionDetails';
import Transactions from 'views/dashboard/transactions/transactions';

export const transactionsRoutes: AppRouteConfig[] = [
  {
    path: TRANSACTIONS_ROUTE,
    element: createElement(Transactions),
    restricted: true,
    showNav: true,
    showSideBar: true,
  },
  {
    path: TRANSACTION_DETAILS_ROUTE(':id'),
    element: createElement(TransactionDetails),
    restricted: true,
    showNav: false,
    showSideBar: false,
  },
];
