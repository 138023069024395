import { allCountries } from 'lib/countries/countries';
import { cn } from 'lib/utils';

const Flag = ({ code, className }: { code: string; className?: string }) => {
  const country = allCountries.find((x) => x.cca2 === code);
  return (
    <div>
      {country && (
        <div className={cn('w-10 h-10 flex items-center', className)}>
          <img src={country.flags.svg} alt="code" className="" />
        </div>
      )}
    </div>
  );
};

export default Flag;
