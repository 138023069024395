import { cn } from 'lib/utils';
import { useAppDispatch, useAppSelector } from 'store';
import { setPhysicalCardColor, setVirtualCardColor } from 'store/slices/cards/cardsSlice';
import {
  CARD_SLICE_REDUCER_PATH,
  PHYSICAL_CARD_COLOR_KEY,
  physicalCardList,
  VIRTUAL_CARD_COLOR_KEY,
  virtualCardList,
} from 'store/slices/cards/constants';
import { Mousewheel, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';

import EmptyCard from './emptyCard';

const ChooseCardColor = ({ type }: { type: 'physical' | 'virtual' }) => {
  const dispatch = useAppDispatch();
  const {
    [PHYSICAL_CARD_COLOR_KEY]: physicalCardColor,
    [VIRTUAL_CARD_COLOR_KEY]: virtualCardColor,
  } = useAppSelector((state) => state[CARD_SLICE_REDUCER_PATH]);

  return (
    <div className="w-full">
      <Swiper
        slidesPerView={1.2}
        mousewheel={{
          forceToAxis: true,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Mousewheel, Pagination]}
        className="cardSwiper max-w-xl"
        breakpoints={{
          300: {
            slidesPerView: 1.05,
          },
          760: {
            slidesPerView: 1.2,
          },
        }}
      >
        {type === 'physical' &&
          physicalCardList.map((color, index) => (
            <SwiperSlide key={index}>
              <button
                className={cn(
                  'border-4 rounded-2xl p-1',
                  physicalCardColor === color ? 'border-platnova-purple' : 'border-transparent'
                )}
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(setPhysicalCardColor(color));
                }}
              >
                <EmptyCard type="physical" background={color.class} />
              </button>
            </SwiperSlide>
          ))}
        {type === 'virtual' &&
          virtualCardList.map((color, index) => (
            <SwiperSlide key={index}>
              <button
                className={cn(
                  'border-4 rounded-2xl p-1',
                  virtualCardColor === color ? 'border-platnova-purple' : 'border-transparent'
                )}
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(setVirtualCardColor(color));
                }}
              >
                <EmptyCard type="virtual" background={color.class} />
              </button>
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
};

export default ChooseCardColor;
