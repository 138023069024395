import { createElement } from 'react';
import { CARDS_ROUTE, CREATE_PHYSICAL_CARD_ROUTE, CREATE_VIRTUAL_CARD_ROUTE } from 'routes/path';
import { AppRouteConfig } from 'types/navigation';
import Cards from 'views/dashboard/cards/cards';
import CreatePhysicalCard from 'views/dashboard/cards/createPhysicalCard';
import CreateVirtualCard from 'views/dashboard/cards/createVirtualCard';

export const cardsRoutes: AppRouteConfig[] = [
  {
    path: CARDS_ROUTE,
    element: createElement(Cards),
    restricted: true,
    showNav: true,
    showSideBar: true,
    isKycLimited: true,
  },
  {
    path: CREATE_PHYSICAL_CARD_ROUTE,
    element: createElement(CreatePhysicalCard),
    restricted: true,
    showNav: false,
    showSideBar: false,
    isKycLimited: true,
  },
  {
    path: CREATE_VIRTUAL_CARD_ROUTE,
    element: createElement(CreateVirtualCard),
    restricted: true,
    showNav: false,
    showSideBar: false,
    isKycLimited: true,
  },
];
