import { createElement } from 'react';
import {
  ACCOUNT_DETAILS_ROUTE,
  CHANGE_PASSWORD_ROUTE,
  REFER_ROUTE,
  SETTINGS_BENEFICIARIES_ROUTE,
  SETTINGS_BENEFICIARY_DETAILS,
  SETTINGS_PIN_ROUTE,
  SETTINGS_ROUTE,
  SETTINGS_SECURITY_ROUTE,
} from 'routes/path';
import { AppRouteConfig } from 'types/navigation';
import Beneficiaries from 'views/dashboard/settings/beneficiaries';
import BeneficiaryDetails from 'views/dashboard/settings/beneficiaryDetails';
import ChangePassword from 'views/dashboard/settings/changePassword';
import Pin from 'views/dashboard/settings/pin';
import Refer from 'views/dashboard/settings/refer';
import Security from 'views/dashboard/settings/security';
import Settings from 'views/dashboard/settings/settings';
import UserAccountDetails from 'views/dashboard/settings/userAccountDetails';

export const settingsRoutes: AppRouteConfig[] = [
  {
    path: SETTINGS_ROUTE,
    element: createElement(Settings),
    restricted: true,
    showNav: true,
    showSideBar: true,
  },
  {
    path: ACCOUNT_DETAILS_ROUTE,
    element: createElement(UserAccountDetails),
    restricted: true,
    showNav: false,
    showSideBar: false,
  },
  {
    path: SETTINGS_SECURITY_ROUTE,
    element: createElement(Security),
    restricted: true,
    showNav: false,
    showSideBar: false,
  },
  {
    path: SETTINGS_PIN_ROUTE,
    element: createElement(Pin),
    restricted: true,
    showNav: false,
    showSideBar: false,
  },
  {
    path: SETTINGS_BENEFICIARIES_ROUTE,
    element: createElement(Beneficiaries),
    restricted: true,
    showNav: false,
    showSideBar: false,
    isKycLimited: true,
  },
  {
    path: SETTINGS_BENEFICIARY_DETAILS(':id'),
    element: createElement(BeneficiaryDetails),
    restricted: true,
    showNav: false,
    showSideBar: false,
    isKycLimited: true,
  },
  {
    path: REFER_ROUTE,
    element: createElement(Refer),
    restricted: true,
    showNav: false,
    showSideBar: false,
  },
  {
    path: CHANGE_PASSWORD_ROUTE,
    element: createElement(ChangePassword),
    restricted: true,
    showNav: false,
    showSideBar: false,
  },
];
