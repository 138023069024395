import Logo from 'assets/logo/qrlogo.svg';
import Avatar from 'components/avatar';
import { ChevronLeft } from 'lucide-react';
import { QRCodeSVG } from 'qrcode.react';
import { useNavigate } from 'react-router-dom';
import { useGetAccount } from 'services/account';

import SubRouteLayout from '../components/subRouteLayout';

const UserAccountDetails = () => {
  const { data } = useGetAccount();
  const navigate = useNavigate();
  return (
    <SubRouteLayout header="My QR code">
      {data && (
        <div className="pb-20 xl:-ml-[4.688rem] w-full max-w-[52rem]">
          <div className="xl:flex items-start xl:space-x-11 w-full">
            <button
              onClick={() => navigate(-1)}
              className="flex space-x-1.5 xl:space-x-3 items-center"
            >
              <ChevronLeft className="w-3 h-3 xl:w-6 xl:h-6" />
              <span className="text-xs xl:text-base">Back</span>
            </button>

            <div className="w-full mt-5 xl:mt-0">
              <div className="flex justify-between w-full">
                <div>
                  <p className="text-xl xl:text-2xl font-medium">
                    {data?.data.first_name?.toUpperCase()}
                  </p>
                  <p className="mt-1 xl:mt-2 font-medium text-xs xl:text-lg text-platnova-purple">
                    @{data?.data.username}
                  </p>
                </div>

                <Avatar
                  avatar={data.data.preference.avatar}
                  className="w-10 h-10 xl:w-14 xl:h-14 my-auto"
                />
              </div>

              {/* <img src={QrCode} alt="qr code" className="w-96 h-96 mx-auto mt-14" /> */}
              <QRCodeSVG
                value={`platnova.me/${data.data.username}`}
                className="w-96 h-96 mx-auto mt-14 rounded-[10px]"
                includeMargin
                imageSettings={{
                  excavate: false,
                  height: 20,
                  width: 20,
                  src: Logo,
                }}
              />

              <p className="text-center mt-8 xl:mt-10 text-sm xl:text-lg">
                Receive payments with{' '}
                <span className="text-platnova-purple font-medium">
                  platnova.me/{data.data.username}
                </span>
              </p>
            </div>
          </div>
        </div>
      )}
    </SubRouteLayout>
  );
};

export default UserAccountDetails;
