import {
  VERIFY_ACCOUNT_CITY_KEY,
  VERIFY_ACCOUNT_COUNTRY_CODE_KEY,
  VERIFY_ACCOUNT_COUNTRY_KEY,
  VERIFY_ACCOUNT_DIAL_CODE_KEY,
  VERIFY_ACCOUNT_IP_ADDRESS_KEY,
  VERIFY_ACCOUNT_ISSUER_COUNTRY_KEY,
  VERIFY_ACCOUNT_PHONE_KEY,
  VERIFY_ACCOUNT_POSTAL_CODE_KEY,
  VERIFY_ACCOUNT_PROVIDER_KEY,
  VERIFY_ACCOUNT_STATE_KEY,
  VERIFY_ACCOUNT_STREET_KEY,
} from 'lib/constants/kyc/accountVerification';
import { NOVA_USER_VERIFICATION_KEY } from 'services/constants';
import cookie from 'services/cookie';
import { NovaUserVerification } from 'types/auth';
import { SupportedDocumentsIdFields } from 'types/kyc';

const user: NovaUserVerification = cookie.get(NOVA_USER_VERIFICATION_KEY);

export const VERIFICATION_SLICE_REDUCER_PATH = 'verification' as const;
export const VERIFICATION_SLICE_STEP = 'step' as const;
export const VERIFICATION_DOCUMENT_PROVIDER_KEY = 'provider' as const;
export const VERIFICATION_SELECTED_DOC = 'selected_doc' as const;
export const SUMSUB_DOC = 'sumsub_doc' as const;
export const VERIFICATION_VALUES = 'verification_values' as const;
export const SELFIE_SESSION_ID_KEY = 'selfie_session_id' as const;
export const VERIFICATION_DOCUMENT = 'verification_document' as const;
export const VERIFICATION_VARIANT_KEY = 'verification_variant' as const;

export const verificationSliceInitialState = {
  [VERIFICATION_SLICE_STEP]: user && user.verificationStep ? user.verificationStep : 0,
  [VERIFICATION_DOCUMENT_PROVIDER_KEY]: '',
  [VERIFICATION_SELECTED_DOC]: {
    id: '',
    label: '',
    provider: '',
    is_biometric_enabled: false,
    fields: undefined as SupportedDocumentsIdFields[] | undefined,
  },
  [VERIFICATION_VALUES]: {
    [VERIFY_ACCOUNT_PHONE_KEY]: '',
    [VERIFY_ACCOUNT_DIAL_CODE_KEY]: '',
    [VERIFY_ACCOUNT_STREET_KEY]: '',
    [VERIFY_ACCOUNT_CITY_KEY]: '',
    [VERIFY_ACCOUNT_POSTAL_CODE_KEY]: '',
    [VERIFY_ACCOUNT_STATE_KEY]: '',
    [VERIFY_ACCOUNT_COUNTRY_KEY]: '',
    [VERIFY_ACCOUNT_COUNTRY_CODE_KEY]: '',
    [VERIFY_ACCOUNT_IP_ADDRESS_KEY]: '',
    [VERIFY_ACCOUNT_PROVIDER_KEY]: '',
    [VERIFY_ACCOUNT_ISSUER_COUNTRY_KEY]: '',
  },
  [SUMSUB_DOC]: {
    id: '',
    label: '',
    provider: '',
  },
  [SELFIE_SESSION_ID_KEY]: '',
  [VERIFICATION_DOCUMENT]: {},
  [VERIFICATION_VARIANT_KEY]: 0,
};

export type VerificationValues = {
  [VERIFY_ACCOUNT_PHONE_KEY]: string;
  [VERIFY_ACCOUNT_DIAL_CODE_KEY]: string;
  [VERIFY_ACCOUNT_STREET_KEY]: string;
  [VERIFY_ACCOUNT_CITY_KEY]: string;
  [VERIFY_ACCOUNT_POSTAL_CODE_KEY]: string;
  [VERIFY_ACCOUNT_STATE_KEY]: string;
  [VERIFY_ACCOUNT_COUNTRY_KEY]: string;
  [VERIFY_ACCOUNT_COUNTRY_CODE_KEY]: string;
  [VERIFY_ACCOUNT_IP_ADDRESS_KEY]: string;
  [VERIFY_ACCOUNT_PROVIDER_KEY]: string;
  [VERIFY_ACCOUNT_ISSUER_COUNTRY_KEY]: string;
};
