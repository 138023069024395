import { useMutation, useQuery } from '@tanstack/react-query';

import { GET_CARDS_QUERY_KEY } from './constants';
import { getCards, postOrderPhysicalCard } from './function';

export const useGetCards = () => {
  const { data, isPending, isError, refetch } = useQuery({
    queryKey: [GET_CARDS_QUERY_KEY],
    queryFn: () => getCards(),
  });

  return {
    data,
    isPending,
    isError,
    refetch,
  };
};

export const useOrderPhysicalCard = () => {
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: postOrderPhysicalCard,
  });

  return { mutate, isPending, isError, error };
};
