import { createElement } from 'react';
import { AppRouteConfig } from 'types/navigation';
import ForgotPassword from 'views/auth/forgot-password';
import ResetPassword from 'views/auth/forgot-password/resetPassword';
import VerifyCode from 'views/auth/forgot-password/verifyCode';
import Login from 'views/auth/login';
import VerifyLogin from 'views/auth/login/verifyLogin';
import Logout from 'views/auth/logout';
import Register from 'views/auth/register';
import AccountSetup from 'views/auth/register/accountSetup';
import AccountVerification from 'views/auth/register/accountVerification';
import SetPassword from 'views/auth/register/setPassword';

import {
  ACCOUNT_SETUP_ROUTE,
  ACCOUNT_VERIFICATION_ROUTE,
  FORGOT_PASSWORD_RESET_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  LOGIN_ROUTE,
  LOGOUT_ROUTE,
  REGISTER_ROUTE,
  SETUP_PASSWORD_ROUTE,
  VERIFY_FORGOT_PASSWORD_CODE_ROUTE,
  VERIFY_LOGIN_ROUTE,
} from './path';

export const authRoutes: AppRouteConfig[] = [
  {
    path: LOGIN_ROUTE,
    element: createElement(Login),
    restricted: false,
    showNav: false,
    showSideBar: false,
  },
  {
    path: LOGOUT_ROUTE,
    element: createElement(Logout),
    restricted: false,
    showNav: false,
    showSideBar: false,
  },
  {
    path: VERIFY_LOGIN_ROUTE,
    element: createElement(VerifyLogin),
    restricted: false,
    showNav: false,
    showSideBar: false,
  },
  {
    path: FORGOT_PASSWORD_ROUTE,
    element: createElement(ForgotPassword),
    restricted: false,
    showNav: false,
    showSideBar: false,
  },
  {
    path: VERIFY_FORGOT_PASSWORD_CODE_ROUTE,
    element: createElement(VerifyCode),
    restricted: false,
    showNav: false,
    showSideBar: false,
  },
  {
    path: FORGOT_PASSWORD_RESET_ROUTE,
    element: createElement(ResetPassword),
    restricted: false,
    showNav: false,
    showSideBar: false,
  },

  {
    path: REGISTER_ROUTE,
    element: createElement(Register),
    restricted: false,
    showNav: false,
    showSideBar: false,
  },
  {
    path: ACCOUNT_VERIFICATION_ROUTE,
    element: createElement(AccountVerification),
    restricted: false,
    showNav: false,
    showSideBar: false,
  },
  {
    path: SETUP_PASSWORD_ROUTE,
    element: createElement(SetPassword),
    restricted: false,
    showNav: false,
    showSideBar: false,
  },
  {
    path: ACCOUNT_SETUP_ROUTE,
    element: createElement(AccountSetup),
    restricted: true,
    showNav: false,
    showSideBar: false,
  },
];

// https://selfie.platnova.co/
