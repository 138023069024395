import { FORGOT_PASSWORD_KEY } from 'lib/constants/auth';
import { FORGOT_PASSWORD_EMAIL_KEY } from 'lib/constants/auth/forgotPassword';
import { useAppSelector } from 'store';
import { FORGOT_PASSWORD_SLICE_REDUCER_PATH } from 'store/slices/forgotPassword/constants';
import Timer from 'utils/timer';

import VerifyForgotPasswordForm from './components/verifyForgotPasswordForm';
import AuthLayout from '../layout';

const VerifyCode = () => {
  const { [FORGOT_PASSWORD_EMAIL_KEY]: email } = useAppSelector(
    (state) => state[FORGOT_PASSWORD_SLICE_REDUCER_PATH]
  );

  return (
    <AuthLayout>
      <div className="bg-white rounded-[10px] px-5 p-5 md:px-10 md:p-10 w-full h-full xl:h-max">
        <div className="space-y-2">
          <h1 className="text-2xl md:text-3xl font-medium">OTP Authorization</h1>
          <p className="text-sm md:text-base text-grey-text">
            Enter the code that was sent to
            <br />
            <span className="font-bold">{email}</span>
          </p>
        </div>

        <VerifyForgotPasswordForm />

        <div className="mt-4 md:mt-3">
          <Timer
            resendEmail={email}
            type={FORGOT_PASSWORD_KEY}
            className="text-sm md:text-base font-semibold"
            time={60}
            buttonClassName="text-sm md:text-base font-semibold text-platnova-purple"
          />
        </div>
      </div>
    </AuthLayout>
  );
};

export default VerifyCode;
