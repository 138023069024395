import Input from 'components/Inputs/input';
import { ChevronLeft } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SETTINGS_BENEFICIARY_DETAILS } from 'routes/path';
import { useGetBeneficiaries } from 'services/beneficiary';
import { searchBeneficiaries } from 'utils/searchBeneficiaries';

import SubRouteLayout from '../components/subRouteLayout';

const Beneficiaries = () => {
  const navigate = useNavigate();
  const { data: beneficiaries } = useGetBeneficiaries();
  const [searchInput, setSearchInput] = useState('');
  const [displayedBeneficiaries, setDisplayedBeneficiaries] = useState(beneficiaries?.data);

  useEffect(() => {
    const newValues = searchBeneficiaries(beneficiaries?.data, searchInput);
    setDisplayedBeneficiaries(newValues);
  }, [beneficiaries?.data, searchInput]);

  return (
    <SubRouteLayout header="Beneficiaries">
      <div className="pb-20 w-full max-w-[52rem]">
        <div className="xl:flex items-start xl:space-x-11 w-full">
          <button
            onClick={() => navigate(-1)}
            className="flex space-x-1.5 xl:space-x-3 items-center"
          >
            <ChevronLeft className="w-3 h-3 xl:w-6 xl:h-6" />
            <span className="text-xs xl:text-base">Back</span>
          </button>

          <div className="w-full mt-5 xl:mt-0">
            <div className="w-full">
              <div>
                <p className="text-xl xl:text-2xl font-medium">Beneficiaries</p>
                <p className="my-1 xl:mt-2 mb-4 xl:mb-8 font-medium text-xs xl:text-lg text-grey-text">
                  Manage your beneficiary list
                </p>

                <Input
                  id="search"
                  placeholder="search beneficiaries"
                  variant="secondary"
                  onChange={(e) => setSearchInput(e.target.value)}
                />

                <div className="space-y-5">
                  {displayedBeneficiaries &&
                    displayedBeneficiaries.map((beneficiary) => (
                      <button
                        key={beneficiary.id}
                        onClick={() => navigate(SETTINGS_BENEFICIARY_DETAILS(beneficiary.id))}
                        className="bg-white w-full p-3 rounded-[10px] flex space-x-5 hover:bg-platnova-secondary"
                      >
                        <div className="bg-[#F5B12C] w-12 h-12 rounded-full flex justify-center items-center">
                          <p className="text-base">
                            {beneficiary.meta.find((x) => x.id === 'account_name')?.value[0]}
                          </p>
                        </div>
                        <div>
                          <p className="text-sm text-start md:text-base">
                            {beneficiary.meta.find((x) => x.id === 'account_name')?.value}
                          </p>
                          <p className="text-[0.625rem] md:text-xs text-grey-text text-start">
                            {beneficiary.meta.find((x) => x.id === 'bank_name')?.value}{' '}
                            {beneficiary.meta.find((x) => x.id === 'account_number')?.value}
                          </p>
                        </div>
                      </button>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SubRouteLayout>
  );
};

export default Beneficiaries;
