import Button from 'components/Buttons/Button';
import useErrorNotification from 'components/error/useErrorNotification';
import Flag from 'components/flag';
import Input from 'components/Inputs/input';
import Select from 'components/Inputs/select';
import Loader from 'components/Loader';
import useDisclosure from 'hooks/useDisclosure';
import { allCountries } from 'lib/countries/countries';
import { useGetExchangeRate } from 'lib/utils';
import { ChevronDown } from 'lucide-react';
import { useEffect } from 'react';
import {
  ORDER_PHYSICAL_CARDS_ADDRESS_KEY,
  ORDER_PHYSICAL_CARDS_CITY_KEY,
  ORDER_PHYSICAL_CARDS_COUNTRY_KEY,
  ORDER_PHYSICAL_CARDS_DEBIT_CURRENCY_KEY,
  ORDER_PHYSICAL_CARDS_DELIVERY_NOTE_KEY,
  ORDER_PHYSICAL_CARDS_QUANTITY_KEY,
  ORDER_PHYSICAL_CARDS_SHIPPING_COST_KEY,
  ORDER_PHYSICAL_CARDS_STATE_KEY,
  ORDER_PHYSICAL_CARDS_UNIT_PRICE_KEY,
} from 'services/cards/constants';
import { useGetCountryStates } from 'services/configurations';
import { useGetWallets } from 'services/wallet';
import { useAppSelector } from 'store';
import { WALLET_ID_KEY, WALLET_SLICE_REDUCER_PATH } from 'store/slices/wallet/constants';
import { formatAmount } from 'utils/numberFormat';
import SelectDepositWalletModal from 'views/dashboard/accounts/components/selectDepositWalletModal';
import { useOrderPhysicalCardForm } from 'views/dashboard/cards/hooks/useOrderPhysicalCardForm';
import DashboardCardLayout from 'views/dashboard/components/dashboardCardLayout';

const OrderPhysicalCardForm = () => {
  const {
    getInputProps,
    submit,
    values,
    errors,
    isLoadingOrderPhysicalCard,
    isOrderPhysicalCardError,
    orderPhysicalCardError,
    setFieldValue,
  } = useOrderPhysicalCardForm();
  const { data: wallets } = useGetWallets();
  const { [WALLET_ID_KEY]: walletId } = useAppSelector((state) => state[WALLET_SLICE_REDUCER_PATH]);
  const { data: countryStates, isPending: isLoadingCountryStates } = useGetCountryStates();
  const activeWallet =
    walletId.length > 0 ? wallets?.data.find((x) => x.id === walletId) : wallets?.data[0];
  const states = countryStates?.data.map((state) => ({
    value: state.name,
    label: state.name,
  }));

  const currency = 'NGN';

  useErrorNotification(isOrderPhysicalCardError, orderPhysicalCardError);

  const { close, isOpen, open } = useDisclosure();

  const formatCountries = allCountries.map((country) => ({
    value: country.cca2,
    img: country.flags.svg,
    label: country.name.common,
  }));

  const nigeria = formatCountries.find((x) => x.value === 'NG');

  const cityList = countryStates?.data.find(
    (state) => state.name === values[ORDER_PHYSICAL_CARDS_STATE_KEY]
  )?.cities;

  const cities =
    values[ORDER_PHYSICAL_CARDS_STATE_KEY].length > 1 && cityList
      ? cityList.map((lga) => ({
          value: lga.name,
          label: lga.name,
        }))
      : [];

  const shippingCost =
    values[ORDER_PHYSICAL_CARDS_STATE_KEY].length > 1 && cityList
      ? cityList.find((city) => city.name === values[ORDER_PHYSICAL_CARDS_CITY_KEY])?.shipping_cost
      : 0;

  useEffect(() => {
    setFieldValue(ORDER_PHYSICAL_CARDS_SHIPPING_COST_KEY, shippingCost);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippingCost]);

  useEffect(() => {
    activeWallet &&
      values[ORDER_PHYSICAL_CARDS_DEBIT_CURRENCY_KEY] &&
      values[ORDER_PHYSICAL_CARDS_DEBIT_CURRENCY_KEY].length < 1 &&
      setFieldValue(ORDER_PHYSICAL_CARDS_DEBIT_CURRENCY_KEY, activeWallet?.type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const vat = shippingCost && (shippingCost * 7.5) / 100;

  const ngnRate = useGetExchangeRate('ngn');
  const otherRate = useGetExchangeRate(activeWallet && activeWallet.type ? activeWallet.type : '');

  const exchangeRate = ngnRate && otherRate && otherRate.rates.sell / ngnRate.rates.buy;
  const total =
    shippingCost &&
    vat &&
    values[ORDER_PHYSICAL_CARDS_UNIT_PRICE_KEY] &&
    activeWallet &&
    (shippingCost + vat + values[ORDER_PHYSICAL_CARDS_UNIT_PRICE_KEY]) *
      (activeWallet.type === 'ngn' ? 1 : exchangeRate);

  return (
    <div>
      {wallets && activeWallet ? (
        <form onSubmit={submit} className="w-full mt-10 space-y-10">
          <DashboardCardLayout className=" space-y-2">
            <Select
              id={ORDER_PHYSICAL_CARDS_COUNTRY_KEY}
              label="Country"
              variant="tertiary"
              disabled
              tertiaryOptions={formatCountries}
              {...getInputProps(ORDER_PHYSICAL_CARDS_COUNTRY_KEY)}
              value={nigeria?.value}
              error={errors[ORDER_PHYSICAL_CARDS_COUNTRY_KEY]}
              className="cursor-not-allowed"
            />

            <Select
              id={ORDER_PHYSICAL_CARDS_STATE_KEY}
              label="State"
              options={states}
              isLoading={isLoadingCountryStates}
              {...getInputProps(ORDER_PHYSICAL_CARDS_STATE_KEY)}
              error={errors[ORDER_PHYSICAL_CARDS_STATE_KEY]}
            />

            {values[ORDER_PHYSICAL_CARDS_STATE_KEY].length > 0 && (
              <Select
                id={ORDER_PHYSICAL_CARDS_CITY_KEY}
                label="City"
                options={cities}
                {...getInputProps(ORDER_PHYSICAL_CARDS_CITY_KEY)}
                error={errors[ORDER_PHYSICAL_CARDS_CITY_KEY]}
              />
            )}

            <Input
              id={ORDER_PHYSICAL_CARDS_ADDRESS_KEY}
              label="Address"
              inputMode="text"
              type="text"
              placeholder="Enter your address"
              error={errors[ORDER_PHYSICAL_CARDS_ADDRESS_KEY]}
              {...getInputProps(ORDER_PHYSICAL_CARDS_ADDRESS_KEY)}
            />

            <Input
              id={ORDER_PHYSICAL_CARDS_DELIVERY_NOTE_KEY}
              label="Note"
              inputMode="text"
              type="text"
              placeholder="Enter delivery note"
              error={errors[ORDER_PHYSICAL_CARDS_DELIVERY_NOTE_KEY]}
              {...getInputProps(ORDER_PHYSICAL_CARDS_DELIVERY_NOTE_KEY)}
            />
          </DashboardCardLayout>

          <DashboardCardLayout className="space-y-2">
            <div className="flex justify-between items-center">
              <p className="text-sm md:text-base text-black/70">Debit account</p>
              {wallets && (
                <button type="button" onClick={open} className="flex items-center space-x-2 py-5">
                  <Flag
                    code={activeWallet ? activeWallet.country.code : wallets.data[0].country.code}
                    className="w-6 h-6"
                  />
                  <p className="text-sm md:text-base">
                    {activeWallet
                      ? activeWallet.type.toUpperCase()
                      : wallets?.data[0].type.toUpperCase()}
                  </p>
                  <ChevronDown className="w-6 h-6" />
                </button>
              )}
            </div>
            <div className="space-y-7">
              <div className="flex justify-between items-center">
                <p className="text-sm md:text-base text-black/70">Items</p>
                <p className="text-sm md:text-base font-medium">
                  {currency} {formatAmount(String(values[ORDER_PHYSICAL_CARDS_UNIT_PRICE_KEY]))}
                </p>
              </div>
              <div className="flex justify-between items-center">
                <p className="text-sm md:text-base text-black/70">Quantity</p>
                <p className="text-sm md:text-base font-medium">
                  {values[ORDER_PHYSICAL_CARDS_QUANTITY_KEY]}
                </p>
              </div>
              <div className="flex justify-between items-center">
                <p className="text-sm md:text-base text-black/70">Shipping cost</p>
                <p className="text-sm md:text-base font-medium">
                  {currency} {formatAmount(shippingCost ? String(shippingCost) : '0')}
                </p>
              </div>
              <div className="flex justify-between items-center">
                <p className="text-sm md:text-base text-black/70">VAT (7.5%)</p>
                <p className="text-sm md:text-base font-medium">
                  {currency} {formatAmount(shippingCost ? String(vat) : '0')}
                </p>
              </div>
              <div className="flex justify-between items-center">
                <p className="text-sm md:text-base text-black/70">Total</p>
                <p className="text-sm md:text-base font-medium">
                  {activeWallet.type.toUpperCase()} {formatAmount(String(total))}
                </p>
              </div>
            </div>
          </DashboardCardLayout>

          <Button
            type="submit"
            variant="purple"
            isLoading={isLoadingOrderPhysicalCard}
            className="w-full"
          >
            Order now
          </Button>
        </form>
      ) : (
        <Loader />
      )}
      <SelectDepositWalletModal variant="debit" close={close} isOpen={isOpen} />
    </div>
  );
};

export default OrderPhysicalCardForm;
