import { useMutation } from '@tanstack/react-query';

import { patchChangePassword, postAuthorizePin, postCreatePin } from './function';

export const useCreatePin = () => {
  const { mutate, isPending, isError, error, isSuccess } = useMutation({
    mutationFn: postCreatePin,
  });

  return { mutate, isPending, isError, error, isSuccess };
};

export const useAuthorizePin = () => {
  const { mutate, isPending, isError, error, isSuccess } = useMutation({
    mutationFn: postAuthorizePin,
  });

  return { mutate, isPending, isError, error, isSuccess };
};

export const useChangePassword = () => {
  const { mutate, isPending, isError, error, isSuccess } = useMutation({
    mutationFn: patchChangePassword,
  });

  return { mutate, isPending, isError, error, isSuccess };
};
