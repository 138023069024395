import { useFormik } from 'formik';
import {
  REGISTER_COUNTRY_CODE_KEY,
  REGISTER_COUNTRY_OF_RESIDENCE_KEY,
  REGISTER_DIAL_CODE_KEY,
  REGISTER_FIRST_NAME_KEY,
  REGISTER_LAST_NAME_KEY,
  REGISTER_PHONE_NUMBER_KEY,
} from 'lib/constants/auth/register';
import { allCountries } from 'lib/countries/countries';
import { useNavigate } from 'react-router-dom';
import { LOGIN_ROUTE, VERIFICATION_ROUTE } from 'routes/path';
import { useSetupAccount } from 'services/auth/register';
import { object, string } from 'yup';

export default function useAccountSetupForm() {
  const { mutate, error, isError, isPending } = useSetupAccount();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      [REGISTER_FIRST_NAME_KEY]: '',
      [REGISTER_LAST_NAME_KEY]: '',
      [REGISTER_PHONE_NUMBER_KEY]: '',
      [REGISTER_DIAL_CODE_KEY]: '',
      [REGISTER_COUNTRY_OF_RESIDENCE_KEY]: '',
      [REGISTER_COUNTRY_CODE_KEY]: '',
    },
    onSubmit: (values) => {
      values[REGISTER_COUNTRY_CODE_KEY] = allCountries.find(
        (x) => x.name.common === values[REGISTER_COUNTRY_OF_RESIDENCE_KEY]
      )?.cca2 as string;
      mutate(values, {
        onSuccess: () => {
          try {
            navigate(VERIFICATION_ROUTE);
          } catch {
            navigate(LOGIN_ROUTE);
          }
        },
      });
    },
    validationSchema: object().shape({
      [REGISTER_FIRST_NAME_KEY]: string().required('First name cannot be empty'),
      [REGISTER_LAST_NAME_KEY]: string().required('Last name cannot be empty'),
      [REGISTER_PHONE_NUMBER_KEY]: string(),
      [REGISTER_COUNTRY_OF_RESIDENCE_KEY]: string().required(
        'Kindly select a country of residence'
      ),
    }),
  });

  function getInputProps(id: keyof typeof formik.values) {
    return {
      ...formik.getFieldProps(id),
      ...formik.getFieldMeta(id),
      ...formik.getFieldHelpers(id),
      ...formik.errors,
    };
  }

  return Object.freeze({
    onSubmit: formik.handleSubmit,
    getInputProps,
    setValue: formik.setFieldValue,
    isValid: formik.isValid,
    errors: formik.errors,
    accountSetupError: error,
    isError: isError,
    isPending: isPending,
  });
}
