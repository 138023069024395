const userPlus = () => {
  return (
    <div className="w-7 h-7">
      <svg
        className="w-full h-full"
        width="1em"
        height="1em"
        viewBox="0 0 28 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.3346 13.8333C14.0165 13.8333 17.0013 10.8486 17.0013 7.16667C17.0013 3.48477 14.0165 0.5 10.3346 0.5C6.65274 0.5 3.66797 3.48477 3.66797 7.16667C3.66797 10.8486 6.65274 13.8333 10.3346 13.8333Z"
          fill="#7049F7"
        />
        <path
          d="M22.334 9.83203V17.832M26.334 13.832H18.334"
          stroke="#7049F7"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M19.8566 23.6773C20.4726 23.5373 20.8419 22.8973 20.5686 22.328C19.8326 20.7947 18.6232 19.4453 17.0566 18.4293C15.1272 17.1787 12.7646 16.5 10.3339 16.5C7.90324 16.5 5.54058 17.1773 3.61124 18.4293C2.04458 19.4453 0.835242 20.7933 0.0992419 22.328C-0.174091 22.8973 0.195242 23.5373 0.811242 23.6773C7.0803 25.0972 13.5875 25.0972 19.8566 23.6773Z"
          fill="#7049F7"
        />
      </svg>
    </div>
  );
};

export default userPlus;
