import { useMutation } from '@tanstack/react-query';

import { postForgotPassword, postResetPassword, postVerifyForgotPassword } from './function';

export const useForgotPassword = () => {
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: postForgotPassword,
    // onSuccess: () => queryClient
  });

  return { mutate, isPending, isError, error };
};

export const useResetPassword = () => {
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: postResetPassword,
  });

  return { mutate, isPending, isError, error };
};

export const useVerifyForgotPassword = () => {
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: postVerifyForgotPassword,
  });

  return { mutate, isPending, isError, error };
};
