import http from 'services/http';
import { GeneralApiResponse, OrderPhysicalCardInput } from 'types';

export const getCards = async (): Promise<GeneralApiResponse<[]>> => {
  const res = await http.get('/cards');
  return res.data;
};

export const postOrderPhysicalCard = async (
  data: OrderPhysicalCardInput
): Promise<GeneralApiResponse<object>> => {
  const res = await http.post('/cards/order', data);
  return res.data;
};
