import http from 'services/http';
import { GeneralApiResponse } from 'types';
import {
  Account,
  CreateBankAccountInput,
  DepositPaymentInput,
  IssuedBankAccount,
} from 'types/account';
import { GetTagResponse } from 'types/send';

export const getAccount = async (): Promise<GeneralApiResponse<Account>> => {
  const res = await http.get('/accounts');
  return res.data;
};

export const getIssuedBankAccount = async (): Promise<GeneralApiResponse<IssuedBankAccount[]>> => {
  const res = await http.get(`/banks`);
  return res.data;
};

export const postOtcDepositRequest = async (): Promise<GeneralApiResponse<string>> => {
  const res = await http.post('/payments/otc_deposit/request');
  return res.data;
};

export const postCreateBankAccount = async (
  data: CreateBankAccountInput
): Promise<GeneralApiResponse<unknown>> => {
  const res = await http.post('/banks/account/create', data);
  return res.data;
};

export const postDepositPayment = async (
  data: DepositPaymentInput
): Promise<GeneralApiResponse<string>> => {
  const res = await http.post('/payments/deposit', data);
  return res.data;
};

export const searchNovatag = async (tag: string): Promise<GeneralApiResponse<GetTagResponse[]>> => {
  const res = await http.get(`/accounts/${tag}`);
  return res.data;
};
