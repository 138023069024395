import { ACCOUNT_AUTHORIZE_KEY } from 'lib/constants/auth';
import { LOGIN_EMAIL_KEY } from 'lib/constants/auth/login';
import { useAppSelector } from 'store';
import { LOGIN_SLICE_REDUCER_PATH } from 'store/slices/login/constants';
import Timer from 'utils/timer';

import VerifyLoginForm from './components/verifyLoginForm';
import AuthLayout from '../layout';

const VerifyLogin = () => {
  const { [LOGIN_EMAIL_KEY]: email } = useAppSelector((state) => state[LOGIN_SLICE_REDUCER_PATH]);
  return (
    <AuthLayout containerClassName="w-full">
      <div className="bg-white rounded-[10px] px-5 p-5 md:px-10 md:p-10 w-full h-full xl:h-max">
        <div className="space-y-2">
          <h1 className="text-2xl md:text-3xl font-medium">OTP Authorization</h1>
          <p className="text-sm md:text-base text-grey-text">
            We sent an OTP code to
            <br />
            <span className="font-bold">{email || ''}</span> for the authorization of this account
          </p>
        </div>

        {/* <div className="text-sm xl:text-xl mt-5 flex space-x-1">
          <p className="text-grey-text">is this email correct? </p>
          <Link to={LOGIN_ROUTE} className="text-platnova-purple font-bold flex items-center">
            Edit email <LuPencilLine />
          </Link>
        </div> */}

        <VerifyLoginForm />
        <div className="mt-4 md:mt-3">
          <Timer
            type={ACCOUNT_AUTHORIZE_KEY}
            className="text-sm md:text-base font-semibold"
            time={60}
            buttonClassName="text-sm md:text-base font-semibold text-platnova-purple"
            resendEmail={email}
          />
        </div>
      </div>
    </AuthLayout>
  );
};

export default VerifyLogin;
