import { useFormik } from 'formik';
import {
  CONVERT_FROM_AMOUNT_KEY,
  CONVERT_FROM_CURRENCY_KEY,
  CONVERT_TO_AMOUNT_KEY,
  CONVERT_TO_CURRENCY_KEY,
} from 'lib/constants/accounts/convert';
import { useGetConvertTransactionLimits, useGetExchangeValue } from 'lib/utils';
import { useState } from 'react';
import { useConvert } from 'services/convert';
import { useGetWallets } from 'services/wallet';
import { useAppSelector } from 'store';
import {
  TO_ACCOUNT_WALLET_ID_KEY,
  WALLET_ID_KEY,
  WALLET_SLICE_REDUCER_PATH,
} from 'store/slices/wallet/constants';
import { formatAmount, removeNonDigit } from 'utils/numberFormat';
import { number, object, string } from 'yup';

export default function useConvertForm() {
  const { error, isError, isPending } = useConvert();
  const [openModal, setOpenModal] = useState(false);
  const limits = useGetConvertTransactionLimits();
  const { data: wallets } = useGetWallets();

  const { [WALLET_ID_KEY]: walletId, [TO_ACCOUNT_WALLET_ID_KEY]: toAccountWalletId } =
    useAppSelector((state) => state[WALLET_SLICE_REDUCER_PATH]);

  const activeWallet =
    walletId.length > 0 ? wallets?.data.find((x) => x.id === walletId) : wallets?.data[0];

  const activeToWallet =
    toAccountWalletId.length > 0
      ? wallets?.data.find((x) => x.id === toAccountWalletId)
      : wallets?.data[1];
  const exchangeValue = useGetExchangeValue(activeWallet?.type ?? '', activeToWallet?.type ?? '');

  let validationSchema = object().shape({
    [CONVERT_FROM_AMOUNT_KEY]: number().transform((value, originalValue) => {
      return Number(removeNonDigit(String(originalValue)));
    }),
    [CONVERT_FROM_CURRENCY_KEY]: string().required(),
    [CONVERT_TO_AMOUNT_KEY]: number()
      .transform((value, originalValue) => {
        return Number(removeNonDigit(String(originalValue)));
      })
      .required(),
    [CONVERT_TO_CURRENCY_KEY]: string().required(),
  });

  if (limits && activeToWallet) {
    validationSchema = object().shape({
      [CONVERT_FROM_AMOUNT_KEY]: number().transform((value, originalValue) => {
        return Number(removeNonDigit(String(originalValue)));
      }),
      [CONVERT_FROM_CURRENCY_KEY]: string().required(),
      [CONVERT_TO_AMOUNT_KEY]: number()
        .transform((value, originalValue) => {
          return Number(removeNonDigit(String(originalValue)));
        })
        .required('Amount cannot be empty')
        .min(
          limits?.min ?? 0,
          `Minimum ${formatAmount(String(limits?.min))} ${activeToWallet?.type.toUpperCase()}`
        )
        .max(
          limits?.max ?? 0,
          `Maximum ${formatAmount(String(limits?.max))} ${activeToWallet?.type.toUpperCase()}`
        ),
      [CONVERT_TO_CURRENCY_KEY]: string().required(),
    });
  }

  const formik = useFormik({
    initialValues: {
      [CONVERT_FROM_AMOUNT_KEY]: '',
      [CONVERT_FROM_CURRENCY_KEY]: (activeWallet && activeWallet.type) ?? '',
      [CONVERT_TO_AMOUNT_KEY]: '',
      [CONVERT_TO_CURRENCY_KEY]: (activeToWallet && activeToWallet.type) ?? '',
    },
    onSubmit: () => {
      setOpenModal(true);
    },
    validationSchema: validationSchema,
  });

  const handleFromAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(removeNonDigit(String(e.target.value)));
    formik.setFieldValue(CONVERT_FROM_AMOUNT_KEY, value);
    formik.setFieldValue(CONVERT_TO_AMOUNT_KEY, value * exchangeValue);
  };

  const handleToAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(removeNonDigit(String(e.target.value)));
    formik.setFieldValue(CONVERT_TO_AMOUNT_KEY, value);
    formik.setFieldValue(CONVERT_FROM_AMOUNT_KEY, value / exchangeValue);
  };

  function getInputProps(id: keyof typeof formik.values) {
    return {
      ...formik.getFieldProps(id),
      ...formik.getFieldMeta(id),
      ...formik.errors,
      onChange:
        id === CONVERT_FROM_AMOUNT_KEY
          ? handleFromAmountChange
          : id === CONVERT_TO_AMOUNT_KEY
          ? handleToAmountChange
          : formik.handleChange,
    };
  }

  return Object.freeze({
    onSubmit: formik.handleSubmit,
    getInputProps,
    setValue: formik.setFieldValue,
    isValid: formik.isValid,
    errors: formik.errors,
    convertError: error,
    isError: isError,
    isPending: isPending,
    values: formik.values,
    openModal,
    setOpenModal,
  });
}
