import { useGetConfigurations } from 'services/configurations';
import { Configuration, PaymentMethodConfig } from 'types/configuration';

export const useGetSendPaymentMethods = (currency: string | undefined) => {
  const { data: configurations } = useGetConfigurations();
  const paymentMethods = configurations?.data.find(
    (x): x is Configuration<PaymentMethodConfig> => x.name === 'payment_methods'
  )?.value;

  const currencyTypes = paymentMethods?.find((x) => x.currency === currency)?.types;

  const methods = currencyTypes?.find((x) => x.id === 'send_payment')?.methods;

  return methods;
};
