import http from 'services/http';
import { GeneralApiResponse } from 'types';
import { Configuration, ConfigurationValue, StateConfiguration } from 'types/configuration';

export const getConfigurations = async (): Promise<
  GeneralApiResponse<Configuration<ConfigurationValue>[]>
> => {
  const res = await http.get('/configurations');
  return res.data;
};

export const getCountryStates = async (): Promise<GeneralApiResponse<StateConfiguration[]>> => {
  const res = await http.get('/configurations/country_states');
  return res.data;
};

export const getTransactionLimitsConfiguration = async (): Promise<
  GeneralApiResponse<Configuration<ConfigurationValue>[]>
> => {
  const res = await http.get('/configurations?name=transaction_limits');
  return res.data;
};
