/* eslint-disable @typescript-eslint/no-explicit-any */
import { GeneralApiResponse } from 'types';

import { useSearchNovatag } from './account';
import { accountKeys } from './account/key';
import {
  useGetBankBranches,
  useGetBanks,
  useGetCryptoNetworks,
  useGetCryptos,
  useVerifyBankAccount,
} from './banks';
import {
  GET_BANK_BRANCHES_QUERY_KEY,
  GET_BANKS_QUERY_KEY,
  GET_CRYPTO_NETWORKS_QUERY_KEY,
  GET_CRYPTOS_QUERY_KEY,
  VERIFY_BANK_ACCOUNT_MUTATION_KEY,
} from './banks/constants';

export const USER_TOKEN_COOKIE_KEY = 'nova_user' as const;
export const NOVA_USER_VERIFICATION_KEY = 'nova_user_verification' as const;

export const queryHooks: QueryHooks = {
  queries: {
    [GET_BANKS_QUERY_KEY]: useGetBanks,
    [GET_BANK_BRANCHES_QUERY_KEY]: useGetBankBranches,
    [accountKeys.SEARCH_NOVATAG]: useSearchNovatag,
    [GET_CRYPTOS_QUERY_KEY]: useGetCryptos,
    [GET_CRYPTO_NETWORKS_QUERY_KEY]: useGetCryptoNetworks,
  },
  mutations: {
    [VERIFY_BANK_ACCOUNT_MUTATION_KEY]: useVerifyBankAccount,
  },
};

type UseGetBanksType = (
  countryCode: string,
  bankCode: string
) => {
  data: GeneralApiResponse<any> | undefined;
  isPending: boolean;
  isError: boolean;
  refetch: any;
};

type UseVerifyBankAccountType = () => {
  mutate: (params: any) => void;
  isPending: boolean;
  isError: boolean;
  error: Error | null;
};

interface QueryHooks {
  queries: {
    [GET_BANKS_QUERY_KEY]: UseGetBanksType;
    [GET_BANK_BRANCHES_QUERY_KEY]: UseGetBanksType;
    [accountKeys.SEARCH_NOVATAG]: any;
    [GET_CRYPTOS_QUERY_KEY]: any;
    [GET_CRYPTO_NETWORKS_QUERY_KEY]: any;
  };
  mutations: {
    [VERIFY_BANK_ACCOUNT_MUTATION_KEY]: UseVerifyBankAccountType;
  };
}

export type HookType = 'queries' | 'mutations';

export interface UseDynamicHookParams {
  type: HookType;
  key: string;
  params?: any;
}

export const useDynamicHook = ({ type, key, params }: UseDynamicHookParams) => {
  const hookType = queryHooks[type];

  const hook = hookType[key as keyof typeof hookType];

  if (type === 'mutations') {
    return params ? (hook as any)(params) : (hook as any)();
  } else {
    if (key === accountKeys.SEARCH_NOVATAG) {
      return params ? (hook as any)(params.tag) : (hook as any)();
    }
    if (key === GET_CRYPTO_NETWORKS_QUERY_KEY) {
      return params ? (hook as any)(params.currency) : (hook as any)();
    }
    return params
      ? (hook as any)(params.countryCode, params.bankCode, params.tag)
      : (hook as any)();
  }
};
