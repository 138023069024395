import AccountActivationBanner from 'components/accountActivationBanner';
import { motion } from 'framer-motion';
import { cn } from 'lib/utils';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { useGetAccount } from 'services/account';
import { useAppDispatch, useAppSelector } from 'store';
import { setSideBarIsCollapsed } from 'store/slices/layout/layoutSlice';
import { getCurrentRouteContext } from 'utils/route';

import NavigationBar from './components/navigationBar';
import SideBar from './components/sideBar';

const DashboardLayout = ({ children, className }: { children: ReactNode; className?: string }) => {
  const location = useLocation();
  const { sideBarIsCollapsed } = useAppSelector((state) => state.layout);
  const dispatch = useAppDispatch();

  const { data } = useGetAccount();

  const account = data?.data;

  const matched = getCurrentRouteContext(location);
  return (
    <div className="bg-side-bar-gradient">
      <div className="bg-[#FAFAFA4D] backdrop-blur-[150px]">
        <div className="flex bg-grey-background max-w-[1540px] mx-auto h-screen">
          <div className="">
            <motion.div
              className={`relative hidden xl:block w-full 
              ${sideBarIsCollapsed ? 'w-20' : 'w-[17rem]'}
              `}
              // initial={{ width: '17rem' }}
              animate={{ width: sideBarIsCollapsed ? '5rem' : '17rem' }}
              transition={{ duration: 0.2, type: 'bounce', stiffness: 150, damping: 20 }}
            >
              <div className="w-full bg-side-bar-gradient">
                {matched.showSideBar && <SideBar />}
              </div>
              {!sideBarIsCollapsed ? (
                <button
                  className="absolute top-8 -right-3  p-1 bg-platnova-purple text-white rounded-full z-20"
                  onClick={() => dispatch(setSideBarIsCollapsed(true))}
                >
                  <ChevronLeft size={18} />
                </button>
              ) : (
                <button
                  className="absolute top-8 -right-3 p-1 bg-platnova-purple text-white rounded-full z-20"
                  onClick={() => dispatch(setSideBarIsCollapsed(false))}
                >
                  <ChevronRight size={18} />
                </button>
              )}
            </motion.div>
          </div>
          <div
            className={cn(
              'w-full h-full transition-all duration-300 ease-in-out flex flex-col overflow-y-scroll'
            )}
          >
            {matched.showNav && <NavigationBar />}
            {account &&
              (account.status !== 'ACTIVE' && matched.isKycLimited ? (
                <div className={cn('py-8 xl:py-14 flex flex-col items-center', className)}>
                  <AccountActivationBanner />
                  {children}
                </div>
              ) : (
                <div className={cn('py-8 xl:py-14 flex flex-col items-center', className)}>
                  {children}
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
