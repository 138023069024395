import { useEffect, useState } from 'react';
import {
  Location,
  NavigateFunction,
  RoutesProps,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { ACCOUNT_SETUP_ROUTE, ACCOUNTS_ROUTE, LOGIN_ROUTE } from 'routes/path';
import { getAuthToken } from 'services';
import { useGetAccount } from 'services/account';
import { getAccount } from 'services/account/function';
import { getConfigurations } from 'services/configurations/function';
import { getTransactions } from 'services/transactions/function';
import { getWallets } from 'services/wallet/function';
import { useAppDispatch } from 'store';
import { setLayoutPageLoader } from 'store/slices/layout/layoutSlice';
import { Account } from 'types/account';
import { AppRouteConfig } from 'types/navigation';
import { getCurrentRouteContext } from 'utils/route';

let authToken: string | null;
let matched: AppRouteConfig;

const checkJwtAuth = async (location: Location, navigate: NavigateFunction, account: Account) => {
  if (account !== undefined) {
    authToken = getAuthToken();

    if (matched.path && matched.isError) {
      navigate(matched.path);
    }

    if (matched.isolated) {
      return null;
    }

    //  User is fully registered but they are trying to access a public view
    if (!matched.restricted && authToken && account.first_name.length) {
      return redirectToApp(navigate);
    }

    // User is fully registered and they're trying to access the app
    if (matched.restricted && authToken && account.first_name.length) {
      return redirectToApp(navigate);
    }

    // User is partially registered and they're trying to access the app
    if (
      matched.restricted &&
      authToken &&
      !account?.first_name.length &&
      location.pathname !== ACCOUNT_SETUP_ROUTE
    ) {
      return redirectToSetup(navigate);
    }

    //User is partially registered and they're trying to access a public view
    if (!matched.restricted && authToken && !account?.first_name.length) {
      return redirectToSetup(navigate);
    }

    /**
     * User is not authenticated but they are trying to access a restricted view
     * OR
     * User is not authenticated and they are trying to access a public
     * view
     */
    if ((matched.restricted && !authToken) || (!matched.restricted && !authToken)) {
      return [redirectToLogin(navigate)];
    }

    return null;
  }
};

const redirectToLogin = (navigate: NavigateFunction) => {
  if (matched.restricted && !authToken) {
    return [navigate(LOGIN_ROUTE)];
  }
};

const redirectToSetup = (navigate: NavigateFunction) => {
  return navigate(ACCOUNT_SETUP_ROUTE);
};

const redirectToApp = (navigate: NavigateFunction) => {
  //user is registered and they're trying to access a public view
  if (!matched.restricted && authToken) {
    return navigate(ACCOUNTS_ROUTE);
  }
  //user is fully registered and they're trying to access the account setup page
  else if (matched.restricted && authToken && location.pathname === ACCOUNT_SETUP_ROUTE) {
    return navigate(ACCOUNTS_ROUTE);
  }
};

const Initialization = (props: RoutesProps) => {
  const { children } = props;
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate: NavigateFunction = useNavigate();
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const { data: accountResponse, isPending: isLoadingAccount } = useGetAccount();
  matched = getCurrentRouteContext(location);

  useEffect(() => {
    const initialize = async () => {
      dispatch(setLayoutPageLoader(true));
      if (isLoadingAccount) {
        await new Promise<void>((resolve) => {
          const interval = setInterval(() => {
            if (!isLoadingAccount) {
              clearInterval(interval);
              resolve();
            }
          }, 100);
        });
      }

      if (accountResponse?.data) {
        await checkJwtAuth(location, navigate, accountResponse.data);
      }
      if (authToken) {
        if (isInitialLoad) {
          await Promise.all([getConfigurations(), getAccount(), getWallets(), getTransactions()]);
          setIsDataLoaded(true);
          setIsInitialLoad(false);
        }
      } else {
        redirectToLogin(navigate);
        setIsDataLoaded(true);
      }

      dispatch(setLayoutPageLoader(false));
    };

    initialize();
  }, [location, navigate, dispatch, isInitialLoad, accountResponse, isLoadingAccount]);

  if (!isDataLoaded) {
    return null;
  }

  return children;
};

export default Initialization;
