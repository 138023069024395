import { useFormik } from 'formik';
import {
  DEPOSIT_AMOUNT_KEY,
  DEPOSIT_BANK_TRANSFER_KEY,
  DEPOSIT_CANCEL_URL_KEY,
  DEPOSIT_CASHAPP_KEY,
  DEPOSIT_CURRENCY_KEY,
  DEPOSIT_OTC_KEY,
  DEPOSIT_PAYMENT_OPTION_KEY,
  DEPOSIT_PAYPAL_KEY,
  DEPOSIT_PLATFORM_KEY,
  DEPOSIT_REVOLUT_KEY,
  DEPOSIT_SUCCESS_URL_KEY,
  DEPOSIT_ZELLE_KEY,
} from 'lib/constants/accounts/deposit';
import { useGetDepositTransationLimits } from 'lib/utils';
import { useNavigate } from 'react-router-dom';
import { ACCOUNTS_DEPOSIT_FINALIZE_ROUTE, END_DEPOSIT_ROUTE } from 'routes/path';
import { useDepositPayment } from 'services/account';
import { useGetWallets } from 'services/wallet';
import { useAppSelector } from 'store';
import { WALLET_ID_KEY, WALLET_SLICE_REDUCER_PATH } from 'store/slices/wallet/constants';
import { formatAmount, removeNonDigit } from 'utils/numberFormat';
import { number, object, string } from 'yup';

export default function useDepositForm() {
  const navigate = useNavigate();
  const { mutate: depositPaymentMutation, error, isError, isPending } = useDepositPayment();
  const limits = useGetDepositTransationLimits();
  const { data: wallets } = useGetWallets();
  const { [WALLET_ID_KEY]: walletId } = useAppSelector((state) => state[WALLET_SLICE_REDUCER_PATH]);
  const activeWallet =
    walletId.length > 0 ? wallets?.data.find((x) => x.id === walletId) : wallets?.data[0];

  // const dispatch = useAppDispatch();
  const url = `${import.meta.env.VITE_PLATNOVA_WEB_APP_URL}${END_DEPOSIT_ROUTE}`;
  const initialValues = {
    [DEPOSIT_AMOUNT_KEY]: '',
    [DEPOSIT_CURRENCY_KEY]: '',
    [DEPOSIT_PAYMENT_OPTION_KEY]: '',
    [DEPOSIT_PLATFORM_KEY]: 'app',
    [DEPOSIT_SUCCESS_URL_KEY]: url,
    [DEPOSIT_CANCEL_URL_KEY]: url,
  };

  let validationSchema = object().shape({
    [DEPOSIT_AMOUNT_KEY]: number()
      .transform((value, originalValue) => {
        return Number(removeNonDigit(String(originalValue)));
      })
      .required('Kindly input amount'),
    [DEPOSIT_CURRENCY_KEY]: string().required(),
    [DEPOSIT_PAYMENT_OPTION_KEY]: string().required('Kindly select a payment option'),
  });

  if (limits && activeWallet) {
    validationSchema = object().shape({
      [DEPOSIT_AMOUNT_KEY]: number()
        .transform((value, originalValue) => {
          return Number(removeNonDigit(String(originalValue)));
        })
        .required('Kindly input amount')
        .min(
          limits?.min ?? 0,
          `Minimum ${formatAmount(String(limits?.min))} ${activeWallet?.type.toUpperCase()}`
        )
        .max(
          limits?.max ?? 0,
          `Maximum ${formatAmount(String(limits?.max))} ${activeWallet?.type.toUpperCase()}`
        ),
      [DEPOSIT_CURRENCY_KEY]: string().required(),
      [DEPOSIT_PAYMENT_OPTION_KEY]: string().required('Kindly select a payment option'),
    });
  }

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values) => {
      const amount = Number(removeNonDigit(String(values[DEPOSIT_AMOUNT_KEY])));
      const updatedValues = { ...values, [DEPOSIT_AMOUNT_KEY]: amount };
      if (
        values[DEPOSIT_PAYMENT_OPTION_KEY] === DEPOSIT_OTC_KEY ||
        values[DEPOSIT_PAYMENT_OPTION_KEY] === DEPOSIT_BANK_TRANSFER_KEY ||
        values[DEPOSIT_PAYMENT_OPTION_KEY] === DEPOSIT_PAYPAL_KEY ||
        values[DEPOSIT_PAYMENT_OPTION_KEY] === DEPOSIT_ZELLE_KEY ||
        values[DEPOSIT_PAYMENT_OPTION_KEY] === DEPOSIT_REVOLUT_KEY ||
        values[DEPOSIT_PAYMENT_OPTION_KEY] === DEPOSIT_CASHAPP_KEY
      ) {
        navigate(ACCOUNTS_DEPOSIT_FINALIZE_ROUTE);
      } else {
        depositPaymentMutation(updatedValues, {
          onSuccess: (res) => {
            window.open(res.data, '_self');
            // dispatch(setDepositRedirectUrl(res.data));
            // navigate(DEPOSIT_REDIRECT_ROUTE(values[DEPOSIT_PAYMENT_OPTION_KEY]));
          },
        });
      }
    },
    validationSchema: validationSchema,
  });

  function getInputProps(id: keyof typeof formik.values) {
    return {
      ...formik.getFieldProps(id),
      ...formik.getFieldMeta(id),
      ...formik.getFieldHelpers(id),
      ...formik.errors,
    };
  }

  return Object.freeze({
    onSubmit: formik.handleSubmit,
    setValue: formik.setFieldValue,
    isValid: formik.isValid,
    errors: formik.errors,
    depositError: error,
    isError: isError,
    isPending: isPending,
    values: formik.values,
    getInputProps,
    setError: formik.setErrors,
  });
}
