import axios, { AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios';
import { getAuthToken } from 'services';

import cookie from './cookie';

const http = axios.create({
  baseURL: import.meta.env.VITE_API_URL + '/v1',
  withCredentials: false,
  timeout: 90000,
  headers: {
    'Content-Type': 'application/json',
    'api-version': import.meta.env.VITE_API_VERSION,
  },
});

http.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    if (config.headers === undefined) {
      config.headers = {};
    }

    const internalConfig = config as InternalAxiosRequestConfig<unknown>;

    const token = getAuthToken();
    if (token) {
      internalConfig.headers['Authorization'] = `Bearer ${token.toString()}`;
    }

    return internalConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    if (!error.response) {
      return Promise.reject(error);
    }
    switch (error.response.status) {
      case 401:
        httpLogout();
        return Promise.reject(error);
      case 404:
        return Promise.reject(error);
      case 422:
        return Promise.reject(error);
      case 429:
        return Promise.reject(error);

      default:
        break;
    }
    return Promise.reject(error);
  }
);

export const httpLogout = () => {
  cookie.deleteAll();
};

export default http;
