import Button from 'components/Buttons/Button';
import { ChevronLeft, CircleCheck, Minus, Plus } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { ACCOUNTS_ROUTE } from 'routes/path';
import { useAppDispatch, useAppSelector } from 'store';
import {
  resetCardState,
  setCreatePhysicalCardStage,
  setNumberOfPhysicalCards,
} from 'store/slices/cards/cardsSlice';
import {
  ACTIVE_CARD_BRAND,
  CARD_SLICE_REDUCER_PATH,
  PHYSICAL_CARD_COLOR_KEY,
  PHYSICAL_CARD_NUMBER,
} from 'store/slices/cards/constants';
import SubRouteLayout from 'views/dashboard/components/subRouteLayout';

import ChooseCardColor from './components/chooseCardColor';
import OrderPhysicalCardForm from './components/orderPhysicalCardForm';
import SelectCardType from './components/selectCardType';
import DashboardCardLayout from '../components/dashboardCardLayout';

const CreatePhysicalCard = () => {
  const {
    [PHYSICAL_CARD_NUMBER]: physicalCardNumber,
    stage,
    [PHYSICAL_CARD_COLOR_KEY]: color,
    [ACTIVE_CARD_BRAND]: activeCard,
  } = useAppSelector((state) => state[CARD_SLICE_REDUCER_PATH]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const increaseNumber = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    dispatch(setNumberOfPhysicalCards(physicalCardNumber + 1));
  };

  const decreaseNumber = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    physicalCardNumber > 1 ? dispatch(setNumberOfPhysicalCards(physicalCardNumber - 1)) : null;
  };

  return (
    <div>
      {stage !== 'success' && (
        <SubRouteLayout
          header={stage === 'create' ? 'Create Physical card' : 'Order physical card'}
        >
          <div className="pb-20 xl:-ml-[4.688rem]">
            <div className="xl:flex items-start xl:space-x-11">
              <button
                onClick={() => {
                  stage === 'create' && navigate(-1);
                  stage === 'order' && dispatch(setCreatePhysicalCardStage({ stage: 'create' }));
                }}
                className="flex space-x-1.5 xl:space-x-3 items-center"
              >
                <ChevronLeft className="w-3 h-3 xl:w-6 xl:h-6" />
                <span className="text-xs xl:text-base">Back</span>
              </button>

              {stage === 'create' && (
                <div className="mt-5 xl:mt-0">
                  <div className="mb-10">
                    <p className="text-lg md:text-2xl font-medium">Choose color</p>
                    <p className="text-xs md:text-base text-black/70">
                      Select a color for your physical card
                    </p>
                  </div>

                  <ChooseCardColor type="physical" />

                  <DashboardCardLayout className="mt-14">
                    <p className="text-sm  font-medium md:text-base">
                      Please select the number of cards you want
                    </p>
                    <div className="w-10/12 mx-auto flex justify-between mt-5">
                      <button
                        className="bg-[#DBDBDB] rounded-full p-1 md:p-2"
                        onClick={(e) => decreaseNumber(e)}
                      >
                        <Minus color="white" />
                      </button>
                      <div className="bg-[#FAFAFA] w-2/3 flex justify-center items-center text-base">
                        {physicalCardNumber}
                      </div>
                      <button
                        className="bg-[#DBDBDB] rounded-full p-1 md:p-2"
                        onClick={(e) => increaseNumber(e)}
                      >
                        <Plus color="white" />
                      </button>
                    </div>
                  </DashboardCardLayout>

                  <DashboardCardLayout className="mt-5">
                    <SelectCardType type="physical" />
                  </DashboardCardLayout>

                  <Button
                    variant="purple"
                    className="mt-14 w-full"
                    onClick={(e) => {
                      e.preventDefault();
                      if (color.class && activeCard) {
                        dispatch(setCreatePhysicalCardStage({ stage: 'order' }));
                      }
                    }}
                  >
                    Continue
                  </Button>
                </div>
              )}

              {stage === 'order' && (
                <div className="mt-5 xl:mt-0">
                  <div className="mb-10">
                    <p className="text-lg md:text-2xl font-medium">Order Physical card</p>
                    <p className="text-xs md:text-base text-black/70">
                      Enter your delivery information
                    </p>

                    <OrderPhysicalCardForm />
                  </div>
                </div>
              )}
            </div>
          </div>
        </SubRouteLayout>
      )}

      {stage === 'success' && (
        <div className="flex w-full px-5 md:max-w-lg md:mx-auto pt-[20%] flex-col items-center space-y-3">
          <CircleCheck className="text-[#17B643]" size={83} />
          <p className="text-lg md:text-2xl font-medium">Order Placed Successfully</p>
          <p className="text-base"></p>
          <Button
            onClick={() => {
              dispatch(resetCardState());
              navigate(ACCOUNTS_ROUTE);
            }}
            variant="purple"
            className="w-full"
            type="button"
          >
            Go home
          </Button>
        </div>
      )}
    </div>
  );
};

export default CreatePhysicalCard;
