import AccountSetupForm from './components/accountSetupForm';
import AuthCardLayout from '../authCardLayout';
import AuthLayout from '../layout';

const AccountSetup = () => {
  return (
    <AuthLayout>
      <AuthCardLayout
        title="Account setup"
        subtitle="Enter your details to complete your account setup"
      >
        <AccountSetupForm />
      </AuthCardLayout>
    </AuthLayout>
  );
};

export default AccountSetup;
