import Flag from 'components/flag';
import Modal, { ModalProps } from 'components/modal';
import { X } from 'lucide-react';
import { useAddWallet, useGetSupportedWallets, useGetWallets } from 'services/wallet';

const NewAccountModal = ({ ...props }: ModalProps) => {
  const { data: wallets } = useGetWallets();
  const { data: supportedWallets } = useGetSupportedWallets();

  const { mutate: addWallet } = useAddWallet();

  const walletIds = new Set(wallets?.data.map((item) => item.country.code));
  const filteredSupported = supportedWallets?.data.filter(
    (item) => !walletIds.has(item.country.code)
  );

  const handleAddWallet = (code: string) => {
    addWallet({ currency: code });
    props.close();
  };

  return (
    <Modal {...props} className="py-7 px-[5%] xl:px-5 xl:w-full xl:max-w-xl">
      <div className="">
        <div className="flex justify-between">
          <p className="text-lg xl:text-2xl font-medium">Add Account</p>
          <X size={25} className="cursor-pointer" onClick={props.close} />
        </div>

        <div className="mt-12 space-y-5">
          {filteredSupported?.map((wallet) => (
            <button
              onClick={() => handleAddWallet(wallet.type.toUpperCase())}
              key={wallet.country.code}
              className="w-full flex items-center rounded-[10px] space-x-5 p-2.5 hover:bg-platnova-secondary"
            >
              <Flag code={wallet.country.code} />
              <div className="flex flex-col items-start">
                <p className="text-sm md:text-base font-medium">{wallet.description}</p>
                <p className="text-xs text-grey-silent-text capitalize">
                  {wallet.type.toUpperCase()}
                </p>
              </div>
            </button>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default NewAccountModal;
