const UserTick = () => {
  return (
    <div className="w-7 h-7">
      <svg
        className="w-full h-full"
        width="1em"
        height="1em"
        viewBox="0 0 26 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M24.1333 12.1654L26 14.0454L17.2933 22.832L12.6667 18.1654L14.5333 16.2854L17.2933 19.0587L24.1333 12.1654ZM9.33333 18.1654L13.3333 22.1654H0V19.4987C0 16.552 4.77333 14.1654 10.6667 14.1654L13.1867 14.312L9.33333 18.1654ZM10.6667 0.832031C12.0812 0.832031 13.4377 1.39393 14.4379 2.39413C15.4381 3.39432 16 4.75088 16 6.16536C16 7.57985 15.4381 8.93641 14.4379 9.9366C13.4377 10.9368 12.0812 11.4987 10.6667 11.4987C9.25218 11.4987 7.89562 10.9368 6.89543 9.9366C5.89524 8.93641 5.33333 7.57985 5.33333 6.16536C5.33333 4.75088 5.89524 3.39432 6.89543 2.39413C7.89562 1.39393 9.25218 0.832031 10.6667 0.832031Z"
          fill="#7049F7"
        />
      </svg>
    </div>
  );
};

export default UserTick;
