import { useFormik } from 'formik';
import {
  FORGOT_PASSWORD_EMAIL_KEY,
  FORGOT_PASSWORD_OTP_KEY,
} from 'lib/constants/auth/forgotPassword';
import { useNavigate } from 'react-router-dom';
import { FORGOT_PASSWORD_RESET_ROUTE } from 'routes/path';
import { useVerifyForgotPassword } from 'services/auth/forgotPassword';
import { useAppDispatch, useAppSelector } from 'store';
import { FORGOT_PASSWORD_SLICE_REDUCER_PATH } from 'store/slices/forgotPassword/constants';
import { setForgotPasswordOtp } from 'store/slices/forgotPassword/forgotPasswordSlice';
import { object, string } from 'yup';

export default function useVerifyForgotPasswordForm() {
  const { mutate, error, isError, isPending } = useVerifyForgotPassword();
  const navigate = useNavigate();
  const { [FORGOT_PASSWORD_EMAIL_KEY]: email } = useAppSelector(
    (state) => state[FORGOT_PASSWORD_SLICE_REDUCER_PATH]
  );

  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      [FORGOT_PASSWORD_EMAIL_KEY]: email,
      [FORGOT_PASSWORD_OTP_KEY]: '',
    },
    onSubmit: (values) => {
      mutate(values, {
        onSuccess: () => {
          dispatch(setForgotPasswordOtp(values[FORGOT_PASSWORD_OTP_KEY]));
          navigate(FORGOT_PASSWORD_RESET_ROUTE);
        },
      });
    },
    validationSchema: object().shape({
      [FORGOT_PASSWORD_OTP_KEY]: string()
        .min(4, 'Invalid Token')
        .max(4, 'Invalid Token')
        .required('token cannot be empty'),
    }),
  });

  function getInputProps(id: keyof typeof formik.values) {
    return {
      ...formik.getFieldProps(id),
      ...formik.getFieldMeta(id),
      ...formik.getFieldHelpers(id),
      ...formik.errors,
    };
  }

  return Object.freeze({
    onSubmit: formik.handleSubmit,
    getInputProps,
    setValue: formik.setFieldValue,
    isValid: formik.isValid,
    errors: formik.errors,
    verifyForgotPasswordError: error,
    isPending: isPending,
    isError: isError,
    values: formik.values,
  });
}
