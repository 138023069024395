import { useMutation } from '@tanstack/react-query';
import { USER_TOKEN_COOKIE_KEY } from 'services/constants';
import cookie from 'services/cookie';
import { GeneralApiResponse } from 'types';
import { AuthUser } from 'types/auth';

import {
  patchSetupAccount,
  postRegister,
  postRegisterAccountVerification,
  postResendRegistrationOtp,
  postSetPassword,
} from './function';
import { registerKeys } from './key';

export const useRegisterAccount = () => {
  // const queryClient = useQueryClient()
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: postRegister,
    // onSuccess: () => queryClient
  });

  return { mutate, isPending, isError, error };
};

export const useRegisterAccountVerification = () => {
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: postRegisterAccountVerification,
  });

  return { mutate, isPending, isError, error };
};

export const useResendRegistrationOtp = () => {
  const { mutate, error } = useMutation({
    mutationFn: postResendRegistrationOtp,
  });

  return { mutate, error };
};

export const useSetPassword = () => {
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: postSetPassword,
    onSuccess: (res: GeneralApiResponse<AuthUser>) => {
      cookie.setWithExpiry(USER_TOKEN_COOKIE_KEY, res.data.token, 3600);
    },
  });

  return { mutate, isPending, isError, error };
};

export const useSetupAccount = () => {
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: patchSetupAccount,
    onSuccess: () => {
      localStorage.setItem(registerKeys.IS_FIRST_LOGIN_KEY, '1');
    },
  });

  return { mutate, isPending, isError, error };
};
