import ForgotPasswordForm from './components/forgotPasswordForm';
import AuthCardLayout from '../authCardLayout';
import AuthLayout from '../layout';

const ForgotPassword = () => {
  return (
    <AuthLayout>
      <AuthCardLayout
        title="Forgot password?"
        subtitle="We would send a code to your email address to reset your password"
      >
        <ForgotPasswordForm />
      </AuthCardLayout>
    </AuthLayout>
  );
};

export default ForgotPassword;
