const CardPlus = () => {
  return (
    <div className="w-7 h-7">
      <svg
        className="w-full h-full"
        width="1em"
        height="1em"
        viewBox="0 0 30 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.33268 11.4987H24.666V6.16536H3.33268V11.4987ZM23.3327 24.832V20.832H19.3327V18.1654H23.3327V14.1654H25.9993V18.1654H29.9993V20.832H25.9993V24.832H23.3327ZM3.33268 22.1654C2.59935 22.1654 1.97179 21.9045 1.45002 21.3827C0.928238 20.8609 0.666905 20.2329 0.666016 19.4987V3.4987C0.666016 2.76536 0.927349 2.13781 1.45002 1.61603C1.97268 1.09425 2.60024 0.83292 3.33268 0.832031H24.666C25.3993 0.832031 26.0273 1.09336 26.55 1.61603C27.0727 2.1387 27.3336 2.76625 27.3327 3.4987V11.4987H23.3327C21.4882 11.4987 19.9162 12.1489 18.6167 13.4494C17.3171 14.7498 16.6669 16.3218 16.666 18.1654V22.1654H3.33268Z"
          fill="#7049F7"
        />
      </svg>
    </div>
  );
};

export default CardPlus;
