import http from 'services/http';
import { GeneralApiResponse } from 'types';
import { ForgotPasswordInput, ResetPasswordInput, VerifyForgotPasswordInput } from 'types/auth';

export const postForgotPassword = async (
  data: ForgotPasswordInput
): Promise<GeneralApiResponse<object>> => {
  const res = await http.post('/auth/password/reset', data);
  return res.data;
};

export const postResetPassword = async (
  data: ResetPasswordInput
): Promise<GeneralApiResponse<object>> => {
  const res = await http.post('/auth/password/reset/change', data);
  return res.data;
};

export const postVerifyForgotPassword = async (
  data: VerifyForgotPasswordInput
): Promise<GeneralApiResponse<object>> => {
  const res = await http.post('/auth/password/reset/confirm', data);
  return res.data;
};
