export const BASE_ROUTE = '/home' as const;
export const LOGOUT_ROUTE = '/logout' as const;

export const LOGIN_ROUTE = '/' as const;
export const VERIFY_LOGIN_ROUTE = '/auth/login/verify' as const;

export const FORGOT_PASSWORD_ROUTE = '/auth/forgot-password' as const;
export const VERIFY_FORGOT_PASSWORD_CODE_ROUTE = '/auth/forgot-password/verify-code' as const;
export const FORGOT_PASSWORD_RESET_ROUTE = '/auth/forgot-password/reset-password' as const;

export const REGISTER_ROUTE = '/auth/register' as const;
export const ACCOUNT_VERIFICATION_ROUTE = '/auth/register/account-verification' as const;
export const SETUP_PASSWORD_ROUTE = '/auth/register/set-password' as const;
export const ACCOUNT_SETUP_ROUTE = '/account-setup' as const;

export const VERIFICATION_ROUTE = '/verification' as const;
// export const DOCUMENT_TYPE_ROUTE = '/verification/document' as const;
export const ID_VERIFICATION_ROUTE = (id: string) => `/verification/document/${id}` as const;
export const SELFIE_ROUTE = '/verification/selfie' as const;
export const SELFIE_SUCCESS_ROUTE = '/verification/selfie/success' as const;
export const SELFIE_ERROR_ROUTE = '/verification/selfie/error' as const;
export const SELFIE_CANCEL_ROUTE = '/verification/selfie/cancel' as const;
export const UPLOAD_DOCUMENT_ROUTE = '/verification/document/upload' as const;
export const SUMSUB_ROUTE = '/verification/sumsub' as const;

export const ERROR_404_ROUTE = '/error/404' as const;

export const ACCOUNTS_ROUTE = '/accounts' as const;
export const MY_ACCOUNTS_ROUTE = '/my-accounts' as const;
export const WALLET_ACCOUNT_DETAILS_ROUTE = (type: string) => `/accounts/settings/${type}` as const;
export const ACCOUNTS_SETTINGS_ROUTE = '/accounts/settings' as const;
export const ACCOUNTS_DEPOSIT_ROUTE = '/deposit' as const;
export const ACCOUNTS_DEPOSIT_FINALIZE_ROUTE = '/deposit/finalize' as const;
export const DEPOSIT_REDIRECT_ROUTE = (name: string) => `/deposit/${name}` as const;
export const DEPOSIT_BANK_ACCOUNT_ROUTE = (currency: string) =>
  `/deposit/bank-account/${currency}` as const;
export const END_DEPOSIT_ROUTE = '/payments/process/' as const;

export const ACCOUNTS_SEND_ROUTE = '/send' as const;
export const ACCOUNTS_FINALIZE_SEND_ROUTE = (method: string) => `/send/${method}` as const;

export const ACCOUNTS_CONVERT_ROUTE = '/convert' as const;

export const SETTINGS_ROUTE = '/settings' as const;
export const ACCOUNT_DETAILS_ROUTE = '/settings/account' as const;
export const SETTINGS_SECURITY_ROUTE = '/settings/security' as const;
export const SETTINGS_PIN_ROUTE = `/settings/security/pin/` as const;
export const SETTINGS_BENEFICIARIES_ROUTE = '/settings/beneficiaries' as const;
export const SETTINGS_BENEFICIARY_DETAILS = (id: string) =>
  `/settings/beneficiaries/${id}` as const;
export const REFER_ROUTE = '/settings/refer-and-earn' as const;
export const CHANGE_PASSWORD_ROUTE = '/settings/security/change-password' as const;

export const TRANSACTIONS_ROUTE = '/transactions' as const;
export const TRANSACTION_DETAILS_ROUTE = (id: string) => `/transactions/${id}` as const;

export const CARDS_ROUTE = '/cards' as const;
export const CREATE_PHYSICAL_CARD_ROUTE = '/cards/create-physical-card' as const;
export const CREATE_VIRTUAL_CARD_ROUTE = '/cards/create-virtual-card' as const;
