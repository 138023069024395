import Modal, { ModalProps } from 'components/modal';
import {
  DEPOSIT_BANK_TRANSFER_KEY,
  DEPOSIT_CARD_KEY,
  DEPOSIT_CRYPTO_KEY,
  DEPOSIT_PAYMENT_METHOD_KEY,
} from 'lib/constants/accounts/deposit';
import { SEND_PAYMENT_METHOD_KEY } from 'lib/constants/accounts/send';
import { useGetPaymentMethods, useGetSendPaymentMethods } from 'lib/utils';
import { Check, X } from 'lucide-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DEPOSIT_BANK_ACCOUNT_ROUTE } from 'routes/path';
import { useAppDispatch, useAppSelector } from 'store';
import {
  setDuration,
  setPaymentMethod,
  setSendPaymentMethod,
} from 'store/slices/account/accountsSlice';
import { ACCOUNTS_SLICE_REDUCER_PATH } from 'store/slices/account/constants';
import { setComingSoonModalOpen } from 'store/slices/layout/layoutSlice';
import DashboardCardLayout from 'views/dashboard/components/dashboardCardLayout';

interface PaymentMethodModlProps extends ModalProps {
  type: 'deposit' | 'send';
}

const PaymentMethodModal = ({ ...props }: PaymentMethodModlProps) => {
  const {
    [DEPOSIT_PAYMENT_METHOD_KEY]: payment_method,
    [SEND_PAYMENT_METHOD_KEY]: sendPaymentMethod,
  } = useAppSelector((state) => state[ACCOUNTS_SLICE_REDUCER_PATH]);
  const paymentMethods = useGetPaymentMethods();
  const sendPaymentMethods = useGetSendPaymentMethods();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleSelect = (key: string, duration: string) => {
    dispatch(setPaymentMethod(key));
    dispatch(setDuration(duration));
    props.close();
  };

  const handleSendSelect = (key: string, duration: string) => {
    dispatch(setSendPaymentMethod(key));
    dispatch(setDuration(duration));
    props.close();
  };

  useEffect(() => {
    if (
      payment_method === DEPOSIT_BANK_TRANSFER_KEY &&
      paymentMethods &&
      props.type === 'deposit'
    ) {
      navigate(DEPOSIT_BANK_ACCOUNT_ROUTE('NGN'));
    } else if (
      (payment_method === DEPOSIT_CARD_KEY || payment_method === DEPOSIT_CRYPTO_KEY) &&
      paymentMethods &&
      props.type === 'deposit'
    ) {
      dispatch(setComingSoonModalOpen(true));
      dispatch(setPaymentMethod(''));
    }
  }, [dispatch, navigate, paymentMethods, payment_method, props.type]);

  return (
    <Modal {...props} className="py-7 px-5 w-full max-w-xl scroll-component">
      <div className="flex justify-between">
        <p className="text-lg xl:text-2xl font-medium">Select a payment method</p>
        <X size={25} className="cursor-pointer" onClick={props.close} />
      </div>

      <p className="mt-2 text-grey-text text-xs xl:text-base">
        This payment method will be used to add money
      </p>

      <div className="mt-10 grid w-full gap-y-5">
        {props.type === 'deposit' &&
          paymentMethods?.map((method) => (
            <button
              key={method.id}
              onClick={() => handleSelect(method.id, method.duration)}
              className="w-full"
            >
              <DashboardCardLayout className="xl:w-[30rem]">
                <div className="flex justify-between">
                  <div className="flex items-center space-x-3 xl:space-x-6">
                    <img
                      src={`https://res.cloudinary.com/platnova/assets/payment_methods/${method.id}`}
                      alt="gg"
                      className="w-6 h-6 xl:w-10 xl:h-10"
                    />
                    <div className="flex flex-col items-start text-start">
                      <p className="text-sm xl:text-base">{method.name}</p>
                      <p className="text-[0.625rem] xl:text-xs text-grey-text">
                        {method.description}
                      </p>
                    </div>
                  </div>
                  {payment_method === method.id && (
                    <Check size={25} className="text-platnova-purple" />
                  )}
                </div>
                {method.channel.length > 0 && (
                  <div className="border-t mt-5 pt-5">
                    <p className="text-start text-[0.625rem] text-grey-text">Supports</p>
                    <div className="flex mt-3 items-center space-x-3">
                      {method.channel.map((item, idx) => (
                        <img
                          src={`https://res.cloudinary.com/platnova/assets/payment_methods/${item}`}
                          alt="gg"
                          key={idx}
                          className="h-3.5 xl:h-5 w-auto"
                        />
                      ))}
                    </div>
                  </div>
                )}
              </DashboardCardLayout>
            </button>
          ))}
        {props.type === 'send' &&
          sendPaymentMethods?.map((method) => (
            <button
              key={method.id}
              onClick={() => handleSendSelect(method.id, method.duration)}
              className="w-full"
            >
              <DashboardCardLayout className="xl:w-[30rem]">
                <div className="flex justify-between">
                  <div className="flex items-center space-x-3 xl:space-x-6">
                    <img
                      src={`https://res.cloudinary.com/platnova/assets/payment_methods/${method.id}`}
                      alt="gg"
                      className="w-6 h-6 xl:w-10 xl:h-10"
                    />
                    <div className="flex flex-col items-start text-start">
                      <p className="text-sm xl:text-base">{method.name}</p>
                      <p className="text-[0.625rem] xl:text-xs text-grey-text">
                        {method.description}
                      </p>
                    </div>
                  </div>
                  {sendPaymentMethod === method.id && (
                    <Check size={25} className="text-platnova-purple" />
                  )}
                </div>
                {method.channel.length > 0 && (
                  <div className="border-t mt-5 pt-5">
                    <p className="text-start text-[0.625rem] text-grey-text">Supports</p>
                    <div className="flex mt-3 items-center space-x-3">
                      {method.channel.map((item, idx) => (
                        <img
                          src={`https://res.cloudinary.com/platnova/assets/payment_methods/${item}`}
                          alt="gg"
                          key={idx}
                          className="h-3.5 xl:h-5 w-auto"
                        />
                      ))}
                    </div>
                  </div>
                )}
              </DashboardCardLayout>
            </button>
          ))}
      </div>
    </Modal>
  );
};

export default PaymentMethodModal;
