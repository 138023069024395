import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { setInactiveModalIsOpen } from 'store/slices/account/accountsSlice';
import { ACCOUNTS_SLICE_REDUCER_PATH, IS_ACTIVE } from 'store/slices/account/constants';

const ActionButton = ({ icon, text, href }: { icon: ReactNode; text: string; href: string }) => {
  const { [IS_ACTIVE]: userIsActive } = useAppSelector(
    (state) => state[ACCOUNTS_SLICE_REDUCER_PATH]
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleInactiveRedirect = (url: string) => {
    userIsActive ? navigate(url) : dispatch(setInactiveModalIsOpen(true));
  };
  return (
    <button onClick={() => handleInactiveRedirect(href)} className="flex flex-col items-center">
      <div className="bg-platnova-secondary w-max p-4 rounded-xl xl:rounded-2xl">{icon}</div>
      <p className="mt-1 text-platnova-purple text-xs md:text-base">{text}</p>
    </button>
  );
};

export default ActionButton;
