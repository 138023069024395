import { ChevronLeft } from 'lucide-react';
import { useEffect } from 'react';
import { NOVA_USER_VERIFICATION_KEY } from 'services/constants';
import cookie from 'services/cookie';
import { useAppDispatch, useAppSelector } from 'store';
import {
  VERIFICATION_SLICE_REDUCER_PATH,
  VERIFICATION_SLICE_STEP,
} from 'store/slices/verification/constants';
import { setVerificationStep } from 'store/slices/verification/verificationSlice';
import { NovaUserVerification } from 'types/auth';
import AuthCardLayout from 'views/auth/authCardLayout';

import ChooseDocumentForm from './components/chooseDocumentForm';
import AuthLayout from '../auth/layout';

const ChooseDocument = () => {
  const dispatch = useAppDispatch();

  const account: NovaUserVerification = cookie.get(NOVA_USER_VERIFICATION_KEY);
  const { [VERIFICATION_SLICE_STEP]: step } = useAppSelector(
    (state) => state[VERIFICATION_SLICE_REDUCER_PATH]
  );

  useEffect(() => {
    null;
  }, [step, dispatch]);

  return (
    <AuthLayout>
      <div className="w-full flex">
        <button
          onClick={() => {
            dispatch(setVerificationStep(0));
            cookie.setWithExpiry(
              NOVA_USER_VERIFICATION_KEY,
              { ...account, verificationStep: 0 },
              600
            );
          }}
          className="mr-[5%] text-xs xl:text-base text-black/70 flex items-center space-x-1"
        >
          <ChevronLeft size={16} />
          <p>Back</p>
        </button>
      </div>
      <AuthCardLayout
        title="Document type"
        subtitle="If you can't find your document type or country of issue, contact support"
      >
        <ChooseDocumentForm />
      </AuthCardLayout>
    </AuthLayout>
  );
};

export default ChooseDocument;
