import { ChevronLeft, Copy, Download } from 'lucide-react';
import { useNavigate, useParams } from 'react-router-dom';
import { TRANSACTIONS_ROUTE } from 'routes/path';
import { useGetTransctions } from 'services/transactions';
import { useAppDispatch } from 'store';
import { setComingSoonModalOpen } from 'store/slices/layout/layoutSlice';
import { copyToClipboard } from 'utils/clipboard';
import { formatAmount, truncateText } from 'utils/numberFormat';

import RecentActivityStatus from '../accounts/components/recentActivityStatus';
import DashboardCardLayout from '../components/dashboardCardLayout';
import SubRouteLayout from '../components/subRouteLayout';

const TransactionDetails = () => {
  const { data: transactions } = useGetTransctions();
  const dispatch = useAppDispatch();

  const { id } = useParams();
  const transaction = transactions?.data.items.find((x) => x.id === id);
  const navigate = useNavigate();
  return (
    <SubRouteLayout header="Transaction Details" closeRoute={TRANSACTIONS_ROUTE}>
      {transaction && (
        <div className="pb-20 xl:-ml-[4.688rem] w-full max-w-[52rem]">
          <div className="xl:flex items-start xl:space-x-11 w-full">
            <button
              onClick={() => navigate(-1)}
              className="flex space-x-1.5 xl:space-x-3 items-center"
            >
              <ChevronLeft className="w-3 h-3 xl:w-6 xl:h-6" />
              <span className="text-xs xl:text-base">Back</span>
            </button>

            <div className="flex flex-col w-full">
              <div className="mt-5 xl:mt-0 flex justify-between">
                <div>
                  <p className="font-medium text-xl xl:text-2xl">
                    {transaction.wallet.type.toUpperCase()} {formatAmount(transaction?.amount)}
                  </p>
                  <p className="mt-2 text-grey-text text-xs xl:text-base">{transaction.message}</p>
                </div>
                <button
                  type="button"
                  // onClick={() => dispatch(setComingSoonModalOpen(true))}
                  className="p-3 xl:p-3 h-max flex items-center space-x-2 text-xs xl:text-base xl:mt-0"
                >
                  <button onClick={() => dispatch(setComingSoonModalOpen(true))}>Receipt</button>
                  <Download size={16} />
                </button>
              </div>

              <DashboardCardLayout className="w-full xl:w-full mt-14 space-y-7">
                <div className="flex justify-between">
                  <p className="text-sm md:text-base text-grey-text">Ref</p>
                  <div className="flex space-x-2">
                    <p className="text-xs md:text-base font-medium">
                      {truncateText(transaction.ref, 30)}
                    </p>
                    <button onClick={() => copyToClipboard('Transaction ref', transaction.ref)}>
                      <Copy size={15} className="text-platnova-purple" />
                    </button>
                  </div>
                </div>

                <div className="flex justify-between">
                  <p className="text-sm md:text-base text-grey-text">Time</p>
                  <div className="flex space-x-2">
                    <p className="text-xs md:text-base font-medium">
                      {new Date(transaction.created_at).toLocaleString()}
                    </p>
                  </div>
                </div>

                <div className="flex justify-between">
                  <p className="text-sm md:text-base text-grey-text">Status</p>
                  <RecentActivityStatus status={transaction.status} />
                </div>
              </DashboardCardLayout>

              {transaction.type === 'deposit' && (
                <DashboardCardLayout className="mt-7 w-full xl:w-full space-y-7">
                  <p>Deposit info</p>
                  <div className="flex justify-between">
                    <p className="text-sm md:text-base text-grey-text">Method</p>
                    <div className="flex space-x-2">
                      <p className="text-xs md:text-base font-medium">
                        {transaction.deposit?.method === 'bank_transfer'
                          ? 'Bank Transfer'
                          : transaction.deposit?.method}
                      </p>
                    </div>
                  </div>
                </DashboardCardLayout>
              )}

              {transaction.type === 'convert_payment' && (
                <DashboardCardLayout className="mt-7 w-full xl:w-full space-y-7">
                  <p>Convert info</p>
                  <div className="flex justify-between">
                    <p className="text-sm md:text-base text-grey-text">Conversion Amount</p>
                    <div className="flex space-x-2">
                      <p className="text-xs md:text-base font-medium">
                        {formatAmount(String(transaction.payment_transfer?.info.amount))}
                        {transaction.payment_transfer?.info.currency}
                      </p>
                    </div>
                  </div>
                </DashboardCardLayout>
              )}

              {transaction.type === 'send_payment' && (
                <DashboardCardLayout className="mt-7 w-full xl:w-full space-y-7">
                  <p>Payment info</p>
                  <div className="flex justify-between">
                    <p className="text-sm md:text-base text-grey-text">Method</p>
                    <div className="flex space-x-2">
                      <p className="text-xs md:text-base font-medium">
                        {transaction.payment_transfer?.method === 'bank_transfer'
                          ? 'Bank Transfer'
                          : transaction.deposit?.method}
                      </p>
                    </div>
                  </div>
                  {transaction.payment_transfer?.info.account_number && (
                    <div className="flex justify-between">
                      <p className="text-sm md:text-base text-grey-text">Account number</p>
                      <div className="flex space-x-2">
                        <p className="text-xs md:text-base font-medium">
                          {transaction.payment_transfer?.info.account_number}
                        </p>
                      </div>
                    </div>
                  )}
                  {transaction.payment_transfer?.info.account_name && (
                    <div className="flex justify-between">
                      <p className="text-sm md:text-base text-grey-text">Account name</p>
                      <div className="flex space-x-2">
                        <p className="text-xs md:text-base font-medium">
                          {truncateText(transaction.payment_transfer?.info.account_name, 28)}
                        </p>
                      </div>
                    </div>
                  )}
                  {transaction.payment_transfer?.info.amount && (
                    <div className="flex justify-between">
                      <p className="text-sm md:text-base text-grey-text">Amount</p>
                      <div className="flex space-x-2">
                        <p className="text-xs md:text-base font-medium">
                          {formatAmount(String(transaction.payment_transfer?.info.amount))}
                          {transaction.payment_transfer.info.currency.toUpperCase()}
                        </p>
                      </div>
                    </div>
                  )}
                </DashboardCardLayout>
              )}
            </div>
          </div>
        </div>
      )}
    </SubRouteLayout>
  );
};

export default TransactionDetails;
