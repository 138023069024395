import Button from 'components/Buttons/Button';
import useErrorNotification from 'components/error/useErrorNotification';
import Input from 'components/Inputs/input';
import {
  RESET_PASSWORD_CONFIRM_PASSWORD_KEY,
  RESET_PASSWORD_PASSWORD_KEY,
} from 'lib/constants/auth/forgotPassword';

import useResetPasswordForm from '../hooks/useResetPasswordForm';

const ResetPasswordForm = () => {
  const { getInputProps, onSubmit, isValid, errors, isError, isPending, resetPasswordError } =
    useResetPasswordForm();

  useErrorNotification(isError, resetPasswordError);

  return (
    <form className="mt-5 xl:mt-10 mb-5 space-y-3 xl:space-y-5" onSubmit={onSubmit}>
      <Input
        id={RESET_PASSWORD_PASSWORD_KEY}
        label="Enter password"
        inputMode="text"
        type="password"
        className="xl:w-[29.25rem]"
        placeholder="Enter password"
        {...getInputProps(RESET_PASSWORD_PASSWORD_KEY)}
        error={errors[RESET_PASSWORD_PASSWORD_KEY]}
      />

      <Input
        id={RESET_PASSWORD_CONFIRM_PASSWORD_KEY}
        label="Confirm password"
        {...getInputProps(RESET_PASSWORD_CONFIRM_PASSWORD_KEY)}
        error={errors[RESET_PASSWORD_CONFIRM_PASSWORD_KEY]}
        inputMode="text"
        type="password"
        className="xl:w-[29.25rem]"
        placeholder="Enter password"
      />

      <Button
        variant="purple"
        className="w-full mt-5"
        isDisabled={!isValid || isPending}
        isLoading={isPending}
      >
        Reset
      </Button>
    </form>
  );
};

export default ResetPasswordForm;
