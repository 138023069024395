import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { TRANSACTION_DETAILS_ROUTE } from 'routes/path';
import { Transaction } from 'types/transactions';
import { formatAmount } from 'utils/numberFormat';

import RecentActivityStatus from './recentActivityStatus';

const RecentActivityCard = ({
  transaction,
  date = 'relative',
}: {
  transaction: Transaction;
  date?: 'relative' | 'timestamp';
}) => {
  const navigate = useNavigate();
  return (
    <button
      onClick={() => navigate(TRANSACTION_DETAILS_ROUTE(transaction.id))}
      className="flex justify-between items-center w-full cursor-pointer"
    >
      <div className="flex items-center space-x-2 xl:space-x-5">
        <div className="bg-platnova-secondary w-10 h-10 rounded-full"></div>
        <div>
          <p className="font-medium text-sm md:text-base text-start">{transaction.message}</p>
          <p className="text-xs text-grey-silent-text text-start">
            {date === 'relative' && moment(transaction.created_at).startOf('second').fromNow()}
            {date === 'timestamp' && new Date(transaction.created_at).toLocaleString()}
          </p>
        </div>
      </div>

      <div className="flex flex-col items-end">
        <p className="font-medium text-sm md:text-base">
          {transaction.wallet.symbol} {formatAmount(transaction.amount)}
        </p>
        <RecentActivityStatus status={transaction.status} />
      </div>
    </button>
  );
};

export default RecentActivityCard;
