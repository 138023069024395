export const WALLET_SLICE_REDUCER_PATH = 'wallet' as const;
export const WALLET_ID_KEY = 'wallet_id' as const;
export const TO_ACCOUNT_WALLET_ID_KEY = 'to_account_wallet_id' as const;
export const INSUFFICIENT_FUND_MODAL_IS_OPEN = 'insufficient_fund_modal_is_open' as const;
export const RECIPIENT_CURRENCY_KEY = 'recipient_currency' as const;
export const RECIPIENT_COUNTRY_KEY = 'recipient_country' as const;

export const walletSliceReducerInitialState = {
  [WALLET_ID_KEY]: '',
  [TO_ACCOUNT_WALLET_ID_KEY]: '',
  [INSUFFICIENT_FUND_MODAL_IS_OPEN]: false,
  [RECIPIENT_CURRENCY_KEY]: '',
  [RECIPIENT_COUNTRY_KEY]: '',
};
