const Book = () => {
  return (
    <div className="w-6 h-6 xl:w-10 xl:h-10">
      <svg
        className="w-full h-full"
        width="1em"
        height="1em"
        viewBox="0 0 39 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M34.2369 3.42043H4.76326C3.64656 3.42043 2.5756 3.86404 1.78597 4.65367C0.996342 5.4433 0.552734 6.51426 0.552734 7.63096V23.4204C0.552734 24.5371 0.996342 25.6081 1.78597 26.3977C2.5756 27.1874 3.64656 27.631 4.76326 27.631H16.7801C17.3275 28.2583 18.3348 28.6836 19.5001 28.6836C20.6654 28.6836 21.6727 28.2583 22.2201 27.631H34.2369C35.3536 27.631 36.4246 27.1874 37.2142 26.3977C38.0039 25.6081 38.4475 24.5371 38.4475 23.4204V7.63096C38.4475 6.51426 38.0039 5.4433 37.2142 4.65367C36.4246 3.86404 35.3536 3.42043 34.2369 3.42043Z"
          fill="#7049F7"
        />
        <path
          d="M21.6051 24.4731C21.6051 25.0315 21.3833 25.5669 20.9884 25.9618C20.5936 26.3566 20.0581 26.5784 19.4998 26.5784C18.9414 26.5784 18.406 26.3566 18.0111 25.9618C17.6163 25.5669 17.3945 25.0315 17.3945 24.4731V5.52574C17.3945 4.96739 17.6163 4.43191 18.0111 4.03709C18.406 3.64228 18.9414 3.42048 19.4998 3.42048C20.0581 3.42048 20.5936 3.64228 20.9884 4.03709C21.3833 4.43191 21.6051 4.96739 21.6051 5.52574V24.4731Z"
          fill="#292F33"
        />
        <path
          d="M19.5003 23.4201C19.5003 23.9785 19.2785 24.514 18.8837 24.9088C18.4889 25.3036 17.9534 25.5254 17.395 25.5254H4.76347C4.20512 25.5254 3.66963 25.3036 3.27482 24.9088C2.88001 24.514 2.6582 23.9785 2.6582 23.4201V4.47276C2.6582 3.91441 2.88001 3.37893 3.27482 2.98412C3.66963 2.5893 4.20512 2.3675 4.76347 2.3675H17.395C17.9534 2.3675 18.4889 2.5893 18.8837 2.98412C19.2785 3.37893 19.5003 3.91441 19.5003 4.47276V23.4201Z"
          fill="#EEE9FE"
        />
        <path
          d="M19.5005 23.4209C18.4489 21.3177 15.651 21.3156 15.29 21.3156C13.1847 21.3156 10.0268 23.4209 6.86894 23.4209C5.8163 23.4209 4.76367 22.4777 4.76367 21.3156V4.47353C4.76367 3.31143 5.8163 2.36827 6.86894 2.36827C10.2953 2.36827 13.1847 0.263006 15.29 0.263006C18.4479 0.263006 19.5005 2.25879 19.5005 3.4209V23.4209Z"
          fill="#E1E8ED"
        />
        <path
          d="M36.3421 23.4201C36.3421 23.9785 36.1203 24.514 35.7255 24.9088C35.3307 25.3036 34.7952 25.5254 34.2368 25.5254H21.6053C21.0469 25.5254 20.5114 25.3036 20.1166 24.9088C19.7218 24.514 19.5 23.9785 19.5 23.4201V4.47276C19.5 3.91441 19.7218 3.37893 20.1166 2.98412C20.5114 2.5893 21.0469 2.3675 21.6053 2.3675H34.2368C34.7952 2.3675 35.3307 2.5893 35.7255 2.98412C36.1203 3.37893 36.3421 3.91441 36.3421 4.47276V23.4201Z"
          fill="#EEE9FE"
        />
        <path
          d="M19.5 23.4209C20.5516 21.3177 23.3495 21.3156 23.7105 21.3156C25.8158 21.3156 28.9737 23.4209 32.1316 23.4209C33.1842 23.4209 34.2368 22.4777 34.2368 21.3156V4.47353C34.2368 3.31143 33.1842 2.36827 32.1316 2.36827C28.7042 2.36827 25.8158 0.263006 23.7105 0.263006C20.5526 0.263006 19.5 2.25879 19.5 3.4209V23.4209Z"
          fill="#CCD6DD"
        />
      </svg>
    </div>
  );
};

export default Book;
