import Rectangle from 'assets/images/rectangle.svg';
import ErrorCard from 'assets/logo/errorCard.svg';
import Logo from 'assets/logo/platnova-logo.png';
import Button from 'components/Buttons/Button';
import { Link, useNavigate } from 'react-router-dom';
import { ACCOUNTS_ROUTE } from 'routes/path';

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="h-screen py-10">
      {/* <div className=''>
    </div> */}
      <div className="w-11/12 xl:w-10/12 mx-auto max-w-[1540px]">
        <Link to={ACCOUNTS_ROUTE} className="">
          <img src={Logo} alt="Platnova" className="h-6 w-auto" />
        </Link>
      </div>
      <div className="xl:flex justify-center xl:space-x-28 mt-[20%] md:mt-[10%] max-w-[70%] mx-auto">
        <img src={ErrorCard} alt="error card" className="xl:w-[25.938rem] mx-auto" />
        <div className="flex flex-col items-center space-y-6">
          <p className="text-xl md:text-4xl font-medium text-center">Page not found!</p>
          <p className="text-center mt-6 text-sm md:text-xl text-grey-text">
            The page you are looking for does not exist You might have typed in the wrong address or
            the page may have moved
          </p>
          <Button variant="purple" className="w-1/2" onClick={() => navigate(ACCOUNTS_ROUTE)}>
            Go home
          </Button>
        </div>
      </div>

      <img src={Rectangle} alt="rectangle" className="absolute left-0 bottom-0 w-28 xl:w-auto" />
      <img
        src={Rectangle}
        alt="rectangle"
        className="absolute right-0 transform bottom-0 scale-x-[-1] w-28 xl:w-auto"
      />
    </div>
  );
};

export default NotFound;
