import Button from 'components/Buttons/Button';
import { ChevronLeft, CircleCheck } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { ACCOUNTS_ROUTE } from 'routes/path';
import { useAppDispatch, useAppSelector } from 'store';
import { resetCardState, setCreateVirtualCardStage } from 'store/slices/cards/cardsSlice';
import {
  ACTIVE_VIRTUAL_CARD_BRAND,
  CARD_SLICE_REDUCER_PATH,
  VIRTUAL_CARD_COLOR_KEY,
} from 'store/slices/cards/constants';

import ChooseCardColor from './components/chooseCardColor';
import CreateVirtualCardForm from './components/createVirtualCardForm';
import SelectCardType from './components/selectCardType';
import DashboardCardLayout from '../components/dashboardCardLayout';
import SubRouteLayout from '../components/subRouteLayout';

const CreateVirtualCard = () => {
  const {
    virtualStage,
    [VIRTUAL_CARD_COLOR_KEY]: color,
    [ACTIVE_VIRTUAL_CARD_BRAND]: activeCard,
  } = useAppSelector((state) => state[CARD_SLICE_REDUCER_PATH]);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <div>
      <div>
        {virtualStage !== 'success' && (
          <SubRouteLayout header="Create Virtual card">
            <div className="pb-20 xl:-ml-[4.688rem]">
              <div className="xl:flex items-start xl:space-x-11">
                <button
                  onClick={() => {
                    virtualStage === 'create' && navigate(-1);
                    virtualStage === 'pay' &&
                      dispatch(setCreateVirtualCardStage({ virtualStage: 'create' }));
                  }}
                  className="flex space-x-1.5 xl:space-x-3 items-center"
                >
                  <ChevronLeft className="w-3 h-3 xl:w-6 xl:h-6" />
                  <span className="text-xs xl:text-base">Back</span>
                </button>

                {virtualStage === 'create' && (
                  <div className="mt-5 xl:mt-0">
                    <div className="mb-10">
                      <p className="text-lg md:text-2xl font-medium">Choose color</p>
                      <p className="text-xs md:text-base text-black/70">
                        Select a color for your physical card
                      </p>
                    </div>

                    <ChooseCardColor type="virtual" />

                    <DashboardCardLayout className="mt-5">
                      <SelectCardType type="virtual" />
                    </DashboardCardLayout>

                    <Button
                      variant="purple"
                      className="mt-14 w-full"
                      onClick={(e) => {
                        e.preventDefault();
                        if (color.class && activeCard) {
                          dispatch(setCreateVirtualCardStage({ virtualStage: 'pay' }));
                        }
                      }}
                    >
                      Continue
                    </Button>
                  </div>
                )}

                {virtualStage === 'pay' && (
                  <div className="mt-5 xl:mt-0">
                    <div className="mb-10">
                      <p className="text-lg md:text-2xl font-medium">Create Virtual card</p>
                      <p className="text-xs md:text-base text-black/70">
                        Enter amount to prefund the card
                      </p>

                      <CreateVirtualCardForm />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </SubRouteLayout>
        )}

        {virtualStage === 'success' && (
          <div className="flex w-full px-5 md:max-w-lg md:mx-auto pt-[20%] flex-col items-center space-y-3">
            <CircleCheck className="text-[#17B643]" size={83} />
            <p className="text-lg md:text-2xl font-medium">Card created Successfully</p>
            <p className="text-base"></p>
            <Button
              onClick={() => {
                dispatch(resetCardState());
                navigate(ACCOUNTS_ROUTE);
              }}
              variant="purple"
              className="w-full"
              type="button"
            >
              Go home
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateVirtualCard;
