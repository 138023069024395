import http from 'services/http';
import { GeneralApiResponse } from 'types';
import { SupportedWallet, Wallet } from 'types/wallet';

export const getWallets = async (): Promise<GeneralApiResponse<Wallet[]>> => {
  const res = await http.get('/wallets');
  return res.data;
};

export const getSupportedWallets = async (): Promise<GeneralApiResponse<SupportedWallet[]>> => {
  const res = await http.get('/wallets/supported');
  return res.data;
};

export const postAddWallet = async (data: {
  currency: string;
}): Promise<GeneralApiResponse<object>> => {
  const res = await http.post('/wallets', data);
  return res.data;
};
