import CardsGroup from 'assets/images/cardsGroup';
import Button from 'components/Buttons/Button';
import Loader from 'components/Loader';
import useDisclosure from 'hooks/useDisclosure';
import { useGetCards } from 'services/cards';

import CreateCardModal from './components/createCardModal';
import DashboardCardLayout from '../components/dashboardCardLayout';
import PaddedContainer from '../components/paddedContainer';

const Cards = () => {
  const { data: getCardsResponse, isPending: isLoadingGetCardsResponse } = useGetCards();
  const {
    close: closeCreateCardModal,
    isOpen: createCardModalIsOpen,
    open: openCreateCardModal,
  } = useDisclosure();

  return (
    <PaddedContainer>
      {!isLoadingGetCardsResponse && !getCardsResponse?.data.length ? (
        <DashboardCardLayout className="my-auto w-full flex flex-col items-center text-center space-y-5">
          <CardsGroup />
          <p className="text-xl md:text-2xl font-medium">Create your Platnova Card</p>
          <p className="text-black/60 text-sm md:text-base">
            Tap here to instantly create a new physical or virtual card. Ideal for secure,
            hassle-free shopping both online and in-store.
          </p>
          <Button
            className="w-full md:w-1/2 text-base"
            variant="secondary"
            onClick={openCreateCardModal}
          >
            Create Card
          </Button>
        </DashboardCardLayout>
      ) : (
        <Loader />
      )}
      <CreateCardModal close={closeCreateCardModal} isOpen={createCardModalIsOpen} />
    </PaddedContainer>
  );
};

export default Cards;
