const Accounts = () => {
  return (
    <div className="w-[1.188rem] h-[1.188rem]">
      <svg
        className="w-full h-full"
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_3789_19)">
          <path
            d="M12.67 2.21704L21.17 6.96704C21.4195 7.09161 21.6294 7.28328 21.776 7.52051C21.9226 7.75775 22.0002 8.03116 22 8.31004V9.75004C22 10.44 21.44 11 20.75 11H20V19H21C21.2652 19 21.5196 19.1054 21.7071 19.2929C21.8946 19.4805 22 19.7348 22 20C22 20.2653 21.8946 20.5196 21.7071 20.7071C21.5196 20.8947 21.2652 21 21 21H3C2.73478 21 2.48043 20.8947 2.29289 20.7071C2.10536 20.5196 2 20.2653 2 20C2 19.7348 2.10536 19.4805 2.29289 19.2929C2.48043 19.1054 2.73478 19 3 19H4V11H3.25C2.56 11 2 10.44 2 9.75004V8.31004C2 7.78804 2.27 7.30804 2.706 7.03604L11.329 2.21704C11.5373 2.11284 11.7671 2.05859 12 2.05859C12.2329 2.05859 12.4617 2.11284 12.67 2.21704ZM18 11H6V19H9V13H11V19H13V13H15V19H18V11ZM12 4.11804L4 8.61804V9.00004H20V8.61804L12 4.11804ZM12 6.00004C12.2652 6.00004 12.5196 6.1054 12.7071 6.29294C12.8946 6.48047 13 6.73483 13 7.00004C13 7.26526 12.8946 7.51961 12.7071 7.70715C12.5196 7.89469 12.2652 8.00004 12 8.00004C11.7348 8.00004 11.4804 7.89469 11.2929 7.70715C11.1054 7.51961 11 7.26526 11 7.00004C11 6.73483 11.1054 6.48047 11.2929 6.29294C11.4804 6.1054 11.7348 6.00004 12 6.00004Z"
            fill="black"
            fillOpacity="0.7"
          />
        </g>
        <defs>
          <clipPath id="clip0_3789_19">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default Accounts;
