import Flag from 'components/flag';
import Modal, { ModalProps } from 'components/modal';
import { cn } from 'lib/utils';
import { X } from 'lucide-react';
import { useGetWallets } from 'services/wallet';
import { useAppDispatch, useAppSelector } from 'store';
import {
  TO_ACCOUNT_WALLET_ID_KEY,
  WALLET_ID_KEY,
  WALLET_SLICE_REDUCER_PATH,
} from 'store/slices/wallet/constants';
import { setWallet } from 'store/slices/wallet/walletSlice';
import { formatAmount } from 'utils/numberFormat';

interface SelectWalletModalProps extends ModalProps {
  variant: 'primary' | 'convert' | 'send' | 'debit';
}

const SelectDepositWalletModal = ({ ...props }: SelectWalletModalProps) => {
  const { data: wallets } = useGetWallets();
  const { [WALLET_ID_KEY]: walletId, [TO_ACCOUNT_WALLET_ID_KEY]: toWalletId } = useAppSelector(
    (state) => state[WALLET_SLICE_REDUCER_PATH]
  );

  const filteredWallets = wallets?.data.filter((x) => x.id !== toWalletId);

  const activeWallet = wallets?.data.find((x) => x.id === walletId);
  const dispatch = useAppDispatch();

  const handleClick = (id: string) => {
    dispatch(setWallet(id));
    props.close();
  };
  return (
    <Modal {...props} className="py-7 px-5  xl:w-full xl:max-w-xl">
      <div className="flex justify-between">
        <p className="text-lg xl:text-2xl font-medium">
          {props.variant === 'convert' || props.variant === 'send'
            ? 'Select Account'
            : props.variant === 'debit'
            ? 'Select debit account'
            : 'Select Deposit Wallet'}
        </p>
        <X onClick={props.close} className="cursor-pointer" />
      </div>

      <div className="mt-6 xl:mt-12 space-y-2 xl:space-y-5">
        {props.variant === 'primary' ||
          (props.variant === 'debit' &&
            wallets?.data.map((wallet) => (
              <button
                onClick={() => handleClick(wallet.id)}
                key={wallet.id}
                className={cn(
                  'w-full flex items-center justify-between rounded-[10px] px-4 py-2.5 hover:bg-platnova-secondary',
                  wallet.id === activeWallet?.id && 'bg-platnova-secondary'
                )}
              >
                <div className="flex items-center space-x-5 ">
                  <Flag code={wallet.country.code} />
                  <div className="flex flex-col items-start">
                    <p className="text-xs xl:text-base font-medium">{wallet.description}</p>
                    <p className="text-[0.625rem] xl:text-xs text-grey-silent-text">
                      {wallet.type.toUpperCase()}
                    </p>
                  </div>
                </div>
                <p className="text-xs xl:text-base font-medium">
                  {wallet.type.toUpperCase() + ' ' + formatAmount(wallet.amount)}
                </p>
              </button>
            )))}
        {(props.variant === 'convert' || props.variant === 'send') &&
          filteredWallets?.map((wallet) => (
            <button
              onClick={() => handleClick(wallet.id)}
              key={wallet.id}
              className={cn(
                'w-full flex items-center justify-between rounded-[10px] px-4 py-2.5 hover:bg-platnova-secondary',
                wallet.id === activeWallet?.id && 'bg-platnova-secondary'
              )}
            >
              <div className="flex items-center space-x-5 ">
                <Flag code={wallet.country.code} />
                <div className="flex flex-col items-start">
                  <p className="text-xs xl:text-base font-medium">{wallet.description}</p>
                  <p className="text-[0.625rem] xl:text-xs text-grey-silent-text">
                    {wallet.type.toUpperCase()}
                  </p>
                </div>
              </div>
              <p className="text-xs xl:text-base font-medium">
                {wallet.type.toUpperCase() + ' ' + formatAmount(wallet.amount)}
              </p>
            </button>
          ))}
      </div>
    </Modal>
  );
};

export default SelectDepositWalletModal;
