import { useFormik } from 'formik';
import {
  SEND_FROM_AMOUNT_KEY,
  SEND_FROM_CURRENCY_KEY,
  SEND_PAYMENT_METHOD_KEY,
  SEND_TO_AMOUNT_KEY,
  SEND_TO_CURRENCY_KEY,
} from 'lib/constants/accounts/send';
import { useGetExchangeValue, useGetSendTransactionLimits } from 'lib/utils';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ACCOUNTS_FINALIZE_SEND_ROUTE } from 'routes/path';
import { useGetBeneficiaries } from 'services/beneficiary';
import { useSend } from 'services/send';
import { useGetWallets } from 'services/wallet';
import { useAppDispatch, useAppSelector } from 'store';
import { setSendAmountValues, setSendPaymentStep } from 'store/slices/account/accountsSlice';
import { ACCOUNTS_SLICE_REDUCER_PATH, SEND_AMOUNT_VALUES } from 'store/slices/account/constants';
import {
  RECIPIENT_CURRENCY_KEY,
  WALLET_ID_KEY,
  WALLET_SLICE_REDUCER_PATH,
} from 'store/slices/wallet/constants';
import { formatAmount, removeNonDigit } from 'utils/numberFormat';
import { number, object, string } from 'yup';

export default function useSendForm() {
  const { error, isError, isPending } = useSend();
  const limits = useGetSendTransactionLimits();
  const { data: wallets } = useGetWallets();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { data: beneficiaries } = useGetBeneficiaries();

  const { [WALLET_ID_KEY]: walletId, [RECIPIENT_CURRENCY_KEY]: recipientWalletCurrency } =
    useAppSelector((state) => state[WALLET_SLICE_REDUCER_PATH]);

  const { [SEND_AMOUNT_VALUES]: sendAmountValues } = useAppSelector(
    (state) => state[ACCOUNTS_SLICE_REDUCER_PATH]
  );

  const activeWallet =
    walletId.length > 0 ? wallets?.data.find((x) => x.id === walletId) : wallets?.data[0];

  const exchangeValue = useGetExchangeValue(
    activeWallet?.type ?? '',
    recipientWalletCurrency ?? ''
  );

  const validationSchema = useMemo(() => {
    if (limits && recipientWalletCurrency) {
      return object().shape({
        [SEND_FROM_AMOUNT_KEY]: number().transform((value, originalValue) => {
          return Number(removeNonDigit(String(originalValue)));
        }),
        [SEND_FROM_CURRENCY_KEY]: string().required(),
        [SEND_TO_AMOUNT_KEY]: number()
          .transform((value, originalValue) => {
            return Number(removeNonDigit(String(originalValue)));
          })
          .required('Amount cannot be empty')
          .min(
            limits.min ?? 0,
            `Minimum ${formatAmount(String(limits?.min))} ${recipientWalletCurrency.toUpperCase()}`
          )
          .max(
            limits.max ?? 0,
            `Maximum ${formatAmount(String(limits?.max))} ${recipientWalletCurrency.toUpperCase()}`
          ),
        [SEND_TO_CURRENCY_KEY]: string().required(),
        [SEND_PAYMENT_METHOD_KEY]: string().required('Kindly select a payment option'),
      });
    }
    return object().shape({
      [SEND_FROM_AMOUNT_KEY]: number().transform((value, originalValue) => {
        return Number(removeNonDigit(String(originalValue)));
      }),
      [SEND_FROM_CURRENCY_KEY]: string().required(),
      [SEND_TO_AMOUNT_KEY]: number()
        .transform((value, originalValue) => {
          return Number(removeNonDigit(String(originalValue)));
        })
        .required('Amount cannot be empty'),
      [SEND_TO_CURRENCY_KEY]: string().required(),
      [SEND_PAYMENT_METHOD_KEY]: string().required('Kindly select a payment option'),
    });
  }, [limits, recipientWalletCurrency]);

  const formik = useFormik({
    initialValues: {
      [SEND_FROM_AMOUNT_KEY]: sendAmountValues[SEND_FROM_AMOUNT_KEY] || '',
      [SEND_FROM_CURRENCY_KEY]: (activeWallet && activeWallet.type) ?? '',
      [SEND_TO_AMOUNT_KEY]: sendAmountValues[SEND_TO_AMOUNT_KEY] || '',
      [SEND_TO_CURRENCY_KEY]: recipientWalletCurrency ?? '',
      [SEND_PAYMENT_METHOD_KEY]: sendAmountValues[SEND_PAYMENT_METHOD_KEY] || '',
    },
    onSubmit: (values) => {
      const filteredBeneficiaries = beneficiaries?.data.filter(
        (beneficiary) =>
          beneficiary.payment_method === values[SEND_PAYMENT_METHOD_KEY] &&
          beneficiary.currency === values[SEND_TO_CURRENCY_KEY]
      );
      const newValues = {
        ...values,
        [SEND_FROM_AMOUNT_KEY]: Number(values[SEND_FROM_AMOUNT_KEY]),
        [SEND_TO_AMOUNT_KEY]: Number(values[SEND_TO_AMOUNT_KEY]),
      };
      dispatch(setSendAmountValues(newValues));
      if (values[SEND_PAYMENT_METHOD_KEY] === 'tag_transfer') {
        dispatch(setSendPaymentStep(2));
      } else if (filteredBeneficiaries && !filteredBeneficiaries.length) {
        navigate(ACCOUNTS_FINALIZE_SEND_ROUTE(values[SEND_PAYMENT_METHOD_KEY]));
      } else {
        dispatch(setSendPaymentStep(1));
      }
    },
    validationSchema: validationSchema,
  });

  const handleFromAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(removeNonDigit(String(e.target.value)));
    formik.setFieldValue(SEND_FROM_AMOUNT_KEY, value);
    formik.setFieldValue(SEND_TO_AMOUNT_KEY, value * exchangeValue);
  };

  const handleToAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(removeNonDigit(String(e.target.value)));
    formik.setFieldValue(SEND_TO_AMOUNT_KEY, value);
    formik.setFieldValue(SEND_FROM_AMOUNT_KEY, value / exchangeValue);
  };

  function getInputProps(id: keyof typeof formik.values) {
    return {
      ...formik.getFieldProps(id),
      ...formik.getFieldMeta(id),
      ...formik.errors,
      onChange:
        id === SEND_FROM_AMOUNT_KEY
          ? handleFromAmountChange
          : id === SEND_TO_AMOUNT_KEY
          ? handleToAmountChange
          : formik.handleChange,
    };
  }

  const filteredBeneficiaries = beneficiaries?.data.filter(
    (beneficiary) =>
      beneficiary.payment_method === formik.values[SEND_PAYMENT_METHOD_KEY] &&
      beneficiary.currency === formik.values[SEND_TO_CURRENCY_KEY]
  );

  if (
    filteredBeneficiaries &&
    !filteredBeneficiaries.length &&
    formik.values[SEND_PAYMENT_METHOD_KEY] !== 'tag_transfer'
  ) {
    dispatch(setSendPaymentStep(0));
  }

  return Object.freeze({
    onSubmit: formik.handleSubmit,
    getInputProps,
    setValue: formik.setFieldValue,
    isValid: formik.isValid,
    errors: formik.errors,
    sendError: error,
    isError: isError,
    isPending: isPending,
    values: formik.values,
  });
}
