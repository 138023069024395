import { useEffect } from 'react';

import { notifySuccess } from './notifySuccess';

const useSuccessNotification = (isSuccess: boolean, value: string) => {
  useEffect(() => {
    if (isSuccess && value) {
      notifySuccess(value);
    }
  }, [isSuccess, value]);
};

export default useSuccessNotification;
