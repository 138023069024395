import { Link } from 'react-router-dom';
import { LOGIN_ROUTE } from 'routes/path';

import RegisterForm from './components/registerForm';
import AuthCardLayout from '../authCardLayout';
import AuthLayout from '../layout';

const Register = () => {
  return (
    <AuthLayout>
      <AuthCardLayout title="Create an account" subtitle="Create a free account to get started">
        <RegisterForm />

        <p className="text-xs xl:text-sm font-normal mt-7">
          Already have an account?{' '}
          <Link to={LOGIN_ROUTE}>
            <span className="text-platnova-purple font-bold">Log in</span>
          </Link>
        </p>
      </AuthCardLayout>
    </AuthLayout>
  );
};

export default Register;
