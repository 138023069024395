import Button from 'components/Buttons/Button';
import useErrorNotification from 'components/error/useErrorNotification';
import Loader from 'components/Loader';
import { CircleCheck } from 'lucide-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ACCOUNTS_ROUTE, SELFIE_ERROR_ROUTE } from 'routes/path';
import { NOVA_USER_VERIFICATION_KEY } from 'services/constants';
import cookie from 'services/cookie';
import { useGetSelfieCaptureResult } from 'services/kyc';
import { SELFIE_SESSION_ID_KEY } from 'store/slices/verification/constants';
import { NovaUserVerification } from 'types/auth';
import AuthLayout from 'views/auth/layout';

import useUploadDocs from './hooks/useUploadDocs';

const Success = () => {
  const {
    onSubmit,
    isPending,
    isError: isUploadDocsError,
    uploadDocsError,
    isUploadDocsSuccess,
  } = useUploadDocs();
  const navigate = useNavigate();
  const {
    data,
    isPending: isLoadingSelfieResult,
    isError,
    isSuccess,
  } = useGetSelfieCaptureResult(
    'verification',
    cookie.get(NOVA_USER_VERIFICATION_KEY)?.[SELFIE_SESSION_ID_KEY] ?? ''
  );

  const verificationCache: NovaUserVerification = cookie.get(NOVA_USER_VERIFICATION_KEY);

  useErrorNotification(isUploadDocsError, uploadDocsError);

  useEffect(() => {
    if (isSuccess && data?.data) {
      onSubmit();
    }
  }, [data, isSuccess, onSubmit]);

  if (!verificationCache) {
    return null;
  }

  if (isLoadingSelfieResult || isPending) {
    return (
      <AuthLayout containerClassName="">
        <div>
          <Loader />
        </div>
      </AuthLayout>
    );
  }

  if (!isPending && !isLoadingSelfieResult && isUploadDocsSuccess) {
    return (
      <AuthLayout>
        <div className="flex flex-col items-center space-y-3">
          <CircleCheck className="text-[#17B643]" size={83} />
          <p className="text-2xl font-medium">Thank you for your submission</p>
          <p className="text-base">You will be notified once we verify your account</p>
          <Button
            onClick={() => navigate(ACCOUNTS_ROUTE)}
            variant="purple"
            className="w-full"
            type="button"
          >
            Go home
          </Button>
        </div>
      </AuthLayout>
    );
  }

  if (isError) {
    navigate(SELFIE_ERROR_ROUTE, { replace: true });
    return null; // Returning null after navigation to avoid rendering issues
  }

  return null; // Default return if no conditions are met
};

export default Success;
