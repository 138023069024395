import Button from 'components/Buttons/Button';
import useDisclosure from 'hooks/useDisclosure';
import { useGetCardBrands } from 'lib/utils';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { setActiveCardBrand, setActiveVirtualCardBrand } from 'store/slices/cards/cardsSlice';
import {
  ACTIVE_CARD_BRAND,
  ACTIVE_VIRTUAL_CARD_BRAND,
  CARD_SLICE_REDUCER_PATH,
} from 'store/slices/cards/constants';

import ChoseCardTypeModal from './chooseCardTypeModal';

const SelectCardType = ({ type }: { type: 'physical' | 'virtual' }) => {
  const dispatch = useAppDispatch();

  const {
    isOpen: isCardTypeModalOpen,
    open: openCardTypeModal,
    close: closeCardTypeModal,
  } = useDisclosure();

  const {
    isOpen: isVirtualCardTypeModalOpen,
    open: openVirtualCardTypeModal,
    close: closeVirtualCardTypeModal,
  } = useDisclosure();

  const { [ACTIVE_CARD_BRAND]: selectedCard, [ACTIVE_VIRTUAL_CARD_BRAND]: selectedVirtualCard } =
    useAppSelector((state) => state[CARD_SLICE_REDUCER_PATH]);

  const cardType = useGetCardBrands();
  const physicalCards = cardType?.filter((x) => x.type === 'physical');
  const virtualCards = cardType?.filter((x) => x.type === 'virtual');

  useEffect(() => {
    !selectedCard &&
      type === 'physical' &&
      physicalCards &&
      dispatch(setActiveCardBrand(physicalCards[0]));
  }, [dispatch, physicalCards, selectedCard, type]);

  useEffect(() => {
    !selectedVirtualCard &&
      type === 'virtual' &&
      virtualCards &&
      dispatch(setActiveVirtualCardBrand(virtualCards[0]));
  }, [dispatch, virtualCards, selectedVirtualCard, type]);

  // console.log(sendPaymentMethods)

  return (
    <div>
      <div className="bg-primary-grey p-2 xl:p-5">
        {selectedCard && type === 'physical' && (
          <div className="flex justify-between items-center">
            <div className="flex space-x-8 text-start items-center">
              <img
                src={`https://res.cloudinary.com/platnova/assets/payment_methods/${selectedCard.id}.svg`}
                alt="gg"
                className="w-6 h-max xl:w-10"
              />
              <div>
                <p className="text-base md:text-lg font-medium">{selectedCard.name}</p>
                <p className="text-sm md:text-base">{selectedCard.description}</p>
              </div>
            </div>
            <Button
              variant="secondary"
              type="button"
              onClick={openCardTypeModal}
              className="py-1.5 px-2 xl:py-3 xl:px-5 h-max xl:ml-5 shadow-none my-auto"
            >
              Change
            </Button>
          </div>
        )}
        {selectedVirtualCard && type === 'virtual' && (
          <div className="flex justify-between">
            <div className="flex space-x-8 text-start items-center">
              <img
                src={`https://res.cloudinary.com/platnova/assets/payment_methods/${selectedVirtualCard.id}.svg`}
                alt="gg"
                className="w-6 xl:w-10 h-max"
              />
              <div>
                <p className="text-base md:text-lg font-medium">{selectedVirtualCard.name}</p>
                <p className="text-sm md:text-base">{selectedVirtualCard.description}</p>
              </div>
            </div>
            <Button
              variant="secondary"
              type="button"
              onClick={openVirtualCardTypeModal}
              className="py-1.5 px-2 xl:py-3 xl:px-5 h-max xl:ml-5 shadow-none my-auto"
            >
              Change
            </Button>
          </div>
        )}
      </div>

      <ChoseCardTypeModal type="physical" isOpen={isCardTypeModalOpen} close={closeCardTypeModal} />
      <ChoseCardTypeModal
        type="virtual"
        isOpen={isVirtualCardTypeModalOpen}
        close={closeVirtualCardTypeModal}
      />
    </div>
  );
};

export default SelectCardType;
