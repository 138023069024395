import Button from 'components/Buttons/Button';
import { notifyError } from 'components/error/notifyError';
import { BsInfoCircleFill } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
import { ACCOUNTS_ROUTE, SELFIE_ROUTE } from 'routes/path';
import { NOVA_USER_VERIFICATION_KEY } from 'services/constants';
import cookie from 'services/cookie';
import { useInitiateSelfie } from 'services/kyc';
import { SELFIE_SESSION_ID_KEY } from 'store/slices/verification/constants';
import AuthCardLayout from 'views/auth/authCardLayout';
import AuthLayout from 'views/auth/layout';

const Error = () => {
  const { data: initiateSelfie, isPending } = useInitiateSelfie();
  const navigate = useNavigate();
  const verificationCache = cookie.get(NOVA_USER_VERIFICATION_KEY);
  const handleRetry = () => {
    if (initiateSelfie && initiateSelfie.data) {
      cookie.setWithExpiry(
        NOVA_USER_VERIFICATION_KEY,
        { ...verificationCache, [SELFIE_SESSION_ID_KEY]: initiateSelfie.data },
        600
      );
      navigate(SELFIE_ROUTE);
    } else {
      notifyError('Error loading selfie, please try again later');
    }
  };

  return (
    <AuthLayout>
      <AuthCardLayout title="Selfie Capture" subtitle="Position your face towards the camera">
        <div className="bg-blue-200 p-4 rounded-[10px] mt-4 flex items-center space-x-3">
          <BsInfoCircleFill className="w-6 h-6" fill="#172554" stroke="transparent" />
          <p className="text-sm">
            This check flashes different colors. Use caution if you are photosensitive.
          </p>
        </div>
        <img
          src="https://res.cloudinary.com/platnova/assets/generic/selfie-capture.png"
          alt="selfie"
          className="h-80 mx-auto"
        />
        <p className="text-center text-grey-text">
          Capture a selfie of yourself while ensuring that you're in a well lit environment.
        </p>

        <div className="w-full flex flex-col items-center space-y-6 mt-8">
          <Button
            variant="purple"
            isLoading={isPending}
            type="button"
            className="w-[80%]"
            onClick={handleRetry}
          >
            Try again
          </Button>
          <Link className="text-platnova-purple font-medium" to={ACCOUNTS_ROUTE}>
            Go Home
          </Link>
        </div>
      </AuthCardLayout>
    </AuthLayout>
  );
};

export default Error;
