/* eslint-disable react-hooks/exhaustive-deps */
import Logo from 'assets/logo/favicon.svg';
import { motion } from 'framer-motion';
import useDisclosure from 'hooks/useDisclosure';
import { Suspense, useEffect } from 'react';
import { SkeletonTheme } from 'react-loading-skeleton';
import { useGetAccount } from 'services/account';
import { useAppDispatch, useAppSelector } from 'store';
import { setInactiveModalIsOpen } from 'store/slices/account/accountsSlice';
import {
  ACCOUNTS_SLICE_REDUCER_PATH,
  INACTIVE_MODAL_IS_OPEN,
} from 'store/slices/account/constants';
import {
  COMING_SOON_MODAL_IS_OPEN,
  LAYOUT_SLICE_REDUCER_PATH,
} from 'store/slices/layout/constants';
import { setComingSoonModalOpen } from 'store/slices/layout/layoutSlice';
import {
  INSUFFICIENT_FUND_MODAL_IS_OPEN,
  WALLET_SLICE_REDUCER_PATH,
} from 'store/slices/wallet/constants';
import { setInsufficientFundModalIsOpen } from 'store/slices/wallet/walletSlice';
import InsufficientFundsModal from 'views/dashboard/components/insufficientFundsModal';

import ComingSoonModal from './comingSoonModal';
import InactiveModal from './inactiveModal';

const Layout = ({ children }: { children: React.ReactNode }) => {
  const { pageLoader } = useAppSelector((state) => state.layout);
  const dispatch = useAppDispatch();
  const { data: account } = useGetAccount();

  const { [INSUFFICIENT_FUND_MODAL_IS_OPEN]: insufficientFundModalIsOpen } = useAppSelector(
    (state) => state[WALLET_SLICE_REDUCER_PATH]
  );

  const { [INACTIVE_MODAL_IS_OPEN]: inactiveModalIsOpen } = useAppSelector(
    (state) => state[ACCOUNTS_SLICE_REDUCER_PATH]
  );

  const { [COMING_SOON_MODAL_IS_OPEN]: comingSoonModalIsOpen } = useAppSelector(
    (state) => state[LAYOUT_SLICE_REDUCER_PATH]
  );

  const { open: openInsufficientModal, close: closeInsufficientModal } = useDisclosure();
  const { open: openComingSoonModal, close: closeComingSoonModal } = useDisclosure();
  const { open: openInactiveModal, close: closeInactiveModal } = useDisclosure();

  const handleCloseInsufficientModal = () => {
    dispatch(setInsufficientFundModalIsOpen(false));
    closeInsufficientModal();
  };

  const handleOpenComingSoonModal = () => {
    if (comingSoonModalIsOpen && account?.data.status === 'ACTIVE') {
      openComingSoonModal();
    } else {
      dispatch(setComingSoonModalOpen(false));
      dispatch(setInactiveModalIsOpen(true));
    }
  };

  const handleCloseComingSoonModal = () => {
    dispatch(setComingSoonModalOpen(false));
    closeComingSoonModal();
  };

  const handleCloseInactiveModal = () => {
    dispatch(setInactiveModalIsOpen(false));
    closeInactiveModal();
  };

  useEffect(() => {
    insufficientFundModalIsOpen && openInsufficientModal();
    comingSoonModalIsOpen && handleOpenComingSoonModal();
    inactiveModalIsOpen && openInactiveModal();
  }, [
    comingSoonModalIsOpen,
    inactiveModalIsOpen,
    insufficientFundModalIsOpen,
    openComingSoonModal,
    openInactiveModal,
    openInsufficientModal,
  ]);

  return (
    <Suspense>
      <SkeletonTheme baseColor="#f7f7f7" highlightColor="#EEEEEE">
        {pageLoader ? (
          <div
            className={`flex items-center justify-center bg-white transition duration-150 ease-in-out z-[1000000] fixed top-0 bottom-0 right-0 left-0 overflow-y-hidden ${
              pageLoader ? 'visible' : 'hidden'
            }`}
            id="PageLoader"
          >
            <motion.img
              src={Logo}
              animate={{
                scale: [1, 1.5, 1],
              }}
              transition={{
                duration: 1.5,
                ease: 'easeInOut',
                repeat: Infinity,
                repeatType: 'loop',
              }}
              alt="logo"
              className="w-20 h-20 md:w-40 md:h-40"
            />
          </div>
        ) : (
          children
        )}
      </SkeletonTheme>
      <InsufficientFundsModal
        isOpen={insufficientFundModalIsOpen}
        close={handleCloseInsufficientModal}
      />
      <ComingSoonModal isOpen={comingSoonModalIsOpen} close={handleCloseComingSoonModal} />
      <InactiveModal isOpen={inactiveModalIsOpen} close={handleCloseInactiveModal} />
    </Suspense>
  );
};

export default Layout;
