import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LOGIN_ROUTE } from 'routes/path';
import cookie from 'services/cookie';
import { useAppDispatch } from 'store';

const Logout = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const handleLogout = async () => {
      cookie.deleteAll();
      navigate(LOGIN_ROUTE);
    };

    handleLogout();
  }, [dispatch, navigate]);

  return <div>Logging out...</div>;
};

export default Logout;
