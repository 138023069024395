const Cards = () => {
  return (
    <div className="w-[1.188rem] h-[1.188rem]">
      <svg
        className="w-full h-full"
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.125 4.5H4.875C3.42525 4.5 2.25 5.67525 2.25 7.125V16.875C2.25 18.3247 3.42525 19.5 4.875 19.5H19.125C20.5747 19.5 21.75 18.3247 21.75 16.875V7.125C21.75 5.67525 20.5747 4.5 19.125 4.5Z"
          stroke="black"
          strokeOpacity="0.7"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.25 9H21.75M6 14.0625H8.25V15H6V14.0625Z"
          stroke="black"
          strokeOpacity="0.7"
          strokeWidth="2.8125"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default Cards;
