import Button from 'components/Buttons/Button';
import { notifySuccess } from 'components/error/notifySuccess';
import useErrorNotification from 'components/error/useErrorNotification';
import InputPin from 'components/Inputs/inputPin';
import Loader from 'components/Loader';
import { useFormik } from 'formik';
import { CUSTOM_SELFIE_URL_PATH } from 'lib/constants/selfie';
import { ChevronLeft } from 'lucide-react';
import { useEffect, useState } from 'react';
import { BsInfoCircleFill } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
import { SETTINGS_PIN_ROUTE, SETTINGS_ROUTE, SETTINGS_SECURITY_ROUTE } from 'routes/path';
import cookie from 'services/cookie';
import { useGetSelfieCaptureResult, useInitiateSelfie } from 'services/kyc';
import { useCreatePin } from 'services/settings';
import AuthCardLayout from 'views/auth/authCardLayout';
import * as yup from 'yup';

import SubRouteLayout from '../components/subRouteLayout';

const Pin = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [selfieUrl, setSelfieUrl] = useState('');

  const { data: selfieId } = useInitiateSelfie();

  const { mutate, isPending, error, isError, isSuccess } = useCreatePin();
  const selfieIdCookie = cookie.get('selfie_auth_id');

  const { data, isPending: isLoadingSelfieResults } = useGetSelfieCaptureResult(
    'authorization',
    selfieIdCookie
  );

  const formik = useFormik({
    initialValues: { pin: '' },
    validationSchema: yup.object().shape({
      pin: yup.string().min(4, 'Invalid pin').max(4, 'Invalid pin').required('Pin cannot be empty'),
    }),
    onSubmit: () => setPage(2),
  });

  function getInputProps(id: keyof typeof formik.values) {
    return {
      ...formik.getFieldHelpers(id),
      ...formik.getFieldMeta(id),
      ...formik.getFieldProps(id),
      ...formik.errors,
    };
  }

  const confirmFormik = useFormik({
    initialValues: { pin: '' },
    validationSchema: yup.object().shape({
      pin: yup
        .string()
        .test('match-pin', 'Pins do not match', function (value) {
          return value === formik.values.pin;
        })
        .required('Pin cannot be empty'),
    }),
    onSubmit: (values) => mutate(values),
  });

  function getConfirmInputProps(id: keyof typeof formik.values) {
    return {
      ...confirmFormik.getFieldHelpers(id),
      ...confirmFormik.getFieldProps(id),
      ...confirmFormik.getFieldMeta(id),
      ...confirmFormik.errors,
    };
  }

  useErrorNotification(isError, error);

  useEffect(() => {
    if (!selfieIdCookie) {
      if (selfieId) {
        cookie.setWithExpiry('selfie_auth_id', selfieId.data, 300);
        setSelfieUrl(
          CUSTOM_SELFIE_URL_PATH({
            sessionId: selfieId.data,
            appUrl: import.meta.env.VITE_PLATNOVA_WEB_APP_URL,
            successRoute: SETTINGS_PIN_ROUTE,
            errorRoute: SETTINGS_SECURITY_ROUTE,
          })
        );
      }
    } else {
      if (data?.data && !isLoadingSelfieResults) {
        setPage(1);
        const handleSuccess = () => {
          navigate(SETTINGS_SECURITY_ROUTE);
          notifySuccess('Pin created Successfully');
          cookie.delete('selfie_auth_id');
        };
        isSuccess && handleSuccess();
      } else {
        if (selfieId && !isLoadingSelfieResults) {
          cookie.setWithExpiry('selfie_auth_id', selfieId.data, 300);
          setSelfieUrl(
            CUSTOM_SELFIE_URL_PATH({
              sessionId: selfieId.data,
              appUrl: import.meta.env.VITE_PLATNOVA_WEB_APP_URL,
              successRoute: SETTINGS_PIN_ROUTE,
              errorRoute: SETTINGS_SECURITY_ROUTE,
            })
          );
        }
      }
    }
  }, [data, isLoadingSelfieResults, isSuccess, navigate, selfieId, selfieIdCookie]);

  useEffect(() => {
    if (selfieId && isError) {
      cookie.delete('selfie_auth_id');
    }
  }, [isError, selfieId]);

  const handleClick = () => {
    window.open(selfieUrl, '_self');
  };

  return (
    <SubRouteLayout header="Security">
      <div className="pb-20 w-full max-w-[52rem]">
        <div className="xl:flex items-start xl:space-x-11 w-full">
          <Link to={SETTINGS_ROUTE} className="flex space-x-1.5 xl:space-x-3 items-center">
            <ChevronLeft className="w-3 h-3 xl:w-6 xl:h-6" />
            <span className="text-xs xl:text-base">Back</span>
          </Link>

          {!page && !isLoadingSelfieResults && (
            <AuthCardLayout title="Selfie Capture" subtitle="Position your face towards the camera">
              <div className="bg-blue-200 p-4 rounded-[10px] mt-4 flex items-center space-x-3">
                <BsInfoCircleFill className="w-6 h-6" fill="#172554" stroke="transparent" />
                <p className="text-sm">
                  This check flashes different colors. Use caution if you are photosensitive.
                </p>
              </div>
              <img
                src="https://res.cloudinary.com/platnova/assets/generic/selfie-capture.png"
                alt="selfie"
                className="h-80 mx-auto"
              />
              <p className="text-center text-grey-text">
                Capture a selfie of yourself while ensuring that you're in a well lit environment.
              </p>
              <Button variant="purple" onClick={handleClick} className="w-full mt-10">
                Continue
              </Button>
            </AuthCardLayout>
          )}

          {!page && isLoadingSelfieResults && <Loader />}

          {page === 1 && (
            <div className="w-full mt-5 xl:mt-0">
              <div className="flex justify-between w-full">
                <div>
                  <p className="text-xl xl:text-2xl font-medium">Security</p>
                  <p className="mt-1 xl:mt-2 font-medium text-xs xl:text-lg text-grey-text">
                    Input your 4 digit pin
                  </p>
                </div>
              </div>

              <div className="mt-14">
                <form
                  onSubmit={formik.handleSubmit}
                  className="flex flex-col items-center space-y-10"
                >
                  <div className="w-max mx-auto">
                    <InputPin
                      id="pin"
                      type="text"
                      numInputs={4}
                      {...getInputProps('pin')}
                      error={formik.errors.pin}
                    />
                  </div>
                  <Button
                    className="w-full xl:w-1/2 mx-auto"
                    variant="purple"
                    disabled={isPending}
                    isLoading={isPending}
                  >
                    Submit
                  </Button>
                </form>
              </div>
            </div>
          )}

          {page === 2 && (
            <div className="w-full mt-5 xl:mt-0">
              <div className="flex justify-between w-full">
                <div>
                  <p className="text-xl xl:text-2xl font-medium">Security</p>
                  <p className="mt-1 xl:mt-2 font-medium text-xs xl:text-lg text-grey-text">
                    Confirm Pin
                  </p>
                </div>
              </div>

              <div className="mt-14">
                <form
                  onSubmit={confirmFormik.handleSubmit}
                  className="flex flex-col items-center space-y-10"
                >
                  <div className="w-max mx-auto">
                    <InputPin
                      id="pin"
                      type="text"
                      numInputs={4}
                      {...getConfirmInputProps('pin')}
                      error={confirmFormik.errors.pin}
                    />
                  </div>
                  <Button
                    className="w-full xl:w-1/2 mx-auto"
                    variant="purple"
                    disabled={isPending}
                    isLoading={isPending}
                  >
                    Submit
                  </Button>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </SubRouteLayout>
  );
};

export default Pin;
