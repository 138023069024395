import { useMutation } from '@tanstack/react-query';

import { postSend } from './function';

export const useSend = () => {
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: postSend,
  });

  return { mutate, isPending, isError, error };
};
