const Chip = () => {
  return (
    <div>
      <svg
        width="49"
        height="48"
        viewBox="0 0 49 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.5 35V13C5.5 10.8 7.3 9 9.5 9H39.5C41.7 9 43.5 10.8 43.5 13V35C43.5 37.2 41.7 39 39.5 39H9.5C7.3 39 5.5 37.2 5.5 35Z"
          fill="#FF9800"
        />
        <path
          d="M43.5 21V19H31.5C30.4 19 29.5 18.1 29.5 17C29.5 15.9 30.4 15 31.5 15H32.5V13H31.5C29.3 13 27.5 14.8 27.5 17C27.5 19.2 29.3 21 31.5 21H34.5V27H31.5C28.7 27 26.5 29.2 26.5 32C26.5 34.8 28.7 37 31.5 37H33.5V35H31.5C29.8 35 28.5 33.7 28.5 32C28.5 30.3 29.8 29 31.5 29H43.5V27H36.5V21H43.5ZM17.5 27H14.5V21H17.5C19.7 21 21.5 19.2 21.5 17C21.5 14.8 19.7 13 17.5 13H14.5V15H17.5C18.6 15 19.5 15.9 19.5 17C19.5 18.1 18.6 19 17.5 19H5.5V21H12.5V27H5.5V29H17.5C19.2 29 20.5 30.3 20.5 32C20.5 33.7 19.2 35 17.5 35H15.5V37H17.5C20.3 37 22.5 34.8 22.5 32C22.5 29.2 20.3 27 17.5 27Z"
          fill="#FFD54F"
        />
      </svg>
    </div>
  );
};

export default Chip;
