import AppStore from 'assets/icons/appStore';
import PlayStore from 'assets/icons/playStore';
import ComingSoon from 'assets/images/coming-soon.svg';
import Modal, { ModalProps } from 'components/modal';
import { TbX } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import { VERIFICATION_ROUTE } from 'routes/path';
import { useGetAccount } from 'services/account';

import Button from './Buttons/Button';

const ComingSoonModal = ({ ...props }: ModalProps) => {
  const { data: account } = useGetAccount();
  const navigate = useNavigate();

  return account && account.data.status === 'ACTIVE' ? (
    <Modal
      {...props}
      className="py-7 px-[5%] xl:px-5 w-full max-w-xl flex flex-col space-y-6 items-center text-center"
    >
      <div className="flex justify-end w-full">
        <button onClick={props.close} className="">
          <TbX className="w-6 h-6" />
        </button>
      </div>

      <img src={ComingSoon} alt="coming-soon" className="w-36 xl:w-52" />
      <p className="text-sm xl:text-xl text-grey-text xl:px-12">
        This feature is only available on the mobile app. Stay tuned for more updates or Download
        out mobile app!
      </p>
      <div className="flex space-x-4">
        <a href="https://platnova.me/get-started">
          <AppStore />
        </a>
        <a href="https://platnova.me/get-started">
          <PlayStore />
        </a>
      </div>
    </Modal>
  ) : account && account.data.status === 'ACTIVE' ? (
    <Modal
      {...props}
      className="py-7 px-[5%] xl:px-5 w-full max-w-md flex flex-col space-y-6 items-center text-center"
    >
      <h1 className="text-xl font-semibold">
        {account.data.verification_message ? 'Action Required' : 'Complete your account activation'}
      </h1>
      <p className="text-base">
        {account.data.verification_message
          ? account.data.verification_message
          : 'Complete your verification to access all of our features'}
      </p>

      <div className="flex w-full justify-center space-x-5">
        <button
          className="border rounded-[6px] text-xs md:text-sm px-4 border-platnova-purple text-platnova-purple"
          onClick={props.close}
        >
          Not now
        </button>
        <Button
          variant="secondary"
          className="h-auto xl:h-auto px-4 xl:px-4 shadow-none rounded-[6px]"
          onClick={() => {
            navigate(VERIFICATION_ROUTE);
            props.close();
          }}
        >
          {account.data.verification_message ? 'Try again' : 'Verify Account'}
        </Button>
      </div>
    </Modal>
  ) : null;
};

export default ComingSoonModal;
