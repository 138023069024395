import Modal, { ModalProps } from 'components/modal';
import { useNavigate } from 'react-router-dom';
import { VERIFICATION_ROUTE } from 'routes/path';
import { useGetAccount } from 'services/account';

import Button from './Buttons/Button';

const InactiveModal = ({ ...props }: ModalProps) => {
  const { data: account, isPending: isLoadingAccount } = useGetAccount();

  const navigate = useNavigate();
  return (
    account &&
    !isLoadingAccount && (
      <Modal
        {...props}
        className="py-7 px-[5%] xl:px-5 w-full max-w-md flex flex-col space-y-6 items-center text-center"
      >
        <h1 className="text-xl font-semibold">
          {account.data.verification_message
            ? 'Action Required'
            : 'Complete your account activation'}
        </h1>
        <p className="text-base">
          {account.data.verification_message
            ? account.data.verification_message
            : 'Complete your verification to access all of our features'}
        </p>

        <div className="flex w-full justify-center space-x-5">
          <button
            className="border rounded-[10px] px-4 border-platnova-purple text-platnova-purple"
            onClick={props.close}
          >
            Not now
          </button>
          <Button
            variant="secondary"
            className="h-auto xl:h-auto px-4 xl:px-4 shadow-none"
            onClick={() => {
              navigate(VERIFICATION_ROUTE);
              props.close();
            }}
          >
            {account.data.verification_message ? 'Try again' : 'Verify Account'}
          </Button>
        </div>
      </Modal>
    )
  );
};

export default InactiveModal;
