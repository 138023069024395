import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  INSUFFICIENT_FUND_MODAL_IS_OPEN,
  RECIPIENT_COUNTRY_KEY,
  RECIPIENT_CURRENCY_KEY,
  TO_ACCOUNT_WALLET_ID_KEY,
  WALLET_ID_KEY,
  WALLET_SLICE_REDUCER_PATH,
  walletSliceReducerInitialState,
} from './constants';

const walletSlice = createSlice({
  name: WALLET_SLICE_REDUCER_PATH,
  initialState: walletSliceReducerInitialState,
  reducers: {
    setWallet: (state, { payload }: PayloadAction<string>) => {
      state[WALLET_ID_KEY] = payload;
    },
    setRecipientCurrency: (state, { payload }: PayloadAction<string>) => {
      state[RECIPIENT_CURRENCY_KEY] = payload;
    },
    setRecipientCountry: (state, { payload }: PayloadAction<string>) => {
      state[RECIPIENT_COUNTRY_KEY] = payload;
    },
    setToAccountWallet: (state, { payload }: PayloadAction<string>) => {
      state[TO_ACCOUNT_WALLET_ID_KEY] = payload;
    },
    setInsufficientFundModalIsOpen: (state, { payload }: PayloadAction<boolean>) => {
      state[INSUFFICIENT_FUND_MODAL_IS_OPEN] = payload;
    },
  },
});

export const walletReducer = walletSlice.reducer;

export const {
  setWallet,
  setToAccountWallet,
  setInsufficientFundModalIsOpen,
  setRecipientCurrency,
  setRecipientCountry,
} = walletSlice.actions;
