/* eslint-disable react-hooks/exhaustive-deps */

import CardPlus from 'assets/icons/cardPlus';
import UserPlus from 'assets/icons/userPlus';
import UserTick from 'assets/icons/userTick';
import Button from 'components/Buttons/Button';
import { ChevronLeft, Copy } from 'lucide-react';
import { BsCashStack } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { useGetAccount } from 'services/account';
import { useAppDispatch } from 'store';
import { setInactiveModalIsOpen } from 'store/slices/account/accountsSlice';
import { copyToClipboard } from 'utils/clipboard';
import SubRouteLayout from 'views/dashboard/components/subRouteLayout';

import DashboardCardLayout from '../components/dashboardCardLayout';

const Refer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data: account } = useGetAccount();

  return (
    <SubRouteLayout header="Refer and earn">
      <div className="pb-20 xl:-ml-[4.688rem] xl:max-w-[50%]">
        <div className="xl:flex items-start xl:space-x-11">
          <button
            onClick={() => navigate(-1)}
            className="flex space-x-1.5 xl:space-x-3 items-center"
          >
            <ChevronLeft className="w-3 h-3 xl:w-6 xl:h-6" />
            <span className="text-xs xl:text-base">Back</span>
          </button>

          <div className="space-y-8 mt-5 xl:mt-0 xl:space-y-10">
            <div className="xl:flex justify-between space-y-4 xl:space-y-0">
              <div>
                <p className="text-lg md:text-2xl font-medium">Earn Rewards and Points</p>
                <p className="text-xs md:text-base">
                  Use your referral code below to invite your friends and cliques and earn big once
                  they join, verify, and transact.
                </p>
              </div>
            </div>

            <DashboardCardLayout className="flex flex-col items-center xl:w-full">
              {account && account.data.status === 'ACTIVE' ? (
                <div className="flex flex-col items-center xl:w-full">
                  <p className="text-grey-text text-base">Your code</p>
                  <div className="flex space-x-2 mt-5 mx-auto">
                    <p className="text-base">{account?.data.referral.code}</p>
                    <button
                      onClick={() => copyToClipboard('Referral Code', account?.data.referral.code)}
                    >
                      <Copy size={15} className="text-platnova-purple" />
                    </button>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col items-center xl:w-full">
                  <p className="text-sm md:text-base text-center">
                    Your account needs to be active before you can invite your friends
                  </p>
                  <div className="flex space-x-2 mt-5 mx-auto">
                    <button
                      className="text-base text-platnova-purple font-medium"
                      onClick={() => dispatch(setInactiveModalIsOpen(true))}
                    >
                      Get code
                    </button>
                  </div>
                </div>
              )}
            </DashboardCardLayout>

            <DashboardCardLayout className="flex flex-col items-center xl:w-full">
              {account && (
                <div className="flex space-x-2 mx-auto items-end border-b w-full justify-center pb-6 mb-6">
                  <p className="text-4xl">{account?.data.referral.earning}</p>
                  <p className="text-sm">points</p>
                </div>
              )}

              <p className="text-sm md:text-lg text-start w-full">
                These are the points you earn for each step your friends complete:
              </p>

              <div className="mt-12 w-full space-y-10">
                <div className="flex justify-between w-full items-center">
                  <div className="flex space-x-6 items-center">
                    <UserPlus />
                    <div>
                      <p className="text-base md:text-xl font-medium">
                        Sign up & Email Confirmation
                      </p>
                      <p className="text-sm md:text-base">Done with account registration</p>
                    </div>
                  </div>
                  <p className="text-xs md:text-base">50p</p>
                </div>

                <div className="flex justify-between w-full items-center">
                  <div className="flex space-x-6 items-center">
                    <UserTick />
                    <div>
                      <p className="text-base md:text-xl font-medium">Account verification</p>
                      <p className="text-sm md:text-base">Verified and activated their account</p>
                    </div>
                  </div>
                  <p className="text-xs md:text-base">100p</p>
                </div>

                <div className="flex justify-between w-full items-center">
                  <div className="flex space-x-6 items-center">
                    <BsCashStack fill="#7049f7" className="w-7 h-7" />
                    <div>
                      <p className="text-base md:text-xl font-medium">Transaction</p>
                      <p className="text-sm md:text-base">Done at least one deposit.</p>
                    </div>
                  </div>
                  <p className="text-xs md:text-base">150p</p>
                </div>

                <div className="flex justify-between w-full items-center">
                  <div className="flex space-x-6 items-center">
                    <CardPlus />
                    <div>
                      <p className="text-base md:text-xl font-medium">Create card</p>
                      <p className="text-sm md:text-base">Create a virtual or physical card</p>
                    </div>
                  </div>
                  <p className="text-xs md:text-base">200p</p>
                </div>
              </div>
            </DashboardCardLayout>

            <Button variant="purple" className="w-full">
              Invite Friends
            </Button>
          </div>
        </div>
      </div>
    </SubRouteLayout>
  );
};

export default Refer;
