import Button from 'components/Buttons/Button';
import Flag from 'components/flag';
import useDisclosure from 'hooks/useDisclosure';
import { cn } from 'lib/utils';
import { ChevronLeft, Plus } from 'lucide-react';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import { ACCOUNTS_ROUTE } from 'routes/path';
import { useAddWallet, useGetWallets } from 'services/wallet';
import { useAppDispatch, useAppSelector } from 'store';
import { WALLET_ID_KEY, WALLET_SLICE_REDUCER_PATH } from 'store/slices/wallet/constants';
import { setWallet } from 'store/slices/wallet/walletSlice';
import { formatAmount } from 'utils/numberFormat';

import NewAccountModal from './components/newAccountModal';
import DashboardCardLayout from '../components/dashboardCardLayout';
import SubRouteLayout from '../components/subRouteLayout';

const MyAccounts = () => {
  const {
    isOpen: isNewAccountModalOpen,
    open: openNewAccountModal,
    close: closeNewAccountModal,
  } = useDisclosure();

  const { data: wallets, isPending: isLoadingWallets } = useGetWallets();
  const { isPending: isLoadingAddWallet } = useAddWallet();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { [WALLET_ID_KEY]: walletId } = useAppSelector((state) => state[WALLET_SLICE_REDUCER_PATH]);

  const activeWallet = wallets?.data.find((x) => x.id === walletId);

  const handleSetActive = (id: string) => {
    dispatch(setWallet(id));
    navigate(ACCOUNTS_ROUTE);
  };

  return (
    <SubRouteLayout header="My Accounts">
      <div className="pb-20 xl:-ml-[4.688rem]">
        <div className="xl:flex items-start xl:space-x-11">
          <button
            onClick={() => navigate(-1)}
            className="flex space-x-1.5 xl:space-x-3 items-center"
          >
            <ChevronLeft className="w-3 h-3 xl:w-6 xl:h-6" />
            <span className="text-xs xl:text-base">Back</span>
          </button>
          <DashboardCardLayout className="overflow-y-scroll  mt-5 xl:mt-0">
            <div className="flex justify-between items-center">
              <p className="text-lg xl:text-2xl font-medium">My Accounts</p>

              <Button
                variant="purple"
                onClick={openNewAccountModal}
                className="flex items-center space-x-1 xl:space-x-3 px-4 h-auto xl:p-3"
              >
                <Plus className="w-3 h-3 md:w-6 md:h-6" />
                <p className="text-[0.625rem] md:text-base font-bold">New Account</p>
              </Button>
            </div>

            <div className="mt-7 xl:mt-14 space-y-2">
              {!isLoadingWallets && !isLoadingAddWallet && wallets ? (
                wallets.data.map((wallet) => (
                  <button
                    onClick={() => handleSetActive(wallet.id)}
                    key={wallet.id}
                    className={cn(
                      'w-full px-2 xl:px-5 py-2 xl:py-5 rounded-[10px] hover:bg-platnova-secondary',
                      wallet.id === activeWallet?.id && 'bg-platnova-secondary'
                    )}
                  >
                    <div className="flex justify-between items-center">
                      <div className="flex space-x-2 xl:space-x-5 items-center">
                        <Flag code={wallet.country.code} />
                        <div className="flex flex-col items-start">
                          <p className="text-xs md:text-base font-medium text-start">
                            {wallet.description}
                          </p>
                          <p className="text-[0.625rem] xl:text-xs text-grey-silent-text">
                            {wallet.type.toUpperCase()}
                          </p>
                        </div>
                      </div>
                      <p className="text-xs md:text-base font-medium">
                        {wallet.symbol} {formatAmount(wallet.amount)}
                      </p>
                    </div>
                  </button>
                ))
              ) : (
                <Skeleton count={4} className="h-20" />
              )}
            </div>
          </DashboardCardLayout>
          <NewAccountModal close={closeNewAccountModal} isOpen={isNewAccountModalOpen} />
        </div>
      </div>
    </SubRouteLayout>
  );
};

export default MyAccounts;
