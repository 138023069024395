import { useFormik } from 'formik';
import {
  VERIFY_UPLOAD_ADDITIONAL_FIELDS_KEY,
  VERIFY_UPLOAD_BACK_DOC_KEY,
  VERIFY_UPLOAD_COUNTRY_CODE_KEY,
  VERIFY_UPLOAD_FRONT_DOC_KEY,
  VERIFY_UPLOAD_ID_TYPE_KEY,
  VERIFY_UPLOAD_IS_BIOMETRIC_ENABLED_KEY,
  VERIFY_UPLOAD_PROVIDER_KEY,
  VERIFY_UPLOAD_SELFIE_ID_KEY,
} from 'lib/constants/kyc/accountVerification';
import { NOVA_USER_VERIFICATION_KEY } from 'services/constants';
import cookie from 'services/cookie';
import { useSubmitAccountMutation, useUploadDocsMutation } from 'services/kyc';
import { SELFIE_SESSION_ID_KEY } from 'store/slices/verification/constants';
import { boolean, object, string } from 'yup';

import { useGetInitialValues } from '../utils/constants';

export default function useUploadDocs() {
  const {
    mutate,
    error: uploadDocsError,
    isError: isUploadDocsError,
    isPending,
    isSuccess: isUploadDocsSuccess,
  } = useUploadDocsMutation();

  const {
    mutate: mutateSubmitAccount,
    error: submitAccountError,
    isError: isSubmitAccountError,
    isPending: isSubmitAccountPending,
    closeSuccessModal,
    successModalIsOpen,
  } = useSubmitAccountMutation();

  const initialValues = useGetInitialValues();

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      mutate(values, {
        onSuccess: () => {
          mutateSubmitAccount();
          cookie.setWithExpiry(NOVA_USER_VERIFICATION_KEY, '', 600);
          window.localStorage.removeItem(SELFIE_SESSION_ID_KEY);
          window.localStorage.removeItem(VERIFY_UPLOAD_FRONT_DOC_KEY);
          window.localStorage.removeItem(VERIFY_UPLOAD_ADDITIONAL_FIELDS_KEY);
        },
      });
    },
    validationSchema: object().shape({
      [VERIFY_UPLOAD_ID_TYPE_KEY]: string().required(),
      [VERIFY_UPLOAD_IS_BIOMETRIC_ENABLED_KEY]: boolean(),
      [VERIFY_UPLOAD_FRONT_DOC_KEY]: string(),
      [VERIFY_UPLOAD_BACK_DOC_KEY]: string(),
      [VERIFY_UPLOAD_SELFIE_ID_KEY]: string().required(),
      [VERIFY_UPLOAD_PROVIDER_KEY]: string().required(),
      [VERIFY_UPLOAD_COUNTRY_CODE_KEY]: string().required(),
    }),
  });

  function getInputProps(id: keyof typeof formik.values) {
    return {
      ...formik.getFieldProps(id),
      ...formik.getFieldMeta(id),
      ...formik.getFieldHelpers(id),
      ...formik.errors,
    };
  }

  return Object.freeze({
    onSubmit: formik.handleSubmit,
    getInputProps,
    setValue: formik.setFieldValue,
    isValid: formik.isValid,
    errors: formik.errors,
    formValues: formik.values,
    closeSuccessModal,
    successModalIsOpen,
    submitAccountError,
    uploadDocsError,
    isError: isUploadDocsError || isSubmitAccountError,
    isPending: isPending || isSubmitAccountPending,
    isUploadDocsSuccess,
  });
}
