import { useMutation, useQuery } from '@tanstack/react-query';
import { queryClient } from 'main';

import { GET_BENEFICIARIES_QUERY_KEY } from './constants';
import { deleteBeneficiary, getBeneficiaries, postCreateBeneficiary } from './function';

export const useGetBeneficiaries = () => {
  const { data, isPending, isError } = useQuery({
    queryKey: [GET_BENEFICIARIES_QUERY_KEY],
    queryFn: getBeneficiaries,
  });

  return {
    data,
    isPending,
    isError,
  };
};

export const useCreateBeneficiary = () => {
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: postCreateBeneficiary,
  });

  return { mutate, isPending, isError, error };
};

export const useDeleteBeneficiary = () => {
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: deleteBeneficiary,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GET_BENEFICIARIES_QUERY_KEY] });
    },
  });

  return { mutate, isPending, isError, error };
};
