export function removeNonDigit(amount: string) {
  // Remove any non-digit characters
  if (!amount) return '';

  const cleanAmount = amount.replace(/[^0-9.]/g, '');
  const parts = cleanAmount.split('.');
  if (parts.length > 2) {
    const integerPart = parts.shift(); // Get integer part
    const decimalPart = parts.join(''); // Join remaining parts
    return `${integerPart}.${decimalPart}`;
  }

  return cleanAmount.length ? Number(cleanAmount).toString() : '';
}

export function formatAmount(amount: string) {
  if (!amount.length) return '';
  // Remove any non-digit characters
  const cleanAmount = removeNonDigit(amount);

  // Remove any non-digit characters
  return `${
    cleanAmount
      ? parseFloat(cleanAmount).toLocaleString('en-us', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      : ''
  }`;
}

export function formatInputAmount(amount: string) {
  // Remove any non-digit characters
  const cleanAmount = removeNonDigit(amount);

  // Remove any non-digit characters
  return `${
    cleanAmount ? parseFloat(cleanAmount).toLocaleString('en-us', { maximumFractionDigits: 2 }) : ''
  }`;
}

export function moneyFormat(amount: string) {
  const newAmount = Number(amount).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return newAmount;
}

export const truncateText = (text: string, length: number) => {
  if (text.length > length) {
    return text.substring(0, length) + '...';
  }
  return text;
};
