import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { REGISTER_EMAIL_KEY } from 'lib/constants/auth/register';

import { REGISTER_SLICE_REDUCER_PATH, registerSliceReducerInitialState } from './constants';

const registerSlice = createSlice({
  name: REGISTER_SLICE_REDUCER_PATH,
  initialState: registerSliceReducerInitialState,
  reducers: {
    resetRegisterSlice: () => ({
      ...registerSliceReducerInitialState,
    }),

    setRegisterEmail: (state, { payload }: PayloadAction<string>) => {
      state[REGISTER_EMAIL_KEY] = payload;
    },
  },
});

export const registerSliceReducer = registerSlice.reducer;

export const { resetRegisterSlice, setRegisterEmail } = registerSlice.actions;
