import Button from 'components/Buttons/Button';
import useErrorNotification from 'components/error/useErrorNotification';
import InputPin from 'components/Inputs/inputPin';
import { VERIFY_LOGIN_OTP_KEY } from 'lib/constants/auth/login';
import { useEffect } from 'react';

import useVerifyLoginForm from '../hooks/useVerifyLoginForm';

const VerifyLoginForm = () => {
  const {
    getInputProps,
    onSubmit,
    isValid,
    errors,
    isVerifyError,
    isVerifyPending,
    verifyError,
    values,
  } = useVerifyLoginForm();

  useEffect(() => {
    values[VERIFY_LOGIN_OTP_KEY].length === 4 && onSubmit();
  }, [onSubmit, values]);
  useErrorNotification(isVerifyError, verifyError);

  return (
    <form className="space-y-5 xl:space-y-10 mt-5 xl:mt-10" onSubmit={onSubmit}>
      <div className="w-max mx-auto">
        <InputPin
          id={VERIFY_LOGIN_OTP_KEY}
          numInputs={4}
          {...getInputProps(VERIFY_LOGIN_OTP_KEY)}
          error={errors[VERIFY_LOGIN_OTP_KEY]}
        />
      </div>
      <Button
        className="w-full"
        variant="purple"
        disabled={!isValid || isVerifyPending}
        isLoading={isVerifyPending}
      >
        Verify Code
      </Button>
    </form>
  );
};

export default VerifyLoginForm;
