import http from 'services/http';
import { GeneralApiResponse } from 'types';
import {
  AccountVerifyInput,
  PostAccountVerifyResponse,
  SupportedDocuments,
  UploadDocsInput,
} from 'types/kyc';

import { SelfieType } from './key';

export const postAccountVerify = async (
  data: AccountVerifyInput
): Promise<GeneralApiResponse<PostAccountVerifyResponse>> => {
  const res = await http.post('/accounts/verify', data);
  return res.data;
};

export const getSupportedDocs = async (): Promise<GeneralApiResponse<SupportedDocuments[]>> => {
  const res = await http.get('/accounts/verify/supported_docs');
  return res.data;
};

export const getSelfieSessionId = async (id: string): Promise<GeneralApiResponse<string>> => {
  const res = await http.get(`/accounts/selfie_capture/${id}/start`);
  return res.data;
};

export const getSelfieCaptureResult = async (
  id: string,
  session_id: string,
  type: SelfieType
): Promise<GeneralApiResponse<string>> => {
  const res = await http.get(`/accounts/selfie_capture/${id}/${session_id}/${type}/results`);
  return res.data;
};

export const postUploadDocs = async (
  data: UploadDocsInput
): Promise<GeneralApiResponse<object>> => {
  const res = await http.post('/accounts/verify/upload', data);
  return res.data;
};

export const postSubmitAccount = async (): Promise<GeneralApiResponse<object>> => {
  const res = await http.post('/accounts/verify/submit');
  return res.data;
};
