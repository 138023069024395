import SetPasswordForm from './components/setPasswordForm';
import AuthCardLayout from '../authCardLayout';
import AuthLayout from '../layout';

const SetPassword = () => {
  return (
    <AuthLayout>
      <AuthCardLayout
        title="Set your password"
        subtitle="Your password would be used when you want to log in"
      >
        <SetPasswordForm />
      </AuthCardLayout>
    </AuthLayout>
  );
};

export default SetPassword;
