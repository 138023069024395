import { ChevronLeft, Copy } from 'lucide-react';
import { BsFlagFill } from 'react-icons/bs';
import { GiCheckedShield } from 'react-icons/gi';
import { MdLightbulb, MdTimelapse } from 'react-icons/md';
import Skeleton from 'react-loading-skeleton';
import { Link, useParams } from 'react-router-dom';
import { ACCOUNTS_SETTINGS_ROUTE } from 'routes/path';
import { useGetAccount, useGetIssuedBankAccount } from 'services/account';
import { copyToClipboard } from 'utils/clipboard';

import DashboardCardLayout from '../components/dashboardCardLayout';
import SubRouteLayout from '../components/subRouteLayout';

const AccountDetails = () => {
  const { type } = useParams();

  const { data: account } = useGetAccount();

  const { data, isPending: isLoadingBankAccount } = useGetIssuedBankAccount();

  const bankAccount = data?.data.find((x) => x.meta.currency === type?.toUpperCase());

  return (
    <SubRouteLayout header="Account details">
      <div className="pb-20 xl:-ml-[4.688rem]">
        <div className="xl:flex items-start xl:space-x-11">
          <Link to={ACCOUNTS_SETTINGS_ROUTE} className="flex space-x-1.5 xl:space-x-3 items-center">
            <ChevronLeft className="w-3 h-3 xl:w-6 xl:h-6" />
            <span className="text-xs xl:text-base">Back</span>
          </Link>

          <div className="mt-10 xl:mt-0">
            <p className="text-lg xl:text-2xl font-medium">{type?.toUpperCase()} Account details</p>
            <p className="mt-2 text-grey-text text-xs xl:text-base">
              Receive money using the details below
            </p>

            <div className="mt-7 xl:mt-14 space-y-5">
              <DashboardCardLayout className="xl:w-[37.5rem]">
                <div className="flex justify-between">
                  <p className="text-grey-text text-sm xl:text-base">Nova tag</p>
                  <p className="font-medium text-xs xl:text-base">@{account?.data.username}</p>
                </div>
              </DashboardCardLayout>

              {bankAccount && !isLoadingBankAccount && (
                <DashboardCardLayout className="xl:w-[37.5rem]">
                  <div className="space-y-5">
                    <div className="flex justify-between">
                      <p className="text-xs xl:text-base text-grey-text">Account number</p>
                      <div className="flex items-center space-x-3">
                        <p className="text-xs xl:text-base font-medium">
                          {bankAccount.account_number}
                        </p>
                        <button
                          onClick={() =>
                            copyToClipboard('Account number', bankAccount.account_number)
                          }
                        >
                          <Copy size={15} className="text-platnova-purple" />
                        </button>
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <p className="text-xs xl:text-base text-grey-text min-w-max">Account name</p>
                      <div className="flex items-center space-x-3 max-w-[65%] md:max-w-[70%]">
                        <p className="text-xs xl:text-base font-medium truncate">
                          {bankAccount.account_name}
                        </p>
                        <button
                          onClick={() =>
                            copyToClipboard('Account name', bankAccount.account_number)
                          }
                        >
                          <Copy size={15} className="text-platnova-purple" />
                        </button>
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <p className="text-xs xl:text-base text-grey-text">Bank name</p>
                      <div className="flex items-center space-x-3 max-w-[65%] md:max-w-[70%]">
                        <p className="text-xs xl:text-base font-medium truncate">
                          {bankAccount.bank_name}
                        </p>
                        <button onClick={() => copyToClipboard('Bank name', bankAccount.bank_name)}>
                          <Copy size={15} className="text-platnova-purple" />
                        </button>
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <p className="text-xs xl:text-base text-grey-text">Account type</p>
                      <div className="flex items-center space-x-3">
                        <p className="text-xs xl:text-base font-medium">
                          {bankAccount.meta.account_type}
                        </p>
                      </div>
                    </div>
                  </div>
                </DashboardCardLayout>
              )}

              {isLoadingBankAccount && (
                <div>
                  <Skeleton className="h-40 xl:h-56" />
                </div>
              )}

              <DashboardCardLayout className="xl:w-[37.5rem] space-y-8">
                <div className="flex space-x-3 xl:space-x-11 items-center">
                  <GiCheckedShield className="stroke-grey-silent-icons fill-grey-silent-icons w-5 h-5" />
                  <p className="text-grey-text text-xs xl:text-base">
                    Your money is held in licensed banks.
                  </p>
                </div>

                <div className="flex space-x-3 xl:space-x-11 items-center">
                  <MdLightbulb className="stroke-grey-silent-icons fill-grey-silent-icons w-5 h-5" />
                  <p className="text-grey-text text-xs xl:text-base w-fit">
                    Use these details to receive transfers from only NGN bank accounts.
                  </p>
                </div>

                <div className="flex space-x-3 xl:space-x-11 items-center">
                  <MdTimelapse className="stroke-grey-silent-icons fill-grey-silent-icons w-5 h-5" />
                  <p className="text-grey-text text-xs xl:text-base">
                    Transfers are usually instant.
                  </p>
                </div>

                <div className="flex space-x-3 xl:space-x-11 items-center">
                  <BsFlagFill className="stroke-grey-silent-icons fill-grey-silent-icons w-5 h-5" />
                  <p className="text-grey-text text-xs xl:text-base w-fit">
                    Only local transfers are accepted. For international transfers, please contact
                    support
                  </p>
                </div>
              </DashboardCardLayout>
            </div>
          </div>
        </div>
      </div>
    </SubRouteLayout>
  );
};

export default AccountDetails;
