import Button from 'components/Buttons/Button';
import { ChevronLeft } from 'lucide-react';
import { useNavigate, useParams } from 'react-router-dom';
import { SETTINGS_BENEFICIARIES_ROUTE } from 'routes/path';
import { useDeleteBeneficiary, useGetBeneficiaries } from 'services/beneficiary';
import { useGetSendPaymentMethods } from 'utils/getPaymentMethod';

import DashboardCardLayout from '../components/dashboardCardLayout';
import SubRouteLayout from '../components/subRouteLayout';

const BeneficiaryDetails = () => {
  const navigate = useNavigate();
  const { data: beneficiaries } = useGetBeneficiaries();

  const { id } = useParams();
  const beneficiary = beneficiaries?.data.find((x) => x.id === id);

  const { mutate, isPending } = useDeleteBeneficiary();

  const methods = useGetSendPaymentMethods(beneficiary?.currency);

  const paymentMethod = methods?.find((x) => x.id === beneficiary?.payment_method)?.name;

  const handleDeleteBenficiary = () => {
    mutate(id as string);
    navigate(SETTINGS_BENEFICIARIES_ROUTE);
  };

  return (
    <SubRouteLayout header="View Beneficiary">
      <div className="pb-20 w-full max-w-[52rem]">
        <div className="xl:flex items-start xl:space-x-11 w-full">
          <button
            onClick={() => navigate(-1)}
            className="flex space-x-1.5 xl:space-x-3 items-center"
          >
            <ChevronLeft className="w-3 h-3 xl:w-6 xl:h-6" />
            <span className="text-xs xl:text-base">Back</span>
          </button>

          <div className="w-full mt-5 xl:mt-0">
            <div className="w-full">
              <div>
                <p className="text-xl xl:text-2xl font-medium">View Beneficiary</p>
                <p className="my-1 xl:mt-2 mb-4 xl:mb-8 font-medium text-xs xl:text-lg text-grey-text">
                  Manage your beneficiary
                </p>

                <DashboardCardLayout className="xl:w-full space-y-5">
                  <div className="w-full flex justify-between">
                    <p className="text-grey-text">Currency</p>
                    <p className="font-medium">{beneficiary?.currency.toUpperCase()}</p>
                  </div>
                  <div className="w-full flex justify-between">
                    <p className="text-grey-text">Payment method</p>
                    <p className="font-medium">{paymentMethod}</p>
                  </div>
                  {beneficiary?.meta.map((item) => (
                    <div className="w-full flex justify-between" key={item.id}>
                      <p className="text-grey-text">{item.label}</p>
                      <p className="font-medium">{item.value}</p>
                    </div>
                  ))}
                </DashboardCardLayout>
                <Button
                  variant="remove"
                  className="w-full mt-12 shadow-none"
                  isLoading={isPending}
                  onClick={handleDeleteBenficiary}
                >
                  Remove
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SubRouteLayout>
  );
};

export default BeneficiaryDetails;
