import Button from 'components/Buttons/Button';
import useErrorNotification from 'components/error/useErrorNotification';
import InputPin from 'components/Inputs/inputPin';
import { REGISTER_OTP_KEY } from 'lib/constants/auth/register';
import { useEffect } from 'react';

import useAccountVerificationForm from '../hooks/useAccountVerificationForm';

const AccountVerificationForm = () => {
  const {
    getInputProps,
    onSubmit,
    isValid,
    errors,
    isError,
    isPending,
    registerVerificationError,
    values,
  } = useAccountVerificationForm();

  useEffect(() => {
    values[REGISTER_OTP_KEY].length === 4 && onSubmit();
  }, [onSubmit, values]);

  useErrorNotification(isError, registerVerificationError);

  return (
    <form className="space-y-5 mt-10 flex flex-col" onSubmit={onSubmit}>
      <div className="w-max mx-auto">
        <InputPin
          id={REGISTER_OTP_KEY}
          numInputs={4}
          {...getInputProps(REGISTER_OTP_KEY)}
          error={errors[REGISTER_OTP_KEY]}
        />
      </div>
      <Button
        type="submit"
        className="w-full"
        variant="purple"
        isDisabled={!isValid || isPending}
        isLoading={isPending}
      >
        Verify Code
      </Button>
    </form>
  );
};

export default AccountVerificationForm;
