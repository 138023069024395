import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LOGIN_EMAIL_KEY } from 'lib/constants/auth/login';

import { LOGIN_SLICE_REDUCER_PATH, loginSliceReducerInitialState } from './constants';

const loginSlice = createSlice({
  name: LOGIN_SLICE_REDUCER_PATH,
  initialState: loginSliceReducerInitialState,
  reducers: {
    resetLoginSlice: () => ({
      ...loginSliceReducerInitialState,
    }),

    setLoginEmail: (state, { payload }: PayloadAction<string>) => {
      state[LOGIN_EMAIL_KEY] = payload;
    },
  },
});

export const loginSliceReducer = loginSlice.reducer;

export const { resetLoginSlice, setLoginEmail } = loginSlice.actions;
