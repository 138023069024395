import Modal, { ModalProps } from 'components/modal';
import { ChevronRight, X } from 'lucide-react';
import { Link } from 'react-router-dom';
import { CREATE_PHYSICAL_CARD_ROUTE } from 'routes/path';
import { useAppDispatch } from 'store';
import { setComingSoonModalOpen } from 'store/slices/layout/layoutSlice';

const CreateCardModal = ({ ...props }: ModalProps) => {
  const dispatch = useAppDispatch();
  return (
    <Modal {...props} className="p-8 w-full max-w-xl scroll-component pb-24 xl:pb-8">
      <div className="flex justify-between pb-3 border-b">
        <p className="text-xl md:text-2xl font-medium">Select Card Type</p>
        <X size={25} className="cursor-pointer" onClick={props.close} />
      </div>

      <Link
        to={CREATE_PHYSICAL_CARD_ROUTE}
        className="py-8 w-full flex justify-between space-x-10 px-3 pt-12 border-b"
      >
        <div className="text-start">
          <p className="text-base md:text-xl font-medium">Physical Card</p>
          <p className="text-sm md:text-lg text-black/70 mt-3">
            Customize your card and have it delivered to you
          </p>
        </div>
        <ChevronRight className="my-auto" size={24} />
      </Link>
      {/* <Link
        to={CREATE_VIRTUAL_CARD_ROUTE} className="py-8 w-full flex justify-between space-x-10 px-3">
        <div className="text-start">
          <p className="text-base md:text-xl font-medium">Virtual Card</p>
          <p className="text-sm md:text-lg text-black/70 mt-3">
            Get a free virtual card instantly and access
            <br /> seamless and secure online payments
          </p>
        </div>
        <ChevronRight className="my-auto" size={24} />
      </Link> */}
      <button
        onClick={() => {
          props.close();
          dispatch(setComingSoonModalOpen(true));
        }}
        className="py-8 w-full flex justify-between space-x-10 px-3"
      >
        <div className="text-start">
          <p className="text-base md:text-xl font-medium">Virtual Card</p>
          <p className="text-sm md:text-lg text-black/70 mt-3">
            Get a free virtual card instantly and access
            <br /> seamless and secure online payments
          </p>
        </div>
        <ChevronRight className="my-auto" size={24} />
      </button>

      <span className="text-sm xl:text-base font-medium text-black/70">
        Already have a Platnova card?{' '}
        {/* <Link to="#" className="text-platnova-purple font-semibold">
          Link it now
        </Link> */}
        <button
          onClick={() => {
            props.close();
            dispatch(setComingSoonModalOpen(true));
          }}
          className="text-platnova-purple font-semibold"
        >
          Link it now
        </button>
      </span>
    </Modal>
  );
};

export default CreateCardModal;
