/* eslint-disable unused-imports/no-unused-vars */
import { useEffect, useRef } from 'react';

const PaymentIframe = ({ url, script }: { url: string; script: string }) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // const div = divRef.current;
    // const iframe = iframeRef.current;
    // if (iframe) {
    //   const handleLoad = () => {
    //     const iframeDocument = window.document;
    //     if (iframeDocument) {
    //       const scriptElement = iframeDocument.createElement('script');
    //       scriptElement.type = 'text/javascript';
    //       scriptElement.textContent = script;
    //       iframeDocument.head.appendChild(scriptElement);
    //     }
    //   };
    //   iframe.addEventListener('message', (e) => console.log(e));
    //   iframe.addEventListener('load', handleLoad);
    //   return () => {
    //     iframe.removeEventListener('load', handleLoad);
    //   };
    // }
  }, [script]);

  return (
    <div ref={divRef}>
      <iframe
        ref={iframeRef}
        src={url}
        style={{ width: '100%', height: '100vh', border: 'none' }}
        title="Deposit"
      />
    </div>
  );
};

export default PaymentIframe;
