import { useMutation, useQuery } from '@tanstack/react-query';

import {
  getAccount,
  getIssuedBankAccount,
  postCreateBankAccount,
  postDepositPayment,
  postOtcDepositRequest,
  searchNovatag,
} from './function';
import { accountKeys } from './key';

export const useGetAccount = () => {
  const { data, isPending, isError } = useQuery({
    queryKey: [accountKeys.GET_ACCOUNT_KEY],
    queryFn: getAccount,
    retry: 1,
  });

  return {
    data,
    isPending,
    isError,
  };
};

export const useGetIssuedBankAccount = () => {
  const { data, isPending, isError, error } = useQuery({
    queryKey: [accountKeys.GET_ISSUED_ACCOUNT_KEY],
    queryFn: () => getIssuedBankAccount(),
  });

  return {
    data,
    isPending,
    isError,
    error,
  };
};

export const useCreateBankAccount = () => {
  const { mutate, isPending, isError, error, isSuccess } = useMutation({
    mutationFn: postCreateBankAccount,
  });

  return { mutate, isPending, isError, error, isSuccess };
};
export const useOtcDepositRequest = () => {
  const { mutate, isPending, isError, error, isSuccess } = useMutation({
    mutationFn: postOtcDepositRequest,
  });

  return { mutate, isPending, isError, error, isSuccess };
};

export const useDepositPayment = () => {
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: postDepositPayment,
  });

  return { mutate, isPending, isError, error };
};

export const useSearchNovatag = (tag: string) => {
  const { data, isPending, refetch } = useQuery({
    queryKey: [accountKeys.SEARCH_NOVATAG, tag],
    queryFn: () => searchNovatag(tag),
  });

  return {
    data,
    isPending,
    refetch,
  };
};
