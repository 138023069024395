import { useMutation } from '@tanstack/react-query';

import { postConvert } from './function';

export const useConvert = () => {
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: postConvert,
  });

  return { mutate, isPending, isError, error };
};
