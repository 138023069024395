import { DEPOSIT_REDIRECT_URL } from 'lib/constants/accounts/deposit';
import { useAppSelector } from 'store';

import PaymentIframe from './components/paymentIframe';
import { getNowPaymentScript } from './components/paymentScript';

const IframeDeposit = () => {
  const { [DEPOSIT_REDIRECT_URL]: url } = useAppSelector((state) => state.account);
  const selectedScript = getNowPaymentScript();

  // console.log(url)

  return (
    url && (
      <div style={{ width: '100%', height: '100vh' }}>
        <PaymentIframe url={url} script={selectedScript} />
      </div>
    )
  );
};

export default IframeDeposit;
