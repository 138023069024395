import Button from 'components/Buttons/Button';
import { notifyError } from 'components/error/notifyError';
import { VERIFY_UPLOAD_FRONT_DOC_KEY } from 'lib/constants/kyc/accountVerification';
import { ChevronLeft } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { ID_VERIFICATION_ROUTE, SELFIE_ROUTE, VERIFICATION_ROUTE } from 'routes/path';
import { NOVA_USER_VERIFICATION_KEY } from 'services/constants';
import cookie from 'services/cookie';
import { useInitiateSelfie } from 'services/kyc';
import { useAppSelector } from 'store';
import {
  SELFIE_SESSION_ID_KEY,
  VERIFICATION_SELECTED_DOC,
  VERIFICATION_VARIANT_KEY,
} from 'store/slices/verification/constants';
import AuthCardLayout from 'views/auth/authCardLayout';

import ImageInput from './components/imageInput';
import AuthLayout from '../auth/layout';

const UploadDocument = () => {
  const { [VERIFICATION_SELECTED_DOC]: selectedDoc } = useAppSelector(
    (state) => state.verification
  );

  const verificationCache = cookie.get(NOVA_USER_VERIFICATION_KEY);
  const navigate = useNavigate();
  const { data: initiateSelfie } = useInitiateSelfie();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (initiateSelfie?.data) {
      cookie.setWithExpiry(
        NOVA_USER_VERIFICATION_KEY,
        {
          ...verificationCache,
          [SELFIE_SESSION_ID_KEY]: initiateSelfie.data,
          [VERIFICATION_VARIANT_KEY]: 1,
        },
        600
      );
      navigate(SELFIE_ROUTE);
    } else {
      notifyError('Error loading selfie, please try again later');
    }
  };

  const handleImageUpload = (file: File) => {
    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result as string;
      const trimmedBase64String = base64String.split(',')[1];
      window.localStorage.setItem(VERIFY_UPLOAD_FRONT_DOC_KEY, trimmedBase64String);
    };
    reader.readAsDataURL(file);
  };

  return (
    <div>
      {selectedDoc.id && (
        <AuthLayout>
          <div className="w-full flex">
            <Link
              to={ID_VERIFICATION_ROUTE(selectedDoc.id)}
              className="mr-[5%] text-xs xl:text-base text-black/70 flex items-center space-x-1"
            >
              <ChevronLeft size={16} />
              <p>Back</p>
            </Link>
          </div>
          <AuthCardLayout
            title={selectedDoc.label}
            subtitle="Upload a clear picture of your document"
          >
            <form className="mt-10 space-y-6" onSubmit={handleSubmit}>
              <ImageInput onImageUpload={handleImageUpload} />
              <Button variant="purple" type="submit" className="w-full">
                Continue
              </Button>
            </form>
          </AuthCardLayout>
        </AuthLayout>
      )}

      {!selectedDoc.id && (
        <AuthLayout containerClassName="flex flex-col items-center space-y-3 xl:space-y-6">
          <p className="text-xl">Oops, Something went wrong...</p>
          <Button variant="purple" onClick={() => navigate(VERIFICATION_ROUTE)}>
            Back to verification
          </Button>
        </AuthLayout>
      )}
    </div>
  );
};

export default UploadDocument;
