import { SELFIE_ERROR_ROUTE, SELFIE_SUCCESS_ROUTE } from 'routes/path';

export const SELFIE_URL_PATH = (sessionId: string, appUrl: string) =>
  `https://selfie.platnova.co?session_id=${sessionId}&success_url=${appUrl}${SELFIE_SUCCESS_ROUTE}&error_url=${appUrl}${SELFIE_ERROR_ROUTE}&cancel_url=${appUrl}${SELFIE_ERROR_ROUTE}&theme_mode=light`;

export const CUSTOM_SELFIE_URL_PATH = ({
  sessionId,
  appUrl,
  successRoute,
  errorRoute,
}: {
  sessionId: string;
  appUrl: string;
  successRoute: string;
  errorRoute: string;
}) =>
  `https://selfie.platnova.co?session_id=${sessionId}&success_url=${appUrl}${successRoute}&error_url=${appUrl}${errorRoute}&cancel_url=${appUrl}${errorRoute}&theme_mode=light`;
