import Button from 'components/Buttons/Button';
import useErrorNotification from 'components/error/useErrorNotification';
import InputPin from 'components/Inputs/inputPin';
import { FORGOT_PASSWORD_OTP_KEY } from 'lib/constants/auth/forgotPassword';
import { useEffect } from 'react';

import useVerifyForgotPasswordForm from '../hooks/useVerifyForgotPasswordForm';

const VerifyForgotPasswordForm = () => {
  const {
    getInputProps,
    onSubmit,
    isValid,
    errors,
    isError,
    isPending,
    verifyForgotPasswordError,
    values,
  } = useVerifyForgotPasswordForm();

  useEffect(() => {
    values[FORGOT_PASSWORD_OTP_KEY].length === 4 && onSubmit();
  }, [onSubmit, values]);

  useErrorNotification(isError, verifyForgotPasswordError);

  return (
    <form className="space-y-5 xl:space-y-10 mt-5 xl:mt-10" onSubmit={onSubmit}>
      <div className="w-max mx-auto">
        <InputPin
          id={FORGOT_PASSWORD_OTP_KEY}
          numInputs={4}
          error={errors[FORGOT_PASSWORD_OTP_KEY]}
          {...getInputProps(FORGOT_PASSWORD_OTP_KEY)}
        />
      </div>
      <Button
        type="submit"
        className="w-full"
        variant="purple"
        isDisabled={!isValid || isPending}
        isLoading={isPending}
      >
        Verify Code
      </Button>
    </form>
  );
};

export default VerifyForgotPasswordForm;
