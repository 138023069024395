import Button from 'components/Buttons/Button';
import Flag from 'components/flag';
import { ChevronLeft } from 'lucide-react';
import { AiOutlineExport } from 'react-icons/ai';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { ACCOUNTS_ROUTE, WALLET_ACCOUNT_DETAILS_ROUTE } from 'routes/path';
import { useGetWallets } from 'services/wallet';
import { useAppDispatch } from 'store';
import { setComingSoonModalOpen } from 'store/slices/layout/layoutSlice';
import { formatAmount } from 'utils/numberFormat';

import SubRouteLayout from '../components/subRouteLayout';

const AccountSettings = () => {
  const { data: wallets, isPending: isLoadingWallets } = useGetWallets();
  const dispatch = useAppDispatch();

  return (
    <SubRouteLayout header="Account settings">
      <div className="pb-20 xl:-ml-[4.688rem]">
        <div className="xl:flex items-start xl:space-x-11">
          <Link to={ACCOUNTS_ROUTE} className="flex space-x-1.5 xl:space-x-3 items-center">
            <ChevronLeft className="w-3 h-3 xl:w-6 xl:h-6" />
            <span className="text-xs xl:text-base">Back</span>
          </Link>

          <div className="flex flex-col">
            <div className="mt-5 xl:mt-0 xl:flex xl:space-x-14">
              <div>
                <p className="font-medium text-xl xl:text-2xl">View all Accounts</p>
                <p className="mt-2 text-grey-text text-xs xl:text-base">
                  View and manage all your accounts in one place
                </p>
              </div>
              <Button
                variant="purple"
                type="button"
                onClick={() => dispatch(setComingSoonModalOpen(true))}
                className="p-3 xl:p-3 h-max hidden xl:flex items-center space-x-2 text-xs xl:text-base xl:mt-0"
              >
                <AiOutlineExport />
                <span>Export statement</span>
              </Button>
            </div>
            <div className="mt-14 grid grid-cols-2 xl:grid-cols-3 gap-5">
              {!isLoadingWallets &&
                wallets &&
                wallets.data.map((wallet) => (
                  <Link
                    to={WALLET_ACCOUNT_DETAILS_ROUTE(wallet.type)}
                    key={wallet.id}
                    className="bg-white p-3 rounded-[10px] shadow-sm"
                  >
                    <Flag code={wallet.country.code} />

                    <div className="mt-10 space-y-2">
                      <p className="text-sm">{wallet.type.toUpperCase()}</p>
                      <p className="text-grey-silent-text text-sm">{wallet.description}</p>
                      <p className="text-base font-semibold">
                        {wallet.symbol}
                        {formatAmount(wallet.amount)}
                      </p>
                    </div>
                  </Link>
                ))}
            </div>
            <Button
              variant="purple"
              type="button"
              onClick={() => dispatch(setComingSoonModalOpen(true))}
              className="p-3 xl:p-3 h-max fixed bottom-10 left-0 right-0 mx-5 flex xl:hidden justify-center items-center space-x-2 text-sm xl:mt-0"
            >
              <AiOutlineExport />
              <span>Export statement</span>
            </Button>
            {isLoadingWallets && (
              <div className="grid grid-cols-3 gap-5">
                <Skeleton className="w-full h-[13rem]" />
                <Skeleton className="w-full h-[13rem]" />
                <Skeleton className="w-full h-[13rem]" />
              </div>
            )}
          </div>
        </div>
      </div>
    </SubRouteLayout>
  );
};

export default AccountSettings;
