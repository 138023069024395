import multiavatar from '@multiavatar/multiavatar/esm';
import { cn } from 'lib/utils';
import { useGetAccount } from 'services/account';

const Avatar = ({ avatar, className }: { avatar: string; className: string }) => {
  const avatarSvg = multiavatar(avatar);
  const { data: account } = useGetAccount();

  return account && account?.data.preference.avatar ? (
    <div className={cn('w-10 h-10', className)} dangerouslySetInnerHTML={{ __html: avatarSvg }} />
  ) : account ? (
    <div
      className={cn(
        'w-10 h-10 bg-platnova-secondary flex justify-center items-center rounded-full text-base',
        className
      )}
    >
      {account?.data.first_name[0].toUpperCase()}
    </div>
  ) : null;
};

export default Avatar;
