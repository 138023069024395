import { ring } from 'ldrs';
import { cn } from 'lib/utils';
import * as React from 'react';

type ButtonProps = {
  className?: string;
  variant?: 'white' | 'purple' | 'secondary' | 'remove';
  isDisabled?: boolean;
  isLoading?: boolean;
} & React.ComponentPropsWithRef<'button'>;

ring.register();

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { className, children, variant = 'white', isDisabled = false, isLoading = false, ...rest },
    ref
  ) => {
    return (
      <button
        ref={ref}
        className={cn(
          'px-4 py-2 h-10 md:h-14 font-medium text-xs xl:text-sm rounded-[8px] shadow-md hover:shadow-none hover:bg-opacity-90',
          'transition-colors duration-500',
          [isDisabled && 'cursor-not-allowed'],
          [variant === 'white' && ' text-platnova-purple bg-white'],
          [variant === 'purple' && 'text-white bg-platnova-purple'],
          [variant === 'secondary' && 'text-platnova-purple bg-platnova-secondary'],
          [
            variant === 'remove' &&
              'text-error-primary bg-transparent border-2 border-error-primary',
          ],
          className
        )}
        disabled={isDisabled}
        {...rest}
      >
        {isLoading ? <l-ring size="16" stroke="2" speed="2" color="white"></l-ring> : children}
      </button>
    );
  }
);

export default Button;
