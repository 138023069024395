export const GET_CARDS_QUERY_KEY = 'get_cards' as const;
export const ORDER_PHYSICAL_CARDS_DEBIT_CURRENCY_KEY = 'debit_currency' as const;
export const ORDER_PHYSICAL_CARDS_COLORS_KEY = 'colors' as const;
export const ORDER_PHYSICAL_CARDS_QUANTITY_KEY = 'quantity' as const;
export const ORDER_PHYSICAL_CARDS_UNIT_PRICE_KEY = 'unit_price' as const;
export const ORDER_PHYSICAL_CARDS_BRAND_KEY = 'brand' as const;
export const ORDER_PHYSICAL_CARDS_PROVIDER_KEY = 'provider' as const;
export const ORDER_PHYSICAL_CARDS_CURRENCY_KEY = 'currency' as const;
export const ORDER_PHYSICAL_CARDS_SHIPPING_COST_KEY = 'shipping_cost' as const;
export const ORDER_PHYSICAL_CARDS_BILLING_KEY = 'billing' as const;
export const ORDER_PHYSICAL_CARDS_STATE_KEY = 'state' as const;
export const ORDER_PHYSICAL_CARDS_CITY_KEY = 'city' as const;
export const ORDER_PHYSICAL_CARDS_COUNTRY_KEY = 'country' as const;
export const ORDER_PHYSICAL_CARDS_ADDRESS_KEY = 'address' as const;
export const ORDER_PHYSICAL_CARDS_DELIVERY_NOTE_KEY = 'delivery_note' as const;
