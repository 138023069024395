import { cn } from 'lib/utils';
import { ReactNode } from 'react';

interface InputLabelProps {
  id?: string;
  label?: ReactNode;
  className?: string;
}

const InputLabel = ({ id, label, className }: InputLabelProps) => {
  if (!id || !label) {
    return <></>;
  }
  return (
    <label htmlFor={id} className={cn('font-medium', [className && className])}>
      {label}
    </label>
  );
};

export default InputLabel;
