import { UseMutateFunction } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { GeneralApiResponse } from 'types';
import { object, string } from 'yup';

import Button from './Buttons/Button';
import useErrorNotification from './error/useErrorNotification';
import InputPin from './Inputs/inputPin';

type Props = {
  mutate: UseMutateFunction<
    GeneralApiResponse<object>,
    Error,
    {
      pin: string;
    },
    unknown
  >;
  isPending: boolean;
  isError: boolean;
  error: Error | null;
  close?: () => void;
};
const VerifyPinForm = ({ mutate, isPending, isError, error, close }: Props) => {
  const formik = useFormik({
    initialValues: { pin: '' },
    validationSchema: object().shape({
      pin: string().min(4, 'Invalid pin').max(4, 'Invalid pin').required('Pin cannot be empty'),
    }),
    onSubmit: (values) => mutate(values),
  });

  useEffect(() => {
    formik.values.pin.length === 4 && mutate(formik.values);
  }, [formik.values, mutate]);

  useErrorNotification(isError, error);

  function getInputProps(id: keyof typeof formik.values) {
    return {
      ...formik.getFieldHelpers(id),
      ...formik.getFieldMeta(id),
      ...formik.errors,
    };
  }

  return (
    <form onSubmit={formik.handleSubmit} className="flex flex-col items-center space-y-10 p-8">
      <p className="font-semibold text-2xl">Enter Pin</p>
      <div className="w-max mx-auto">
        <InputPin id="pin" numInputs={4} {...getInputProps('pin')} error={formik.errors.pin} />
      </div>
      <div className="w-full flex flex-col justify-center space-y-6">
        <Button
          className="mx-auto px-8 xl:px-8 w-full"
          variant="purple"
          disabled={isPending}
          isLoading={isPending}
        >
          Continue
        </Button>
        <button onClick={close} className="text-xs md:text-sm">
          Cancel
        </button>
      </div>
    </form>
  );
};

export default VerifyPinForm;
