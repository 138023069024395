const Vault = () => {
  return (
    <div className="w-[1.188rem] h-[1.188rem]">
      <svg
        className="w-full h-full"
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_3789_37)">
          <path
            d="M21.4289 0.857178H2.57171C2.11705 0.857178 1.68102 1.03779 1.35952 1.35928C1.03803 1.68077 0.857422 2.11681 0.857422 2.57146V18.8572C0.857422 19.3118 1.03803 19.7479 1.35952 20.0694C1.68102 20.3909 2.11705 20.5715 2.57171 20.5715H21.4289C21.8835 20.5715 22.3195 20.3909 22.641 20.0694C22.9625 19.7479 23.1431 19.3118 23.1431 18.8572V2.57146C23.1431 2.11681 22.9625 1.68077 22.641 1.35928C22.3195 1.03779 21.8835 0.857178 21.4289 0.857178Z"
            stroke="black"
            strokeOpacity="0.7"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.5725 13.7144C15.3682 13.7144 16.1313 13.3983 16.6939 12.8357C17.2565 12.2731 17.5725 11.51 17.5725 10.7144M14.5725 13.7144C13.7769 13.7144 13.0138 13.3983 12.4512 12.8357C11.8886 12.2731 11.5725 11.51 11.5725 10.7144M14.5725 13.7144V15.8572M17.5725 10.7144C17.5725 9.91874 17.2565 9.15568 16.6939 8.59307C16.1313 8.03046 15.3682 7.71439 14.5725 7.71439M17.5725 10.7144H19.7154M14.5725 7.71439C13.7769 7.71439 13.0138 8.03046 12.4512 8.59307C11.8886 9.15568 11.5725 9.91874 11.5725 10.7144M14.5725 7.71439V5.57153M11.5725 10.7144H9.42969M18.2068 7.0801L16.6983 8.58868M12.4468 12.8401L10.9383 14.3487M18.2068 14.3487L16.6983 12.8401M12.4468 8.58868L10.9383 7.0801M5.14397 7.71439V13.7144M3.42969 20.5715V23.143M19.7154 20.5715V23.143"
            stroke="black"
            strokeOpacity="0.7"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_3789_37">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default Vault;
