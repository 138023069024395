import { createElement } from 'react';
import { AppRouteConfig } from 'types/navigation';
import Base from 'views/base';

import { authRoutes } from './auth';
import dashboardRoutes from './dashboard';
import errorRoutes from './error';
import { BASE_ROUTE } from './path';
import { verificationRoutes } from './verification';

const baseRoute: AppRouteConfig[] = [
  {
    path: BASE_ROUTE,
    element: createElement(Base),
    restricted: false,
    showNav: false,
    showSideBar: false,
  },
];

export default [
  ...baseRoute,
  ...authRoutes,
  ...verificationRoutes,
  ...errorRoutes,
  ...dashboardRoutes,
];
