import { useFormik } from 'formik';
import {
  LOGIN_EMAIL_KEY,
  LOGIN_PASSWORD_KEY,
  LOGIN_VERIFY_ACCOUNT_ERROR_MESSAGE_KEY,
} from 'lib/constants/auth/login';
import { REGISTER_EMAIL_KEY } from 'lib/constants/auth/register';
import { useNavigate } from 'react-router-dom';
import { ACCOUNT_VERIFICATION_ROUTE, VERIFY_LOGIN_ROUTE } from 'routes/path';
import { useLogin } from 'services/auth/login';
import { useResendRegistrationOtp } from 'services/auth/register';
import { useAppDispatch } from 'store';
import { setLoginEmail } from 'store/slices/login/loginSlice';
import { setRegisterEmail } from 'store/slices/register/registerSlice';
import { errorHandler } from 'utils/error';
import { object, string } from 'yup';

export default function useLoginForm() {
  const {
    mutate,
    isPending: isLoginPending,
    isError: isLoginError,
    error: loginError,
  } = useLogin();

  const { mutate: resendRegistrationOtp } = useResendRegistrationOtp();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      [LOGIN_EMAIL_KEY]: '',
      [LOGIN_PASSWORD_KEY]: '',
    },
    onSubmit: (values) => {
      mutate(values, {
        onSuccess: () => {
          dispatch(setLoginEmail(values[LOGIN_EMAIL_KEY]));
          navigate(VERIFY_LOGIN_ROUTE);
        },
        onError: (err) => {
          // notify(errorHandler(err))
          if (errorHandler(err) === LOGIN_VERIFY_ACCOUNT_ERROR_MESSAGE_KEY) {
            dispatch(setRegisterEmail(values[LOGIN_EMAIL_KEY]));
            navigate(ACCOUNT_VERIFICATION_ROUTE);
            resendRegistrationOtp({ [REGISTER_EMAIL_KEY]: values[LOGIN_EMAIL_KEY] });
          }
        },
      });
    },
    validationSchema: object().shape({
      [LOGIN_EMAIL_KEY]: string()
        .email('Enter a valid email address')
        .required('Email cannot be empty'),
      [LOGIN_PASSWORD_KEY]: string().required('Password cannot be empty'),
    }),
  });

  function getInputProps(id: keyof typeof formik.values) {
    return {
      ...formik.getFieldProps(id),
      ...formik.getFieldMeta(id),
      ...formik.errors,
    };
  }

  return Object.freeze({
    onSubmit: formik.handleSubmit,
    getInputProps,
    setValue: formik.setFieldValue,
    isValid: formik.isValid,
    errors: formik.errors,
    isLoginPending: isLoginPending,
    isLoginError: isLoginError,
    loginError: loginError,
  });
}
