import { useFormik } from 'formik';
import {
  VERIFY_ACCOUNT_CITY_KEY,
  VERIFY_ACCOUNT_COUNTRY_CODE_KEY,
  VERIFY_ACCOUNT_COUNTRY_KEY,
  VERIFY_ACCOUNT_DIAL_CODE_KEY,
  VERIFY_ACCOUNT_IP_ADDRESS_KEY,
  VERIFY_ACCOUNT_ISSUER_COUNTRY_KEY,
  VERIFY_ACCOUNT_PHONE_KEY,
  VERIFY_ACCOUNT_POSTAL_CODE_KEY,
  VERIFY_ACCOUNT_PROVIDER_KEY,
  VERIFY_ACCOUNT_STATE_KEY,
  VERIFY_ACCOUNT_STREET_KEY,
} from 'lib/constants/kyc/accountVerification';
import { allCountries } from 'lib/countries/countries';
import { useMemo } from 'react';
import { useGetAccount } from 'services/account';
import { NOVA_USER_VERIFICATION_KEY } from 'services/constants';
import cookie from 'services/cookie';
import { useAccountVerify } from 'services/kyc';
import { useAppDispatch, useAppSelector } from 'store';
import { VERIFICATION_VALUES } from 'store/slices/verification/constants';
import {
  setVerificationStep,
  setVerificationValues,
} from 'store/slices/verification/verificationSlice';
import { NovaUserVerification } from 'types/auth';
import { object, string } from 'yup';

export default function useVerifyAccountForm() {
  const { error, isError, isPending } = useAccountVerify();
  const dispatch = useAppDispatch();
  const { [VERIFICATION_VALUES]: verificationValues } = useAppSelector(
    (state) => state.verification
  );
  const { data: accountResponse } = useGetAccount();
  const account = accountResponse?.data;
  const verificationCache: NovaUserVerification = cookie.get(NOVA_USER_VERIFICATION_KEY);

  const country =
    account && account.country_code
      ? allCountries.find((x) => x.cca2 === account.country_code)
      : '';

  const memoizedInitialValues = useMemo(() => {
    return {
      [VERIFY_ACCOUNT_PHONE_KEY]:
        verificationCache && verificationCache[VERIFY_ACCOUNT_PHONE_KEY]
          ? verificationCache[VERIFY_ACCOUNT_PHONE_KEY]
          : verificationValues[VERIFY_ACCOUNT_PHONE_KEY].length
          ? verificationValues[VERIFY_ACCOUNT_PHONE_KEY]
          : account && account.phone
          ? account.phone
          : '',
      [VERIFY_ACCOUNT_DIAL_CODE_KEY]:
        verificationCache && verificationCache[VERIFY_ACCOUNT_DIAL_CODE_KEY]
          ? verificationCache[VERIFY_ACCOUNT_DIAL_CODE_KEY]
          : verificationValues[VERIFY_ACCOUNT_DIAL_CODE_KEY].length > 0
          ? verificationValues[VERIFY_ACCOUNT_DIAL_CODE_KEY]
          : account && account.dial_code
          ? account.dial_code
          : '',
      [VERIFY_ACCOUNT_STREET_KEY]:
        verificationCache && verificationCache[VERIFY_ACCOUNT_STREET_KEY]
          ? verificationCache[VERIFY_ACCOUNT_STREET_KEY]
          : verificationValues[VERIFY_ACCOUNT_STREET_KEY],
      [VERIFY_ACCOUNT_CITY_KEY]:
        verificationCache && verificationCache[VERIFY_ACCOUNT_CITY_KEY]
          ? verificationCache[VERIFY_ACCOUNT_CITY_KEY]
          : verificationValues[VERIFY_ACCOUNT_CITY_KEY],
      [VERIFY_ACCOUNT_STATE_KEY]:
        verificationCache && verificationCache[VERIFY_ACCOUNT_STATE_KEY]
          ? verificationCache[VERIFY_ACCOUNT_STATE_KEY]
          : verificationValues[VERIFY_ACCOUNT_STATE_KEY],
      [VERIFY_ACCOUNT_POSTAL_CODE_KEY]:
        verificationCache && verificationCache[VERIFY_ACCOUNT_POSTAL_CODE_KEY]
          ? verificationCache[VERIFY_ACCOUNT_POSTAL_CODE_KEY]
          : verificationValues[VERIFY_ACCOUNT_POSTAL_CODE_KEY],
      [VERIFY_ACCOUNT_COUNTRY_CODE_KEY]:
        verificationCache && verificationCache[VERIFY_ACCOUNT_COUNTRY_CODE_KEY]
          ? verificationCache[VERIFY_ACCOUNT_COUNTRY_CODE_KEY]
          : verificationValues[VERIFY_ACCOUNT_COUNTRY_CODE_KEY],
      [VERIFY_ACCOUNT_COUNTRY_KEY]:
        verificationCache && verificationCache[VERIFY_ACCOUNT_COUNTRY_KEY]
          ? verificationCache[VERIFY_ACCOUNT_COUNTRY_KEY]
          : verificationValues[VERIFY_ACCOUNT_COUNTRY_KEY].length > 0
          ? verificationValues[VERIFY_ACCOUNT_COUNTRY_KEY]
          : country && country.name.common
          ? country.name.common
          : '',
    };
  }, [account, country, verificationCache, verificationValues]);

  const formik = useFormik({
    initialValues: memoizedInitialValues,
    onSubmit: (values) => {
      values[VERIFY_ACCOUNT_COUNTRY_CODE_KEY] = allCountries.find(
        (x) => x.name.common === values[VERIFY_ACCOUNT_COUNTRY_KEY]
      )?.cca2 as string;
      cookie.setWithExpiry(
        NOVA_USER_VERIFICATION_KEY,
        {
          ...cookie.get(NOVA_USER_VERIFICATION_KEY),
          verificationStep: 1,
          id: account?.id,
          ...values,
        },
        600
      );

      dispatch(setVerificationStep(1));
      dispatch(
        setVerificationValues({
          ...values,
          [VERIFY_ACCOUNT_IP_ADDRESS_KEY]: '',
          [VERIFY_ACCOUNT_PROVIDER_KEY]: '',
          [VERIFY_ACCOUNT_ISSUER_COUNTRY_KEY]: '',
        })
      );
    },
    validationSchema: object().shape({
      [VERIFY_ACCOUNT_PHONE_KEY]: string().required(),
      [VERIFY_ACCOUNT_DIAL_CODE_KEY]: string(),
      [VERIFY_ACCOUNT_STREET_KEY]: string().required('Street cannot be empty'),
      [VERIFY_ACCOUNT_CITY_KEY]: string().required('City cannot be empty'),
      [VERIFY_ACCOUNT_STATE_KEY]: string().required().required(),
      [VERIFY_ACCOUNT_POSTAL_CODE_KEY]: string().required('Postal code cannot be empty'),
      [VERIFY_ACCOUNT_COUNTRY_CODE_KEY]: string(),
      [VERIFY_ACCOUNT_COUNTRY_KEY]: string().required('Select a country'),
    }),
  });

  function getInputProps(id: keyof typeof formik.values) {
    return {
      ...formik.getFieldProps(id),
      ...formik.getFieldMeta(id),
      ...formik.getFieldHelpers(id),
    };
  }

  return Object.freeze({
    onSubmit: formik.handleSubmit,
    getInputProps,
    setValue: formik.setFieldValue,
    isValid: formik.isValid,
    errors: formik.errors,
    verifyAccountError: error,
    isError: isError,
    isPending: isPending,
    formValues: formik.values,
  });
}
