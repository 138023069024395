import QrCode from 'assets/images/qrcode.svg';
import { Link } from 'react-router-dom';
import { REGISTER_ROUTE } from 'routes/path';

import LoginForm from './components/loginForm';
import AuthLayout from '../layout';

const Login = () => {
  return (
    <AuthLayout containerClassName="">
      <div className="bg-white rounded-[10px] px-2 p-5 md:px-10 md:p-10 w-full h-full xl:h-max">
        <div className="w-full flex items-center justify-between gap-10 xl:gap-16 flex-[1_1_max-content]">
          <div className="md:min-w-[21.875rem] 2xl:min-w-[25rem] space-y-10 w-full">
            <div className="space-y-2">
              <h1 className="text-2xl md:text-3xl font-medium">Log in</h1>
              <p className="text-sm md:text-base text-grey-text">
                Enter your log in details to continue
              </p>
            </div>

            <LoginForm />
            <h4 className="text-sm font-normal mt-10">
              Don’t have an account?{' '}
              <Link to={REGISTER_ROUTE}>
                <span className="font-bold text-platnova-purple">Create Account</span>
              </Link>
            </h4>
          </div>

          <div className="xl:px-6 py-10 h-max min-w-[15.625rem] 2xl:min-w-[18.75rem] xl:bg-secondary-grey rounded-[20px] hidden md:block">
            <div className="w-10/12 mx-auto">
              <img src={QrCode} alt="qr code" className="w-52 h-52 mx-auto" />
            </div>

            <div className="mx-auto text-center my-6 space-y-1.5">
              <span className="text-lg font-medium">Scan QR code to log in</span>
              <p className="opacity-60 w-10/12 mx-auto text-sm">
                Scan this code and log in instantly!
              </p>
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default Login;
