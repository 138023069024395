import Button from 'components/Buttons/Button';
import { notifySuccess } from 'components/error/notifySuccess';
import useErrorNotification from 'components/error/useErrorNotification';
import Input from 'components/Inputs/input';
import { useFormik } from 'formik';
import {
  CHANGE_PASSWORD_CONFIRM_NEW_PASSWORD_ID,
  CHANGE_PASSWORD_NEW_PASSWORD_ID,
  CHANGE_PASSWORD_OLD_PASSWORD_ID,
} from 'lib/constants/changePassword';
import { ChevronLeft } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { ACCOUNTS_ROUTE, SETTINGS_ROUTE } from 'routes/path';
import { useChangePassword } from 'services/settings';
import * as yup from 'yup';

import DashboardCardLayout from '../components/dashboardCardLayout';
import SubRouteLayout from '../components/subRouteLayout';

const ChangePassword = () => {
  const { mutate, isPending, isError, error } = useChangePassword();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      [CHANGE_PASSWORD_OLD_PASSWORD_ID]: '',
      [CHANGE_PASSWORD_NEW_PASSWORD_ID]: '',
      [CHANGE_PASSWORD_CONFIRM_NEW_PASSWORD_ID]: '',
    },
    validationSchema: yup.object().shape({
      [CHANGE_PASSWORD_OLD_PASSWORD_ID]: yup.string().required('Enter your current password'),
      [CHANGE_PASSWORD_NEW_PASSWORD_ID]: yup.string().required('Password cannot be empty'),
      [CHANGE_PASSWORD_CONFIRM_NEW_PASSWORD_ID]: yup
        .string()
        .required('Confirm Password cannot be empty')
        .oneOf([yup.ref(CHANGE_PASSWORD_NEW_PASSWORD_ID)], 'Passwords do not match'),
    }),
    onSubmit: (values) => {
      mutate(values, {
        onSuccess: () => {
          navigate(ACCOUNTS_ROUTE);
          notifySuccess('Password changed successfully');
        },
      });
    },
  });

  useErrorNotification(isError, error);

  function getInputProps(id: keyof typeof formik.values) {
    return {
      ...formik.getFieldHelpers(id),
      ...formik.getFieldMeta(id),
      ...formik.getFieldProps(id),
      ...formik.errors,
    };
  }
  return (
    <SubRouteLayout header="Change Password">
      <div className="pb-20 w-full max-w-[52rem]">
        <div className="xl:flex items-start xl:space-x-11 w-full">
          <Link to={SETTINGS_ROUTE} className="flex space-x-1.5 xl:space-x-3 items-center">
            <ChevronLeft className="w-3 h-3 xl:w-6 xl:h-6" />
            <span className="text-xs xl:text-base">Back</span>
          </Link>

          <div className="mt-6 md:mt-0">
            <p className="text-xl md:text-2xl font-medium">Change Password</p>
            <p className="text-grey-text text-xs md:text-base mt-2">
              Enter your old password and your preferred new password
            </p>

            <DashboardCardLayout className="w-full mt-7 md:mt-14">
              <form className="space-y-5" onSubmit={formik.handleSubmit}>
                <Input
                  id={CHANGE_PASSWORD_OLD_PASSWORD_ID}
                  label="Old Password"
                  inputMode="text"
                  type="password"
                  className="w-full"
                  placeholder="Enter old password"
                  error={formik.errors[CHANGE_PASSWORD_OLD_PASSWORD_ID]}
                  {...getInputProps(CHANGE_PASSWORD_OLD_PASSWORD_ID)}
                />
                <Input
                  id={CHANGE_PASSWORD_NEW_PASSWORD_ID}
                  label="New Password"
                  inputMode="text"
                  type="password"
                  className="w-full"
                  placeholder="Enter new password"
                  error={formik.errors[CHANGE_PASSWORD_NEW_PASSWORD_ID]}
                  {...getInputProps(CHANGE_PASSWORD_NEW_PASSWORD_ID)}
                />
                <Input
                  id={CHANGE_PASSWORD_CONFIRM_NEW_PASSWORD_ID}
                  label="Confirm New Password"
                  inputMode="text"
                  type="password"
                  className="w-full"
                  placeholder="Confirm new password"
                  error={formik.errors[CHANGE_PASSWORD_CONFIRM_NEW_PASSWORD_ID]}
                  {...getInputProps(CHANGE_PASSWORD_CONFIRM_NEW_PASSWORD_ID)}
                />
                <Button variant="purple" type="submit" isLoading={isPending} className="w-full">
                  Next
                </Button>
              </form>
            </DashboardCardLayout>
          </div>
        </div>
      </div>
    </SubRouteLayout>
  );
};

export default ChangePassword;
