import clsx, { ClassValue } from 'clsx';
import { useGetConfigurations } from 'services/configurations';
import { useGetWallets } from 'services/wallet';
import { useAppSelector } from 'store';
import { ACCOUNTS_SLICE_REDUCER_PATH } from 'store/slices/account/constants';
import {
  RECIPIENT_CURRENCY_KEY,
  TO_ACCOUNT_WALLET_ID_KEY,
  WALLET_ID_KEY,
  WALLET_SLICE_REDUCER_PATH,
} from 'store/slices/wallet/constants';
import { twMerge } from 'tailwind-merge';
import {
  CardBrands,
  Configuration,
  DynamicInputs,
  ExchangeRate,
  PaymentMethodConfig,
  PaymentMethodConfigType,
  ProcessingFeesConfig,
  TransactionLimitsConfig,
} from 'types/configuration';
import { formatAmount } from 'utils/numberFormat';

import { DEPOSIT_PAYMENT_METHOD_KEY } from './constants/accounts/deposit';
import { SEND_PAYMENT_METHOD_KEY } from './constants/accounts/send';
import {
  CONFIGURATION_CONVERT_PAYMENT_KEY,
  CONFIGURATION_DEPOSIT_KEY,
  CONFIGURATION_DEPOSIT_PROCESSING_FEES_ID_KEY,
  CONFIGURATION_PAYMENT_METHOD_KEY,
  CONFIGURATION_PROCESSING_FEES_KEY,
  CONFIGURATION_TRANSACTION_LIMITS_KEY,
} from './constants/configuration';

/** Merge classes with tailwind-merge with clsx full feature */
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const useGetActiveWallet = () => {
  const { data: wallets } = useGetWallets();
  const { [WALLET_ID_KEY]: walletId } = useAppSelector((state) => state[WALLET_SLICE_REDUCER_PATH]);
  const activeWallet =
    walletId.length > 0 ? wallets?.data.find((x) => x.id === walletId) : wallets?.data[0];
  return activeWallet;
};

export const useGetActiveRecipientWallet = () => {
  const { data: wallets } = useGetWallets();
  const { [RECIPIENT_CURRENCY_KEY]: recipientCurrency } = useAppSelector(
    (state) => state[WALLET_SLICE_REDUCER_PATH]
  );
  const activeWallet = recipientCurrency.length ? recipientCurrency : wallets?.data[0].type;
  return activeWallet;
};

export const useGetActiveToWallet = () => {
  const { data: wallets } = useGetWallets();
  const { [TO_ACCOUNT_WALLET_ID_KEY]: walletId } = useAppSelector(
    (state) => state[WALLET_SLICE_REDUCER_PATH]
  );
  const activeWallet =
    walletId.length > 1 ? wallets?.data.find((x) => x.id === walletId) : wallets?.data[1];
  return activeWallet;
};

export const useGetPaymentMethods = () => {
  const activeWallet = useGetActiveWallet();
  const { data: configurations } = useGetConfigurations();
  const paymentMethods: Configuration<PaymentMethodConfig> | undefined = configurations?.data.find(
    (method): method is Configuration<PaymentMethodConfig> =>
      method.name === CONFIGURATION_PAYMENT_METHOD_KEY
  );
  const currencyMethods: PaymentMethodConfigType[] | undefined = paymentMethods?.value.find(
    (x) => x.currency === activeWallet?.type
  )?.types;
  const finalPaymentMethods = currencyMethods?.find(
    (x) => x.id === CONFIGURATION_DEPOSIT_KEY
  )?.methods;
  const displayedPaymentMethods = finalPaymentMethods?.filter((x) => x.is_visible);
  return displayedPaymentMethods;
};

export const useGetSendPaymentMethods = () => {
  const activeWallet = useGetActiveRecipientWallet();
  const { data: configurations } = useGetConfigurations();
  const paymentMethods: Configuration<PaymentMethodConfig> | undefined = configurations?.data.find(
    (method): method is Configuration<PaymentMethodConfig> =>
      method.name === CONFIGURATION_PAYMENT_METHOD_KEY
  );
  const currencyMethods: PaymentMethodConfigType[] | undefined = paymentMethods?.value.find(
    (x) => x.currency === (activeWallet || 'ngn')
  )?.types;
  const finalPaymentMethods = currencyMethods?.find((x) => x.id === 'send_payment')?.methods;
  const displayedPaymentMethods = finalPaymentMethods?.filter((x) => x.is_visible);
  return displayedPaymentMethods;
};

export const useGetDepositFees = () => {
  const { data: configurations } = useGetConfigurations();
  const { [DEPOSIT_PAYMENT_METHOD_KEY]: paymentMethod } = useAppSelector(
    (state) => state[ACCOUNTS_SLICE_REDUCER_PATH]
  );
  const paymentMethods = useGetPaymentMethods();

  const activePaymentMethod = paymentMethods?.find((x) => x.id === paymentMethod);
  const activeWallet = useGetActiveWallet();

  const processingFees: Configuration<ProcessingFeesConfig> | undefined = configurations?.data.find(
    (method): method is Configuration<ProcessingFeesConfig> =>
      method.name === CONFIGURATION_PROCESSING_FEES_KEY
  );
  const currencyMethods = processingFees?.value.find(
    (x) => x.currency === activeWallet?.type
  )?.types;
  const depositFees = currencyMethods?.find(
    (x) => x.id === CONFIGURATION_DEPOSIT_PROCESSING_FEES_ID_KEY
  )?.payment_methods;
  const methodFees = depositFees?.find((x) => x.id === activePaymentMethod?.id);
  return methodFees;
};

export const useGetConvertFees = () => {
  const { data: configurations } = useGetConfigurations();
  const activeWallet = useGetActiveWallet();

  const processingFees: Configuration<ProcessingFeesConfig> | undefined = configurations?.data.find(
    (method): method is Configuration<ProcessingFeesConfig> =>
      method.name === CONFIGURATION_PROCESSING_FEES_KEY
  );
  const currencyMethods = processingFees?.value.find(
    (x) => x.currency === activeWallet?.type
  )?.types;
  const convertFees = currencyMethods?.find(
    (x) => x.id === CONFIGURATION_CONVERT_PAYMENT_KEY
  )?.payment_methods;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return convertFees as any;
};

export const useGetSendFees = () => {
  const { data: configurations } = useGetConfigurations();
  const activeRecipientWallet = useGetActiveRecipientWallet();
  const { [SEND_PAYMENT_METHOD_KEY]: paymentMethod } = useAppSelector(
    (state) => state[ACCOUNTS_SLICE_REDUCER_PATH]
  );
  const paymentMethods = useGetSendPaymentMethods();
  const activeSendMethod = paymentMethods?.find((x) => x.id === paymentMethod);

  const processingFees: Configuration<ProcessingFeesConfig> | undefined = configurations?.data.find(
    (method): method is Configuration<ProcessingFeesConfig> =>
      method.name === CONFIGURATION_PROCESSING_FEES_KEY
  );
  const currencyMethods = processingFees?.value.find(
    (x) => x.currency === activeRecipientWallet
  )?.types;
  const sendFees = currencyMethods?.find((x) => x.id === 'send_payment')?.payment_methods;
  const methodFees = sendFees?.find((x) => x.id === activeSendMethod?.id);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return methodFees as any;
};

export const useGetDepositTransationLimits = () => {
  const { data: configurations } = useGetConfigurations();
  const activeWallet = useGetActiveWallet();
  const { [DEPOSIT_PAYMENT_METHOD_KEY]: paymentMethod } = useAppSelector(
    (state) => state[ACCOUNTS_SLICE_REDUCER_PATH]
  );
  const paymentMethods = useGetPaymentMethods();

  const activePaymentMethod = paymentMethods?.find((x) => x.id === paymentMethod);

  const transactionLimits = configurations?.data.find(
    (x): x is Configuration<TransactionLimitsConfig> =>
      x.name === CONFIGURATION_TRANSACTION_LIMITS_KEY
  );
  const currencyLimits = transactionLimits?.value.find(
    (x) => x.currency === activeWallet?.type
  )?.types;
  const depositLimits = currencyLimits?.find((x) => x.id === 'deposit')?.payment_methods;
  const paymentMethodLimits = depositLimits?.find((x) => x.id === activePaymentMethod?.id)?.amount;
  return paymentMethodLimits;
};

export const useGetConvertTransactionLimits = () => {
  const { data: configurations } = useGetConfigurations();
  const activeWallet = useGetActiveToWallet();

  const transactionLimits = configurations?.data.find(
    (x): x is Configuration<TransactionLimitsConfig> =>
      x.name === CONFIGURATION_TRANSACTION_LIMITS_KEY
  );
  const currencyLimits = transactionLimits?.value.find(
    (x) => x.currency === activeWallet?.type
  )?.types;
  const convertLimits = currencyLimits?.find((x) => x.id === 'convert_payment')?.payment_methods;
  const paymentMethodLimits = convertLimits?.find((x) => x.id === 'wallet')?.amount;
  return paymentMethodLimits;
};

export const useGetSendTransactionLimits = () => {
  const { data: configurations } = useGetConfigurations();
  const { [RECIPIENT_CURRENCY_KEY]: recipientCurrency } = useAppSelector(
    (state) => state[WALLET_SLICE_REDUCER_PATH]
  );
  const { [SEND_PAYMENT_METHOD_KEY]: paymentMethod } = useAppSelector(
    (state) => state[ACCOUNTS_SLICE_REDUCER_PATH]
  );

  const transactionLimits = configurations?.data.find(
    (x): x is Configuration<TransactionLimitsConfig> =>
      x.name === CONFIGURATION_TRANSACTION_LIMITS_KEY
  );

  const currencyLimits = transactionLimits?.value.find(
    (x) => x.currency === recipientCurrency
  )?.types;

  const convertLimits = currencyLimits?.find((x) => x.id === 'send_payment')?.payment_methods;
  const paymentMethodLimits = convertLimits?.find((x) => x.id === paymentMethod)?.amount;
  return paymentMethodLimits;
};

export const useGetExchangeRate = (type: string) => {
  const { data: configurations } = useGetConfigurations();

  const exchangeRates = configurations?.data
    .find((x): x is Configuration<ExchangeRate> => x.name === 'exchange_rates')
    ?.value.find((x) => x.currency === type);

  return exchangeRates as ExchangeRate;
};

export const useGetExchangeValue = (from: string, to: string) => {
  const fromRate = useGetExchangeRate(from);
  const toRate = useGetExchangeRate(to);

  const rate =
    fromRate?.rates.buy === toRate?.rates.buy ? 1 : toRate?.rates.buy / fromRate?.rates.sell;
  return rate;
};

export const useGetFormattedExchangeRate = (from: string, to: string) => {
  const fromRate = useGetExchangeRate(from);
  const toRate = useGetExchangeRate(to);

  let rate;
  let formattedExchangeRate;

  // Calculate the equivalent value for 1 unit of the higher value currency
  if (fromRate && toRate) {
    if (fromRate.rates.buy > toRate.rates.buy) {
      rate = fromRate.rates.sell / toRate.rates.buy;
      formattedExchangeRate = `1 ${toRate.currency.toUpperCase()} = ${fromRate.currency.toUpperCase()} ${formatAmount(
        String(rate)
      )}`;
    } else if (fromRate.rates.buy === toRate.rates.buy) {
      formattedExchangeRate = `1 ${fromRate.currency.toUpperCase()} = ${fromRate.currency.toUpperCase()} ${formatAmount(
        '1'
      )}`;
    } else {
      rate = toRate.rates.buy / fromRate.rates.sell;
      formattedExchangeRate = `1 ${fromRate.currency.toUpperCase()} = ${toRate.currency.toUpperCase()} ${formatAmount(
        String(rate)
      )}`;
    }

    return formattedExchangeRate;
  }
};

export const useGetSendBeneficiaryDynamicInputs = (currency: string, paymentMethod: string) => {
  const { data: configurations } = useGetConfigurations();

  const dynamicInputs = configurations?.data.find(
    (x): x is Configuration<DynamicInputs> => x.name === 'dynamic_inputs'
  )?.value;

  const currencyTypes = dynamicInputs
    ?.find((x) => x.name === 'beneficiary_input')
    ?.inputs.find((x) => x.currency === currency)?.types;

  const fields = currencyTypes
    ?.find((x) => x.id === 'send_payment')
    ?.payment_methods.find((x) => x.id === paymentMethod)?.fields;

  return fields;
};

export const useGetCardBrands = () => {
  const { data: configurations } = useGetConfigurations();
  const cardBrands = configurations?.data.find(
    (x): x is Configuration<CardBrands> => x.name === 'card_brands'
  )?.value;

  return cardBrands;
};

// export const useGetCardFees = () => {
//   const { data: configurations } = useGetConfigurations();
//   const activeWallet = useGetActiveWallet();

//   const processingFees = configurations?.data.find((x): x is Configuration<ProcessingFeesConfig> => x.name === 'processing_fees')?.value
//   const currencyValueTypes = processingFees?.find((x) => x.currency === activeWallet?.type)?.types

// }
