import { Link } from 'react-router-dom';
import { LOGIN_ROUTE } from 'routes/path';

import ResetPasswordForm from './components/resetPasswordForm';
import AuthCardLayout from '../authCardLayout';
import AuthLayout from '../layout';

const ResetPassword = () => {
  return (
    <AuthLayout>
      <AuthCardLayout title="Reset password" subtitle="You can now create a new password">
        <ResetPasswordForm />
        <Link to={LOGIN_ROUTE} className="text-sm md:text-base font-bold text-platnova-purple">
          Back to Log in
        </Link>
      </AuthCardLayout>
    </AuthLayout>
  );
};

export default ResetPassword;
