import SumsubWebSdk from '@sumsub/websdk-react';
import {
  VERIFY_ACCOUNT_CITY_KEY,
  VERIFY_ACCOUNT_COUNTRY_CODE_KEY,
  VERIFY_ACCOUNT_COUNTRY_KEY,
  VERIFY_ACCOUNT_DIAL_CODE_KEY,
  VERIFY_ACCOUNT_IP_ADDRESS_KEY,
  VERIFY_ACCOUNT_ISSUER_COUNTRY_KEY,
  VERIFY_ACCOUNT_PHONE_KEY,
  VERIFY_ACCOUNT_POSTAL_CODE_KEY,
  VERIFY_ACCOUNT_STATE_KEY,
  VERIFY_ACCOUNT_STREET_KEY,
  VERIFY_UPLOAD_PROVIDER_KEY,
} from 'lib/constants/kyc/accountVerification';
import { NOVA_USER_VERIFICATION_KEY } from 'services/constants';
import cookie from 'services/cookie';
import { useAccountVerify } from 'services/kyc';

const Sumsub = () => {
  const { mutate } = useAccountVerify();

  const verificationCache = cookie.get(NOVA_USER_VERIFICATION_KEY);
  const accessToken = verificationCache && verificationCache.sumSub ? verificationCache.sumSub : '';

  const expirationHandler = async (): Promise<string> => {
    return new Promise((resolve, reject) => {
      if (verificationCache) {
        mutate(
          {
            [VERIFY_ACCOUNT_CITY_KEY]: verificationCache.verification[VERIFY_ACCOUNT_CITY_KEY],
            [VERIFY_ACCOUNT_COUNTRY_KEY]:
              verificationCache.verification[VERIFY_ACCOUNT_COUNTRY_KEY],
            [VERIFY_ACCOUNT_COUNTRY_CODE_KEY]:
              verificationCache.verification[VERIFY_ACCOUNT_COUNTRY_CODE_KEY],
            [VERIFY_ACCOUNT_DIAL_CODE_KEY]:
              verificationCache.verification[VERIFY_ACCOUNT_DIAL_CODE_KEY],
            [VERIFY_ACCOUNT_IP_ADDRESS_KEY]:
              verificationCache.verification[VERIFY_ACCOUNT_IP_ADDRESS_KEY],
            [VERIFY_ACCOUNT_ISSUER_COUNTRY_KEY]:
              verificationCache.verification[VERIFY_ACCOUNT_ISSUER_COUNTRY_KEY],
            [VERIFY_ACCOUNT_PHONE_KEY]: verificationCache.verification[VERIFY_ACCOUNT_PHONE_KEY],
            [VERIFY_ACCOUNT_POSTAL_CODE_KEY]:
              verificationCache.verification[VERIFY_ACCOUNT_POSTAL_CODE_KEY],
            [VERIFY_UPLOAD_PROVIDER_KEY]:
              verificationCache.verification[VERIFY_UPLOAD_PROVIDER_KEY],
            [VERIFY_ACCOUNT_STATE_KEY]: verificationCache.verification[VERIFY_ACCOUNT_STATE_KEY],
            [VERIFY_ACCOUNT_STREET_KEY]: verificationCache.verification[VERIFY_ACCOUNT_STREET_KEY],
          },
          {
            onSuccess: (res) => {
              if (res && res.data && res.data.token) {
                resolve(res.data.token);
              } else {
                reject(new Error('Token not found in response'));
              }
            },
            onError: (error) => {
              reject(error);
            },
          }
        );
      } else {
        reject(new Error('Account verification information is missing'));
      }
    });
  };

  return (
    <>
      <SumsubWebSdk accessToken={accessToken} expirationHandler={expirationHandler} />
    </>
  );
};

export default Sumsub;
