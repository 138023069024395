import Button from 'components/Buttons/Button';
import { notifyError } from 'components/error/notifyError';
import Input from 'components/Inputs/input';
import { VERIFY_UPLOAD_ADDITIONAL_FIELDS_KEY } from 'lib/constants/kyc/accountVerification';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SELFIE_ROUTE, UPLOAD_DOCUMENT_ROUTE } from 'routes/path';
import { NOVA_USER_VERIFICATION_KEY } from 'services/constants';
import cookie from 'services/cookie';
import { useInitiateSelfie } from 'services/kyc';
import { useAppSelector } from 'store';
import {
  SELFIE_SESSION_ID_KEY,
  VERIFICATION_SELECTED_DOC,
  VERIFICATION_VARIANT_KEY,
} from 'store/slices/verification/constants';
import { NovaUserVerification } from 'types/auth';
// import { VERIFY_ID_NUMBER_KEY } from 'lib/constants/auth/verifyAccount';

const IdVerificationForm = () => {
  const { [VERIFICATION_SELECTED_DOC]: selectedDoc } = useAppSelector(
    (state) => state.verification
  );
  const { data: initiateSelfie, isPending } = useInitiateSelfie();

  const navigate = useNavigate();
  const verificationCache: NovaUserVerification = cookie.get(NOVA_USER_VERIFICATION_KEY);
  const [value, setValue] = useState({});
  const handleInputChange = (id: string, value: string, isFirst: boolean) => {
    isFirst && setValue({ [id]: value });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (initiateSelfie?.data) {
      if (selectedDoc.is_biometric_enabled) {
        cookie.setWithExpiry(
          NOVA_USER_VERIFICATION_KEY,
          {
            ...verificationCache,
            [VERIFY_UPLOAD_ADDITIONAL_FIELDS_KEY]: value,
            [VERIFICATION_VARIANT_KEY]: 0,
            [SELFIE_SESSION_ID_KEY]: initiateSelfie.data,
          },
          600
        );
        navigate(SELFIE_ROUTE);
      } else {
        cookie.setWithExpiry(
          NOVA_USER_VERIFICATION_KEY,
          { ...verificationCache, [VERIFY_UPLOAD_ADDITIONAL_FIELDS_KEY]: value },
          600
        );
        navigate(UPLOAD_DOCUMENT_ROUTE);
      }
    } else {
      notifyError('Error loading Selfie, please try again later');
    }
  };

  return (
    <form className="mt-5 xl:mt-10 space-y-3" onSubmit={handleSubmit}>
      {/* Select */}
      {selectedDoc.fields?.map((field, index) => (
        <Input
          key={field.id}
          id={field.id}
          label={field.label}
          pattern={field.regex}
          onChange={(e) => handleInputChange(field.id, e.target.value, index === 0)}
          required
        />
      ))}
      <div>
        <p className="text-xs xl:text-sm text-grey-text">
          On clicking continue, you will be prompted to capture a selfie of yourself and to provide
          your govt. issued ID for yor account verification
        </p>
        <Button
          className="w-full mt-5"
          type="submit"
          variant="purple"
          disabled={isPending}
          isLoading={isPending}
        >
          Continue
        </Button>
      </div>
    </form>
  );
};

export default IdVerificationForm;
