export const GET_BANKS_QUERY_KEY = 'get_banks' as const;
export const VERIFY_BANK_ACCOUNT_MUTATION_KEY = 'verify_account_number' as const;
export const GET_BANK_BRANCHES_QUERY_KEY = 'get_bank_branches';
export const GET_CRYPTO_NETWORKS_QUERY_KEY = 'get_crypto_networks' as const;
export const GET_CRYPTOS_QUERY_KEY = 'get_crypto_currencies' as const;

export interface VerifiedBankAccount {
  account_name: string;
  account_number: string;
  bank_code: string;
}
