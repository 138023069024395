import { ReactNode } from 'react';

import DashboardLayout from '../layout';

const PaddedContainer = ({ children }: { children: ReactNode }) => {
  return (
    <DashboardLayout className="space-y-10 xl:-ml-[7.813rem] mx-4 h-full">
      {children}
    </DashboardLayout>
  );
};

export default PaddedContainer;
