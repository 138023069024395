import Button from 'components/Buttons/Button';
import useErrorNotification from 'components/error/useErrorNotification';
import useSuccessNotification from 'components/error/useSuccessNotification';
import {
  DEPOSIT_CASHAPP_KEY,
  DEPOSIT_OTC_KEY,
  DEPOSIT_PAYMENT_METHOD_KEY,
  DEPOSIT_PAYPAL_KEY,
  DEPOSIT_REVOLUT_KEY,
  DEPOSIT_ZELLE_KEY,
} from 'lib/constants/accounts/deposit';
import { useGetActiveWallet, useGetDepositTransationLimits } from 'lib/utils';
import { ChevronLeft } from 'lucide-react';
import { AiFillInfoCircle } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { ACCOUNTS_DEPOSIT_ROUTE } from 'routes/path';
import { useOtcDepositRequest } from 'services/account';
import { useAppSelector } from 'store';
import { ACCOUNTS_SLICE_REDUCER_PATH } from 'store/slices/account/constants';
import { formatAmount } from 'utils/numberFormat';

import SubRouteLayout from '../../components/subRouteLayout';

const FinalizeDeposit = () => {
  const { [DEPOSIT_PAYMENT_METHOD_KEY]: paymentMethod } = useAppSelector(
    (state) => state[ACCOUNTS_SLICE_REDUCER_PATH]
  );
  const activeWallet = useGetActiveWallet();
  const limits = useGetDepositTransationLimits();

  const account =
    paymentMethod === DEPOSIT_OTC_KEY ? activeWallet?.type.toUpperCase() : paymentMethod;

  const { mutate, isPending, isError, error, isSuccess } = useOtcDepositRequest();

  useErrorNotification(isError, error);
  useSuccessNotification(isSuccess, 'Operation was successful');

  return paymentMethod === DEPOSIT_OTC_KEY ||
    paymentMethod === DEPOSIT_PAYPAL_KEY ||
    paymentMethod === DEPOSIT_ZELLE_KEY ||
    paymentMethod === DEPOSIT_REVOLUT_KEY ||
    paymentMethod === DEPOSIT_CASHAPP_KEY ? (
    <SubRouteLayout header={`Request ${account} Account details`}>
      <div className="pb-20 xl:-ml-[4.688rem] mx-[5%] xl:mx-0">
        <div className="xl:flex items-start xl:space-x-11 max-w-[43.75rem]">
          <Link to={ACCOUNTS_DEPOSIT_ROUTE} className="flex space-x-1.5 xl:space-x-3 items-center">
            <ChevronLeft className="w-3 h-3 xl:w-6 xl:h-6" />
            <span className="text-xs xl:text-base">Back</span>
          </Link>

          <div className="mt-5 xl:mt-0">
            <p className="text-lg xl:text-2xl font-medium capitalize">
              Request {account} Account details
            </p>
            <p className="mt-2 text-grey-text text-xs xl:text-base">
              {paymentMethod === DEPOSIT_OTC_KEY
                ? `Get a static ${activeWallet?.type.toUpperCase()} account for limitless deposits. Please
                note that the minimum required amount is ${activeWallet?.symbol} ${
                    limits && formatAmount(String(limits?.min))
                  }`
                : `Request ${account} account details, Please note that the minimum required amount is ${
                    activeWallet?.symbol
                  } ${limits && formatAmount(String(limits?.min))}`}
            </p>
            <div className="mt-6 bg-platnova-secondary p-5 xl:p-12 rounded-[10px] flex flex-col items-center">
              <AiFillInfoCircle className="fill-platnova-purple stroke-white w-8 h-8 xl:w-12 xl:h-12" />

              <p className="text-base xl:text-xl font-medium mt-5 text-center">
                You will be notified once we approve your request
              </p>
              <p className="mt-5 text-sm xl:text-lg text-center text-grey-text">
                We usually respond within 48 hours but due to high volume of requests, we may take
                longer to respond to your request.
              </p>

              <Button
                variant="purple"
                className="w-full mt-10"
                onClick={() => mutate()}
                isLoading={isPending}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
    </SubRouteLayout>
  ) : (
    <p>NULL</p>
  );
};

export default FinalizeDeposit;
