import { cn } from 'lib/utils';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  containerClassName?: string;
  title: string;
  subtitle: string;
};

const AuthCardLayout = ({ children, containerClassName, title, subtitle }: Props) => {
  return (
    <div
      className={cn(
        'shadow-sm bg-white px-2 py-5 xl:p-10 rounded-[10px] xl:w-[34.25rem] h-full xl:h-max mb-6',
        containerClassName
      )}
    >
      <h1 className="text-2xl md:text-3xl font-medium">{title}</h1>
      <p className="text-sm md:text-base text-grey-text mt-2">{subtitle}</p>
      {children}
    </div>
  );
};

export default AuthCardLayout;
