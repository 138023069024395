import { useFormik } from 'formik';
import {
  REGISTER_EMAIL_KEY,
  REGISTER_PASSWORD_CONFIRM_KEY,
  REGISTER_PASSWORD_KEY,
} from 'lib/constants/auth/register';
import { useNavigate } from 'react-router-dom';
import { ACCOUNT_SETUP_ROUTE } from 'routes/path';
import { useSetPassword } from 'services/auth/register';
import { useAppSelector } from 'store';
import { REGISTER_SLICE_REDUCER_PATH } from 'store/slices/register/constants';
import { object, ref, string } from 'yup';

export default function useSetPasswordForm() {
  const { mutate, error, isError, isPending } = useSetPassword();
  const navigate = useNavigate();
  const { [REGISTER_EMAIL_KEY]: email } = useAppSelector(
    (state) => state[REGISTER_SLICE_REDUCER_PATH]
  );

  const formik = useFormik({
    initialValues: {
      [REGISTER_EMAIL_KEY]: email,
      [REGISTER_PASSWORD_KEY]: '',
      [REGISTER_PASSWORD_CONFIRM_KEY]: '',
    },
    onSubmit: (values) => {
      mutate(values, {
        onSuccess: () => navigate(ACCOUNT_SETUP_ROUTE),
      });
    },
    validationSchema: object().shape({
      [REGISTER_PASSWORD_KEY]: string().required('Password cannot be empty'),
      [REGISTER_PASSWORD_CONFIRM_KEY]: string()
        .required('Confirm Password cannot be empty')
        .oneOf([ref(REGISTER_PASSWORD_KEY)], 'Passwords do not match'),
    }),
  });

  function getInputProps(id: keyof typeof formik.values) {
    return {
      ...formik.getFieldProps(id),
      ...formik.getFieldMeta(id),
      ...formik.errors,
    };
  }

  return Object.freeze({
    onSubmit: formik.handleSubmit,
    getInputProps,
    setValue: formik.setFieldValue,
    isValid: formik.isValid,
    errors: formik.errors,
    setPasswordError: error,
    isError: isError,
    isPending: isPending,
  });
}
