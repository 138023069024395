import WhiteLogo from 'assets/logo/white-logo.png';
import Button from 'components/Buttons/Button';
import { Link, useNavigate } from 'react-router-dom';
import { LOGIN_ROUTE, REGISTER_ROUTE } from 'routes/path';

const Base = () => {
  const navigate = useNavigate();
  return (
    <div className="radial-gradient min-h-screen relative text-white flex justify-center items-center px-5">
      <div className="">
        <div className="flex flex-col items-center">
          <img src={WhiteLogo} alt="Platnova" className="h-6 xl:h-14" />

          <p className="mt-20 xl:mt-52 text-center text-2xl xl:text-5xl font-bold">
            Welcome to Platnova
          </p>
          <p className="font-normal mt-3 xl:mt-6 text-center text-xs xl:text-xl">
            Seamlessly send and receive money across the globe
          </p>
          <Button
            className="text-xs xl:text-xl mt-7 xl:mt-14 w-full md:w-10/12"
            onClick={() => navigate(REGISTER_ROUTE)}
          >
            Create a free account
          </Button>
          <Link to={LOGIN_ROUTE}>
            <p className="mt-4 xl:mt-7 text-grey font-medium text-xs xl:text-xl">
              Already have an account? <span className="text-white font-bold">Sign in</span>
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Base;
