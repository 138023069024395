import Flag from 'components/flag';
import Modal, { ModalProps } from 'components/modal';
import { allCountries } from 'lib/countries/countries';
import { cn } from 'lib/utils';
import { X } from 'lucide-react';
import { useGetConfigurations } from 'services/configurations';
import { useAppDispatch, useAppSelector } from 'store';
import { RECIPIENT_CURRENCY_KEY, WALLET_SLICE_REDUCER_PATH } from 'store/slices/wallet/constants';
import { setRecipientCountry, setRecipientCurrency } from 'store/slices/wallet/walletSlice';
import { Configuration, RecipientCurrenciesValueType } from 'types/configuration';

const RecipientInputModal = ({ ...props }: ModalProps) => {
  const { data: configurations } = useGetConfigurations();

  const { [RECIPIENT_CURRENCY_KEY]: recipientCurrencyCode } = useAppSelector(
    (state) => state[WALLET_SLICE_REDUCER_PATH]
  );

  const recipientCurrencies = configurations?.data.find(
    (x): x is Configuration<RecipientCurrenciesValueType> => x.name === 'recipient_currencies'
  )?.value;

  const country = (countryName: string) => {
    return allCountries.find((x) => x.cca2 === countryName)?.name.common;
  };
  const dispatch = useAppDispatch();

  const handleClick = (id: string, country: string) => {
    dispatch(setRecipientCurrency(id));
    dispatch(setRecipientCountry(country));
    props.close();
  };
  return (
    <Modal {...props} className="py-7 px-5 xl:w-full xl:max-w-xl">
      <div className="flex justify-between">
        <p className="text-lg xl:text-2xl font-medium">Select Recepient Currency</p>
        <X onClick={props.close} className="cursor-pointer" />
      </div>

      <div className="mt-6 xl:mt-12 space-y-2 xl:space-y-5">
        {recipientCurrencies?.map((wallet) => (
          <button
            onClick={() => handleClick(wallet.type, wallet.country.code)}
            key={wallet.country.code}
            className={cn(
              'w-full flex items-center justify-between rounded-[10px] px-[5%] py-2.5 hover:bg-platnova-secondary',
              wallet.country.code === recipientCurrencyCode && 'bg-platnova-secondary'
            )}
          >
            <div className="flex items-center space-x-5 ">
              <Flag code={wallet.country.code} />
              <div className="flex flex-col items-start">
                <p className="text-xs xl:text-base font-medium">{country(wallet.country.code)}</p>
                <p className="text-[0.625rem] xl:text-xs text-grey-silent-text">
                  {wallet.description}
                </p>
              </div>
            </div>
          </button>
        ))}
      </div>
    </Modal>
  );
};

export default RecipientInputModal;
