import Button from 'components/Buttons/Button';
import { ChevronLeft } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { VERIFICATION_ROUTE } from 'routes/path';
import { useAppSelector } from 'store';
import { VERIFICATION_SELECTED_DOC } from 'store/slices/verification/constants';
import AuthCardLayout from 'views/auth/authCardLayout';

import IdVerificationForm from './components/idVerificationForm';
import AuthLayout from '../auth/layout';

const IdVerification = () => {
  const { [VERIFICATION_SELECTED_DOC]: selectedDoc } = useAppSelector(
    (state) => state.verification
  );
  const navigate = useNavigate();
  return (
    <div>
      {selectedDoc.id && (
        <AuthLayout>
          <div className="w-full flex">
            <Link
              to={VERIFICATION_ROUTE}
              className="mr-[5%] text-xs xl:text-base text-black/70 flex items-center space-x-1"
            >
              <ChevronLeft size={16} />
              <p>Back</p>
            </Link>
          </div>
          <AuthCardLayout title={selectedDoc.label} subtitle="Please enter the fields below">
            <IdVerificationForm />
          </AuthCardLayout>
        </AuthLayout>
      )}

      {!selectedDoc.id && (
        <AuthLayout containerClassName="flex flex-col items-center space-y-3 xl:space-y-6">
          <p className="text-xl">Oops, Something went wrong...</p>
          <Button variant="purple" onClick={() => navigate(VERIFICATION_ROUTE)}>
            Back to verification
          </Button>
        </AuthLayout>
      )}
    </div>
  );
};

export default IdVerification;
