import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  FORGOT_PASSWORD_EMAIL_KEY,
  FORGOT_PASSWORD_OTP_KEY,
} from 'lib/constants/auth/forgotPassword';

import {
  FORGOT_PASSWORD_SLICE_REDUCER_PATH,
  forgotPasswordSliceReducerInitialState,
} from './constants';

const forgotPasswordSlice = createSlice({
  name: FORGOT_PASSWORD_SLICE_REDUCER_PATH,
  initialState: forgotPasswordSliceReducerInitialState,
  reducers: {
    resetForgotPasswordSlice: () => ({
      ...forgotPasswordSliceReducerInitialState,
    }),

    setForgotPasswordEmail: (state, { payload }: PayloadAction<string>) => {
      state[FORGOT_PASSWORD_EMAIL_KEY] = payload;
    },

    setForgotPasswordOtp: (state, { payload }: PayloadAction<string>) => {
      state[FORGOT_PASSWORD_OTP_KEY] = payload;
    },
  },
});

export const forgotPasswordReducer = forgotPasswordSlice.reducer;

export const { resetForgotPasswordSlice, setForgotPasswordEmail, setForgotPasswordOtp } =
  forgotPasswordSlice.actions;
