import { AnimatePresence, motion } from 'framer-motion';
import OTPInput from 'react-otp-input';

import InputLabel from './inputLabel';
import { InputPinProps } from './types';

const InputPin = ({
  id,
  error,
  touched,
  eagerError,
  label,
  labelClassName,
  helperText,
  value,
  setValue,
  setTouched,
  type = 'password',
  numInputs = 4,
}: InputPinProps) => {
  function handleChange(enteredPin: string) {
    setValue && setValue(enteredPin, true);
    setTouched && setTouched(true, false);
  }

  return (
    <div className="w-full">
      <div className="flex flex-col gap-2">
        <div className="flex flex-col items-start justify-center gap-1">
          {label && !!label.length && (
            <InputLabel className={labelClassName} id={id} label={label} />
          )}
          {helperText && !!helperText.length && (
            <label htmlFor={id} className="text-secondary-grey text-xs font-medium">
              {helperText}
            </label>
          )}
        </div>

        <div className="mx-auto w-full">
          <OTPInput
            value={value}
            onChange={handleChange}
            renderInput={(props) => (
              <input
                {...props}
                className="focus:ring-2 focus:border-2 focus:outline-none focus:ring-platnova-purple focus:border-platnova-purple border-transparent w-1/4 rounded-[10px] border py-2 text-center text-lg"
                inputMode="numeric"
                type={type}
                onFocus={() => {
                  setTouched && setTouched(true, false);
                }}
                onBlur={() => {
                  setTouched && setTouched(true, true);
                }}
                style={{
                  width: '40px',
                  height: '40px',
                  border: '1px solid #D1D1D2',
                }}
              />
            )}
            numInputs={numInputs}
            containerStyle={{
              width: '100%',
              display: 'grid',
              gridTemplateColumns: `repeat(${numInputs}, minmax(0, 1fr))`,
              gap: '3rem',
            }}
            shouldAutoFocus={true}
            placeholder={Array.from({ length: numInputs }, (_, index) => index)
              .map(() => '*')
              .join('')}
          />
        </div>
      </div>

      <AnimatePresence>
        <div className="h-5 mt-1 xl:mt-3">
          {(eagerError || touched) && error && (
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 100 }}
              transition={{ ease: 'easeOut', duration: 0.5 }}
              className="pl-1 text-xs md:text-sm font-semibold text-error-primary"
            >
              {error}
            </motion.div>
          )}
        </div>
      </AnimatePresence>
    </div>
  );
};

export default InputPin;
