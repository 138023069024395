import { AnimatePresence, motion } from 'framer-motion';
import { cn } from 'lib/utils';
import PhoneInput from 'react-phone-input-2';

import 'react-phone-input-2/lib/style.css';

import InputLabel from './inputLabel';

type InputPhoneProps = {
  country?: string;
  onChange: (
    value: string,
    data: {
      countryCode: string;
      dialCode: string;
      format: string;
      name: string;
    }
  ) => void;
  label?: string;
  labelClassName?: string;
  id: string;
  helperText?: string;
  eagerError?: boolean;
  touched?: boolean;
  error?: string;
  value?: string;
};
const InputPhone = ({
  country,
  onChange,
  label,
  labelClassName,
  id,
  helperText,
  error,
  value,
}: InputPhoneProps) => {
  return (
    <div className="w-full">
      <div className="flex flex-col gap-2">
        <div className="flex flex-col items-start justify-center gap-1">
          {label && !!label.length && (
            <InputLabel
              className={cn('text-xs md:text-sm font-normal', labelClassName)}
              id={id}
              label={label}
            />
          )}
          {helperText && !!helperText.length && (
            <label htmlFor={id} className="text-secondary-grey text-xs font-medium">
              {helperText}
            </label>
          )}
        </div>
        <div className="mx-auto w-full">
          <PhoneInput
            country={country || 'ng'}
            onChange={onChange}
            value={value}
            containerClass={cn(
              'border rounded-[10px] focus-within:border-2 focus-within:outline-none focus-within:border-platnova-purple',
              [error && 'border-error-primary']
            )}
            countryCodeEditable={false}
            inputStyle={{
              width: '100%',
              height: '40px',
              border: 'none',
              backgroundColor: '#FAFAFA',
              borderRadius: '10px',
              fontSize: '14px',
            }}
            buttonStyle={{
              border: 'none',
              backgroundColor: '#FAFAFA',
              paddingLeft: '10px',
              borderRadius: '10px',
            }}
            containerStyle={{
              width: '100%',
            }}
          />
        </div>
      </div>
      <AnimatePresence>
        <div className="h-5">
          {error && (
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 100 }}
              transition={{ ease: 'easeOut', duration: 0.5 }}
              className="pl-1 text-xs font-semibold text-error-primary"
            >
              {error}
            </motion.div>
          )}
        </div>
      </AnimatePresence>
    </div>
  );
};

export default InputPhone;
