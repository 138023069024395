import { useFormik } from 'formik';
import { REGISTER_EMAIL_KEY, REGISTER_OTP_KEY } from 'lib/constants/auth/register';
import { useNavigate } from 'react-router-dom';
import { SETUP_PASSWORD_ROUTE } from 'routes/path';
import { useRegisterAccountVerification } from 'services/auth/register';
import { useAppSelector } from 'store';
import { REGISTER_SLICE_REDUCER_PATH } from 'store/slices/register/constants';
import { object, string } from 'yup';

export default function useAccountVerificationForm() {
  const { mutate, error, isError, isPending } = useRegisterAccountVerification();
  const navigate = useNavigate();
  const { [REGISTER_EMAIL_KEY]: email } = useAppSelector(
    (state) => state[REGISTER_SLICE_REDUCER_PATH]
  );

  const formik = useFormik({
    initialValues: {
      [REGISTER_EMAIL_KEY]: email,
      [REGISTER_OTP_KEY]: '',
    },
    onSubmit: (values) => {
      mutate(values, {
        onSuccess: () => navigate(SETUP_PASSWORD_ROUTE),
      });
    },
    validationSchema: object().shape({
      [REGISTER_OTP_KEY]: string()
        .min(4, 'Invalid token')
        .max(4, 'Invalid token')
        .required('token cannot be empty'),
    }),
  });

  function getInputProps(id: keyof typeof formik.values) {
    return {
      ...formik.getFieldHelpers(id),
      ...formik.getFieldMeta(id),
      ...formik.errors,
    };
  }

  return Object.freeze({
    onSubmit: formik.handleSubmit,
    getInputProps,
    setValue: formik.setFieldValue,
    isValid: formik.isValid,
    errors: formik.errors,
    registerVerificationError: error,
    isError: isError,
    isPending: isPending,
    values: formik.values,
  });
}
