import Cookies from 'js-cookie';
import { AuthUser, NovaUserVerification } from 'types/auth';

class CookieService {
  cookie = Cookies;

  set(key: string, value: AuthUser) {
    // const expiryDate = new Date(new Date().getTime() + 3600000);
    const parsedValue = JSON.stringify(value);
    this.cookie.set(key, parsedValue);
    return true;
  }

  setWithExpiry(key: string, value: string | NovaUserVerification, time: number) {
    const expiryDate = new Date(new Date().getTime() + time * 1000);
    const parsedValue = JSON.stringify(value);
    this.cookie.set(key, parsedValue, { expires: expiryDate });
    return true;
  }

  get(key: string) {
    const value = this.cookie.get(key) as string;
    try {
      return JSON.parse(value);
    } catch (e) {
      return null;
    }
  }

  delete(name: string) {
    this.cookie.remove(name);
  }

  deleteAll() {
    const cookies = this.cookie.get();
    for (const name in cookies) {
      this.delete(name);
    }
  }
}

export default new CookieService();
