import Button from 'components/Buttons/Button';
import useErrorNotification from 'components/error/useErrorNotification';
import Input from 'components/Inputs/input';
import InputPhone from 'components/Inputs/inputPhone';
import Select from 'components/Inputs/select';
import {
  VERIFY_ACCOUNT_CITY_KEY,
  VERIFY_ACCOUNT_COUNTRY_KEY,
  VERIFY_ACCOUNT_DIAL_CODE_KEY,
  VERIFY_ACCOUNT_PHONE_KEY,
  VERIFY_ACCOUNT_POSTAL_CODE_KEY,
  VERIFY_ACCOUNT_STATE_KEY,
  VERIFY_ACCOUNT_STREET_KEY,
} from 'lib/constants/kyc/accountVerification';
import { allCountries } from 'lib/countries/countries';
import { useState } from 'react';
import { useGetAccount } from 'services/account';
import { NOVA_USER_VERIFICATION_KEY } from 'services/constants';
import cookie from 'services/cookie';

import useVerifyAccountForm from '../hooks/useVerifyAccountForm';

const VerifyAccountForm = () => {
  const formatCountries = allCountries.map((country) => ({
    value: country.name.common,
    img: country.flags.svg,
    label: country.name.common,
  }));

  const {
    getInputProps,
    verifyAccountError,
    errors,
    isPending,
    isValid,
    onSubmit,
    setValue,
    isError,
    formValues,
  } = useVerifyAccountForm();
  const { data: account } = useGetAccount();

  const verificationCache = cookie.get(NOVA_USER_VERIFICATION_KEY);
  const [phone, setPhone] = useState(
    verificationCache && verificationCache[VERIFY_ACCOUNT_PHONE_KEY]
      ? `+${verificationCache[VERIFY_ACCOUNT_DIAL_CODE_KEY]}${verificationCache[VERIFY_ACCOUNT_PHONE_KEY]}`
      : formValues[VERIFY_ACCOUNT_DIAL_CODE_KEY].length && formValues[VERIFY_ACCOUNT_PHONE_KEY]
      ? `+${formValues[VERIFY_ACCOUNT_DIAL_CODE_KEY]}${formValues[VERIFY_ACCOUNT_PHONE_KEY]}`
      : account && account.data.dial_code && account.data.phone
      ? `+${account.data.dial_code}${account.data.phone}`
      : ''
  );
  const handleOnChange = (
    value: string,
    data: { countryCode: string; dialCode: string; format: string; name: string }
  ) => {
    const dialCodeLength = data.dialCode.length;
    setPhone(value);
    value = value.slice(dialCodeLength);
    setValue(VERIFY_ACCOUNT_PHONE_KEY, value);
    setValue(VERIFY_ACCOUNT_DIAL_CODE_KEY, data.dialCode);
  };

  useErrorNotification(isError, verifyAccountError);

  return (
    <form className="py-5 xl:py-10 space-y-3" onSubmit={onSubmit}>
      <InputPhone
        id={VERIFY_ACCOUNT_PHONE_KEY}
        label="Phone Number"
        {...getInputProps(VERIFY_ACCOUNT_PHONE_KEY)}
        onChange={handleOnChange}
        value={phone as string}
      />

      <Input
        id={VERIFY_ACCOUNT_STREET_KEY}
        label="Street"
        inputMode="text"
        type="text"
        {...getInputProps(VERIFY_ACCOUNT_STREET_KEY)}
        error={errors[VERIFY_ACCOUNT_STREET_KEY]}
        placeholder="Enter Street"
      />
      <div className="flex justify-between space-x-3 xl:space-x-10">
        <Input
          id={VERIFY_ACCOUNT_CITY_KEY}
          label="City"
          inputMode="text"
          type="text"
          className=""
          {...getInputProps(VERIFY_ACCOUNT_CITY_KEY)}
          error={errors[VERIFY_ACCOUNT_CITY_KEY]}
          placeholder="Enter city"
        />
        <Input
          id={VERIFY_ACCOUNT_POSTAL_CODE_KEY}
          label="Postal Code"
          inputMode="text"
          type="text"
          className=""
          {...getInputProps(VERIFY_ACCOUNT_POSTAL_CODE_KEY)}
          error={errors[VERIFY_ACCOUNT_POSTAL_CODE_KEY]}
          placeholder="Enter postal code"
        />
      </div>
      <Input
        id={VERIFY_ACCOUNT_STATE_KEY}
        label="State"
        inputMode="text"
        type="text"
        {...getInputProps(VERIFY_ACCOUNT_STATE_KEY)}
        error={errors[VERIFY_ACCOUNT_STATE_KEY]}
        placeholder="Enter State"
      />
      <Select
        id={VERIFY_ACCOUNT_COUNTRY_KEY}
        label="Country of residence"
        variant="tertiary"
        tertiaryOptions={formatCountries}
        {...getInputProps(VERIFY_ACCOUNT_COUNTRY_KEY)}
        error={errors[VERIFY_ACCOUNT_COUNTRY_KEY]}
      />
      {/* <p className="font-medium text-platnova-purple">Select on map</p> */}

      <div className="pt-5 xl:pt-10">
        <p className="text-xs xl:text-sm text-grey-text">
          By clicking continue, you agree to our processing your address information
        </p>
        <Button
          className="w-full mt-5"
          variant="purple"
          type="submit"
          disabled={isPending || !isValid}
          isLoading={isPending}
        >
          Continue
        </Button>
      </div>
    </form>
  );
};

export default VerifyAccountForm;
