import axios from 'axios';
import Button from 'components/Buttons/Button';
import useErrorNotification from 'components/error/useErrorNotification';
import Select from 'components/Inputs/select';
import {
  SUMSUB_PROVIDER_KEY,
  VERIFY_ACCOUNT_IP_ADDRESS_KEY,
  VERIFY_ACCOUNT_ISSUER_COUNTRY_KEY,
  VERIFY_ACCOUNT_PROVIDER_KEY,
} from 'lib/constants/kyc/accountVerification';
import { allCountries } from 'lib/countries/countries';
import { useCountrySupportedDocs } from 'lib/countrySupportedDocs';
import { CircleCheck } from 'lucide-react';
import { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useGetSupportedDocs } from 'services/kyc';
import { useAppDispatch, useAppSelector } from 'store';
import { SUMSUB_DOC, VERIFICATION_SELECTED_DOC } from 'store/slices/verification/constants';
import {
  setSumSubValues,
  setVerificationProvider,
  setVerificationSelectedDoc,
} from 'store/slices/verification/verificationSlice';

import useChooseDocumentForm from '../hooks/useChooseDocumentForm';

const ChooseDocumentForm = () => {
  const formatCountries = allCountries.map((country) => ({
    value: country.cca2,
    img: country.flags.svg,
    label: country.name.common,
  }));

  const { isPending: isLoadingSupportedDocs } = useGetSupportedDocs();

  const {
    formValues,
    getInputProps,
    errors,
    setValue,
    onSubmit,
    isAccountVerifyError,
    isAccountVerifyPending,
    accountVerifyError,
  } = useChooseDocumentForm();

  useErrorNotification(isAccountVerifyError, accountVerifyError);

  useEffect(() => {
    axios
      .get(`${import.meta.env.VITE_IP_URL}`, {
        params: {
          format: JSON,
        },
      })
      .then((res) => {
        setValue(VERIFY_ACCOUNT_IP_ADDRESS_KEY, res.data.ip);
      });
  }, [setValue]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setVerificationProvider(''));
    dispatch(
      setVerificationSelectedDoc({
        fields: undefined,
        id: '',
        is_biometric_enabled: false,
        label: '',
        provider: '',
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues[VERIFY_ACCOUNT_ISSUER_COUNTRY_KEY]]);

  const { [VERIFICATION_SELECTED_DOC]: selected, [SUMSUB_DOC]: selectedSumsub } = useAppSelector(
    (state) => state.verification
  );

  const supportedDocs = useCountrySupportedDocs(
    formValues[VERIFY_ACCOUNT_ISSUER_COUNTRY_KEY].toUpperCase()
  );

  const defaultDocumentTypes = [
    {
      id: 'ID_CARD',
      label: 'ID card',
      provider: 'sumsub',
      is_biometric_enabled: false,
      fields: [],
    },
    {
      id: 'PASSPORT',
      label: 'Passport',
      provider: 'sumsub',
      is_biometric_enabled: false,
      fields: [],
    },
    {
      id: 'RESIDENCE_PERMIT',
      label: 'Residence permit',
      provider: 'sumsub',
      is_biometric_enabled: false,
      fields: [],
    },
    {
      id: 'DRIVERS',
      label: 'Driver license',
      provider: 'sumsub',
      is_biometric_enabled: false,
      fields: [],
    },
  ];

  // const cookieValue: AuthUser = cookie.get(USER_TOKEN_COOKIE_KEY)

  return (
    <form className="py-5 xl:py-10 space-y-3" onSubmit={onSubmit}>
      <Select
        id={VERIFY_ACCOUNT_ISSUER_COUNTRY_KEY}
        label="Select country where your ID document was issued"
        variant="tertiary"
        tertiaryOptions={formatCountries}
        {...getInputProps(VERIFY_ACCOUNT_ISSUER_COUNTRY_KEY)}
        error={errors[VERIFY_ACCOUNT_ISSUER_COUNTRY_KEY]}
      />

      {/* Select */}
      <div>
        <p className="mb-2">Click any below to select document type</p>
        {isLoadingSupportedDocs ? (
          <Skeleton count={4} className="p-6 my-4" />
        ) : supportedDocs && supportedDocs.id_types.length > 0 ? (
          <div className="grid gap-4">
            {supportedDocs.id_types.map((type) => (
              <button
                type="button"
                key={type.id}
                onClick={() => {
                  setValue(VERIFY_ACCOUNT_PROVIDER_KEY, type.provider);
                  dispatch(setVerificationProvider(type.provider));
                  dispatch(setVerificationSelectedDoc(type));
                }}
                className={`bg-primary-grey text-xs xl:text-base text-left px-4 py-5 rounded-[10px] flex justify-between ${
                  selected === type && 'border border-platnova-purple'
                }`}
              >
                <span>{type.label}</span>
                {selected === type && (
                  <CircleCheck className="w-4 h-4 xl:w-6 xl:h-6 stroke-platnova-purple" />
                )}
              </button>
            ))}
          </div>
        ) : (
          <div className="grid gap-4">
            {defaultDocumentTypes.map((type) => (
              <button
                type="button"
                key={type.id}
                onClick={() => {
                  setValue(VERIFY_ACCOUNT_PROVIDER_KEY, SUMSUB_PROVIDER_KEY);
                  dispatch(setSumSubValues(type));
                  dispatch(setVerificationSelectedDoc(type));
                  dispatch(setVerificationProvider(SUMSUB_PROVIDER_KEY));
                }}
                className={`bg-primary-grey text-left px-4 py-5 rounded-[10px] flex justify-between ${
                  selectedSumsub.id === type.id && 'border border-platnova-purple'
                }`}
              >
                <span>{type.label}</span>
                {selectedSumsub.id === type.id && (
                  <CircleCheck className="w-4 h-4 xl:w-6 xl:h-6 stroke-platnova-purple" />
                )}
              </button>
            ))}
          </div>
        )}
      </div>

      <div>
        <p className="text-xs xl:text-sm text-grey-text">
          On clicking continue, you will be prompted to capture a selfie of yourself and to provide
          your govt. issued ID for yor account verification
        </p>
        <Button
          disabled={isAccountVerifyPending}
          isLoading={isAccountVerifyPending}
          className="w-full mt-5"
          variant="purple"
          type="submit"
        >
          Continue
        </Button>
      </div>
    </form>
  );
};

export default ChooseDocumentForm;
