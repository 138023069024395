import { cn } from 'lib/utils';
import { Download } from 'lucide-react';
import * as React from 'react';

interface ImageInputProps {
  onImageUpload?: (file: File) => void;
}

const ImageInput: React.FC<ImageInputProps> = ({ onImageUpload }) => {
  const [imagePreview, setImagePreview] = React.useState<string | null>(null);
  const [isDragging, setIsDragging] = React.useState(false);
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
        if (onImageUpload) {
          onImageUpload(file);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);

    const file = event.dataTransfer.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
        if (onImageUpload) {
          onImageUpload(file);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
  };

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <button
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      type="button"
      onClick={handleClick}
      className={`border-4 border-dashed rounded-[10px] py-6 w-full flex flex-col items-center ${
        isDragging ? 'border-platnova-purple' : ''
      }`}
    >
      <input
        type="file"
        ref={fileInputRef}
        className={cn('w-full')}
        style={{ display: 'none' }}
        accept="image/*"
        onChange={handleImageChange}
        required
      />
      {imagePreview ? (
        <div>
          <img
            src={imagePreview}
            alt="Preview"
            style={{ width: '100px', height: '100px', objectFit: 'contain' }}
          />
        </div>
      ) : (
        <div className="flex flex-col space-y-6 items-center">
          <Download className="w-8 h-8 xl:w-16 xl:h-16 text-grey-silent-text" />
          <p className="text-base xl:text-xl text-grey-text">
            <span className="font-semibold">Choose a file</span> or drag it here
          </p>
        </div>
      )}
    </button>
  );
};

export default ImageInput;
