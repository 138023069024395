import { notifySuccess } from 'components/error/notifySuccess';
import { useFormik } from 'formik';
import { useOrderPhysicalCard } from 'services/cards';
import {
  ORDER_PHYSICAL_CARDS_ADDRESS_KEY,
  ORDER_PHYSICAL_CARDS_BILLING_KEY,
  ORDER_PHYSICAL_CARDS_BRAND_KEY,
  ORDER_PHYSICAL_CARDS_CITY_KEY,
  ORDER_PHYSICAL_CARDS_COLORS_KEY,
  ORDER_PHYSICAL_CARDS_COUNTRY_KEY,
  ORDER_PHYSICAL_CARDS_CURRENCY_KEY,
  ORDER_PHYSICAL_CARDS_DEBIT_CURRENCY_KEY,
  ORDER_PHYSICAL_CARDS_DELIVERY_NOTE_KEY,
  ORDER_PHYSICAL_CARDS_PROVIDER_KEY,
  ORDER_PHYSICAL_CARDS_QUANTITY_KEY,
  ORDER_PHYSICAL_CARDS_SHIPPING_COST_KEY,
  ORDER_PHYSICAL_CARDS_STATE_KEY,
  ORDER_PHYSICAL_CARDS_UNIT_PRICE_KEY,
} from 'services/cards/constants';
import { useGetWallets } from 'services/wallet';
import { useAppDispatch, useAppSelector } from 'store';
import { setCreatePhysicalCardStage } from 'store/slices/cards/cardsSlice';
import {
  ACTIVE_CARD_BRAND,
  CARD_SLICE_REDUCER_PATH,
  PHYSICAL_CARD_COLOR_KEY,
  PHYSICAL_CARD_NUMBER,
} from 'store/slices/cards/constants';
import { WALLET_ID_KEY, WALLET_SLICE_REDUCER_PATH } from 'store/slices/wallet/constants';
import { array, number, object, string } from 'yup';

export const useOrderPhysicalCardForm = () => {
  const { data: wallets } = useGetWallets();
  const {
    mutate: orderPhysicalCard,
    isPending: isLoadingOrderPhysicalCard,
    error: orderPhysicalCardError,
    isError: isOrderPhysicalCardError,
  } = useOrderPhysicalCard();
  const { [WALLET_ID_KEY]: walletId } = useAppSelector((state) => state[WALLET_SLICE_REDUCER_PATH]);

  const dispatch = useAppDispatch();

  const activeWallet =
    walletId.length > 0 ? wallets?.data.find((x) => x.id === walletId) : wallets?.data[0];

  const {
    [ACTIVE_CARD_BRAND]: selectedCard,
    [PHYSICAL_CARD_COLOR_KEY]: physicalCardColor,
    [PHYSICAL_CARD_NUMBER]: physicalCardNumber,
  } = useAppSelector((state) => state[CARD_SLICE_REDUCER_PATH]);

  const initialValues = {
    [ORDER_PHYSICAL_CARDS_DEBIT_CURRENCY_KEY]: '',
    [ORDER_PHYSICAL_CARDS_COLORS_KEY]: physicalCardColor.colors,
    [ORDER_PHYSICAL_CARDS_QUANTITY_KEY]: physicalCardNumber,
    [ORDER_PHYSICAL_CARDS_UNIT_PRICE_KEY]:
      selectedCard && selectedCard.price ? selectedCard.price * physicalCardNumber : 0, //
    [ORDER_PHYSICAL_CARDS_BRAND_KEY]: selectedCard && selectedCard.brand ? selectedCard.brand : '',
    [ORDER_PHYSICAL_CARDS_PROVIDER_KEY]:
      selectedCard && selectedCard.provider ? selectedCard.provider : '',
    [ORDER_PHYSICAL_CARDS_CURRENCY_KEY]: 'ngn',
    [ORDER_PHYSICAL_CARDS_SHIPPING_COST_KEY]: 0, //
    [ORDER_PHYSICAL_CARDS_BILLING_KEY]: {}, //
    [ORDER_PHYSICAL_CARDS_STATE_KEY]: '', //
    [ORDER_PHYSICAL_CARDS_CITY_KEY]: '', //
    [ORDER_PHYSICAL_CARDS_COUNTRY_KEY]: 'NG',
    [ORDER_PHYSICAL_CARDS_ADDRESS_KEY]: '', //
    [ORDER_PHYSICAL_CARDS_DELIVERY_NOTE_KEY]: '', //
  };

  const validationSchema = object().shape({
    [ORDER_PHYSICAL_CARDS_DEBIT_CURRENCY_KEY]: string(),
    [ORDER_PHYSICAL_CARDS_COLORS_KEY]: array().required('Select a card color'),
    [ORDER_PHYSICAL_CARDS_QUANTITY_KEY]: number()
      .min(1, 'Minimum of one card')
      .required('Card quantity is required'),
    [ORDER_PHYSICAL_CARDS_UNIT_PRICE_KEY]: number().required(),
    [ORDER_PHYSICAL_CARDS_BRAND_KEY]: string().required(),
    [ORDER_PHYSICAL_CARDS_PROVIDER_KEY]: string().required(),
    [ORDER_PHYSICAL_CARDS_CURRENCY_KEY]: string().required(),
    [ORDER_PHYSICAL_CARDS_SHIPPING_COST_KEY]: number().required(),
    [ORDER_PHYSICAL_CARDS_BILLING_KEY]: object(),
    [ORDER_PHYSICAL_CARDS_STATE_KEY]: string().required(),
    [ORDER_PHYSICAL_CARDS_CITY_KEY]: string().required(),
    [ORDER_PHYSICAL_CARDS_COUNTRY_KEY]: string().required(),
    [ORDER_PHYSICAL_CARDS_ADDRESS_KEY]: string().required(),
    [ORDER_PHYSICAL_CARDS_DELIVERY_NOTE_KEY]: string(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const newValues = {
        [ORDER_PHYSICAL_CARDS_DELIVERY_NOTE_KEY]: values[ORDER_PHYSICAL_CARDS_DELIVERY_NOTE_KEY],
        [ORDER_PHYSICAL_CARDS_DEBIT_CURRENCY_KEY]: values[ORDER_PHYSICAL_CARDS_DEBIT_CURRENCY_KEY]
          ? values[ORDER_PHYSICAL_CARDS_DEBIT_CURRENCY_KEY]
          : activeWallet
          ? activeWallet.type
          : wallets
          ? wallets.data[0].type
          : '',
        [ORDER_PHYSICAL_CARDS_COLORS_KEY]: values[ORDER_PHYSICAL_CARDS_COLORS_KEY],
        [ORDER_PHYSICAL_CARDS_QUANTITY_KEY]: values[ORDER_PHYSICAL_CARDS_QUANTITY_KEY],
        [ORDER_PHYSICAL_CARDS_UNIT_PRICE_KEY]: values[ORDER_PHYSICAL_CARDS_UNIT_PRICE_KEY],
        [ORDER_PHYSICAL_CARDS_BRAND_KEY]: values[ORDER_PHYSICAL_CARDS_BRAND_KEY],
        [ORDER_PHYSICAL_CARDS_PROVIDER_KEY]: values[ORDER_PHYSICAL_CARDS_PROVIDER_KEY],
        [ORDER_PHYSICAL_CARDS_CURRENCY_KEY]: values[ORDER_PHYSICAL_CARDS_CURRENCY_KEY],
        [ORDER_PHYSICAL_CARDS_SHIPPING_COST_KEY]: values[ORDER_PHYSICAL_CARDS_SHIPPING_COST_KEY],
        [ORDER_PHYSICAL_CARDS_BILLING_KEY]: {
          [ORDER_PHYSICAL_CARDS_COUNTRY_KEY]: values[ORDER_PHYSICAL_CARDS_COUNTRY_KEY],
          [ORDER_PHYSICAL_CARDS_STATE_KEY]: values[ORDER_PHYSICAL_CARDS_STATE_KEY],
          [ORDER_PHYSICAL_CARDS_CITY_KEY]: values[ORDER_PHYSICAL_CARDS_CITY_KEY],
          [ORDER_PHYSICAL_CARDS_ADDRESS_KEY]: values[ORDER_PHYSICAL_CARDS_ADDRESS_KEY],
        },
      };
      orderPhysicalCard(newValues, {
        onSuccess: () => {
          notifySuccess('Card ordered successfully');
          formik.resetForm();
          dispatch(setCreatePhysicalCardStage({ stage: 'success' }));
        },
      });
    },
  });

  function getInputProps(id: keyof typeof formik.values) {
    return {
      ...formik.getFieldHelpers(id),
      ...formik.getFieldMeta(id),
      ...formik.getFieldProps(id),
      ...formik.errors,
    };
  }

  return Object.freeze({
    submit: formik.handleSubmit,
    getInputProps,
    values: formik.values,
    errors: formik.errors,
    isLoadingOrderPhysicalCard,
    isOrderPhysicalCardError,
    orderPhysicalCardError,
    setFieldValue: formik.setFieldValue,
  });
};
