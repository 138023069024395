import Button from 'components/Buttons/Button';
import { notifyError } from 'components/error/notifyError';
import { SELFIE_URL_PATH } from 'lib/constants/selfie';
import { BsInfoCircleFill } from 'react-icons/bs';
import { NOVA_USER_VERIFICATION_KEY } from 'services/constants';
import cookie from 'services/cookie';
import { SELFIE_SESSION_ID_KEY } from 'store/slices/verification/constants';
import AuthCardLayout from 'views/auth/authCardLayout';
import AuthLayout from 'views/auth/layout';

const Selfie = () => {
  const sessionId = cookie.get(NOVA_USER_VERIFICATION_KEY)[SELFIE_SESSION_ID_KEY];
  const handleClick = () => {
    if (sessionId) {
      window.open(SELFIE_URL_PATH(sessionId, import.meta.env.VITE_PLATNOVA_WEB_APP_URL), '_self');
    } else {
      notifyError('Error loading Selfie, please try again later');
    }
  };
  return (
    <AuthLayout>
      <AuthCardLayout title="Selfie Capture" subtitle="Position your face towards the camera">
        <div className="bg-blue-200 p-4 rounded-[10px] mt-4 flex items-center space-x-3">
          <BsInfoCircleFill className="w-6 h-6" fill="#172554" stroke="transparent" />
          <p className="text-sm">
            This check flashes different colors. Use caution if you are photosensitive.
          </p>
        </div>
        <img
          src="https://res.cloudinary.com/platnova/assets/generic/selfie-capture.png"
          alt="selfie"
          className="h-80 mx-auto"
        />
        <p className="text-center text-grey-text">
          Capture a selfie of yourself while ensuring that you're in a well lit environment.
        </p>
        <Button onClick={handleClick} variant="purple" className="w-full mt-10">
          Continue
        </Button>
      </AuthCardLayout>
    </AuthLayout>
  );
};

export default Selfie;
