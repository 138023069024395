import { useMutation, useQuery } from '@tanstack/react-query';
import { queryClient } from 'main';

import { getSupportedWallets, getWallets, postAddWallet } from './function';
import { GET_SUPPORTED_WALLETS_KEY, GET_WALLETS_KEY } from './keys';

export const useGetWallets = () => {
  const { data, isPending, isError } = useQuery({
    queryKey: [GET_WALLETS_KEY],
    queryFn: getWallets,
  });

  return {
    data,
    isPending,
    isError,
  };
};

export const useGetSupportedWallets = () => {
  const { data, isPending, isError } = useQuery({
    queryKey: [GET_SUPPORTED_WALLETS_KEY],
    queryFn: getSupportedWallets,
  });

  return {
    data,
    isPending,
    isError,
  };
};

export const useAddWallet = () => {
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: postAddWallet,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GET_WALLETS_KEY] });
    },
  });

  return { mutate, isPending, isError, error };
};
