import Logout from 'assets/icons/logout';
import { motion } from 'framer-motion';
import { cn } from 'lib/utils';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import {
  setComingSoonModalOpen,
  setMobileSidebarIsCollapsed,
} from 'store/slices/layout/layoutSlice';

type Props = {
  route: string;
  icon?: JSX.Element;
  text: string;
  comingSoon?: boolean;
};

const SidebarLink = ({ route, icon, text, comingSoon = false }: Props) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { sideBarIsCollapsed } = useAppSelector((state) => state.layout);

  return (
    <motion.div
      whileTap={{ scale: 0.9 }}
      whileHover={{ scale: 1.02 }}
      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
    >
      <Link
        to={route}
        onClick={() => comingSoon && dispatch(setComingSoonModalOpen(true))}
        className={cn(
          location.pathname === route && 'bg-white rounded-[10px]',
          `flex items-center ${sideBarIsCollapsed && 'justify-center'} space-x-3 p-2`
        )}
      >
        <div className="">{icon}</div>
        {!sideBarIsCollapsed && <p className="text-grey-text font-medium text-sm">{text}</p>}
      </Link>
    </motion.div>
  );
};

export default SidebarLink;

export const LogoutLink = ({ route, text }: Props) => {
  const location = useLocation();

  const { sideBarIsCollapsed } = useAppSelector((state) => state.layout);

  return (
    <Link
      to={route}
      className={cn(
        location.pathname === route && 'bg-white rounded-[10px]',
        `flex items-center space-x-3 p-3`
      )}
    >
      <Logout />
      {!sideBarIsCollapsed && <p className="text-[#CE1124] font-medium text-base">{text}</p>}
    </Link>
  );
};

export const MobileLogoutLink = ({ route, text }: Props) => {
  const location = useLocation();

  return (
    <Link
      to={route}
      className={cn(
        location.pathname === route && 'bg-white rounded-[10px]',
        `flex items-center space-x-3 p-3`
      )}
    >
      <Logout />
      <p className="text-[#CE1124] font-medium text-base">{text}</p>
    </Link>
  );
};

export const MobileSidebarLink = ({ route, icon, text, comingSoon = false }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleClick = () => {
    navigate(route);
    dispatch(setMobileSidebarIsCollapsed(true));
  };

  return (
    <button
      className={cn(
        location.pathname === route && 'bg-white rounded-[10px]',
        `flex items-center space-x-3 p-3 w-full`
      )}
      onClick={() => (comingSoon ? dispatch(setComingSoonModalOpen(true)) : handleClick())}
    >
      {icon}
      <p className="text-grey-text font-medium text-sm">{text}</p>
    </button>
  );
};
