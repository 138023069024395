import { createElement } from 'react';
import { ERROR_404_ROUTE } from 'routes/path';
import { AppRouteConfig } from 'types/navigation';
import NotFound from 'views/error/notFound';

const errorRoutes: AppRouteConfig[] = [
  {
    path: ERROR_404_ROUTE,
    element: createElement(NotFound),
    isolated: true,
    isError: true,
  },
];
export default errorRoutes;
