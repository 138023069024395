import Button from 'components/Buttons/Button';
import DynamicInput from 'components/Inputs/dynamicInput';
import Input from 'components/Inputs/input';
import { SEND_PAYMENT_METHOD_KEY } from 'lib/constants/accounts/send';
import { useGetSendBeneficiaryDynamicInputs } from 'lib/utils';
import { ChevronLeft, Plus } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ACCOUNTS_FINALIZE_SEND_ROUTE } from 'routes/path';
import { useGetBeneficiaries } from 'services/beneficiary';
import { Beneficiary as BeneficiaryType } from 'services/beneficiary/constants';
import { useAppDispatch, useAppSelector } from 'store';
import {
  setFinalizeSendPage,
  setIsBeneficiary,
  setSendInputValues,
  setSendPaymentStep,
} from 'store/slices/account/accountsSlice';
import { ACCOUNTS_SLICE_REDUCER_PATH } from 'store/slices/account/constants';
import { RECIPIENT_CURRENCY_KEY, WALLET_SLICE_REDUCER_PATH } from 'store/slices/wallet/constants';
import { searchBeneficiaries } from 'utils/searchBeneficiaries';
import SubRouteLayout from 'views/dashboard/components/subRouteLayout';

const Beneficiary = ({ type }: { type: number }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { [SEND_PAYMENT_METHOD_KEY]: sendPaymentMethod } = useAppSelector(
    (state) => state[ACCOUNTS_SLICE_REDUCER_PATH]
  );

  const { [RECIPIENT_CURRENCY_KEY]: recipientCurrency } = useAppSelector(
    (state) => state[WALLET_SLICE_REDUCER_PATH]
  );

  const { data: beneficiaries } = useGetBeneficiaries();
  const filteredBeneficiaries = beneficiaries?.data.filter(
    (beneficiary) =>
      beneficiary.payment_method === sendPaymentMethod && beneficiary.currency === recipientCurrency
  );

  useEffect(() => {
    if (
      sendPaymentMethod !== 'tag_transfer' &&
      filteredBeneficiaries &&
      !filteredBeneficiaries.length
    ) {
      dispatch(setSendPaymentStep(0));
    }
  }, [beneficiaries, dispatch, filteredBeneficiaries, sendPaymentMethod]);

  const fields = useGetSendBeneficiaryDynamicInputs(recipientCurrency, sendPaymentMethod);

  const [displayedBeneficiaries, setDisplayedBeneficiaries] = useState(filteredBeneficiaries);
  const [searchInput, setSearchInput] = useState('');

  const handleClickUser = (user: BeneficiaryType) => {
    const metaObj: { [key: string]: string } = {};
    user.meta.forEach((item) => {
      metaObj[item.id] = item.value;
    });
    dispatch(setSendInputValues(metaObj));
    dispatch(setFinalizeSendPage(1));
    dispatch(setIsBeneficiary(true));
    navigate(ACCOUNTS_FINALIZE_SEND_ROUTE(sendPaymentMethod));
  };

  useEffect(() => {
    const newValues = searchBeneficiaries(filteredBeneficiaries, searchInput);
    if (JSON.stringify(displayedBeneficiaries) !== JSON.stringify(newValues)) {
      setDisplayedBeneficiaries(newValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredBeneficiaries, searchInput]);

  return (
    <div>
      {!type && (
        <SubRouteLayout header="Select beneficiary">
          <div className="pb-20 xl:-ml-[4.688rem] w-full max-w-[52rem]">
            <div className="xl:flex items-start xl:space-x-11 w-full">
              <button
                onClick={() => dispatch(setSendPaymentStep(0))}
                className="flex space-x-1.5 xl:space-x-3 items-center"
              >
                <ChevronLeft className="w-3 h-3 xl:w-6 xl:h-6" />
                <span className="text-xs xl:text-base">Back</span>
              </button>

              <div className="w-full mt-5 xl:mt-0 space-y-4 xl:space-y-10">
                <div className="xl:flex space-y-4 xl:space-y-0 justify-between w-full">
                  <div>
                    <p className="text-xl xl:text-2xl font-medium">Beneficiaries</p>
                    <p className="mt-1 xl:mt-2 font-medium text-xs xl:text-lg text-grey-text">
                      Select a beneficiary or add a new beneficiary
                    </p>
                  </div>

                  <Button
                    onClick={() => {
                      navigate(ACCOUNTS_FINALIZE_SEND_ROUTE(sendPaymentMethod));
                      dispatch(setIsBeneficiary(false));
                      dispatch(setFinalizeSendPage(0));
                    }}
                    variant="secondary"
                    className="flex items-center space-x-2 text-xs xl:text-base font-bold"
                  >
                    <Plus />
                    <span>Add Recipient</span>
                  </Button>
                </div>

                <Input
                  id="search"
                  searchIcon
                  variant="secondary"
                  onChange={(e) => setSearchInput(e.target.value)}
                />

                <div className="space-y-5">
                  {displayedBeneficiaries &&
                    displayedBeneficiaries.map((beneficiary) => (
                      <button
                        onClick={() => handleClickUser(beneficiary)}
                        key={beneficiary.id}
                        className="bg-white w-full p-3 rounded-[10px] flex space-x-5 hover:bg-platnova-secondary"
                      >
                        <div className="bg-[#F5B12C] w-10 h-10 rounded-full flex justify-center items-center">
                          <p className="text-base">
                            {beneficiary.meta.find((x) => x.id === 'account_name')?.value[0]}
                          </p>
                        </div>
                        <div>
                          <p className="text-xs xl:text-base text-start">
                            {beneficiary.meta.find((x) => x.id === 'account_name')?.value}
                          </p>
                          <p className="text-[0.625rem] xl:text-xs text-grey-text text-start mt-1">
                            {beneficiary.meta.find((x) => x.id === 'bank_name')?.value}{' '}
                            {beneficiary.meta.find((x) => x.id === 'account_number')?.value}
                          </p>
                        </div>
                      </button>
                    ))}
                </div>

                <div></div>
              </div>
            </div>
          </div>
        </SubRouteLayout>
      )}
      {type === 1 && (
        <SubRouteLayout header="Novatag">
          <div className="pb-20 xl:-ml-[4.688rem] w-full max-w-[52rem]">
            <div className="xl:flex items-start xl:space-x-11 w-full">
              <button
                onClick={() => dispatch(setSendPaymentStep(0))}
                className="flex space-x-1.5 xl:space-x-3 items-center"
              >
                <ChevronLeft className="w-3 h-3 xl:w-6 xl:h-6" />
                <span className="text-xs xl:text-base">Back</span>
              </button>

              <div className="w-full mt-5 xl:mt-0 space-y-10">
                <div className="flex justify-between w-full">
                  <div>
                    <p className="text-xl xl:text-2xl font-medium">Recipient details</p>
                    <p className="mt-1 xl:mt-2 font-medium text-xs xl:text-lg text-grey-text">
                      Please enter the details of the recipient
                    </p>
                  </div>
                </div>

                {fields &&
                  fields.map((field) =>
                    field.id !== 'tag_ids' ? (
                      <DynamicInput
                        id={field.id}
                        key={field.id}
                        label={field.label}
                        disabled={field.disabled}
                        hiddenState={field.hidden}
                        maxLength={field.limit}
                        placeholder={field.hint}
                        variant="secondary"
                        dynamicVariant={field.action === 'search' ? 'search' : 'input'}
                        field={field}
                      />
                    ) : (
                      <DynamicInput
                        id={field.id}
                        key={field.id}
                        label={field.label}
                        disabled={field.disabled}
                        hiddenState={field.hidden}
                        maxLength={field.limit}
                        placeholder={field.place_holder}
                        dynamicVariant="tag_ids"
                        field={field}
                      />
                    )
                  )}
                {/* <Input id="search" variant="secondary" /> */}

                <div></div>
              </div>
            </div>
          </div>
        </SubRouteLayout>
      )}
    </div>
  );
};

export default Beneficiary;
