import { useQuery } from '@tanstack/react-query';

import { generateReceipt, getTransactions } from './function';
import { GET_RECEIPT_KEY, GET_TRANSACTIONS_KEY } from './keys';

export const useGetTransctions = () => {
  const { data, isPending, isError } = useQuery({
    queryKey: [GET_TRANSACTIONS_KEY],
    queryFn: getTransactions,
  });

  return {
    data,
    isPending,
    isError,
  };
};

export const useGenerateReceipt = (id: string) => {
  const { data, isPending, isError, refetch } = useQuery({
    queryKey: [GET_RECEIPT_KEY],
    queryFn: () => generateReceipt(id),
  });

  return {
    data,
    isPending,
    isError,
    refetch,
  };
};
