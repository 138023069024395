import http from 'services/http';
import { GeneralApiResponse } from 'types';
import { AuthorizeRequestInput } from 'types/auth';

export const postAuthorizeRequest = async (
  data: AuthorizeRequestInput
): Promise<GeneralApiResponse<object>> => {
  const res = await http.post('/auth/authorize/request', data);
  return res.data;
};
