import http from 'services/http';
import { GeneralApiResponse } from 'types';
import {
  AuthUser,
  RegisterAccountVerificationInput,
  RegisterInput,
  SetPasswordInput,
  SetupAccountInput,
} from 'types/auth';

export const postRegister = async (data: RegisterInput): Promise<GeneralApiResponse<object>> => {
  const res = await http.post('/auth/register', data);
  return res.data;
};

export const postRegisterAccountVerification = async (
  data: RegisterAccountVerificationInput
): Promise<GeneralApiResponse<object>> => {
  const res = await http.post('/auth/confirm-account', data);
  return res.data;
};

export const postResendRegistrationOtp = async (
  data: RegisterInput
): Promise<GeneralApiResponse<object>> => {
  const res = await http.post('/auth/resend-registration-otp', data);
  return res.data;
};

export const postSetPassword = async (
  data: SetPasswordInput
): Promise<GeneralApiResponse<AuthUser>> => {
  const res = await http.post('/auth/create-password', data);
  return res.data;
};

export const patchSetupAccount = async (
  data: SetupAccountInput
): Promise<GeneralApiResponse<object>> => {
  const res = await http.patch('/accounts', data);
  return res.data;
};
