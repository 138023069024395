import { useFormik } from 'formik';
import { FORGOT_PASSWORD_EMAIL_KEY } from 'lib/constants/auth/forgotPassword';
import { useNavigate } from 'react-router-dom';
import { VERIFY_FORGOT_PASSWORD_CODE_ROUTE } from 'routes/path';
import { useForgotPassword } from 'services/auth/forgotPassword';
import { useAppDispatch } from 'store';
import { setForgotPasswordEmail } from 'store/slices/forgotPassword/forgotPasswordSlice';
import { object, string } from 'yup';

export default function useForgotPasswordForm() {
  const { mutate, error, isError, isPending } = useForgotPassword();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      [FORGOT_PASSWORD_EMAIL_KEY]: '',
    },
    onSubmit: (values) => {
      mutate(values, {
        onSuccess: () => {
          dispatch(setForgotPasswordEmail(values[FORGOT_PASSWORD_EMAIL_KEY]));
          navigate(VERIFY_FORGOT_PASSWORD_CODE_ROUTE);
        },
      });
    },
    validationSchema: object().shape({
      [FORGOT_PASSWORD_EMAIL_KEY]: string()
        .email('Enter a valid email address')
        .required('Email cannot be empty'),
    }),
  });

  function getInputProps(id: keyof typeof formik.values) {
    return {
      ...formik.getFieldProps(id),
      ...formik.getFieldMeta(id),
      ...formik.errors,
    };
  }

  return Object.freeze({
    onSubmit: formik.handleSubmit,
    getInputProps,
    setValue: formik.setFieldValue,
    isValid: formik.isValid,
    errors: formik.errors,
    isError: isError,
    forgotPasswordError: error,
    isPending: isPending,
  });
}
