export const SEND_PAYMENT_METHOD_KEY = 'payment_method' as const;
export const SEND_FROM_AMOUNT_KEY = 'from_amount' as const;
export const SEND_FROM_CURRENCY_KEY = 'from_currency' as const;
export const SEND_TO_AMOUNT_KEY = 'to_amount' as const;
export const SEND_TO_CURRENCY_KEY = 'to_currency' as const;
export const SEND_BANK_TRANSFER_ACCOUNT_NUMBER_KEY = 'account_number' as const;
export const SEND_BANK_TRANSFER_BANK_NAME_KEY = 'bank_name' as const;
export const SEND_BANK_TRANSFER_ACCOUNT_NAME_KEY = 'account_name' as const;
export const SEND_BANK_TRANSFER_BANK_CODE_KEY = 'bank_code' as const;
export const SEND_IS_SAVE_TO_BENEFICIARY_KEY = 'is_save_to_beneficiary' as const;
export const BENEFICIARY_PAYMENT_METHOD_KEY = 'payment_method' as const;
export const BENEFICIARY_CURRENCY_KEY = 'currency' as const;
