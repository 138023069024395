import { ChevronLeft } from 'lucide-react';
import { Link } from 'react-router-dom';
import { ACCOUNTS_ROUTE } from 'routes/path';
import { useGetAccount } from 'services/account';
import { registerKeys } from 'services/auth/register/key';
import { useAppSelector } from 'store';
import {
  VERIFICATION_SLICE_REDUCER_PATH,
  VERIFICATION_SLICE_STEP,
} from 'store/slices/verification/constants';
import AuthCardLayout from 'views/auth/authCardLayout';

import ChooseDocument from './chooseDocument';
import VerifyAccountForm from './components/verifyAccountForm';
import AuthLayout from '../auth/layout';

const Verification = () => {
  const { [VERIFICATION_SLICE_STEP]: step } = useAppSelector(
    (state) => state[VERIFICATION_SLICE_REDUCER_PATH]
  );

  const { data: account, isPending: isLoadingAccount } = useGetAccount();

  const isFirstLogin = localStorage.getItem(registerKeys.IS_FIRST_LOGIN_KEY);

  return (
    account &&
    !isLoadingAccount && (
      <div>
        {!step ? (
          <AuthLayout>
            <div className="space-y-3 xl:space-y-6">
              {isFirstLogin && isFirstLogin === '1' ? (
                <div className="w-full flex justify-end">
                  <Link to={ACCOUNTS_ROUTE} className="mr-[5%] text-xs xl:text-base text-black/70">
                    Skip
                  </Link>
                </div>
              ) : (
                <div>
                  <Link to={ACCOUNTS_ROUTE} className="flex space-x-1.5 xl:space-x-3 items-center">
                    <ChevronLeft className="w-3 h-3 xl:w-6 xl:h-6" />
                    <span className="text-xs xl:text-base">Back</span>
                  </Link>
                </div>
              )}
              <AuthCardLayout title="Almost there!" subtitle="Verify your account within minutes!">
                <VerifyAccountForm />
              </AuthCardLayout>
            </div>
          </AuthLayout>
        ) : (
          <ChooseDocument />
        )}
      </div>
    )
  );
};

export default Verification;
