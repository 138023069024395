import Button from 'components/Buttons/Button';
import useErrorNotification from 'components/error/useErrorNotification';
import Modal, { ModalProps } from 'components/modal';
import VerifyPinForm from 'components/verifyPinForm';
import {
  CONVERT_FROM_AMOUNT_KEY,
  CONVERT_FROM_CURRENCY_KEY,
  CONVERT_TO_AMOUNT_KEY,
  CONVERT_TO_CURRENCY_KEY,
} from 'lib/constants/accounts/convert';
import { SEND_IS_SAVE_TO_BENEFICIARY_KEY } from 'lib/constants/accounts/send';
import { CircleCheck } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ACCOUNTS_ROUTE, SETTINGS_PIN_ROUTE } from 'routes/path';
import { useGetAccount } from 'services/account';
import { useConvert } from 'services/convert';
import { useSend } from 'services/send';
import { useAuthorizePin } from 'services/settings';
import { useAppDispatch, useAppSelector } from 'store';
import {
  setFinalizeSendPage,
  setSelectedBank,
  setSendInputValues,
  setSendPaymentStep,
} from 'store/slices/account/accountsSlice';
import {
  ACCOUNTS_SLICE_REDUCER_PATH,
  SAVE_TO_BENEFICIARY,
  SEND_AMOUNT_VALUES,
  SEND_PAYMENT_INPUT_VALUES,
  SendPaymentInputValues,
} from 'store/slices/account/constants';
import { ConvertInput } from 'types/convert';
import { formatAmount } from 'utils/numberFormat';

interface ConfirmationModalProps extends ModalProps {
  fromAmount: string;
  toAmount: string;
  fromCurrency: string;
  toCurrency: string;
  type: 'send' | 'convert';
}

const ConvertConfirmationModal = ({ ...props }: ConfirmationModalProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data: account } = useGetAccount();
  const isPinValid = account?.data.additional_info.find((x) => x.id === 'is_pin_valid')?.value;

  const sendPaymentInputValues: SendPaymentInputValues = useAppSelector(
    (state) => state[ACCOUNTS_SLICE_REDUCER_PATH][SEND_PAYMENT_INPUT_VALUES]
  );

  const { [SEND_AMOUNT_VALUES]: sendAmountValues, [SAVE_TO_BENEFICIARY]: saveToBeneficiary } =
    useAppSelector((state) => state[ACCOUNTS_SLICE_REDUCER_PATH]);

  const [page, setPage] = useState(0);

  const values: ConvertInput = {
    [CONVERT_FROM_AMOUNT_KEY]: Number(props.fromAmount),
    [CONVERT_TO_AMOUNT_KEY]: Number(props.toAmount),
    [CONVERT_FROM_CURRENCY_KEY]: props.fromCurrency,
    [CONVERT_TO_CURRENCY_KEY]: props.toCurrency,
  };

  const { error, isError, isPending, mutate } = useConvert();
  const {
    error: sendError,
    isError: isSendError,
    isPending: sendIsPending,
    mutate: mutateSend,
  } = useSend();

  useErrorNotification(isError, error);

  const {
    mutate: authorizePin,
    isPending: isAuthorizePinPending,
    error: authorizePinError,
    isError: isAuthorizePinError,
    isSuccess,
  } = useAuthorizePin();

  useEffect(() => {
    if (isSuccess && props.type === 'convert') {
      props.isOpen = true;
      handleSubmit(values);
    } else if (isSuccess && props.type === 'send') {
      props.isOpen = true;
      handleSendSubmit();
    } else {
      null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const handleSubmit = (inputValues: ConvertInput) => {
    mutate(inputValues, {
      onSuccess: () => setPage(1),
      onError: () => props.close(),
    });
  };

  const handleSendSubmit = () => {
    mutateSend(
      {
        ...sendAmountValues,
        info: { ...sendPaymentInputValues },
        [SEND_IS_SAVE_TO_BENEFICIARY_KEY]: saveToBeneficiary,
      },
      {
        onSuccess: () => {
          dispatch(setSendInputValues({}));
          dispatch(setFinalizeSendPage(0));
          dispatch(setSelectedBank(''));
          setPage(1);
          dispatch(setSendPaymentStep(0));
        },

        onError: () => props.close(),
      }
    );
  };

  useErrorNotification(isSendError, sendError);

  useEffect(() => {
    setPage(0);
  }, [props.isOpen]);

  return (
    <Modal {...props}>
      {!page && (
        <div className="py-10 px-5 w-full max-w-xl flex flex-col space-y-6 items-center text-center">
          <h1 className="text-xl font-semibold">
            {props.type === 'convert' ? 'Convert' : 'Send'} {props.fromCurrency.toUpperCase()} to{' '}
            {props.toCurrency.toUpperCase()}
          </h1>
          <p className="text-base">
            Click proceed to {props.type === 'convert' ? 'convert' : 'send'}{' '}
            {formatAmount(String(props.fromAmount))} {props.fromCurrency.toUpperCase()} to{' '}
            {props.type === 'convert'
              ? formatAmount(String(props.toAmount))
              : sendPaymentInputValues.account_name}{' '}
            {props.type === 'convert' && props.toCurrency}
          </p>

          <div className="flex flex-col w-full space-y-6">
            <Button
              variant="purple"
              className="px-8"
              isLoading={isPending || sendIsPending}
              onClick={() => setPage(2)}
            >
              Proceed
            </Button>
            <button
              className="text-xs md:text-sm"
              disabled={isPending || sendIsPending}
              onClick={props.close}
            >
              Not now
            </button>
          </div>
        </div>
      )}
      {page === 1 && (
        <div className="py-10 md:py-20 px-5 md:px-24 w-full max-w-xl flex flex-col items-center text-center space-y-5">
          <CircleCheck className="text-[#17B643]" size={83} />
          <p className="text-2xl font-medium">Your Transfer has been initiated</p>
          <p className="text-grey-silent-text text-base">
            Your transfer has been initiated, we would notify you once it's been disbursed.
          </p>
          <Button
            onClick={() => navigate(ACCOUNTS_ROUTE)}
            variant="purple"
            className="w-full"
            type="button"
          >
            Go home
          </Button>
        </div>
      )}
      {page === 2 &&
        (isPinValid ? (
          <VerifyPinForm
            close={props.close}
            error={authorizePinError}
            isError={isAuthorizePinError}
            isPending={isAuthorizePinPending || isPending || sendIsPending}
            mutate={authorizePin}
          />
        ) : (
          <div className="py-10 md:py-20 px-5 md:px-24 w-full max-w-xl flex flex-col items- space-y-5 text-center">
            <p className="text-lg md:text-2xl font-semibold">Create PIN</p>
            <p className="text-sm md:text-base">
              For further security, you are required to create a transaction pin to proceed with
              this transaction.
            </p>
            <Button variant="purple" onClick={() => navigate(SETTINGS_PIN_ROUTE)}>
              Create Transaction Pin
            </Button>
          </div>
        ))}
    </Modal>
  );
};

export default ConvertConfirmationModal;
