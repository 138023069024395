import { AUTHORIZE_REQUEST_EMAIL_KEY, AUTHORIZE_REQUEST_TYPE_KEY } from 'lib/constants/auth';
import { REGISTER_EMAIL_KEY } from 'lib/constants/auth/register';
import { cn } from 'lib/utils';
import { useEffect, useState } from 'react';
import { useAuthorizeRequest } from 'services/auth';
import { useResendRegistrationOtp } from 'services/auth/register';

type TimerProps = {
  className?: string;
  time: number;
  buttonClassName?: string;
  resendEmail: string;
  type: 'register' | 'account_authorize' | 'password_reset';
};
const Timer = ({ className, time, buttonClassName, resendEmail, type }: TimerProps) => {
  const [timeLeft, setTimeLeft] = useState<number>(time || 0);

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
    }, 1000);
    if (timeLeft === 0) {
      clearInterval(intervalId);
    }
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  // const resetTimer = () => {
  //   setTimeLeft(time)
  // }

  const { mutate: resendRegistrationOtp } = useResendRegistrationOtp();
  const { mutate: authorizeRequest } = useAuthorizeRequest();

  const registerValues = {
    [REGISTER_EMAIL_KEY]: resendEmail,
  };

  const authorizeValues = {
    [AUTHORIZE_REQUEST_EMAIL_KEY]: resendEmail,
    [AUTHORIZE_REQUEST_TYPE_KEY]: type,
  };

  const resetTimer = () => {
    if (type === 'register') {
      resendRegistrationOtp(registerValues);
    } else {
      authorizeRequest(authorizeValues);
    }
    setTimeLeft(time);
  };

  return (
    <div className="flex space-x-3">
      <p className={className}>
        {minutes < 10 ? '0' + minutes : minutes}:{seconds < 10 ? '0' + seconds : seconds}
      </p>
      <button
        className={cn(
          buttonClassName,
          [timeLeft > 0 && 'cursor-not-allowed text-platnova-purple/50'],
          'my-auto'
        )}
        disabled={timeLeft > 0}
        onClick={resetTimer}
      >
        Resend Code
      </button>
    </div>
  );
};

export default Timer;
