import Toast from 'components/error/toast';
import Layout from 'components/layout';
import Initialization from 'middlewares/initialize';
import { Fragment } from 'react';
import { useRoutes } from 'react-router-dom';
import routes from 'routes';

function App() {
  const views = useRoutes(routes);

  return (
    <Fragment>
      <Layout>
        <Toast />
        {Initialization({
          children: <div>{views}</div>,
        })}
      </Layout>
    </Fragment>
  );
}

export default App;
