/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import Flag from 'components/flag';
import InputLabel from 'components/Inputs/inputLabel';
import { AnimatePresence, motion } from 'framer-motion';
import useDisclosure from 'hooks/useDisclosure';
import { cn } from 'lib/utils';
import { ChevronDown } from 'lucide-react';
import { useEffect } from 'react';
import { NumericFormat } from 'react-number-format';
import { useGetWallets } from 'services/wallet';
import { useAppDispatch, useAppSelector } from 'store';
import {
  TO_ACCOUNT_WALLET_ID_KEY,
  WALLET_ID_KEY,
  WALLET_SLICE_REDUCER_PATH,
} from 'store/slices/wallet/constants';
import { setToAccountWallet } from 'store/slices/wallet/walletSlice';
import { formatAmount } from 'utils/numberFormat';

import SelectToWalletModal from './selectToWalletModal';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  variant?: 'primary';
  touched?: boolean;
  error?: string;
  labelClassName?: string;
  label?: string;
  id: string;
  type?: 'password' | 'text' | 'tel'; // Ensure only supported types are listed
  inputClassName?: string;
  containerClassName?: string;
  initialValue?: string;
  initialTouched?: boolean;
  initialError?: string;
  eagerError?: boolean;
  helperText?: string;
  chosenWallet?: number;
  toAccount?: boolean;
  defaultValue?: string | number | undefined; // Ensure defaultValue type is compatible
}

const ToWalletInput = ({
  id,
  className,
  labelClassName,
  label,
  required,
  touched,
  error,
  helperText,
  value,
  eagerError,
  ...rest
}: InputProps) => {
  delete rest.initialError;
  delete rest.initialTouched;
  delete rest.initialValue;

  const { data: wallets } = useGetWallets();
  const { [TO_ACCOUNT_WALLET_ID_KEY]: walletId, [WALLET_ID_KEY]: fromWalletId } = useAppSelector(
    (state) => state[WALLET_SLICE_REDUCER_PATH]
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (fromWalletId === walletId && walletId === wallets?.data[0].id) {
      dispatch(setToAccountWallet(wallets.data[1].id));
    } else if (fromWalletId === walletId && walletId === wallets?.data[1].id) {
      dispatch(setToAccountWallet(wallets.data[0].id));
    }
  }, [dispatch, fromWalletId, walletId, wallets]);

  const activeWallet =
    walletId.length > 0 ? wallets?.data.find((x) => x.id === walletId) : wallets?.data[1];

  const {
    isOpen: isSelectDepositWalletModalOpen,
    open: openSelectDepositWalletModal,
    close: closeSelectDepositWalletModal,
  } = useDisclosure();

  return (
    <div className="w-full">
      <div className="flex flex-col gap-2 mb-1">
        {((label && !!label.length) || (helperText && !!helperText.length)) && (
          <div className="flex flex-col items-start justify-center gap-1">
            {label && !!label.length && (
              <InputLabel
                className={cn('text-[0.625rem] xl:text-sm font-normal', labelClassName)}
                id={id}
                label={label}
              />
            )}
            {helperText && !!helperText.length && (
              <label htmlFor={id} className="text-red-500 text-xs 2xl:text-sm font-medium">
                {helperText}
              </label>
            )}
          </div>
        )}
        {wallets && (
          <div>
            <div className="bg-primary-grey flex rounded-[10px] border-2 border-transparent focus-within:border-platnova-purple">
              <button
                type="button"
                onClick={openSelectDepositWalletModal}
                className="flex items-center space-x-2 py-2 xl:py-5 border-r px-2 xl:px-5"
              >
                <Flag
                  code={activeWallet ? activeWallet.country.code : wallets.data[1].country.code}
                  className="w-3 h-3 xl:w-6 xl:h-6"
                />
                <p className="text-xs xl:text-base">
                  {activeWallet
                    ? activeWallet.type.toUpperCase()
                    : wallets?.data[1].type.toUpperCase()}
                </p>
                <ChevronDown className="w-3 h-3 xl:w-6 xl:h-6" />
              </button>
              <NumericFormat
                className={cn(
                  'w-full bg-transparent pl-2 xl:pl-5 outline-none text-xs xl:text-base',
                  [touched && error && 'border-error-primary'],
                  [className && className]
                )}
                placeholder="Enter amount"
                value={value as string | null}
                decimalScale={2}
                type="text"
                fixedDecimalScale
                allowLeadingZeros={false}
                thousandSeparator=","
                allowNegative={false}
                required={required}
                {...rest}
              />
            </div>
          </div>
        )}
      </div>
      <AnimatePresence>
        <div className="h-5 mt-2 w-full flex items-center justify-between">
          {(eagerError || touched) && error ? (
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 100 }}
              transition={{ ease: 'easeOut', duration: 0.5 }}
              className="pl-1 text-[0.625rem] xl:text-xs font-semibold text-error-primary"
            >
              {error}
            </motion.div>
          ) : (
            <div></div>
          )}
          {wallets && (
            <p className="text-grey-silent-text text[0.625rem] md:text-xs xl:text-base text-end flex justify-end">
              Balance:{' '}
              {activeWallet
                ? formatAmount(activeWallet.amount) + ' ' + activeWallet.type.toUpperCase()
                : formatAmount(wallets.data[1].amount) + ' ' + wallets.data[1].type.toUpperCase()}
            </p>
          )}
        </div>
      </AnimatePresence>
      <SelectToWalletModal
        close={closeSelectDepositWalletModal}
        isOpen={isSelectDepositWalletModalOpen}
      />
    </div>
  );
};

export default ToWalletInput;
