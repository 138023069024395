import Button from 'components/Buttons/Button';
import useErrorNotification from 'components/error/useErrorNotification';
import Input from 'components/Inputs/input';
import InputPhone from 'components/Inputs/inputPhone';
import Select from 'components/Inputs/select';
import {
  REGISTER_COUNTRY_OF_RESIDENCE_KEY,
  REGISTER_DIAL_CODE_KEY,
  REGISTER_FIRST_NAME_KEY,
  REGISTER_LAST_NAME_KEY,
  REGISTER_PHONE_NUMBER_KEY,
} from 'lib/constants/auth/register';
import { allCountries } from 'lib/countries/countries';

import 'react-phone-input-2/lib/style.css';

import useAccountSetupForm from '../hooks/useAccountSetupForm';

const AccountSetupForm = () => {
  const {
    getInputProps,
    onSubmit,
    isValid,
    errors,
    accountSetupError,
    isError,
    isPending,
    setValue,
  } = useAccountSetupForm();

  const handleOnChange = (
    value: string,
    data: { countryCode: string; dialCode: string; format: string; name: string }
  ) => {
    const dialCodeLength = data.dialCode.length;
    value = value.slice(dialCodeLength);
    setValue(REGISTER_DIAL_CODE_KEY, data.dialCode);
    setValue(REGISTER_PHONE_NUMBER_KEY, value);
  };

  const formatCountries = allCountries.map((country) => ({
    value: country.name.common,
    img: country.flags.svg,
    label: country.name.common,
  }));

  useErrorNotification(isError, accountSetupError);

  return (
    <form className="mt-5 xl:mt-10 space-y-3 flex flex-col" onSubmit={onSubmit}>
      <Input
        id={REGISTER_FIRST_NAME_KEY}
        label="First name"
        inputMode="text"
        type="text"
        className="xl:w-[29.25rem]"
        placeholder="e.g. John"
        error={errors[REGISTER_FIRST_NAME_KEY]}
        {...getInputProps(REGISTER_FIRST_NAME_KEY)}
      />

      <Input
        id={REGISTER_LAST_NAME_KEY}
        label="Last name"
        inputMode="text"
        type="text"
        className="xl:w-[29.25rem]"
        placeholder="e.g. Doe"
        error={errors[REGISTER_LAST_NAME_KEY]}
        {...getInputProps(REGISTER_LAST_NAME_KEY)}
      />

      <div>
        <InputPhone
          country="ng"
          label="Phone Number (optional)"
          id={REGISTER_PHONE_NUMBER_KEY}
          error={errors[REGISTER_PHONE_NUMBER_KEY]}
          onChange={handleOnChange}
        />
      </div>

      <div>
        <Select
          id={REGISTER_COUNTRY_OF_RESIDENCE_KEY}
          label="Country of residence or citizenship"
          variant="tertiary"
          tertiaryOptions={formatCountries}
          {...getInputProps(REGISTER_COUNTRY_OF_RESIDENCE_KEY)}
          error={errors[REGISTER_COUNTRY_OF_RESIDENCE_KEY]}
        />
        <p className="text-xs mt-3">
          You will be asked to provide documents to verify your residency or citizenship
        </p>
      </div>

      <Button
        variant="purple"
        type="submit"
        className="w-full mt-10"
        isDisabled={!isValid || isPending}
        isLoading={isPending}
      >
        Continue
      </Button>
    </form>
  );
};

export default AccountSetupForm;
