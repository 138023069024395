export const getNowPaymentScript = () => {
  return `
 setInterval(() => {
    
    var paymentPage = document.querySelector('.invoice-box')
    if (paymentPage) {
      paymentPage.style.width = '100vw'
    }
    
    var mountLoader = document.querySelector('img[src="/images/loader-blue-icon.gif"]')
    if (mountLoader) {
      mountLoader.style.filter = 'invert(45%) sepia(78%) saturate(6911%) hue-rotate(245deg) brightness(100%) contrast(94%)'
    }
    
    var invoiceCompanyName = document.querySelector('.company-info__name')
    if (invoiceCompanyName) {
      invoiceCompanyName.innerText = '                               '
    }

    var companyDescription = document.querySelector('.company-info__description')
    if (companyDescription) {
      companyDescription.innerText = 'Please ensure to make the payment within the stipulated time.'
    }
    
    var invoiceRight = document.querySelector('.invoice__right')
    if (invoiceRight) {
      invoiceRight.style.display = 'none'
    }
    
    var invoiceEmailField = document.querySelector('.invoice__email-field')
    if (invoiceEmailField) {
      invoiceEmailField.style.display = 'none'
    }
    
     var invoiceFooter = document.querySelector('.invoice-footer')
    if (invoiceFooter) {
      invoiceFooter.style.display = 'none'
    }
    
    var invoiceTab = document.querySelector('.invoice-tab')
    if (invoiceTab) {
      invoiceTab.style.backgroundColor = '#fff'
      invoiceTab.style.border = '1px solid #7049f7'
      invoiceTab.style.color = '#7049f7'
    }
    
    var activeInvoiceTab = document.querySelector('.invoice-tab_active')
    if (activeInvoiceTab) {
      activeInvoiceTab.style.backgroundColor = '#7049f7'
      activeInvoiceTab.style.border = '1px solid #7049f7'
      invoiceTab.style.color = '#fff'
    }
    
    var invoiceCompletedTab = document.querySelector('.invoice-tab_completed')
    if (invoiceCompletedTab) {
      invoiceCompletedTab.style.backgroundColor = '#fff'
      invoiceCompletedTab.style.border = '1px solid #7049f7'
      invoiceCompletedTab.style.color = '#7049f7'
    }
    
    var invoiceDisabledTab = document.querySelector('.invoice-tab:disabled')
    if (invoiceDisabledTab) {
      invoiceDisabledTab.style.backgroundColor = '#fff'
      invoiceDisabledTab.style.border = '1px solid #d7d7d7'
    }
    
    var invoiceCurrentSelectBody = document.querySelector('.currencies-select__body')
    if (invoiceCurrentSelectBody) {
      invoiceCurrentSelectBody.style.border = '1px solid #e2dafe'
    }
    
    var invoiceCookieBar = document.querySelector('.b-cookies-alert')
    if (invoiceCookieBar) {
      invoiceCookieBar.style.display = 'none'
    }
    
    var checkoutBtn = document.querySelector('.choose-currency-step__button')
    if (checkoutBtn) {
      checkoutBtn.style.backgroundColor = '#7049f7'
    }
    
    var copyAmountBtn = document.querySelector('img[src="/images/copy-small-icon.svg"]')
    if (copyAmountBtn) {
      copyAmountBtn.style.filter = 'invert(45%) sepia(78%) saturate(6911%) hue-rotate(245deg) brightness(100%) contrast(94%)'
    }
    
    var copyAddressBtn = document.querySelector('img[src="/images/copy-small-icon.svg"]')
    if (copyAddressBtn) {
      copyAddressBtn.style.filter = 'invert(45%) sepia(78%) saturate(6911%) hue-rotate(245deg) brightness(100%) contrast(94%)'
    }
    
    var showQrCode = document.querySelector('.qr-code-drop-down__button')
    if (showQrCode) {
      showQrCode.style.border = '1px solid #7049f7'
    }
    
    var showQrIcon = document.querySelector('img[src="/images/qr-code-small.svg"]')
    if (showQrIcon) {
      showQrIcon.style.filter = 'invert(45%) sepia(78%) saturate(6911%) hue-rotate(245deg) brightness(100%) contrast(94%)'
    }
    
    var waitingActive = document.querySelector('img[src="/images/payment/status-waiting-active.svg"]')
    if (waitingActive) {
      waitingActive.style.filter = 'invert(45%) sepia(78%) saturate(6911%) hue-rotate(245deg) brightness(100%) contrast(94%)'
    }
    
    var processingActive = document.querySelector('img[src="/images/payment/status-processing-active.svg"]')
    if (processingActive) {
      processingActive.style.filter = 'invert(45%) sepia(78%) saturate(6911%) hue-rotate(245deg) brightness(100%) contrast(94%)'
    }
    
     var finishedNewActive = document.querySelector('img[src="/images/payment/status-finished-new-active.svg"]')
    if (finishedNewActive) {
      finishedNewActive.style.filter = 'invert(45%) sepia(78%) saturate(6911%) hue-rotate(245deg) brightness(100%) contrast(94%)'
    }
    
    var paymentId = document.querySelector('.company__id span')
    if (paymentId) {
      paymentId.style.color = '#7049f7'
    }
    
    var activeStatus = document.querySelector('.transaction-status-item_loading .transaction-status-item__title')
    if (activeStatus) {
      activeStatus.style.color = '#7049f7'
    }
    
    var activeStatusSpinner = document.querySelector('.transaction-status-item__spinner')
    if (activeStatusSpinner) {
      activeStatusSpinner.style.border = '2px solid #7049f7'
      activeStatusSpinner.style.borderRightColor = 'rgba(100,172,255,.25)'
    }
    
    var invoiceBottom = document.querySelector('.invoice__bottom-info')
    if (invoiceBottom) {
      invoiceBottom.style.display = 'none'
    }
    
    var chatButton = document.querySelector('#zd-button')
    if (chatButton) {
      chatButton.style.display = 'none'
    }  
    }, 20)


  `;
};
