import Button from 'components/Buttons/Button';
import useErrorNotification from 'components/error/useErrorNotification';
import Input from 'components/Inputs/input';
import { FORGOT_PASSWORD_EMAIL_KEY } from 'lib/constants/auth/forgotPassword';
import { Link } from 'react-router-dom';
import { LOGIN_ROUTE } from 'routes/path';

import useForgotPasswordForm from '../hooks/useForgotPasswordForm';

const ForgotPasswordForm = () => {
  const { getInputProps, onSubmit, isValid, errors, forgotPasswordError, isError, isPending } =
    useForgotPasswordForm();

  useErrorNotification(isError, forgotPasswordError);

  return (
    <form className="space-y-3 xl:space-y-10 mt-10" onSubmit={onSubmit}>
      <Input
        id={FORGOT_PASSWORD_EMAIL_KEY}
        label="Email address"
        inputMode="email"
        type="email"
        className="xl:w-[29.25rem]"
        placeholder="Enter email address"
        error={errors[FORGOT_PASSWORD_EMAIL_KEY]}
        {...getInputProps(FORGOT_PASSWORD_EMAIL_KEY)}
      />

      <Button
        type="submit"
        variant="purple"
        className="w-full"
        isDisabled={!isValid || isPending}
        isLoading={isPending}
      >
        Continue
      </Button>

      <Link to={LOGIN_ROUTE}>
        <p className="text-platnova-purple mt-5 text-sm md:text-base font-semibold">
          Back to Log in
        </p>
      </Link>
    </form>
  );
};

export default ForgotPasswordForm;
