import NoTransaction from 'assets/icons/no-transactions.svg';
import Input from 'components/Inputs/input';
import moment from 'moment';
import { useGetTransctions } from 'services/transactions';
import { Transaction } from 'types/transactions';

import RecentActivityCard from '../accounts/components/recentActivityCard';
import DashboardCardLayout from '../components/dashboardCardLayout';
import PaddedContainer from '../components/paddedContainer';

const Transactions = () => {
  const { data: transactions } = useGetTransctions();
  const todayArray: Transaction[] = [];
  const yesterdayArray: Transaction[] = [];
  const twoDaysAgoArray: Transaction[] = [];
  const olderArray: Transaction[] = [];

  const today = moment().startOf('day');
  const yesterday = moment().subtract(1, 'day').startOf('day');
  const twoDaysAgo = moment().subtract(2, 'day').startOf('day');

  transactions?.data.items.forEach((transaction) => {
    const dateMoment = moment(transaction.created_at).startOf('day'); // Normalize to the start of the day

    if (dateMoment.isSame(today)) {
      todayArray.push(transaction);
    } else if (dateMoment.isSame(yesterday)) {
      yesterdayArray.push(transaction);
    } else if (dateMoment.isSame(twoDaysAgo)) {
      twoDaysAgoArray.push(transaction);
    } else {
      olderArray.push(transaction);
    }
  });

  return (
    <PaddedContainer>
      <div className="w-full xl:max-w-[37.5rem] space-y-10">
        <p className="text-start text-xl md:text-3xl font-medium">Transactions</p>
        <Input id="search" variant="secondary" searchIcon placeholder="Search by Reference no" />

        {transactions && transactions?.data.items.length > 0 ? (
          <div className="space-y-10">
            {todayArray.length ? (
              <div className="space-y-6">
                <p className="text-base md:text-2xl font-medium">Today</p>
                <DashboardCardLayout>
                  <div className="space-y-8">
                    {todayArray.map((transaction) => (
                      <RecentActivityCard
                        key={transaction.id}
                        transaction={transaction}
                        date="timestamp"
                      />
                    ))}
                  </div>
                </DashboardCardLayout>
              </div>
            ) : null}

            {yesterdayArray.length ? (
              <div className="space-y-6">
                <p className="text-base md:text-2xl font-medium">Yesterday</p>
                <DashboardCardLayout>
                  <div className="space-y-8">
                    {yesterdayArray.map((transaction) => (
                      <RecentActivityCard
                        key={transaction.id}
                        transaction={transaction}
                        date="timestamp"
                      />
                    ))}
                  </div>
                </DashboardCardLayout>
              </div>
            ) : null}

            {twoDaysAgoArray.length ? (
              <div className="space-y-6">
                <p className="text-base md:text-2xl font-medium">Two days ago</p>
                <DashboardCardLayout>
                  <div className="space-y-8">
                    {twoDaysAgoArray.map((transaction) => (
                      <RecentActivityCard
                        key={transaction.id}
                        transaction={transaction}
                        date="timestamp"
                      />
                    ))}
                  </div>
                </DashboardCardLayout>
              </div>
            ) : null}

            {olderArray.length ? (
              <div className="space-y-6">
                <p className="text-base md:text-2xl font-medium">Older</p>
                <DashboardCardLayout>
                  <div className="space-y-8">
                    {olderArray.map((transaction) => (
                      <RecentActivityCard
                        key={transaction.id}
                        transaction={transaction}
                        date="timestamp"
                      />
                    ))}
                  </div>
                </DashboardCardLayout>
              </div>
            ) : null}
          </div>
        ) : transactions && !transactions.data.items.length ? (
          <DashboardCardLayout>
            <img src={NoTransaction} alt="no transaction" className="mx-auto" />
            <div className="mt-5 text-center space-y-5">
              <p className="text-xl md:text-2xl font-medium">No transactions yet</p>
              <p className="text-grey-text text-sm md:text-base">
                It looks like you haven’t made any transactions yet. Start making transactions to
                see your activity!
              </p>
            </div>
          </DashboardCardLayout>
        ) : null}
      </div>
    </PaddedContainer>
  );
};

export default Transactions;
