import { CardBrands } from 'types/configuration';

export const CARD_SLICE_REDUCER_PATH = 'card_slice_reducer' as const;
export const ACTIVE_CARD_BRAND = 'active_card_brand' as const;
export const ACTIVE_VIRTUAL_CARD_BRAND = 'active_virtual_card_brand' as const;
export const PHYSICAL_CARD_COLOR_KEY = 'physical_card_color' as const;
export const VIRTUAL_CARD_COLOR_KEY = 'virtual_card_color' as const;
export const PHYSICAL_CARD_NUMBER = 'physical_card_number' as const;
const createPhysicalCardStage = ['create', 'order', 'success'] as const;
const createVirtualCardStage = ['create', 'pay', 'success'] as const;

export const physicalCardList = [
  {
    colors: ['#000000', '#000000', '#0c0c0c'],
    class: 'linear-gradient(to right, #000000, #000000, #0c0c0c)',
  },
  {
    class: 'linear-gradient(to right, #4e3bf8, #4e3bf8, #4e3bf8)',
    colors: ['#4e3bf8', '#4e3bf8', '#4e3bf8'],
  },
];

export const virtualCardList = [
  {
    colors: ['#f172b9', '#fdb3ae', '#fcd99b'],
    class: 'linear-gradient(to right, #f172b9, #fdb3ae, #fcd99b)',
  },
  {
    class: 'linear-gradient(to right, #d152b0, #73d1f6, #0f6efe)',
    colors: ['#d152b0', '#73d1f6', '#0f6efe'],
  },
  {
    class: 'linear-gradient(to right, #79c8a4, #95dbcf, #4fd1da)',
    colors: ['#79c8a4', '#95dbcf', '#4fd1da'],
  },
  {
    class: 'linear-gradient(to right, #4882ea, #459ec0, #8847f3)',
    colors: ['#4882ea', '#459ec0', '#8847f3'],
  },
  {
    class: 'linear-gradient(to right, #f8470e, #fe9bc1, #ffb55f)',
    colors: ['#f8470e', '#fe9bc1', '#ffb55f'],
  },
  {
    class: 'linear-gradient(to right, #222222, #383838, #3c3c3c)',
    colors: ['#222222', '#383838', '#3c3c3c'],
  },
];

export type ColorType = {
  colors: string[];
  class: string;
};

export interface CardSliceType {
  [ACTIVE_CARD_BRAND]: CardBrands | null;
  [ACTIVE_VIRTUAL_CARD_BRAND]: CardBrands | null;
  [PHYSICAL_CARD_COLOR_KEY]: ColorType;
  [VIRTUAL_CARD_COLOR_KEY]: ColorType;
  [PHYSICAL_CARD_NUMBER]: number;
  stage: (typeof createPhysicalCardStage)[number];
  virtualStage: (typeof createVirtualCardStage)[number];
}

export const cardSliceInitialState: CardSliceType = {
  [ACTIVE_CARD_BRAND]: null,
  [PHYSICAL_CARD_COLOR_KEY]: physicalCardList[0],
  [PHYSICAL_CARD_NUMBER]: 1,
  stage: 'create',
  virtualStage: 'create',
  [VIRTUAL_CARD_COLOR_KEY]: virtualCardList[0],
  [ACTIVE_VIRTUAL_CARD_BRAND]: null,
};
