import { AxiosError } from 'axios';

export const errorHandler = (error: unknown) => {
  if (error instanceof AxiosError) {
    if (error.response) {
      return error.response.data.message;
    }
  } else {
    return 'Something went wrong';
  }
};
