import Logo from 'assets/logo/platnova-logo.png';
import Toast from 'components/error/toast';
import { cn } from 'lib/utils';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { LOGIN_ROUTE } from 'routes/path';

type Props = {
  children: ReactNode;
  containerClassName?: string;
  otherContainerClassName?: string;
  logoLink?: string;
};
const AuthLayout = ({ children, containerClassName, otherContainerClassName, logoLink }: Props) => {
  return (
    <div className="bg-primary-grey min-h-screen">
      <Toast />
      <div className="">
        <div className="py-6 bg-primary-grey sticky top-0 z-[500]">
          <div className="w-11/12 xl:w-10/12 mx-auto max-w-[1540px]">
            <Link to={logoLink ? logoLink : LOGIN_ROUTE} className="">
              <img src={Logo} alt="Platnova" className="h-6 w-auto" />
            </Link>
          </div>
        </div>
        <div
          className={cn(
            'bg-primary-grey w-full md:max-w-[83%] mx-auto flex justify-center pt-10 md:pt-0 2xl:pt-12 pb-10 h-full xl:h-auto',
            otherContainerClassName
          )}
        >
          <div className={cn('px-5 md:p-10 w-full h-full xl:w-auto xl:h-auto', containerClassName)}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
