const Bell = () => {
  return (
    <div className="w-6 h-6 xl:w-10 xl:h-10">
      <svg
        className="w-full h-full"
        width="1em"
        height="1em"
        viewBox="0 0 21 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.5001 26.3333C11.9667 26.3333 13.1667 25.1333 13.1667 23.6666H7.8334C7.8334 24.3738 8.11435 25.0521 8.61445 25.5522C9.11455 26.0523 9.79282 26.3333 10.5001 26.3333ZM18.5001 18.3333V11.6666C18.5001 7.57325 16.3134 4.14659 12.5001 3.23992V2.33325C12.5001 1.22659 11.6067 0.333252 10.5001 0.333252C9.3934 0.333252 8.50007 1.22659 8.50007 2.33325V3.23992C4.6734 4.14659 2.50007 7.55992 2.50007 11.6666V18.3333L0.780069 20.0533C-0.0599314 20.8933 0.526735 22.3333 1.7134 22.3333H19.2734C20.4601 22.3333 21.0601 20.8933 20.2201 20.0533L18.5001 18.3333Z"
          fill="#7049F7"
        />
      </svg>
    </div>
  );
};

export default Bell;
