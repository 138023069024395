import Button from 'components/Buttons/Button';
import { CircleCheck, CircleX } from 'lucide-react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ACCOUNTS_ROUTE } from 'routes/path';
import AuthLayout from 'views/auth/layout';
import DashboardCardLayout from 'views/dashboard/components/dashboardCardLayout';

const EndDeposit = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const status = searchParams.get('status');

  return (
    <AuthLayout logoLink={ACCOUNTS_ROUTE}>
      {status === 'successful' && (
        <DashboardCardLayout className="mt-[20%] py-7 px-5 w-full max-w-xl flex flex-col items-center text-center space-y-5">
          <CircleCheck className="text-[#17B643]" size={83} />
          <p className="text-2xl font-medium">Transaction Successful</p>
          <p className="text-sm md:text-base md:w-[80%] mx-auto">
            Your wallet would be credited once the payment is confirmed 🌟, this usually takes a few
            minutes
          </p>
          <Button
            onClick={() => navigate(ACCOUNTS_ROUTE)}
            variant="purple"
            className="w-full"
            type="button"
          >
            Go home
          </Button>
        </DashboardCardLayout>
      )}
      {status === 'cancelled' && (
        <DashboardCardLayout className="mt-[20%] py-7 px-5 w-full max-w-xl flex flex-col items-center text-center space-y-5">
          <CircleX className="text-error-primary" size={83} />
          <p className="text-2xl font-medium">Transaction Cancelled</p>
          <p className="text-sm md:text-base md:w-[80%] mx-auto"></p>
          <Button
            onClick={() => navigate(ACCOUNTS_ROUTE)}
            variant="purple"
            className="w-full"
            type="button"
          >
            Go home
          </Button>
        </DashboardCardLayout>
      )}
    </AuthLayout>
  );
};

export default EndDeposit;
