import http from 'services/http';
import { GeneralApiResponse } from 'types';
import { AuthUser, LoginInput, VerifyLoginInput } from 'types/auth';

export const postLogin = async (data: LoginInput): Promise<GeneralApiResponse<object>> => {
  const res = await http.post('/auth/login', data);
  return res.data;
};

export const postVerifyLogin = async (
  data: VerifyLoginInput
): Promise<GeneralApiResponse<AuthUser>> => {
  const res = await http.post('/auth/authorize/verify', data);
  return res.data;
};
