import Button from 'components/Buttons/Button';
import useErrorNotification from 'components/error/useErrorNotification';
import Input from 'components/Inputs/input';
import { REGISTER_PASSWORD_CONFIRM_KEY, REGISTER_PASSWORD_KEY } from 'lib/constants/auth/register';

import useSetPasswordForm from '../hooks/useSetPasswordForm';

const SetPasswordForm = () => {
  const { getInputProps, onSubmit, isValid, errors, isError, isPending, setPasswordError } =
    useSetPasswordForm();

  useErrorNotification(isError, setPasswordError);

  return (
    <form className="mt-5 xl:mt-10 space-y-3 xl:space-y-5 flex flex-col" onSubmit={onSubmit}>
      <Input
        id={REGISTER_PASSWORD_KEY}
        label="Enter password"
        inputMode="text"
        type="password"
        className="xl:w-[29.25rem]"
        placeholder="Enter password"
        {...getInputProps(REGISTER_PASSWORD_KEY)}
        error={errors[REGISTER_PASSWORD_KEY]}
      />

      <Input
        id={REGISTER_PASSWORD_CONFIRM_KEY}
        label="Confirm password"
        inputMode="text"
        type="password"
        className="xl:w-[29.25rem]"
        placeholder="Enter password"
        {...getInputProps(REGISTER_PASSWORD_CONFIRM_KEY)}
        error={errors[REGISTER_PASSWORD_CONFIRM_KEY]}
      />

      <Button
        variant="purple"
        type="submit"
        className="w-full mt-10"
        isDisabled={!isValid || isPending}
        isLoading={isPending}
      >
        Continue
      </Button>
    </form>
  );
};

export default SetPasswordForm;
