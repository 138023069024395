import { alpha, styled, Switch } from '@mui/material';
import Button from 'components/Buttons/Button';
import { notifyError } from 'components/error/notifyError';
import DynamicInput from 'components/Inputs/dynamicInput';
import useDisclosure from 'hooks/useDisclosure';
import {
  SEND_FROM_AMOUNT_KEY,
  SEND_FROM_CURRENCY_KEY,
  SEND_PAYMENT_METHOD_KEY,
  SEND_TO_AMOUNT_KEY,
  SEND_TO_CURRENCY_KEY,
} from 'lib/constants/accounts/send';
import {
  useGetFormattedExchangeRate,
  useGetSendBeneficiaryDynamicInputs,
  useGetSendFees,
  useGetSendPaymentMethods,
} from 'lib/utils';
import { ChevronLeft } from 'lucide-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ACCOUNTS_SEND_ROUTE } from 'routes/path';
import { useGetWallets } from 'services/wallet';
import { useAppDispatch, useAppSelector } from 'store';
import {
  setFinalizeSendPage,
  setSaveToBeneficiary,
  setSendInputValues,
} from 'store/slices/account/accountsSlice';
import {
  ACCOUNTS_SLICE_REDUCER_PATH,
  FINALIZE_SEND_PAGE,
  IS_BENEFICIARY,
  PAYMENT_METHOD_DURATION_KEY,
  SAVE_TO_BENEFICIARY,
  SEND_AMOUNT_VALUES,
  SEND_PAYMENT_INPUT_VALUES,
  SendPaymentInputValues,
} from 'store/slices/account/constants';
import {
  RECIPIENT_CURRENCY_KEY,
  WALLET_ID_KEY,
  WALLET_SLICE_REDUCER_PATH,
} from 'store/slices/wallet/constants';
import { formatAmount, removeNonDigit } from 'utils/numberFormat';
import DashboardCardLayout from 'views/dashboard/components/dashboardCardLayout';
import SubRouteLayout from 'views/dashboard/components/subRouteLayout';

import ConvertConfirmationModal from '../convert/components/convertConfirmationModal';

const FinalizeSend = () => {
  const navigate = useNavigate();
  const { data: wallets } = useGetWallets();
  const dispatch = useAppDispatch();

  const {
    close: closeConfirmationModal,
    isOpen: confirmationModalIsOpen,
    open: openConfirmationModal,
  } = useDisclosure();

  const { [WALLET_ID_KEY]: walletId, [RECIPIENT_CURRENCY_KEY]: recipientCurrency } = useAppSelector(
    (state) => state[WALLET_SLICE_REDUCER_PATH]
  );

  const activeWallet =
    walletId.length > 0 ? wallets?.data.find((x) => x.id === walletId) : wallets?.data[0];
  const exchange = useGetFormattedExchangeRate(
    activeWallet?.type as string,
    recipientCurrency as string
  );

  const sendPaymentInputValues: SendPaymentInputValues = useAppSelector(
    (state) => state[ACCOUNTS_SLICE_REDUCER_PATH][SEND_PAYMENT_INPUT_VALUES]
  );

  const {
    [SEND_AMOUNT_VALUES]: sendAmountValues,
    [PAYMENT_METHOD_DURATION_KEY]: duration,
    [FINALIZE_SEND_PAGE]: page,
    [SAVE_TO_BENEFICIARY]: saveToBeneficiary,
    [IS_BENEFICIARY]: isBeneficiary,
  } = useAppSelector((state) => state[ACCOUNTS_SLICE_REDUCER_PATH]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSaveToBeneficiary(event.target.checked));
  };

  const { [SEND_PAYMENT_METHOD_KEY]: sendPaymentMethod } = useAppSelector(
    (state) => state[ACCOUNTS_SLICE_REDUCER_PATH]
  );

  const sendFees = useGetSendFees();
  const percentage = sendFees && sendFees.fee.percentage;
  const flat = sendFees && sendFees.fee.flat;

  const fee =
    (Number(removeNonDigit(String(sendAmountValues[SEND_TO_AMOUNT_KEY]))) * Number(percentage)) /
      100 +
    flat;

  const sendPaymentMethods = useGetSendPaymentMethods();

  const activeSendPaymentMethod = sendPaymentMethods?.find((x) => x.id === sendPaymentMethod);

  useEffect(() => {
    !sendPaymentMethod && navigate(ACCOUNTS_SEND_ROUTE);
    if (page === 0) {
      dispatch(setSendInputValues({}));
    }
  }, [dispatch, navigate, page, sendPaymentMethod]);

  const fields = useGetSendBeneficiaryDynamicInputs(recipientCurrency, sendPaymentMethod);

  const CustomSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: '#7049F7',
      '&:hover': {
        backgroundColor: alpha('#7049F7', theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#7049F7',
    },
  }));

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // const visibleFields = fields?.filter((item) => !item.hide_on_submit);
    const requiredFields = fields?.filter((item) => item.required && !item.pair);
    if (requiredFields?.every((item) => item.id in sendPaymentInputValues)) {
      dispatch(setFinalizeSendPage(1));
    } else {
      notifyError('Kindly input necessary details');
    }
  };

  const handleShowFee = (fee: string | number, currency?: string) => {
    if (fee === 0 || fee === '0' || fee === '0.00') {
      return 'Free';
    } else {
      return currency + ' ' + formatAmount(String(fee));
    }
  };

  const recipientValue =
    activeWallet?.type !== recipientCurrency && fee
      ? formatAmount(String(Number(sendAmountValues[SEND_TO_AMOUNT_KEY]) - fee))
      : formatAmount(String(sendAmountValues[SEND_TO_AMOUNT_KEY]));

  const fromValue =
    activeWallet?.type !== recipientCurrency
      ? formatAmount(String(sendAmountValues[SEND_FROM_AMOUNT_KEY]))
      : formatAmount(String(Number(sendAmountValues[SEND_FROM_AMOUNT_KEY]) + fee));

  return (
    <div>
      {!page && (
        <SubRouteLayout header={activeSendPaymentMethod?.name as string}>
          <div className="pb-20 xl:-ml-[4.688rem] w-full max-w-[52rem]">
            <div className="xl:flex items-start xl:space-x-11 w-full">
              <button
                onClick={() => navigate(-1)}
                className="flex space-x-1.5 xl:space-x-3 items-center"
              >
                <ChevronLeft className="w-3 h-3 xl:w-6 xl:h-6" />
                <span className="text-xs xl:text-base">Back</span>
              </button>

              <div className="w-full mt-5 xl:mt-0 space-y-10">
                <div className="flex justify-between w-full">
                  <div>
                    <p className="text-xl xl:text-2xl font-medium">Recipient details</p>
                    <p className="mt-1 xl:mt-2 font-medium text-xs xl:text-lg text-grey-text">
                      Please enter the details of the recipient
                    </p>
                  </div>
                </div>

                <form className="space-y-20" onSubmit={handleSubmit}>
                  <DashboardCardLayout className="xl:max-w-full">
                    <div>
                      {fields &&
                        fields.map((field) => (
                          <DynamicInput
                            id={field.id}
                            key={field.id}
                            label={field.label}
                            disabled={field.disabled}
                            hiddenState={field.hidden}
                            maxLength={field.limit}
                            placeholder={field.place_holder}
                            dynamicVariant={
                              field.type === 'list'
                                ? 'select'
                                : field.type === 'phone'
                                ? 'phone'
                                : 'input'
                            }
                            field={field}
                          />
                        ))}
                    </div>

                    <div className="w-full flex justify-between">
                      <p className="text-sm md:text-base">Save as beneficiary</p>
                      <CustomSwitch checked={saveToBeneficiary} onChange={handleChange} />
                    </div>
                  </DashboardCardLayout>

                  <Button variant="purple" type="submit" className="w-full max-w-[52rem]">
                    Proceed
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </SubRouteLayout>
      )}

      {page === 1 && (
        <SubRouteLayout header={activeSendPaymentMethod?.name as string}>
          <div className="pb-20 xl:-ml-[4.688rem] w-full max-w-[52rem]">
            <div className="xl:flex items-start xl:space-x-11 w-full">
              <button
                onClick={() => {
                  if (isBeneficiary) {
                    navigate(-1);
                    dispatch(setSendInputValues({}));
                  } else {
                    dispatch(setFinalizeSendPage(0));
                  }
                }}
                className="flex space-x-1.5 xl:space-x-3 items-center"
              >
                <ChevronLeft className="w-3 h-3 xl:w-6 xl:h-6" />
                <span className="text-xs xl:text-base">Back</span>
              </button>

              <div className="relative mt-5 xl:mt-0 space-y-5 flex flex-col w-full max-w-[37.5rem]">
                <p className="text-xl font-medium">Review transaction</p>
                <DashboardCardLayout className="w-full space-y-5">
                  <p className="text-grey-text font-bold text-base">Recipient</p>
                  {sendPaymentInputValues.account_number && (
                    <div className="flex justify-between">
                      <p className="text-grey-text text-sm md:text-base">Account number</p>
                      <p className="text-xs font-medium md:text-base">
                        {sendPaymentInputValues.account_number}
                      </p>
                    </div>
                  )}
                  {sendPaymentInputValues.asset && (
                    <div className="flex justify-between">
                      <p className="text-grey-text text-sm md:text-base">Asset</p>
                      <p className="text-xs font-medium md:text-base">
                        {sendPaymentInputValues.asset}
                      </p>
                    </div>
                  )}
                  {sendPaymentInputValues.network && (
                    <div className="flex justify-between">
                      <p className="text-grey-text text-sm md:text-base">Network</p>
                      <p className="text-xs font-medium md:text-base">
                        {sendPaymentInputValues.network}
                      </p>
                    </div>
                  )}
                  {sendPaymentInputValues.address && (
                    <div className="flex justify-between">
                      <p className="text-grey-text text-sm md:text-base">Address</p>
                      <p className="text-xs font-medium md:text-base">
                        {sendPaymentInputValues.address}
                      </p>
                    </div>
                  )}
                  {sendPaymentInputValues.revtag && (
                    <div className="flex justify-between">
                      <p className="text-grey-text text-sm md:text-base">Revolut Tag</p>
                      <p className="text-xs font-medium md:text-base">
                        {sendPaymentInputValues.revtag}
                      </p>
                    </div>
                  )}
                  {sendPaymentInputValues.phone_number && (
                    <div className="flex justify-between">
                      <p className="text-grey-text text-sm md:text-base">Phone number</p>
                      <p className="text-xs font-medium md:text-base">
                        {sendPaymentInputValues.phone_number}
                      </p>
                    </div>
                  )}
                  {sendPaymentInputValues.tag_id && (
                    <div className="flex justify-between">
                      <p className="text-grey-text text-sm md:text-base">Nova Tag</p>
                      <p className="text-xs font-medium md:text-base">
                        @{sendPaymentInputValues.tag_id}
                      </p>
                    </div>
                  )}
                  {sendPaymentInputValues.institution_number && (
                    <div className="flex justify-between">
                      <p className="text-grey-text text-sm md:text-base">Institution number</p>
                      <p className="text-xs font-medium md:text-base">
                        {sendPaymentInputValues.institution_number}
                      </p>
                    </div>
                  )}
                  {sendPaymentInputValues.transit_number && (
                    <div className="flex justify-between">
                      <p className="text-grey-text text-sm md:text-base">Transit number</p>
                      <p className="text-xs font-medium md:text-base">
                        {sendPaymentInputValues.transit_number}
                      </p>
                    </div>
                  )}
                  {sendPaymentInputValues.iban && (
                    <div className="flex justify-between">
                      <p className="text-grey-text text-sm md:text-base">IBAN</p>
                      <p className="text-xs font-medium md:text-base">
                        {sendPaymentInputValues.iban}
                      </p>
                    </div>
                  )}
                  {sendPaymentInputValues.swift_code && (
                    <div className="flex justify-between">
                      <p className="text-grey-text text-sm md:text-base">BIC / SWIFT</p>
                      <p className="text-xs font-medium md:text-base">
                        {sendPaymentInputValues.swift_code}
                      </p>
                    </div>
                  )}
                  {sendPaymentInputValues.bank_name && (
                    <div className="flex justify-between">
                      <p className="text-grey-text text-sm md:text-base">Bank name</p>
                      <p className="text-xs font-medium max-w-[65%] md:max-w-[70%] truncate  md:text-base">
                        {sendPaymentInputValues.bank_name}
                      </p>
                    </div>
                  )}
                  {sendPaymentInputValues.account_name && (
                    <div className="flex justify-between">
                      <p className="text-grey-text text-sm  md:text-base">Account name</p>
                      <p className="text-xs font-medium max-w-[65%] md:max-w-[70%] truncate md:text-base">
                        {sendPaymentInputValues.account_name}
                      </p>
                    </div>
                  )}
                  {sendPaymentInputValues.bank_code && (
                    <div className="flex justify-between">
                      <p className="text-grey-text text-sm md:text-base">Bank code</p>
                      <p className="text-xs font-medium md:text-base">
                        {sendPaymentInputValues.bank_code}
                      </p>
                    </div>
                  )}
                  {sendPaymentInputValues.branch_name && (
                    <div className="flex justify-between">
                      <p className="text-grey-text text-sm md:text-base">Branch Name</p>
                      <p className="text-xs font-medium md:text-base">
                        {sendPaymentInputValues.branch_name}
                      </p>
                    </div>
                  )}
                  {sendPaymentInputValues.branch_code && (
                    <div className="flex justify-between">
                      <p className="text-grey-text text-sm md:text-base">Branch Code</p>
                      <p className="text-xs font-medium md:text-base">
                        {sendPaymentInputValues.branch_code}
                      </p>
                    </div>
                  )}
                  {sendPaymentInputValues.bsb_code && (
                    <div className="flex justify-between">
                      <p className="text-grey-text text-sm  md:text-base">BSB code</p>
                      <p className="text-xs font-medium max-w-[65%] md:max-w-[70%] truncate md:text-base">
                        {sendPaymentInputValues.bsb_code}
                      </p>
                    </div>
                  )}
                  {sendPaymentInputValues.beneficiary_name && (
                    <div className="flex justify-between">
                      <p className="text-grey-text text-sm  md:text-base">Beneficiary name</p>
                      <p className="text-xs font-medium max-w-[65%] md:max-w-[70%] truncate md:text-base">
                        {sendPaymentInputValues.beneficiary_name}
                      </p>
                    </div>
                  )}
                </DashboardCardLayout>

                <DashboardCardLayout className="xl:max-w-full space-y-5">
                  <div className="flex justify-between">
                    <p className="text-grey-text text-sm md:text-base">Exchange Rate</p>
                    <p className="text-xs font-medium md:text-base">{exchange}</p>
                  </div>
                  <div className="flex justify-between">
                    <p className="text-grey-text text-sm md:text-base">
                      Fee ({percentage ? percentage : 0}%{' '}
                      {flat > 0 && `+ ${flat}${recipientCurrency.toUpperCase()}`})
                    </p>
                    <p className="text-xs font-medium md:text-base">
                      {isNaN(fee)
                        ? handleShowFee(0)
                        : handleShowFee(fee, recipientCurrency.toUpperCase())}
                    </p>
                  </div>
                  <div className="flex justify-between">
                    <p className="text-grey-text text-sm md:text-base">Estimated arrival</p>
                    <p className="text-xs font-medium md:text-base">{duration || 'Instant'}</p>
                  </div>
                </DashboardCardLayout>

                <DashboardCardLayout className="xl:max-w-full space-y-5">
                  <div className="flex justify-between">
                    <p className="text-grey-text text-sm md:text-base">You're sending</p>
                    <p className="text-xs font-medium md:text-base">
                      {activeWallet?.type.toUpperCase()} {fromValue || formatAmount('0')}
                    </p>
                  </div>
                  <div className="flex justify-between">
                    <p className="text-grey-text text-sm md:text-base">They'll reveive</p>
                    <p className="text-xs font-medium md:text-base">
                      {recipientCurrency.toUpperCase()} {recipientValue || formatAmount('0')}
                    </p>
                  </div>
                </DashboardCardLayout>

                <Button
                  variant="purple"
                  onClick={openConfirmationModal}
                  className="absolute -bottom-20 w-full"
                >
                  Proceed
                </Button>
              </div>
            </div>
          </div>
        </SubRouteLayout>
      )}
      <ConvertConfirmationModal
        type="send"
        close={closeConfirmationModal}
        fromAmount={String(sendAmountValues[SEND_FROM_AMOUNT_KEY])}
        fromCurrency={sendAmountValues[SEND_FROM_CURRENCY_KEY]}
        isOpen={confirmationModalIsOpen}
        toAmount={String(sendAmountValues[SEND_TO_AMOUNT_KEY])}
        toCurrency={sendAmountValues[SEND_TO_CURRENCY_KEY]}
      />
    </div>
  );
};

export default FinalizeSend;
