import {
  VERIFY_UPLOAD_ADDITIONAL_FIELDS_KEY,
  VERIFY_UPLOAD_COUNTRY_CODE_KEY,
  VERIFY_UPLOAD_FRONT_DOC_KEY,
  VERIFY_UPLOAD_ID_TYPE_KEY,
  VERIFY_UPLOAD_IS_BIOMETRIC_ENABLED_KEY,
  VERIFY_UPLOAD_PROVIDER_KEY,
  VERIFY_UPLOAD_SELFIE_ID_KEY,
} from 'lib/constants/kyc/accountVerification';
import { NOVA_USER_VERIFICATION_KEY } from 'services/constants';
import cookie from 'services/cookie';
import {
  SELFIE_SESSION_ID_KEY,
  VERIFICATION_VARIANT_KEY,
} from 'store/slices/verification/constants';

export const useGetInitialValues = () => {
  const verificationCache = cookie.get(NOVA_USER_VERIFICATION_KEY);
  const variant = verificationCache[VERIFICATION_VARIANT_KEY];
  if (verificationCache) {
    if (!variant || variant === '0') {
      return {
        [VERIFY_UPLOAD_ID_TYPE_KEY]: verificationCache[VERIFY_UPLOAD_ID_TYPE_KEY] ?? '',
        [VERIFY_UPLOAD_IS_BIOMETRIC_ENABLED_KEY]:
          verificationCache[VERIFY_UPLOAD_IS_BIOMETRIC_ENABLED_KEY] ?? false,
        [VERIFY_UPLOAD_FRONT_DOC_KEY]:
          window.localStorage.getItem(VERIFY_UPLOAD_FRONT_DOC_KEY) ?? '',
        [VERIFY_UPLOAD_SELFIE_ID_KEY]: verificationCache[SELFIE_SESSION_ID_KEY] ?? '',
        [VERIFY_UPLOAD_PROVIDER_KEY]: verificationCache[VERIFY_UPLOAD_PROVIDER_KEY] ?? '',
        [VERIFY_UPLOAD_COUNTRY_CODE_KEY]: verificationCache[VERIFY_UPLOAD_COUNTRY_CODE_KEY] ?? '',
        [VERIFY_UPLOAD_ADDITIONAL_FIELDS_KEY]:
          verificationCache[VERIFY_UPLOAD_ADDITIONAL_FIELDS_KEY],
      };
    } else {
      return {
        [VERIFY_UPLOAD_ID_TYPE_KEY]: verificationCache[VERIFY_UPLOAD_ID_TYPE_KEY] ?? '',
        [VERIFY_UPLOAD_IS_BIOMETRIC_ENABLED_KEY]:
          verificationCache[VERIFY_UPLOAD_IS_BIOMETRIC_ENABLED_KEY] ?? false,
        [VERIFY_UPLOAD_FRONT_DOC_KEY]:
          window.localStorage.getItem(VERIFY_UPLOAD_FRONT_DOC_KEY) ?? '',
        [VERIFY_UPLOAD_SELFIE_ID_KEY]: verificationCache[SELFIE_SESSION_ID_KEY] ?? '',
        [VERIFY_UPLOAD_PROVIDER_KEY]: verificationCache[VERIFY_UPLOAD_PROVIDER_KEY] ?? '',
        [VERIFY_UPLOAD_COUNTRY_CODE_KEY]: verificationCache[VERIFY_UPLOAD_COUNTRY_CODE_KEY] ?? '',
        [VERIFY_UPLOAD_ADDITIONAL_FIELDS_KEY]:
          verificationCache[VERIFY_UPLOAD_ADDITIONAL_FIELDS_KEY],
      };
    }
  } else {
    return {
      [VERIFY_UPLOAD_ID_TYPE_KEY]: '',
      [VERIFY_UPLOAD_IS_BIOMETRIC_ENABLED_KEY]: false,
      [VERIFY_UPLOAD_FRONT_DOC_KEY]: window.localStorage.getItem(VERIFY_UPLOAD_FRONT_DOC_KEY) ?? '',
      [VERIFY_UPLOAD_SELFIE_ID_KEY]: verificationCache[SELFIE_SESSION_ID_KEY] ?? '',
      [VERIFY_UPLOAD_PROVIDER_KEY]: '',
      [VERIFY_UPLOAD_COUNTRY_CODE_KEY]: '',
      [VERIFY_UPLOAD_ADDITIONAL_FIELDS_KEY]: null,
    };
  }
};
