import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  COMING_SOON_MODAL_IS_OPEN,
  LAYOUT_SLICE_REDUCER_PATH,
  layoutSliceInitialState,
} from './constants';

const layoutSlice = createSlice({
  name: LAYOUT_SLICE_REDUCER_PATH,
  initialState: layoutSliceInitialState,
  reducers: {
    setLayoutPageLoader: (state, { payload }: PayloadAction<boolean>) => {
      state.pageLoader = payload;
    },
    setSideBarIsCollapsed: (state, { payload }: PayloadAction<boolean>) => {
      state.sideBarIsCollapsed = payload;
    },
    setMobileSidebarIsCollapsed: (state, { payload }: PayloadAction<boolean>) => {
      state.mobileSideBarIsCollapsed = payload;
    },
    setComingSoonModalOpen: (state, { payload }: PayloadAction<boolean>) => {
      state[COMING_SOON_MODAL_IS_OPEN] = payload;
    },
  },
});

export const {
  setLayoutPageLoader,
  setSideBarIsCollapsed,
  setMobileSidebarIsCollapsed,
  setComingSoonModalOpen,
} = layoutSlice.actions;

export const layoutReducer = layoutSlice.reducer;
