import { notifyError } from 'components/error/notifyError';
import { useFormik } from 'formik';
import {
  SUMSUB_PROVIDER_KEY,
  VERIFY_ACCOUNT_COUNTRY_KEY,
  VERIFY_ACCOUNT_IP_ADDRESS_KEY,
  VERIFY_ACCOUNT_ISSUER_COUNTRY_KEY,
  VERIFY_ACCOUNT_PROVIDER_KEY,
  VERIFY_UPLOAD_ID_TYPE_KEY,
  VERIFY_UPLOAD_IS_BIOMETRIC_ENABLED_KEY,
} from 'lib/constants/kyc/accountVerification';
import { SELFIE_URL_PATH } from 'lib/constants/selfie';
import { allCountries } from 'lib/countries/countries';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ID_VERIFICATION_ROUTE, SUMSUB_ROUTE } from 'routes/path';
import { NOVA_USER_VERIFICATION_KEY } from 'services/constants';
import cookie from 'services/cookie';
import { useAccountVerify, useInitiateSelfie } from 'services/kyc';
import { useAppDispatch, useAppSelector } from 'store';
import {
  SELFIE_SESSION_ID_KEY,
  VERIFICATION_DOCUMENT_PROVIDER_KEY,
  VERIFICATION_SELECTED_DOC,
  VERIFICATION_VALUES,
} from 'store/slices/verification/constants';
import { setVerificationValues } from 'store/slices/verification/verificationSlice';
import { NovaUserVerification } from 'types/auth';
import { object, string } from 'yup';

export default function useChooseDocumentForm() {
  const {
    error: accountVerifyError,
    isError: isAccountVerifyError,
    isPending: isAccountVerifyPending,
    mutate,
  } = useAccountVerify();

  const verificationCache: NovaUserVerification = cookie.get(NOVA_USER_VERIFICATION_KEY);

  const {
    [VERIFICATION_VALUES]: verificationValues,
    [VERIFICATION_DOCUMENT_PROVIDER_KEY]: provider,
    [VERIFICATION_SELECTED_DOC]: selectedDoc,
  } = useAppSelector((state) => state.verification);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data: initiateSelfie } = useInitiateSelfie();

  const country = allCountries.find((x) => {
    if (verificationCache && verificationCache[VERIFY_ACCOUNT_COUNTRY_KEY]) {
      return x.name.common === verificationCache[VERIFY_ACCOUNT_COUNTRY_KEY];
    } else {
      return x.name.common === verificationValues[VERIFY_ACCOUNT_COUNTRY_KEY];
    }
  });

  const memoizedInitialValues = useMemo(() => {
    return {
      [VERIFY_ACCOUNT_IP_ADDRESS_KEY]: '',
      [VERIFY_ACCOUNT_PROVIDER_KEY]: '',
      [VERIFY_ACCOUNT_ISSUER_COUNTRY_KEY]:
        verificationCache && verificationCache[VERIFY_ACCOUNT_ISSUER_COUNTRY_KEY]
          ? verificationCache[VERIFY_ACCOUNT_ISSUER_COUNTRY_KEY]
          : verificationValues[VERIFY_ACCOUNT_ISSUER_COUNTRY_KEY].length > 0
          ? verificationValues[VERIFY_ACCOUNT_ISSUER_COUNTRY_KEY]
          : country && country.cca2
          ? country.cca2
          : '',
    };
  }, [country, verificationCache, verificationValues]);

  const formik = useFormik({
    initialValues: memoizedInitialValues,
    onSubmit: async (values) => {
      if (provider.length && verificationCache) {
        const newValues = {
          ...verificationCache,
          ...values,
          [VERIFY_ACCOUNT_PROVIDER_KEY]: provider,
          [VERIFY_UPLOAD_ID_TYPE_KEY]: selectedDoc.id,
          [VERIFY_UPLOAD_IS_BIOMETRIC_ENABLED_KEY]: true,
        };
        const noBiometricsNewValues = {
          ...verificationCache,
          ...values,
          [VERIFY_ACCOUNT_PROVIDER_KEY]: provider,
          [VERIFY_UPLOAD_ID_TYPE_KEY]: selectedDoc.id,
          [VERIFY_UPLOAD_IS_BIOMETRIC_ENABLED_KEY]: false,
        };
        selectedDoc.is_biometric_enabled
          ? cookie.setWithExpiry(
              NOVA_USER_VERIFICATION_KEY,
              {
                ...cookie.get(NOVA_USER_VERIFICATION_KEY),
                ...newValues,
              },
              600
            )
          : cookie.setWithExpiry(
              NOVA_USER_VERIFICATION_KEY,
              {
                ...cookie.get(NOVA_USER_VERIFICATION_KEY),
                ...noBiometricsNewValues,
              },
              600
            );
        const valuesToPass = selectedDoc.is_biometric_enabled ? newValues : noBiometricsNewValues;
        valuesToPass &&
          mutate(valuesToPass, {
            onSuccess: (res) => {
              dispatch(setVerificationValues({ ...verificationValues, ...values }));
              if (provider !== SUMSUB_PROVIDER_KEY) {
                if (selectedDoc.fields && selectedDoc.fields.length > 0) {
                  navigate(ID_VERIFICATION_ROUTE(selectedDoc.id));
                } else if (initiateSelfie) {
                  cookie.setWithExpiry(
                    NOVA_USER_VERIFICATION_KEY,
                    {
                      ...cookie.get(NOVA_USER_VERIFICATION_KEY),
                      [SELFIE_SESSION_ID_KEY]: initiateSelfie.data,
                    },
                    600
                  );
                  window.open(
                    SELFIE_URL_PATH(
                      initiateSelfie?.data,
                      import.meta.env.VITE_PLATNOVA_WEB_APP_URL
                    ),
                    '_self'
                  );
                }
              } else {
                const newValues = {
                  ...verificationCache,
                  ...values,
                  [VERIFY_ACCOUNT_PROVIDER_KEY]: provider,
                  [VERIFY_UPLOAD_ID_TYPE_KEY]: selectedDoc.id,
                  [VERIFY_UPLOAD_IS_BIOMETRIC_ENABLED_KEY]: false,
                };
                cookie.setWithExpiry(
                  NOVA_USER_VERIFICATION_KEY,
                  {
                    ...cookie.get(NOVA_USER_VERIFICATION_KEY),
                    sumSub: res.data.token,
                    ...newValues,
                  },
                  600
                );
                navigate(SUMSUB_ROUTE);
              }
              // else {
              //   window.open(SELFIE_URL_PATH(res.data.token, import.meta.env.VITE_PLATNOVA_WEB_APP_URL), 'self')
              // }
            },
          });
      } else {
        notifyError('Select a document type');
      }
    },
    validationSchema: object().shape({
      [VERIFY_ACCOUNT_IP_ADDRESS_KEY]: string().required(),
      [VERIFY_ACCOUNT_ISSUER_COUNTRY_KEY]: string().required('Select a country'),
    }),
  });

  function getInputProps(id: keyof typeof formik.values) {
    return {
      ...formik.getFieldProps(id),
      ...formik.getFieldMeta(id),
      ...formik.getFieldHelpers(id),
      ...formik.errors,
    };
  }

  return Object.freeze({
    onSubmit: formik.handleSubmit,
    getInputProps,
    setValue: formik.setFieldValue,
    isValid: formik.isValid,
    errors: formik.errors,
    formValues: formik.values,
    isAccountVerifyError,
    isAccountVerifyPending,
    accountVerifyError,
  });
}
