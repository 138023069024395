import { ArrowRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { VERIFICATION_ROUTE } from 'routes/path';
import { useGetAccount } from 'services/account';
import { registerKeys } from 'services/auth/register/key';

import Button from './Buttons/Button';
import { ProgressRing } from './progress-ring';

const AccountActivationBanner = () => {
  const navigate = useNavigate();

  const { data: account, isPending: isLoadingAccount } = useGetAccount();

  const handleNavigate = () => {
    navigate(VERIFICATION_ROUTE);
    localStorage.removeItem(registerKeys.IS_FIRST_LOGIN_KEY);
  };
  return (
    account &&
    !isLoadingAccount && (
      <div className=" bg-info-blue rounded-lg p-4 w-full xl:w-[37.5rem] flex justify-between">
        <div className="space-y-3">
          <div className="text-blue-text space-y-1">
            <p className="font-medium text-sm md:text-base">
              {account.data.verification_message
                ? account.data.verification_message
                : 'Complete your account activation'}
            </p>
            <p className="opacity-70 text-[0.625rem] md:text-xs">
              Complete your verification to access all our features.
            </p>
          </div>

          <div className="flex max-w-[10rem]">
            <button onClick={handleNavigate} className="w-full">
              <Button className="gap-2 flex items-center h-auto md:h-auto text-blue-text">
                <span className="text-[0.625rem] md:text-xs">Get Started</span>
                <span>
                  <ArrowRight size={13} />
                </span>
              </Button>
            </button>
          </div>
        </div>

        <div className="flex">
          <ProgressRing progress={60} stroke={6} radius={35} />
        </div>
      </div>
    )
  );
};

export default AccountActivationBanner;
