import { createElement } from 'react';
import { AppRouteConfig } from 'types/navigation';
import Verification from 'views/verification';
import IdVerification from 'views/verification/idVerification';
import Error from 'views/verification/selfie/error';
import Selfie from 'views/verification/selfie/selfie';
import Success from 'views/verification/selfie/success';
import Sumsub from 'views/verification/selfie/sumsub';
import UploadDocument from 'views/verification/uploadDocument';

import {
  ID_VERIFICATION_ROUTE,
  SELFIE_ERROR_ROUTE,
  SELFIE_ROUTE,
  SELFIE_SUCCESS_ROUTE,
  SUMSUB_ROUTE,
  UPLOAD_DOCUMENT_ROUTE,
  VERIFICATION_ROUTE,
} from './path';

export const verificationRoutes: AppRouteConfig[] = [
  {
    path: VERIFICATION_ROUTE,
    element: createElement(Verification),
    restricted: true,
    showNav: false,
    showSideBar: false,
  },
  // {
  //   path: DOCUMENT_TYPE_ROUTE,
  //   element: createElement(ChooseDocument),
  //   restricted: true,
  //   showNav: false,
  //   showSideBar: false,
  // },
  {
    path: ID_VERIFICATION_ROUTE(':id'),
    element: createElement(IdVerification),
    restricted: true,
    showNav: false,
    showSideBar: false,
  },
  {
    path: SELFIE_ERROR_ROUTE,
    element: createElement(Error),
    restricted: true,
    showNav: false,
    showSideBar: false,
  },
  {
    path: SELFIE_SUCCESS_ROUTE,
    element: createElement(Success),
    restricted: true,
    showNav: false,
    showSideBar: false,
  },
  {
    path: SELFIE_ROUTE,
    element: createElement(Selfie),
    restricted: true,
    showNav: false,
    showSideBar: false,
  },
  {
    path: UPLOAD_DOCUMENT_ROUTE,
    element: createElement(UploadDocument),
    restricted: true,
    showNav: false,
    showSideBar: false,
  },
  {
    path: SUMSUB_ROUTE,
    element: createElement(Sumsub),
    restricted: true,
    showNav: false,
    showSideBar: false,
  },
];
