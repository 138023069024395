import { REGISTER_EMAIL_KEY } from 'lib/constants/auth/register';
import { LuPencilLine } from 'react-icons/lu';
import { Link } from 'react-router-dom';
import { REGISTER_ROUTE } from 'routes/path';
import { useAppSelector } from 'store';
import { REGISTER_SLICE_REDUCER_PATH } from 'store/slices/register/constants';
import Timer from 'utils/timer';

import AccountVerificationForm from './components/accountVerificationForm';
import AuthLayout from '../layout';

const AccountVerification = () => {
  const { [REGISTER_EMAIL_KEY]: email } = useAppSelector(
    (state) => state[REGISTER_SLICE_REDUCER_PATH]
  );
  return (
    <AuthLayout>
      <div className="bg-white rounded-[10px] px-5 p-5 md:px-10 md:p-10 w-full h-full xl:h-max">
        <div className="space-y-2">
          <h1 className="text-2xl md:text-3xl font-medium">OTP Authorization</h1>
          <p className="text-sm md:text-base text-grey-text">
            Thanks for registering. We sent an OTP code to
            <br />
            <span className="font-bold">{email || ''}</span>
          </p>
        </div>
        <div className="text-sm xl:text-base mt-5 flex space-x-1">
          <p className="text-grey-text">is this email correct? </p>
          <Link to={REGISTER_ROUTE} className="text-platnova-purple font-bold flex items-center">
            Edit email <LuPencilLine />
          </Link>
        </div>

        <AccountVerificationForm />
        <div className="mt-4 md:mt-3">
          <Timer
            className="text-sm md:text-base font-semibold"
            type="register"
            time={60}
            buttonClassName="text-sm md:text-base font-semibold text-platnova-purple"
            resendEmail={email}
          />
        </div>
      </div>
    </AuthLayout>
  );
};

export default AccountVerification;
