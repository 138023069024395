import { cn } from 'lib/utils';

const RecentActivityStatus = ({ status }: { status: string }) => {
  return (
    <div
      className={cn(
        status === 'success'
          ? 'bg-[#CDF8D9] text-[#06DD42]'
          : status === 'failed' || status === 'cancelled'
          ? 'bg-error-primary/20 text-error-primary'
          : 'bg-orange-500/20 text-orange-500',
        'px-1.5 xl:px-3 py-1 text-[0.625rem] xl:text-xs rounded-[20px] mt-1 capitalize font-medium'
      )}
    >
      {status}
    </div>
  );
};

export default RecentActivityStatus;
