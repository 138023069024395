import { ChevronLeft, ChevronRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import { CHANGE_PASSWORD_ROUTE, SETTINGS_PIN_ROUTE, SETTINGS_ROUTE } from 'routes/path';
import { useGetAccount } from 'services/account';
import { useAppDispatch } from 'store';
import { setComingSoonModalOpen } from 'store/slices/layout/layoutSlice';

import DashboardCardLayout from '../components/dashboardCardLayout';
import SubRouteLayout from '../components/subRouteLayout';

const Security = () => {
  const { data: account } = useGetAccount();
  const isPinValid = account?.data.additional_info.find((x) => x.id === 'is_pin_valid')?.value;
  const dispatch = useAppDispatch();

  return (
    <SubRouteLayout header="Security">
      <div className="pb-20 w-full max-w-[52rem]">
        <div className="xl:flex items-start xl:space-x-11 w-full">
          <Link to={SETTINGS_ROUTE} className="flex space-x-1.5 xl:space-x-3 items-center">
            <ChevronLeft className="w-3 h-3 xl:w-6 xl:h-6" />
            <span className="text-xs xl:text-base">Back</span>
          </Link>

          <div className="w-full mt-5 xl:mt-0">
            <div className="flex justify-between w-full">
              <div>
                <p className="text-xl xl:text-2xl font-medium">Security</p>
                <p className="mt-1 xl:mt-2 font-medium text-xs xl:text-lg text-grey-text">
                  Manage your PIN and general account security.
                </p>
              </div>
            </div>

            <DashboardCardLayout className="mt-6 md:mt-14 py-0 xl:py-0 xl:pt-5 divide-y">
              <div className="w-full flex justify-between items-center py-5">
                <button
                  className=" text-start space-y-2"
                  onClick={() => dispatch(setComingSoonModalOpen(true))}
                >
                  <p className="text-sm md:text-xl font-medium">2FA</p>
                  <p className="text-grey-text text-xs md:text-base">
                    Manage account 2 Factor Authentication
                  </p>
                </button>
                <ChevronRight />
              </div>

              <Link
                to={CHANGE_PASSWORD_ROUTE}
                className="w-full flex justify-between items-center py-5"
              >
                <div className="space-y-2">
                  <p className="text-sm md:text-xl font-medium">Password</p>
                  <p className="text-grey-text text-xs md:text-base">Update your password</p>
                </div>
                <ChevronRight />
              </Link>

              <Link
                to={SETTINGS_PIN_ROUTE}
                className="w-full flex justify-between items-center py-5"
              >
                <div className="space-y-2">
                  <p className="text-sm md:text-xl font-medium">PIN</p>
                  <p className="text-grey-text text-xs md:text-base">
                    {!isPinValid ? 'Create your PIN' : 'Reset your pin'}
                  </p>
                </div>
                <ChevronRight />
              </Link>
            </DashboardCardLayout>
          </div>
        </div>
      </div>
    </SubRouteLayout>
  );
};

export default Security;
