import Button from 'components/Buttons/Button';
import Modal, { ModalProps } from 'components/modal';
import { useNavigate } from 'react-router-dom';
import { ACCOUNTS_DEPOSIT_ROUTE } from 'routes/path';

const InsufficientFundsModal = ({ ...props }: ModalProps) => {
  const navigate = useNavigate();
  return (
    <Modal
      {...props}
      className="py-7 px-[5%] xl:px-5 w-full max-w-md flex flex-col space-y-6 items-center text-center"
    >
      <h1 className="text-xl font-semibold">Insufficient fund</h1>
      <p className="text-base">Sorry, you don't have enough balance for this transaction.</p>

      <div className="flex flex-col space-y-6 w-full">
        <Button
          variant="purple"
          className="w-full"
          onClick={() => {
            navigate(ACCOUNTS_DEPOSIT_ROUTE);
            props.close();
          }}
        >
          Deposit
        </Button>
        <button onClick={props.close} className="text-xs md:text-sm">
          Not now
        </button>
      </div>
    </Modal>
  );
};

export default InsufficientFundsModal;
