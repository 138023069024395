/* eslint-disable @typescript-eslint/no-explicit-any */
import http from 'services/http';
import { GeneralApiResponse } from 'types';
import { VerifyBankAccountInput } from 'types/account';

import { VerifiedBankAccount } from './constants';

export const getBanks = async (countryCode: string): Promise<GeneralApiResponse<any>> => {
  const res = await http.get(`/banks/${countryCode}`);
  return res.data;
};

export const getBankBranches = async (
  countryCode: string,
  bankCode: string
): Promise<GeneralApiResponse<any>> => {
  const res = await http.get(`/banks/${countryCode}/${bankCode}`);
  return res.data;
};

export const postVerifyBankAccount = async (
  data: VerifyBankAccountInput
): Promise<GeneralApiResponse<VerifiedBankAccount>> => {
  const res = await http.post('/banks/verify', data);
  return res.data;
};

export const getCryptoNetworks = async (currency: string): Promise<GeneralApiResponse<any>> => {
  const res = await http.get(`/cryptos/networks?currency=${currency}`);
  return res.data;
};

export const getCryptos = async (): Promise<GeneralApiResponse<any>> => {
  const res = await http.get(`/cryptos?fields=name,symbol`);
  return res.data;
};
