import { useFormik } from 'formik';
import {
  FORGOT_PASSWORD_EMAIL_KEY,
  FORGOT_PASSWORD_OTP_KEY,
  RESET_PASSWORD_CONFIRM_PASSWORD_KEY,
  RESET_PASSWORD_PASSWORD_KEY,
} from 'lib/constants/auth/forgotPassword';
import { useNavigate } from 'react-router-dom';
import { LOGIN_ROUTE } from 'routes/path';
import { useResetPassword } from 'services/auth/forgotPassword';
import { useAppSelector } from 'store';
import { FORGOT_PASSWORD_SLICE_REDUCER_PATH } from 'store/slices/forgotPassword/constants';
import { object, ref, string } from 'yup';

export default function useResetPasswordForm() {
  const { mutate, error, isError, isPending } = useResetPassword();
  const navigate = useNavigate();
  const { [FORGOT_PASSWORD_EMAIL_KEY]: email, [FORGOT_PASSWORD_OTP_KEY]: token } = useAppSelector(
    (state) => state[FORGOT_PASSWORD_SLICE_REDUCER_PATH]
  );

  const formik = useFormik({
    initialValues: {
      [FORGOT_PASSWORD_EMAIL_KEY]: email,
      [RESET_PASSWORD_PASSWORD_KEY]: '',
      [RESET_PASSWORD_CONFIRM_PASSWORD_KEY]: '',
      [FORGOT_PASSWORD_OTP_KEY]: token,
    },
    onSubmit: (values) => {
      mutate(values, {
        onSuccess: () => navigate(LOGIN_ROUTE),
      });
    },
    validationSchema: object().shape({
      [RESET_PASSWORD_PASSWORD_KEY]: string().required('Password cannot be empty'),
      [RESET_PASSWORD_CONFIRM_PASSWORD_KEY]: string()
        .required('Confirm Password cannot be empty')
        .oneOf([ref(RESET_PASSWORD_PASSWORD_KEY)], 'Passwords do not match'),
    }),
  });

  function getInputProps(id: keyof typeof formik.values) {
    return {
      ...formik.getFieldProps(id),
      ...formik.getFieldMeta(id),
      ...formik.errors,
    };
  }

  return Object.freeze({
    onSubmit: formik.handleSubmit,
    getInputProps,
    setValue: formik.setFieldValue,
    isValid: formik.isValid,
    errors: formik.errors,
    resetPasswordError: error,
    isError: isError,
    isPending: isPending,
  });
}
