import http from 'services/http';
import { PaginatedApiResponse } from 'types';
import { Transaction } from 'types/transactions';

export const getTransactions = async (): Promise<PaginatedApiResponse<Transaction[]>> => {
  const res = await http.get('/transactions?size=250');
  return res.data;
};

export const generateReceipt = async (id: string): Promise<PaginatedApiResponse<object>> => {
  const res = await http.get(`/transactions/${id}/receipt`);
  return res.data;
};
