import { AnimatePresence } from 'framer-motion';
import { cn } from 'lib/utils';
import { useEffect, useId, useState } from 'react';
import ReactModal from 'react-modal';

export type ModalProps = {
  isOpen: boolean;
  close: () => void;
  children?: React.ReactNode;
  className?: string;
  onAfterClose?: () => void;
  shouldCloseOnOverlayClick?: boolean;
  shouldCloseOnEsc?: boolean;
  isToWallet?: boolean;
};
const Modal = ({
  isOpen,
  close,
  children,
  className,
  onAfterClose,
  shouldCloseOnOverlayClick = true,
  shouldCloseOnEsc = true,
}: ModalProps) => {
  const id = useId();

  const [width, setWidth] = useState(window.innerWidth >= 768 ? 'lg' : 'sm');

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth >= 768 ? 'lg' : 'sm');
    };
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <AnimatePresence>
      <ReactModal
        isOpen={isOpen}
        closeTimeoutMS={300}
        style={{
          overlay: {
            backgroundColor: '#69696933',
            zIndex: 1000,
            display: 'flex',
            backdropFilter: 'blur(5px)',
            justifyContent: 'center',
            alignItems: width === 'lg' ? 'center' : 'end',
            overflow: 'auto',
          },
          content: {
            outline: 'none',
          },
        }}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        shouldCloseOnEsc={shouldCloseOnEsc}
        shouldReturnFocusAfterClose={true}
        ariaHideApp={false}
        onRequestClose={close}
        className={cn(
          'scroll-component max-h-[75%] w-full md:w-auto md:max-h-[90%] overflow-y-auto overflow-x-hidden rounded-[10px] border-0 bg-white outline-none',
          [className && className]
        )}
        portalClassName={id}
        bodyOpenClassName={`${id}_body_open`}
        htmlOpenClassName={`${id}_html_open`}
        onAfterClose={onAfterClose}
      >
        {children}
      </ReactModal>
    </AnimatePresence>
  );
};

export default Modal;
