import { useEffect } from 'react';
import { useAppDispatch } from 'store';
import { setInsufficientFundModalIsOpen } from 'store/slices/wallet/walletSlice';
import { errorHandler } from 'utils/error';

import { notifyError } from './notifyError';

const useErrorNotification = (isError: boolean, error: unknown) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (isError && error) {
      if (errorHandler(error) === 'insufficient fund') {
        dispatch(setInsufficientFundModalIsOpen(true));
      } else {
        notifyError(errorHandler(error));
      }
    }
  }, [isError, error, dispatch]);
};

export default useErrorNotification;
